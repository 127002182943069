<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pb-1">
      <h6 class="mb-0">
        {{ $t('label.heading.columns') }}
      </h6>
    </div>

    <data-grid
      ref="clientTable"
      :fields="columnFields"
      :items="columnData"
      :total-records="columnData?.length"
      hide-entries-per-page
    >
      <template #title="{ item, rowIndex }">
        <InputText
          :id="`section-${sectionIndex}-title-${rowIndex}`"
          v-model="item.title"
          required
          vertical
          @input="(value) => formatColumnName(value, item)"
        />
      </template>
      <template #name="{ item, rowIndex }">
        <InputText
          :id="`section-${sectionIndex}-columnName-${rowIndex}`"
          v-model="item.name"
          style="max-width: 150px;"
          disabled
        />
      </template>
      <template #description="{ item, rowIndex }">
        <InputText
          :id="`section-${sectionIndex}-columnDescription-${rowIndex}`"
          v-model="item.description"
        />
      </template>
      <template #type="{ item, rowIndex }">
        <div style="min-width: 150px;">
          <InputSelect
            :id="`section-${sectionIndex}-columnType-${rowIndex}`"
            :ref="component => inputSelectRefs[rowIndex] = component"
            v-model="item.type"
            value-field="value"
            :options="columnTypeOptions"
            :disabled="!item.title || (item.id && !!dataTableNameParam)"
            :required="!!item.title"
            vertical
            append-to-body
            @input="onTypeChange(item)"
          />
        </div>
      </template>
      <template #mandatory="{ item, rowIndex }">
        <div class="mt-2">
          <InputCheckBox
            :id="`section-${sectionIndex}-columnMandatory-${rowIndex}`"
            v-model="item.mandatory"
          />
        </div>
      </template>
      <template #length="{ item, rowIndex }">
        <InputText
          v-if="item.type === 'string' || codeSupportedTypes.slice(1).includes(item.type)"
          :id="`section-${sectionIndex}-columnLength-${rowIndex}`"
          v-model="item.length"
          type="number"
          :minimum="0"
          vertical
          :step="1"
          :disabled="codeSupportedTypes.slice(1).includes(item.type)"
          required
          :rules="[numeric]"
        />
      </template>
      <template #regex="{ item, rowIndex }">
        <InputText
          v-if="regexSupportedTypes.includes(item.type)"
          :id="`section-${sectionIndex}-columnRegex-${rowIndex}`"
          v-model="item.regex"
        />
      </template>
      <template #code="{ item, rowIndex }">
        <div style="min-width: 150px;">
          <InputSelect
            v-if="codeSupportedTypes.includes(item.type)"
            :id="`section-${sectionIndex}-columnCode-${rowIndex}`"
            v-model="item.code"
            value-field="name"
            vertical
            append-to-body
            :options="codeOptions"
            required
          />
        </div>
      </template>
      <template #unmaskedSuffixLength="{ item, rowIndex }">
        <InputText
          v-if="unmaskedSuffixSupportedTypes.includes(item.type)"
          :id="`section-${sectionIndex}-columnUnmaskedSuffixLength-${rowIndex}`"
          v-model="item.unmaskedSuffixLength"
          type="number"
          :minimum="0"
          vertical
          :step="1"
          :rules="[numeric]"
        />
      </template>
      <template #dependOnColumn="{ item, rowIndex }">
        <InputSelect
          v-if="rowIndex !== 0"
          :id="`section-${sectionIndex}-columnDependOnColumn-${rowIndex}`"
          v-model="item.parentNameAttribute"
          style="min-width: 150px;"
          vertical
          append-to-body
          :options="dependOnColumnOptions"
          :disabled="!dependOnColumnOptions.length"
        />
      </template>
      <template #actions="{ item, rowIndex }">
        <i
          v-if="rowIndex !== 0"
          class="fa fa-times-circle fa-2x"
          style="cursor: pointer"
          @click="removeColumn(rowIndex, item)"
        />
      </template>
    </data-grid>

    <button
      type="button"
      class="btn btn-default text-primary"
      @click="addColumn"
    >
      <i class="fa fa-plus " />
      {{ $t('label.anchor.newColumn') }}
    </button>
  </div>
</template>

<script setup>
import DataGrid from '@/components/shared/data-grid/index.vue';
import i18n from '@/plugins/i18n';
import router from '@/router/index';
import { get, set } from '@vueuse/core';
import { ref, onMounted, watch } from 'vue';
import { InputText, InputCheckBox, InputSelect } from '@/components/shared/form-input/index';
import { columnTypeOptions, unmaskedSuffixSupportedTypes, codeSupportedTypes, sectionFieldsData, formatName, regexSupportedTypes } from '@/components/system/manage-data-tables/DataTableCommons.js';
import { numeric } from '@/components/shared/form-input/rules';

const props = defineProps({
  sectionColumns: {
    type: Array,
    default: () => ([])
  },
  sectionIndex: {
    type: Number,
    required: true
  },
  codeOptions: {
    type: Array,
    required: true
  }
});

const dependOnColumnOptions = ref([]);
const inputSelectRefs = ref([]);
const dropExistingColumns = ref([]);
const dataTableNameParam = ref(router.currentRoute.params.dataTableName);
const columnData = ref(JSON.parse(JSON.stringify(props.sectionColumns)));

watch(() => props.sectionColumns, (newVal) => {
  columnData.value = newVal;
});

const emit = defineEmits(['on-column-change', 'on-section-change']);
const columnFields = ref([
  { key: 'title', label: i18n.t('label.anchor.title') },
  { key: 'name', label: i18n.t('label.anchor.name') },
  { key: 'description', label: i18n.t('label.anchor.description') },
  { key: 'type', label: i18n.t('label.anchor.type') },
  { key: 'mandatory', label: i18n.t('label.anchor.mandatory') },
  { key: 'length', label: i18n.t('label.anchor.length') },
  { key: 'regex', label: i18n.t('label.anchor.regularExpression') },
  { key: 'code', label: 'Code' },
  { key: 'unmaskedSuffixLength', label: i18n.t('label.anchor.unmaskedSuffixLength') },
  { key: 'dependOnColumn', label: i18n.t('label.anchor.dependOnColumn') },
  { key: 'actions', label: i18n.t('label.anchor.actions') }
]);

onMounted(() => {
  get(inputSelectRefs).forEach(selectComponent => {
    if (selectComponent) selectComponent.$emit('input', selectComponent.value);
  });
  if (router.currentRoute.params.dataTableName) {
    set(columnData, get(columnData).map(column => ({ ...column, originalName: column.name, newName: column.name })));
    emit('on-column-change', { columns: get(columnData), dependOnColumnOptions: get(dependOnColumnOptions), dropExistingColumns: get(dropExistingColumns) });
  }
});

const formatColumnName = (value, item) => {
  if (get(dataTableNameParam) && item.id) return;
  formatName(value, item, 'name');
};

const addColumn = () => {
  get(columnData).push({ ...sectionFieldsData.columns[0] });
  emit('on-column-change', { columns: get(columnData), dependOnColumnOptions: get(dependOnColumnOptions), dropExistingColumns: get(dropExistingColumns) });
};

const removeColumn = (columnIndex, item) => {
  if (item.type === 'boolean') resetParentNameAttributes(item);
  get(columnData).splice(columnIndex, 1);
  if (item.id) get(dropExistingColumns).push({ id: item.id, name: item.name });
  emit('on-column-change', { columns: get(columnData), dependOnColumnOptions: get(dependOnColumnOptions), dropExistingColumns: get(dropExistingColumns) });
};

const resetParentNameAttributes = (item) => {
  if (item.type === 'boolean') {
    const columnIndex = get(dependOnColumnOptions).indexOf(item.name);
    if (columnIndex !== -1) get(dependOnColumnOptions).splice(columnIndex, 1);
    set(columnData, get(columnData).map(column => column.parentNameAttribute === item.name ? { ...column, parentNameAttribute: '' } : column));
  }
};

const onTypeChange = (item) => {
  if (!unmaskedSuffixSupportedTypes.includes(item.type)) item.unmaskedSuffixLength = '';
  if (!codeSupportedTypes.includes(item.type)) item.code = '';
  if (codeSupportedTypes.slice(1).includes(item.type)) item.length = '200';
  if (!regexSupportedTypes.includes(item.type)) item.regex = '';
  if (item.type !== 'string' && !codeSupportedTypes.slice(1).includes(item.type)) item.length = '';

  item.type === 'boolean' ? get(dependOnColumnOptions).push(item.name) : resetParentNameAttributes(item);
  emit('on-column-change', { columns: get(columnData), dependOnColumnOptions: get(dependOnColumnOptions), dropExistingColumns: get(dropExistingColumns) });
};
</script>
