export function removeEmptyFields (object) {
  return Object.fromEntries(Object.entries(object).filter(([_, v]) => v !== null && v !== '' && v !== undefined));
}

export function sortByListOfItems (array, key, list) {
  return array.sort((a, b) => {
    const aKey = a[key] ? a[key] : a;
    const bKey = b[key] ? b[key] : b;
    return list.indexOf(aKey) - list.indexOf(bKey);
  });
}
export const digitRegexToMask = (regex) => {
  if (!regex) return '';
  let mask = '';
  const sourcePattern = new RegExp(regex).source;

  const matchDigit = sourcePattern.match(/\{(\d+)(?:,(\d+))?\}/); // will match {1} or {1,2}
  if (matchDigit) {
    mask = '#'.repeat(matchDigit ? Number(matchDigit[2] || matchDigit[1]) : 0);
  }

  return regex.replace(/[^\d+]/g, '').replace(/\d+/g, mask).replace(/\\/g, ''); // Remove escaped characters
};
