<template>
  <div class="row row-cols-1 row-cols-lg-2">
    <div
      v-for="(arrayList, i) in splitList"
      :key="i"
      class="col list-group"
    >
      <template v-for="(item, index) in arrayList">
        <a
          v-if="item.enabled ?? true"
          :key="index"
          v-has-permission="item.permission || ''"
          class="list-group-item p-3"
          :href="item.href"
        >
          <h6 class="list-group-item-heading">
            <i :class="`fas ${item.icon}`" /> {{ $t(item.title) }}
          </h6>

          <div class="list-group-item-text text-muted">
            {{ $t(item.subtitle) }}
          </div>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemsList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    splitList () {
      const list = this.itemsList;
      const middleIndex = Math.ceil(list.length / 2);
      const firstHalf = list.splice(0, middleIndex);
      const secondHalf = list.splice(-middleIndex);
      return [firstHalf, secondHalf];
    }
  }
};
</script>
