import appModule from './app.modules';

appModule.config(['$routeProvider', '$locationProvider', ($routeProvider, $locationProvider) => {
  $locationProvider.html5Mode(false);
  $locationProvider.hashPrefix('');

  $routeProvider
    .when('/', { template: '<home-page></home-page>' })
    .when('/richdashboard', { template: require('./views/private/rich_dashboard.html') })
    .when('/subscribers', { template: require('./views/subscribers/subscribers.html') })
    .when('/createstandinginstruction/:officeId/:clientId/:accountType', { template: require('./views/accounttransfers/create_standinginstruction.html') })
    .when('/editstandinginstruction/:instructionId/:from', { template: require('./views/accounttransfers/edit_standinginstruction.html') })
    .when('/viewstandinginstruction/:instructionId', { template: require('./views/accounttransfers/view_standinginstruction.html') })
    .when('/liststandinginstructions/:officeId/:clientId', { template: require('./views/accounttransfers/list_standinginstruction.html') })
    .when('/listaccounttransactions/:instructionId', { template: require('./views/accounttransfers/list_transactions.html') })
    .when('/standinginstructions/history', { template: require('./views/accounttransfers/standinginstructions_history.html') })
    .when('/viewsubscriber/:id', { template: require('./views/subscribers/viewsubscriber.html') })
    .when('/createsubscriber', { template: require('./views/subscribers/createsubscriber.html') })
    .when('/editsubscriber/:id', { template: require('./views/subscribers/editsubscriber.html') })
    .when('/createloanproduct', { template: require('./views/products/createloanproduct.html') })
    .when('/createrevolvingloanproduct', { template: require('./views/products/revolvingloan/createrevolvingloanproduct.html') })
    .when('/editrevolvingloanproduct', { template: require('./views/products/revolvingloan/editrevolvingloanproduct.html') })
    .when('/editrevolvingloanproduct/:id', { template: require('./views/products/revolvingloan/editrevolvingloanproduct.html') })
    .when('/viewrevolvingloanproduct', { template: require('./views/products/revolvingloan/viewrevolvingloanproduct.html') })
    .when('/viewrevolvingloanproduct/:id', { template: require('./views/products/revolvingloan/viewrevolvingloanproduct.html') })
    .when('/editloanproduct/:id', { template: require('./views/products/editloanproduct.html') })
    .when('/createsavingproduct', { template: require('./views/products/createsavingproduct.html') })
    .when('/editsavingproduct/:id', { template: require('./views/products/editsavingproduct.html') })
    .when('/admin/viewmctasks', { template: require('./views/administration/makerchecker.html') })
    .when('/admin/users', { template: require('./views/administration/users.html') })
    .when('/client/:id/:action', { template: require('./views/clients/clientactions.html') })
    .when('/createclient', { template: require('./views/clients/createclient.html') })
    .when('/editclient/:id', { template: require('./views/clients/editclient.html') })
    .when('/viewclient/:id', { template: require('./views/clients/viewclient.html') })
    .when('/viewclient/:id/addcharge', { template: require('./views/clients/addnewclientcharge.html') })
    .when('/viewclient/:id/paycharge/:chargeid', { template: require('./views/clients/payclientcharge.html') })
    .when('/viewclient/:clientId/charges/:chargeId', { template: require('./views/clients/viewclientcharge.html') })
    .when('/viewclient/:id/chargeoverview', { template: require('./views/clients/clientchargeoverview.html') })
    .when('/viewclient/:id/limits', { template: require('./views/system/createtransferclientlimits.html') })
    .when('/viewclient/:id/limits/:limitId', { template: require('./views/system/updatetransferclientlimits.html') })
    .when('/clientscreenreport/:clientId', { template: require('./views/clients/clientscreenreport.html') })
    .when('/transferclient/:id', { template: require('./views/clients/transferclient.html') })
    .when('/listrecurringpayment/:clientId', { template: require('./views/recurringpayments/recurringpayment.html') })
    .when('/viewrecurringpayment/:clientId/:id', { template: require('./views/recurringpayments/viewrecurringpayment.html') })
    .when('/createrecurringpayment/:clientId', { template: require('./views/recurringpayments/createrecurringpayment.html') })
    .when('/editrecurringpayment/:clientId/:id', { template: require('./views/recurringpayments/editrecurringpayment.html') })
    .when('/adduserdocument/:userId', { template: require('./views/administration/adduserdocument.html') })
    .when('/edituserdocument/:userId/:documentId', { template: require('./views/administration/edituserdocument.html') })
    // .when('/clientclosedloanaccount/:clientId', {
    //   template: require('./views/clients/clientclosedloanaccount.html')
    // })
    // .when('/clientclosedsavingaccount/:clientId', {
    //   template: require('./views/clients/clientclosedsavingaccount.html')
    // })
    .when('/addclientidentifierdocument/:clientId/:resourceId', { template: require('./views/clients/addclientidentifierdocument.html') })
    .when('/newclientloanaccount/:clientId', { template: require('./views/loans/newloanaccount.html') })
    .when('/newclientrevolvingloanaccount/:clientId', { template: require('./views/loans/newrevolvingloanaccount.html') })
    .when('/newgrouploanaccount/:groupId', { template: require('./views/loans/newloanaccount.html') })
    .when('/newindividualjlgloanaccount/:groupId/:clientId', { template: require('./views/loans/newloanaccount.html') })
    .when('/newjlgloanaccount/:groupId', { template: require('./views/loans/newjlgloanaccount.html') })
    .when('/viewloanaccount/:id', { template: require('./views/loans/viewloanaccountdetails.html') })
    .when('/adjustrepaymentschedule/:accountId', { template: require('./views/loans/AdjustRepaymentSchdule.html') })
    .when('/loanaccount/:id/:action', { template: require('./views/loans/loanaccountactions.html') })
    .when('/loanaccountcharge/:id/:action/:chargeId', { template: require('./views/loans/loanaccountactions.html') })
    .when('/loandisbursedetail/:id/:action/:disbursementId', { template: require('./views/loans/loanaccountactions.html') })
    .when('/loandisbursedetail/:id/:action', { template: require('./views/loans/loanaccountactions.html') })
    .when('/editloanaccount/:id', { template: require('./views/loans/editloanaccount.html') })
    .when('/editrevolvingloanaccount/:id', { template: require('./views/loans/editrevolvingloanaccount.html') })
    .when('/loanscreenreport/:loanId', { template: require('./views/loans/loanscreenreport.html') })
    .when('/addloancharge/:id', { template: require('./views/loans/addloancharge.html') })
    .when('/addcollateral/:id', { template: require('./views/loans/addloancollateral.html') })
    .when('/loan/:loanId/editcollateral/:id', { template: require('./views/loans/editloancollateral.html') })
    .when('/loan/:loanId/viewcollateral/:id', { template: require('./views/loans/viewloancollateral.html') })
    .when('/loan/:loanId/viewcharge/:id', { template: require('./views/loans/viewloancharge.html') })
    .when('/loan/:loanId/editcharge/:id', { template: require('./views/loans/editloancharge.html') })
    .when('/assignloanofficer/:id', { template: require('./views/loans/assignloanofficer.html') })
    .when('/addloandocument/:loanId', { template: require('./views/loans/addloandocument.html') })
    .when('/viewloantrxn/:accountId/trxnId/:id', { template: require('./views/loans/view_loan_transaction.html') })
    .when('/revolvingloanproducts', { template: require('./views/products/revolvingloan/revolvingloanproducts.html') })
    .when('/cardproducts', { template: require('./views/products/cardproducts.html') })
    .when('/createcardproduct', { template: require('./views/products/createcardproduct.html') })
    .when('/charges', { template: require('./views/products/charges.html') })
    .when('/viewcharge/:id', { template: require('./views/products/viewcharge.html') })
    .when('/floatingrates', { template: require('./views/products/floatingrates/FloatingRates.html') })
    .when('/createfloatingrate', { template: require('./views/products/floatingrates/CreateFloatingRate.html') })
    .when('/viewfloatingrate/:floatingRateId', { template: require('./views/products/floatingrates/ViewFloatingRate.html') })
    .when('/editfloatingrate/:floatingRateId', { template: require('./views/products/floatingrates/EditFloatingRate.html') })
    .when('/shareproducts', { template: require('./views/products/shareproducts.html') })
    .when('/createshareproduct', { template: require('./views/products/createshareproduct.html') })
    .when('/editshareproduct/:id', { template: require('./views/products/editshareproduct.html') })
    .when('/viewshareproduct/:id', { template: require('./views/products/viewshareproduct.html') })
    .when('/cardproducts', { template: require('./views/products/cardproducts.html') })
    .when('/viewcardproduct/:id', { template: require('./views/products/viewcardproduct.html') })
    .when('/viewsavingproduct/:id', { template: require('./views/products/viewsavingproduct.html') })
    .when('/fixeddepositproducts', { template: require('./views/products/fixeddepositproducts.html') })
    .when('/viewfixeddepositproduct/:productId', { template: require('./views/products/viewfixeddepositproduct.html') })
    .when('/createfixeddepositproduct', { template: require('./views/products/createfixeddepositproduct.html') })
    .when('/editfixeddepositproduct/:productId', { template: require('./views/products/editfixeddepositproduct.html') })
    .when('/clonefixeddepositproduct/:productId', { template: require('./views/products/clonefixeddepositproduct.html') })
    .when('/recurringdepositproducts', { template: require('./views/products/recurringdepositproducts.html') })
    .when('/editcardproduct/:id', { template: require('./views/products/editcardproduct.html') })
    .when('/viewrecurringdepositproduct/:productId', { template: require('./views/products/viewrecurringdepositproduct.html') })
    .when('/createrecurringdepositproduct', { template: require('./views/products/createrecurringdepositproduct.html') })
    .when('/editrecurringdepositproduct/:productId', { template: require('./views/products/editrecurringdepositproduct.html') })
    .when('/createinterestratechart/:productId/:productName/:productType', { template: require('./views/products/createinterestratechart.html') })
    .when('/interestratecharts/:productId/:productName/:productType', { template: require('./views/products/interestratecharts.html') })
    .when('/editinterestratecharts/:chartId/:productId/:productName/:productType', { template: require('./views/products/editinterestratechart.html') })
    .when('/offices', { template: require('./views/organization/offices.html') })
    .when('/createoffice', { template: require('./views/organization/createoffice.html') })
    .when('/viewoffice/:id', { template: require('./views/organization/viewoffice.html') })
    .when('/editoffice/:id', { template: require('./views/organization/editoffice.html') })
    .when('/tasks', { template: require('./views/tasks.html') })
    .when('/currconfig', { template: require('./views/organization/currencyconfig.html') })
    .when('/search/:query', { template: require('./views/search/glresults.html') })
    .when('/viewloanproduct/:id', { template: require('./views/products/viewloanproduct.html') })
    .when('/usersetting', { template: require('./views/administration/usersettings.html') })
    .when('/employees', { template: require('./views/organization/employees.html') })
    .when('/viewemployee/:id', { template: require('./views/organization/viewemployee.html') })
    .when('/editemployee/:id', { template: require('./views/organization/editemployee.html') })
    .when('/createemployee/', { template: require('./views/organization/createemployee.html') })
    .when('/managefunds/', { template: require('./views/organization/managefunds.html') })
    .when('/accounting-coa', { template: require('./views/accounting/accounting_coa.html') })
    .when('/createglaccount', { template: require('./views/accounting/createglaccounting.html') })
    .when('/viewglaccount/:id', { template: require('./views/accounting/viewglaccounting.html') })
    .when('/editglaccount/:id', { template: require('./views/accounting/editglaccounting.html') })
    .when('/freqposting', { template: require('./views/accounting/freqposting.html') })
    .when('/viewtransactions/:transactionId', { template: require('./views/accounting/view_transactions.html') })
    .when('/journalentry', { template: require('./views/accounting/journalentry_posting.html') })
    .when('/accounts_closure', { template: require('./views/accounting/accounts_closure.html') })
    .when('/view_close_accounting/:id', { template: require('./views/accounting/view_close_accounting.html') })
    .when('/accounting_rules', { template: require('./views/accounting/accounting_rules.html') })
    .when('/viewaccrule/:id', { template: require('./views/accounting/view_acc_rule.html') })
    .when('/add_accrule', { template: require('./views/accounting/add_acc_rule.html') })
    .when('/editaccrule/:id', { template: require('./views/accounting/edit_acc_rule.html') })
    .when('/run_periodic_accrual', { template: require('./views/accounting/periodic_accrual_accounting.html') })
    .when('/openingbalances', { template: require('./views/accounting/openingbalances.html') })
    .when('/operationalAccountsMapping', { template: require('./views/accounting/operational_account_mappings.html') })
    .when('/createoperationaccountmapping', { template: require('./views/accounting/create_operational_account_mapping.html') })
    .when('/viewoperationaccountmapping/:type', { template: require('./views/accounting/view_operational_account_mapping.html') })
    .when('/editoperationaccountmapping/:type', { template: require('./views/accounting/edit_operational_account_mapping.html') })
    .when('/viewprovisioningentries', { template: require('./views/accounting/provisioning/ProvisioningEntries.html') })
    .when('/createprovisioningentries', { template: require('./views/accounting/provisioning/CreateProvisioningEntries.html') })
    .when('/viewprovisioningentry/:entryId', { template: require('./views/accounting/provisioning/ViewProvisioningEntry.html') })
    .when('/viewprovisioningjournalentry/:entryId', { template: require('./views/accounting/provisioning/ViewProvisioningJournalEntries.html') })
    .when('/viewcode/:id', { template: require('./views/system/viewcode.html') })
    .when('/datatableworkflows', { template: require('./views/system/datatableworkflows.html') })
    .when('/viewdatatableworkflow/:entityName', { template: require('./views/system/viewdatatableworkflow.html') })
    .when('/createdatatableworkflow', { template: require('./views/system/createdatatableworkflow.html') })
    .when('/editdatatableworkflow/:entityName', { template: require('./views/system/editdatatableworkflow.html') })
    .when('/makedatatableentry/:tableName/:entityId', { template: require('./views/system/makedatatableentry.html') })
    .when('/viewdatatableentry/:tableName/:entityId/:resourceId', { template: require('./views/system/viewdatatableentry.html') })
    .when('/viewsingledatatableentry/:tableName/:entityId', { template: require('./views/system/viewdatatableentry.html') })
    .when('/jobs', { template: require('./views/system/schedulerjobs.html') })
    .when('/viewschedulerjob/:id', { template: require('./views/system/viewschedulerjob.html') })
    .when('/editschedulerjob/:id', { template: require('./views/system/editschedulerjob.html') })
    .when('/viewhistory/:id', { template: require('./views/system/viewschedulerjobhistory.html') })
    .when('/hooks', { template: require('./views/system/hooks.html') })
    .when('/viewhook/:id', { template: require('./views/system/viewhook.html') })
    .when('/addhook', { template: require('./views/system/addhook.html') })
    .when('/edithook/:id', { template: require('./views/system/edithook.html') })
    .when('/entitytoentitymapping', { template: require('./views/system/entitytoentitymapping.html') })
    .when('/system/reports', { template: require('./views/system/reports.html') })
    .when('/system/viewreport/:id', { template: require('./views/system/viewreport.html') })
    .when('/accountnumberpreferences', { template: require('./views/system/accountnumberpreferences.html') })
    .when('/addaccountnumberpreferences', { template: require('./views/system/addaccountnumberpreferences.html') })
    .when('/viewaccountnumberpreferences/:id', { template: require('./views/system/viewaccountnumberpreferences.html') })
    .when('/editaccountnumberpreferences/:id', { template: require('./views/system/editaccountnumberpreferences.html') })
    .when('/holidays', { template: require('./views/organization/holidays.html') })
    .when('/createholiday', { template: require('./views/organization/createholiday.html') })
    .when('/viewholiday/:id', { template: require('./views/organization/viewholiday.html') })
    .when('/editholiday/:id', { template: require('./views/organization/editholiday.html') })
    .when('/workingdays', { template: require('./views/organization/workingdays.html') })
    .when('/passwordpreferences', { template: require('./views/organization/passwordpreferences.html') })
    .when('/viewpaymenttype/', { template: require('./views/organization/viewpaymenttype.html') })
    .when('/createPaymentType/', { template: require('./views/organization/createpaymenttype.html') })
    .when('/editPaymentType/:id', { template: require('./views/organization/editpaymenttype.html') })
    .when('/run_report/:name', { template: require('./views/reports/run_reports.html') })
    .when('/new_client_saving_application/:clientId', { template: require('./views/savings/new_saving_account_application.html') })
    .when('/new_group_saving_application/:groupId', { template: require('./views/savings/new_saving_account_application.html') })
    .when('/new_group_saving_application/:groupId/:centerEntity', { template: require('./views/savings/new_saving_account_application.html') })
    .when('/new_jlg_saving_application/:groupId/:clientId', { template: require('./views/savings/new_saving_account_application.html') })
    .when('/viewsavingaccount/:id', { template: require('./views/savings/view_saving_account_details.html') })
    .when('/savings/:savingId/viewcharge/:id', { template: require('./views/savings/viewsavingscharge.html') })
    .when('/viewonholdtransactions/:savingsId/:fromPath/:fromPathId', { template: require('./views/savings/list_onhold_transactions.html') })
    .when('/editsavingaccount/:id', { template: require('./views/savings/edit_saving_account_application.html') })
    .when('/savingaccount/:id/:action', { template: require('./views/savings/saving_account_actions.html') })
    .when('/savingaccount/:id/:action/transactions/:transactionId', { template: require('./views/savings/saving_account_actions.html') })
    .when('/savingaccountcharge/:id/:action/:chargeId', { template: require('./views/savings/saving_account_actions.html') })
    .when('/savingaccounts/:id/stoppayments', { template: require('./views/savings/add_stoppayment.html') })
    .when('/savingaccounts/:id/stoppayments/:stoppaymentId', { template: require('./views/savings/edit_stoppayment.html') })
    .when('/savingaccounts/:id/holdamount', { template: require('./views/savings/holdamountsavings.html') })
    .when('/savingaccounts/:id/releaseamount/:authorizationId', { template: require('./views/savings/releaseamountsavings.html') })
    .when('/savingaccounts/:id/settleamount/:authorizationId', { template: require('./views/savings/settleamountsavings.html') })
    .when('/viewaccounttransfers/:id', { template: require('./views/accounttransfers/view_accounttransfer.html') })
    .when('/accounttransfers/:accountType/:accountId/internal', { template: require('./views/accounttransfers/make_accounttransfer_internal.html') })
    .when('/accounttransfers/:accountType/:accountId/external/:inOrOut', { template: require('./views/accounttransfers/make_accounttransfer_external.html') })
    .when('/accounttransfers/:accountType/:accountId/client/:clientId/incomingWireTransfer', { template: require('./views/accounttransfers/incoming_wire_transfer.html') })
    .when('/accounttransfers/:accountType/:accountId/client/:clientId/createExternalWireTransfer', { template: require('./views/accounttransfers/create_external_wire_transfer.html') })
    .when('/accounttransfers/:transferId/client/:clientId/editExternalWireTransfer', { template: require('./views/accounttransfers/edit_external_wire_transfer.html') })
    .when('/accounttransfers/:transferId/viewWireTransfer', { template: require('./views/accounttransfers/view_wire_transfer.html') })
    .when('/viewsavingtrxn/:accountId/trxnId/:id', { template: require('./views/savings/view_saving_transaction.html') })
    .when('/viewcardauthorization/:cardToken/authId/:id', { template: require('./views/cards/viewcardauthorization.html') })
    .when('/viewclient/:id/transfer-options', { template: require('./views/savings/transfer-options.html') })
    .when('/newclientfixeddepositaccount/:clientId', { template: require('./views/deposits/fixed/newapplication.html') })
    .when('/viewfixeddepositaccount/:id', { template: require('./views/deposits/fixed/viewaccountdetails.html') })
    .when('/editfixeddepositaccount/:id', { template: require('./views/deposits/fixed/edit_account_application.html') })
    .when('/fixeddepositaccount/:id/charges', { template: require('./views/deposits/fixed/add_new_fixed_deposit_charge.html') })
    .when('/fixeddepositaccount/:id/:action', { template: require('./views/deposits/fixed/fixed_deposit_account_actions.html') })
    .when('/fixeddepositaccountcharge/:id/:action/:chargeId', { template: require('./views/deposits/fixed/fixed_deposit_account_actions.html') })
    .when('/viewfixeddepositaccounttrxn/:accountId/:transactionId', { template: require('./views/deposits/fixed/view_fixed_deposit_transaction.html') })
    .when('/newclientrecurringdepositaccount/:clientId', { template: require('./views/deposits/recurring/newapplication.html') })
    .when('/viewrecurringdepositaccount/:id', { template: require('./views/deposits/recurring/viewaccountdetails.html') })
    .when('/editrecurringdepositaccount/:id', { template: require('./views/deposits/recurring/edit_account_application.html') })
    .when('/recurringdepositaccount/:id/charges', { template: require('./views/deposits/recurring/add_new_recurring_deposit_charge.html') })
    .when('/recurringdepositaccount/:id/:action', { template: require('./views/deposits/recurring/recurring_deposit_account_actions.html') })
    .when('/recurringdepositaccountcharge/:id/:action/:chargeId', { template: require('./views/deposits/recurring/recurring_deposit_account_actions.html') })
    .when('/viewrecurringdepositaccounttrxn/:accountId/:transactionId', { template: require('./views/deposits/recurring/view_recurring_deposit_transaction.html') })
    .when('/addmember', { template: require('./views/clients/createclient.html') })
    .when('/createcharge', { template: require('./views/products/createcharge.html') })
    .when('/editcharge/:id', { template: require('./views/products/editcharge.html') })
    .when('/global', { template: require('./views/administration/global.html') })
    .when('/configurations/:configId/editconfig', { template: require('./views/administration/editGlobalConfiguration.html') })
    .when('/fieldconfigurations', { template: require('./views/fieldconfigurations/fieldconfigurations.html') })
    .when('/createfieldconfiguration', { template: require('./views/fieldconfigurations/createfieldconfiguration.html') })
    .when('/viewfieldconfiguration/:id', { template: require('./views/fieldconfigurations/viewfieldconfigurations.html') })
    .when('/editfieldconfiguration/:id', { template: require('./views/fieldconfigurations/editfieldconfiguration.html') })
    .when('/createfieldvalidation/:fieldConfigurationId', { template: require('./views/fieldvalidations/createfieldvalidation.html') })
    .when('/editfieldvalidation/:fieldConfigurationId/:fieldValidationId', { template: require('./views/fieldvalidations/editfieldvalidation.html') })
    .when('/productmix', { template: require('./views/products/productmix/productmix.html') })
    .when('/revolvingloan', { template: require('./views/products/revolvingloan/revolvingloanproducts.html') })
    .when('/viewproductmix/:id', { template: require('./views/products/productmix/viewproductmix.html') })
    .when('/editproductmix/:id', { template: require('./views/products/productmix/editproductmix.html') })
    .when('/addproductmix', { template: require('./views/products/productmix/addproductmix.html') })
    .when('/bulkloan', { template: require('./views/organization/bulkloan.html') })
    .when('/audit', { template: require('./views/system/audit.html') })
    .when('/accessattempts', { template: require('./views/system/accessattempts.html') })
    .when('/accessattempts/:userId', { template: require('./views/system/accessattemptlogs.html') })
    .when('/viewaudit/:id', { template: require('./views/system/viewaudit.html') })
    .when('/createclosure', { template: require('./views/accounting/createclosure.html') })
    .when('/guarantor/:id', { template: require('./views/loans/guarantor.html') })
    .when('/listguarantors/:id', { template: require('./views/loans/list_guarantor.html') })
    .when('/viewcheckerinbox/:id', { template: require('./views/system/viewcheckerinbox.html') })
    .when('/editguarantor/:id/:loanId', { template: require('./views/loans/editguarantor.html') })
    .when('/viewguarantortransactions/:savingsId/:fundingId/:fromPath/:fromPathId', { template: require('./views/savings/list_onhold_transactions.html') })
    // .when('/expertsearch', {
    //   template: require('./views/expertsearch.html')
    // })
    .when('/about', { template: require('./views/about.html') })
    .when('/profile', { template: require('./views/profile.html') })
    .when('/viewMakerCheckerTask/:commandId', { template: require('./views/system/viewMakerCheckerTask.html') })
    .when('/help', { template: require('./views/help.html') })
    .when('/checkeractionperformed', { template: require('./views/system/checkerActionPerformed.html') })
    .when('/advsearch', { template: require('./views/search/advsearch.html') })
    .when('/editfinancialactivitymapping/:mappingId', { template: require('./views/accounting/edit_financial_activity_mapping.html') })
    .when('/viewfinancialactivitymapping/:mappingId', { template: require('./views/accounting/view_financial_activity.html') })
    .when('/financialactivityaccountmappings', { template: require('./views/accounting/financial_accounting_mapping.html') })
    .when('/addfinancialmapping', { template: require('./views/accounting/add_financial_accounting_mapping.html') })
    .when('/assignsavingsofficer/:id', { template: require('./views/savings/assignsavingsofficer.html') })
    .when('/unassignsavingsofficer/:id', { template: require('./views/savings/unassignsavingsofficer.html') })
    .when('/tellers', { template: require('./views/organization/cashmgmt/tellers.html') })
    .when('/createTeller', { template: require('./views/organization/cashmgmt/createTeller.html') })
    .when('/viewtellers/:id', { template: require('./views/organization/cashmgmt/viewTeller.html') })
    .when('/tellers/:tellerId/cashiers', { template: require('./views/organization/cashmgmt/cashiersForTeller.html') })
    .when('/tellers/:tellerId/cashiers/:cashierId', { template: require('./views/organization/cashmgmt/viewCashiersForTeller.html') })
    .when('/tellers/:tellerId/cashiers/:cashierId/txns/:currencyCode', { template: require('./views/organization/cashmgmt/cashierTransactions.html') })
    .when('/tellers/:tellerId/cashiers/:cashierId/actions/:action', { template: require('./views/organization/cashmgmt/cashierFundsAllocationSettlement.html') })
    .when('/tellers/:tellerId/cashiers/:cashierId/settle', { template: require('./views/organization/cashmgmt/cashierFundsAllocationSettlement.html') })
    .when('/tellers/:tellerId/createCashierForTeller', { template: require('./views/organization/cashmgmt/createCashier.html') })
    .when('/editteller/:id', { template: require('./views/organization/cashmgmt/editTeller.html') })
    .when('/tellers/:tellerId/editcashier/:id', { template: require('./views/organization/cashmgmt/editcashier.html') })
    .when('/externalservices', { template: require('./views/administration/externalServices.html') })
    .when('/externalservices/:externalServicesType', { template: require('./views/administration/viewExternalServicesConfiguration.html') })
    .when('/externalservices/:externalServicesType/editconfig', { template: require('./views/administration/editExternalServicesConfiguration.html') })
    .when('/twofactorconfig', { template: require('./views/administration/viewTwoFactorConfig.html') })
    .when('/twofactorconfig/edit/:configType', { template: require('./views/administration/editTwoFactorConfig.html') })
    .when('/loans/:loanId/reschedule/', { template: require('./views/loans/rescheduleloans.html') })
    .when('/loans/:loanId/viewreschedulerequest/:requestId', { template: require('./views/loans/viewreschedulerequest.html') })
    .when('/loans/:loanId/approvereschedulerequest/:requestId', { template: require('./views/loans/approveloanreschedule.html') })
    .when('/loans/:loanId/rejectreschedulerequest/:requestId', { template: require('./views/loans/rejectloanreschedule.html') })
    .when('/loans/:loanId/previewloanrepaymentschedule/:requestId', { template: require('./views/loans/previewloanrepaymentschedule.html') })
    .when('/viewallprovisionings', { template: require('./views/organization/provisioning/ViewAllProvisioningCriteria.html') })
    .when('/createprovisioningcriteria', { template: require('./views/organization/provisioning/CreateProvisioningCriteria.html') })
    .when('/viewprovisioningcriteria/:criteriaId', { template: require('./views/organization/provisioning/ViewProvisioningCriteria.html') })
    .when('/editprovisioningcriteria/:criteriaId', { template: require('./views/organization/provisioning/EditProvisioningCriteria.html') })
    .when('/taxconfiguration', { template: require('./views/products/tax/TaxConfigurations.html') })
    .when('/createtaxcomponent', { template: require('./views/products/tax/CreateTaxComponent.html') })
    .when('/taxcomponents', { template: require('./views/products/tax/TaxComponents.html') })
    .when('/viewtaxcomponent/:taxComponentId', { template: require('./views/products/tax/ViewTaxComponent.html') })
    .when('/edittaxcomponent/:taxComponentId', { template: require('./views/products/tax/EditTaxComponent.html') })
    .when('/createtaxgroup', { template: require('./views/products/tax/CreateTaxGroup.html') })
    .when('/taxgroups', { template: require('./views/products/tax/TaxGroups.html') })
    .when('/viewtaxgroup/:taxGroupId', { template: require('./views/products/tax/ViewTaxGroup.html') })
    .when('/edittaxgroup/:taxGroupId', { template: require('./views/products/tax/EditTaxGroup.html') })
    .when('/createshareaccount/:clientId', { template: require('./views/shares/createshareaccount.html') })
    .when('/viewshareaccount/:id', { template: require('./views/shares/viewshareaccount.html') })
    .when('/editshareaccount/:accountId', { template: require('./views/shares/editshareaccount.html') })
    .when('/shareaccount/:accountId/:action', { template: require('./views/shares/shareaccountactions.html') })
    .when('/shareaccount/:accountId/purchasedshares/:purchasedSharesId/:action', { template: require('./views/shares/shareaccountactions.html') })
    .when('/dividends/:productId/', { template: require('./views/products/dividendlisting.html') })
    .when('/dividends/:productId/dividend/:dividendId/:status', { template: require('./views/products/viewdividends.html') })
    .when('/shareproduct/:productId/:action', { template: require('./views/products/shareproductactions.html') })
    .when('/shareproduct/:productId/:dividendId/:action', { template: require('./views/products/shareproductactions.html') })
    .when('/loanforeclosure/:id', { template: require('./views/loans/loanforeclosure.html') })
    .when('/editAddress/:addrType/:addrId/:clientId', { template: require('./views/administration/EditAddress.html') })
    .when('/entitydatatablechecks', { template: require('./views/organization/entitydatatablechecks/entitydatatablechecks.html') })
    .when('/notifications', { template: require('./views/notification/notifications.html') })
    .when('/otpprotectedoperation', { template: require('./views/otpprotectedoperations/otpprotectedoperation.html') })
    .when('/otpprotectedoperation/add', { template: require('./views/otpprotectedoperations/createotpprotectedoperation.html') })
    .when('/otpprotectedoperation/:id', { template: require('./views/otpprotectedoperations/editotpprotectedoperation.html') })
    .when('/editfamilymember/:clientId/:familyMemberId', { template: require('./views/clients/editfamilymembers.html') })
    .when('/addfamilymembers/:clientId/', { template: require('./views/clients/addfamilymembers.html') })
    .when('/bulkimportoffices', { template: require('./views/organization/bulkimport/bulkimportoffices.html') })
    .when('/bulkimportloanaccounts', { template: require('./views/organization/bulkimport/bulkimportloanaccounts.html') })
    .when('/bulkimportloanrepayments', { template: require('./views/organization/bulkimport/bulkimportloanrepayments.html') })
    .when('/bulkimportguarantor', { template: require('./views/organization/bulkimport/bulkimportguarantor.html') })
    .when('/bulkimportsavingsaccounts', { template: require('./views/organization/bulkimport/bulkimportsavingsaccounts.html') })
    .when('/bulkimportsavingsaccountstransactions', { template: require('./views/organization/bulkimport/bulkimportsavingsaccountstransactions.html') })
    .when('/bulkimportrecurringdeposits', { template: require('./views/organization/bulkimport/bulkimportrecurringdeposits.html') })
    .when('/bulkimportrecurringdepositstransactions', { template: require('./views/organization/bulkimport/bulkimportrecurringdepositstransactions.html') })
    .when('/bulkimportshareaccounts', { template: require('./views/organization/bulkimport/bulkimportshareaccounts.html') })
    .when('/bulkimportfixeddepositaccounts', { template: require('./views/organization/bulkimport/bulkimportfixeddepositaccounts.html') })
    .when('/bulkimportfixeddeposittransactions', { template: require('./views/organization/bulkimport/bulkimportfixeddeposittransactions.html') })
    .when('/bulkimportcoa', { template: require('./views/organization/bulkimport/bulkimportcoa.html') })
    .when('/bulkimportjournalentries', { template: require('./views/organization/bulkimport/bulkimportjournalentries.html') })
    .when('/bulkimportcenters', { template: require('./views/organization/bulkimport/bulkimportcenters.html') })
    .when('/bulkimportemployees', { template: require('./views/organization/bulkimport/bulkimportemployees.html') })
    .when('/bulkimportusers', { template: require('./views/organization/bulkimport/bulkimportusers.html') })
    .when('/adhocquery/', { template: require('./views/adhocquery/adhocquerylists.html') })
    .when('/createadhocquery/', { template: require('./views/adhocquery/createadhocquery.html') })
    .when('/viewadhocquery/:id', { template: require('./views/adhocquery/viewadhocquery.html') })
    .when('/editadhocquery/:id', { template: require('./views/adhocquery/editadhocquery.html') })
    // AML Rule Group List
    .when('/payments/aml/rulegroups', { template: require('./views/payments/view_rulegrouplist.html') })
    .when('/payments/aml/viewrulegroup/:id', { template: require('./views/payments/viewrulegroup.html') })
    // AML Rule Group Management
    .when('/payments/aml/rulegroups/:id', { template: require('./views/payments/view_rulegroup.html') })
    .when('/payments/aml/ncrulegroups/:id', { template: require('./views/payments/view_nc_rulegroup.html') })
    .when('/payments/aml/watchmanrulegroup/:id', { template: require('./views/payments/view_watchman_rulegroup.html') })
    .when('/payments/aml/createrulegroup', { template: require('./views/payments/create_rulegroup.html') })
    .when('/payments/aml/createncrulegroup', { template: require('./views/payments/create_nc_rulegroup.html') })
    .when('/payments/aml/editrulegroup/:id', { template: require('./views/payments/edit_rulegroup.html') })
    .when('/payments/aml/editwatchmanrulegroup/:id', { template: require('./views/payments/edit_watchman_rulegroup.html') })
    .when('/payments/aml/editncrulegroup/:id', { template: require('./views/payments/edit_nc_rulegroup.html') })
    .when('/payments/aml/namematchingrule/:id', { template: require('./views/payments/view_name_matching.html') })
    .when('/payments/aml/restrictedcountryrule/:id', { template: require('./views/payments/view_restricted_country.html') })
    .when('/payments/aml/createnamematching', { template: require('./views/payments/create_name_matching.html') })
    .when('/payments/aml/editnamematching/:id', { template: require('./views/payments/edit_name_matching.html') })
    .when('/payments/aml/createwatchmanrulegroup', { template: require('./views/payments/create_watchman_rulegroup.html') })

    // Rules
    .when('/payments/aml/rulegroups/:ruleGroupId/limitrules/:id', { template: require('./views/payments/view_limitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/nclimitrules/:id', { template: require('./views/payments/view_nc_limitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/ncPeriodlimitrules/:id', { template: require('./views/payments/view_nc_period_limitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/periodlimitrules/:id', { template: require('./views/payments/view_periodlimitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/editlimitrule/:id', { template: require('./views/payments/edit_limitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/editNclimitrule/:id', { template: require('./views/payments/edit_nc_limitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/editNcPeriodlimitrule/:id', { template: require('./views/payments/edit_nc_period_limitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/editperiodlimitrule/:id', { template: require('./views/payments/edit_periodlimitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/createlimitrule', { template: require('./views/payments/create_limitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/createnclimitrule', { template: require('./views/payments/create_nc_limitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/createncperiodlimitrule', { template: require('./views/payments/create_nc_period_limitrule.html') })
    .when('/payments/aml/rulegroups/:ruleGroupId/createperiodlimitrule', { template: require('./views/payments/create_periodlimitrule.html') })
    .when('/selfservice/createuser/:clientId', { template: require('./views/selfservice/createuser.html') })
    // Cards
    .when('/viewcard/:cardId/editSecurities', { template: require('./views/cards/editCardSecurities.html') })
    .when('/viewcard/:cardId/editLimit', { template: require('./views/cards/editCardLimit.html') })
    .when('/transfers', { template: require('./views/transfers/index.html') })
    .when('/transfers/:transferId', { template: require('./views/transfers/view_transfer.html') })
    .when('/settlements', { template: require('./views/settlements/index.html') })
    // External Card
    .when('/client/:clientId/viewexternalcard/:externalCardId', { template: require('./views/externalcards/viewExternalCardDetails.html') })

    // statements and documents
    .when('/viewsavingaccount/statementsAndDocuments/:accountId', { template: require('./views/savings/view_statements_and_documents.html') })

    // transfer limits
    .when('/payments/transfer-limits', { template: require('./views/system/viewtransferlimits.html') })
    .when('/payments/transfer-limits/create', { template: require('./views/system/createtransferlimits.html') })
    .when('/payments/transfer-limits/:id/edit', { template: require('./views/system/updatetransferlimits.html') })

    .when('/merchantcharges', { template: require('./views/system/merchant/viewMerchantCharges.html') })
    .when('/viewmerchantcharge/:id', { template: require('./views/system/merchant/viewMerchantCharge.html') })
    .when('/createmerchantcharge', { template: require('./views/system/merchant/createMerchantCharge.html') })
    .when('/editmerchantcharge/:id', { template: require('./views/system/merchant/editMerchantCharge.html') })

    .when('/client-audit/:clientId', { template: require('./views/clients/clientaudithistory.html') })
    .when('/client-deceased/:clientId', { template: require('./views/clients/deceased.html') })
    // Credit Accounts
    .when('/createcreditproduct', { template: require('./views/products/creditproducts/createcreditproduct.html') })
    .when('/applycreditcard/:clientId', { template: require('./views/creditaccount/createcreditaccount.html') })
    .when('/viewcreditproduct/:id', { template: require('./views/products/creditproducts/viewcreditproduct.html') })
    .when('/editcreditproduct/:id', { template: require('./views/products/creditproducts/editcreditproduct.html') })
    .when('/viewcreditaccount/:id', { template: require('./views/creditaccount/viewcreditaccount.html') })
    .when('/editcreditaccount/:id', { template: require('./views/creditaccount/editcreditaccount.html') })
    .when('/creditaccount/:id/:action', { template: require('./views/creditaccount/creditaccountactions.html') })
    .when('/creditaccount/:id/:action/:chargeId', { template: require('./views/creditaccount/creditaccountactions.html') })
    .when('/viewcreditcardtrxn/:cardId/trxnId/:id', { template: require('./views/creditaccount/viewcreditcardtransaction.html') })
    .when('/viewcreditcardtrxn/:cardId/trxnId/:id', { template: require('./views/creditaccount/viewcreditcardtransaction.html') })
    .when('/viewcreditcardauthorization/:cardToken/authId/:id', { template: require('./views/creditaccount/viewcreditcardauthorization.html') })
    .when('/viewcreditaccount/:cardId/billingCyclesAndStatements', { template: require('./views/creditaccount/view_billingcycles_and_statements.html') })

    .when('/viewclient/:clientId/associated-client/:id', { template: require('./views/clients/viewclient.html') })
    .when('/clients/:parentClientId/associated-client/:clientId/saving-accounts/:subAccountId', { template: require('./views/employees/view_employee_account.html') })
    .when('/clients/:clientId/saving-accounts/:accountId/prepaid-account/sub-accounts/:subAccountId', { template: require('./views/employees/view_employee_account.html') })
    .when('/savingsaccounts/:accountId/subsidiaryaccounts/:subAccountId/transactions/:txnId', { template: require('./views/employees/view_employee_transaction.html') })
    .when('/addcreditaccountdocument/:cardId', { template: require('./views/creditaccount/addcreditaccountdocument.html') })
    .otherwise({ template: require('./views/errors/404.html') });
}]);
