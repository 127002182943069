import router from '@/router/index';
export function ReportsController (scope, resourceFactory, location) {
  scope.reports = [];

  scope.routeTo = function (id) {
    location.path(`/system/viewreport/${id}`);
  };

  scope.routeToCreateReport = function () {
    router.push({ name: 'CreateReport' });
  };

  if (!scope.searchCriteria.manrep) {
    scope.searchCriteria.manrep = null;
    scope.saveSC();
  }
  scope.filterText = scope.searchCriteria.manrep || '';

  scope.onFilter = function () {
    scope.searchCriteria.manrep = scope.filterText;
    scope.saveSC();
  };

  scope.ReportsPerPage = 15;
  resourceFactory.reportsResource.getReport(function (data) {
    scope.reports = data;
  });
}

ReportsController.$inject = ['$scope', 'ResourceFactory', '$location'];
