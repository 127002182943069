<template>
  <BaseModal
    id="view-pep"
    ref="pepModal"
    hide-footer
    modal-size="lg"
  >
    <template #body>
      <FormWrapper @submit="submit">
        <div class="row">
          <input-select
            v-model="formData.input.pep_flag"
            :label="$t('label.anchor.pepFlag')"
            :options="allPepOptions.pepFlagOptions"
            value-field="name"
            class="col-sm-6"
            vertical
          />
          <input-select
            v-model="formData.input.pep_type"
            :label="$t('label.anchor.pepType')"
            :options="allPepOptions.pepTypeOptions"
            value-field="name"
            class="col-sm-6"
            vertical
          />
          <input-select
            v-model="formData.input.pep_class"
            :label="$t('label.anchor.pepClass')"
            :options="allPepOptions.pepClassOptions"
            value-field="name"
            class="col-sm-6"
            vertical
          />
          <input-select
            v-model="formData.input.pep_sub_type"
            :label="$t('label.anchor.pepSubType')"
            :options="allPepOptions.pepSubtypeOptions"
            value-field="name"
            class="col-sm-6"
            vertical
          />
          <input-select
            v-model="formData.input.source"
            :label="$t('label.anchor.source')"
            :options="allPepOptions.pepSourceOptions"
            value-field="name"
            disabled
            class="col-sm-6"
            vertical
          />
          <input-text
            v-model="formData.note"
            :label="$t('label.anchor.notes')"
            required
            vertical
            :place-holder="$t('label.receivedIntoFromBankTrustedSource')"
            class="mb-5"
          />
        </div>
        <div class="pull-right">
          <button
            type="button"
            class="btn btn-default py-1 px-4"
            @click="cancel()"
          >
            {{ $t('label.button.back') }}
          </button>
          <button
            type="submit"
            class="btn btn-primary py-1 px-4"
          >
            {{ $t('label.button.save') }}
          </button>
        </div>
      </FormWrapper>
    </template>
  </BaseModal>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { get } from '@vueuse/core';
import { InputText, FormWrapper, InputSelect } from '../../shared/form-input/index';
import BaseModal from '@/components/shared/BaseModal.vue';

const props = defineProps({
  allPepOptions: {
    type: Object,
    default: () => ({})
  },
  item: {
    type: Object,
    default: () => ({})
  }
});
const pepModal = ref(null);
const formData = reactive({
  note: '',
  name: props.item?.name,
  input: {
    pep_type: '',
    pep_class: '',
    source: 'pepcaribbean',
    pep_flag: '',
    pep_sub_type: ''
  }
});
const emit = defineEmits(['on-submit']);
const onResetForm = () => {
  Object.assign(formData, {
    note: '',
    name: props.item?.name,
    input: {
      pep_type: '',
      pep_class: '',
      source: 'pepcaribbean',
      pep_flag: '',
      pep_sub_type: ''
    }
  });
};
const cancel = () => {
  get(pepModal).hide();
  onResetForm();
};
const submit = () => {
  emit('on-submit', formData);
};

defineExpose({ onResetForm });
</script>
