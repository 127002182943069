export function EditLimitRuleController (scope, routeParams, resourceFactory, location) {
  const { ruleGroupId: ruleId, id: limitRuleId } = routeParams;
  scope.limitRule = { id: limitRuleId, ruleGroup: { id: ruleId } };
  scope.ruleGroup = {};
  scope.codeValues = [];
  scope.reservedCodeValues = [{ id: 0, name: 'ALL', clientClassification: 'ALL' }];
  scope.formData = {};
  scope.legalForms = [
    { id: '1', name: 'PERSON' },
    { id: '2', name: 'ENTITY' }
  ];

  function updateLegalForm (value) {
    scope.formData.legalForm = value;
    const legalForm = value === '1' ? 'PERSON' : 'ENTITY';
    scope.codeValues = scope.reservedCodeValues
      .filter(code => ['ALL', legalForm].includes(code.clientClassification))
      .sort((current, next) => {
        if (current.name > next.name) return 1;
        else if (current.name < next.name) return -1;
        return 0;
      });
  }

  scope.legalFormInputHandler = function (selectedLegalForm) {
    scope.clientClassification = undefined;
    updateLegalForm(selectedLegalForm);
  };

  scope.clientClassificationInputHandler = function (value) {
    scope.clientClassification = value;
  };

  resourceFactory.codeValue.get({ codeName: 'ClientClassification-PERSON' }, {}, function (data) {
    const clientPersonCodeValues = data.map(code => ({ ...code, clientClassification: 'PERSON' }));
    scope.reservedCodeValues = [...scope.reservedCodeValues, ...clientPersonCodeValues];
  });

  resourceFactory.codeValue.get({ codeName: 'ClientClassification-ENTITY' }, {}, function (data) {
    const clientEntityCodeValues = data.map(code => ({ ...code, clientClassification: 'ENTITY' }));
    scope.reservedCodeValues.push(...clientEntityCodeValues);
  });

  resourceFactory.paymentTypeResource.getAll(function (data) {
    scope.paymentTypes = [{ id: 0, name: 'ALL' }, ...data.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })];
  }, handleResponseError);

  function loadData () {
    const params = {
      amlRule: { ruleId, fields: 'id,name' },
      limitRule: { ruleId, action: 'limitrule', actionId: limitRuleId }
    };

    resourceFactory.amlRulesResource.get(params.amlRule, function (resAmlRule) {
      resourceFactory.amlRulesResource.get(params.limitRule, function (resLimitRule) {
        scope.ruleGroup = resAmlRule;
        delete resLimitRule.$promise;
        delete resLimitRule.$resolved;
        delete resLimitRule.id;
        delete resLimitRule.ruleGroupId;
        scope.formData = resLimitRule;
        scope.clientClassification = scope.formData.clientClassificationId;
        scope.formData.legalForm = resLimitRule.legalForm.toString();
        updateLegalForm(scope.formData.legalForm);
        if (!scope.formData.paymentType) scope.formData.paymentType = 0;
        scope.paymentType = scope.paymentTypes.find(paymentType => paymentType.id === scope.formData.paymentType);
        if (!scope.formData.clientClassificationId) {
          scope.clientClassification = 0;
          scope.formData.clientClassificationId = 0;
        }
      }, handleResponseError);
    }, handleResponseError);
  }

  loadData();

  scope.onTransactionalRelationshipChange = function () {
    if (scope.formData.filterByTransactionalRelationship === 'SEEN') {
      scope.formData.filterByCounterpartyStatus = 'KNOWN';
    }
  };

  scope.submit = function () {
    const params = { ruleId, action: 'limitrule', actionId: limitRuleId };
    scope.formData.paymentType = scope.paymentType.id;
    scope.formData.clientClassificationId = scope.clientClassification;
    scope.formData.legalForm = Number(scope.formData.legalForm);
    resourceFactory.amlRulesResource.update(
      params, { ...scope.formData },
      function (res) {
        const { resourceId } = res;
        location.path(`/payments/aml/rulegroups/${ruleId}/limitrules/${resourceId}`);
      },
      handleResponseError
    );
  };

  function handleResponseError (error) {
    scope.formData.legalForm = scope.formData.legalForm.toString();
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

EditLimitRuleController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
