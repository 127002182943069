import i18n from '@/plugins/i18n.js';

const maxLength = (value, length) => {
  return value?.toString().length <= length || i18n.t('validators.generic.maxLength', { length });
};

const exactLength = (value, length) => {
  return value?.toString().length === length || i18n.t('validators.generic.exactLength', { length });
};

const minLength = (value, length) => {
  return value?.toString().length >= length || i18n.t('validators.generic.minLength', { length });
};

const minMaxOrEmpty = (value, minLength, maxLength) => {
  if (!value) return true;
  if (
    value.toString().length >= minLength &&
    value.toString().length <= maxLength
  ) return true;
  return i18n.t('validators.generic.minMaxOrEmpty', {
    minLength,
    maxLength
  });
};

const alpha = (value) => /^[a-zA-Zäöüß]+$/i.test(value) || i18n.t('validators.names.alpha');

const numeric = (value) => /^[0-9]*$/i.test(value) || i18n.t('validators.generic.numeric');

const alphaNumeric = (value) => /^[a-zA-Z0-9-_.-äöüß\s]*$/.test(value) || i18n.t('validators.generic.alphanum');

const decimalNumeric = (value) => /^([0-9]*(\.[0-9]*)?)$/.test(value) || i18n.t('validators.generic.numeric');

const emailValidate = (v) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^$/;
  return emailRegex.test(v) || i18n.t('validators.email.valid');
};

const regexValidate = (v, regex, msg = i18n.t('validators.generic.regexValidate')) => new RegExp(regex).test(v) || msg;

export {
  alpha,
  numeric,
  alphaNumeric,
  minLength,
  maxLength,
  exactLength,
  minMaxOrEmpty,
  decimalNumeric,
  emailValidate,
  regexValidate
};
