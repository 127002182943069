export function CreateChargeController (scope, resourceFactory, location, dateFilter, translate) {
  scope.template = [];
  scope.formData = {};
  scope.first = {};
  scope.isCollapsed = true;
  scope.showdatefield = false;
  scope.repeatEvery = false;
  scope.first.date = new Date();
  scope.translate = translate;
  scope.showFrequencyOptions = false;
  scope.showPenalty = true;
  scope.chargeDueDateOnAccountActivation = true;
  scope.isTransfer = false;
  scope.isAtmWithdrawal = false;
  scope.clientClassificationOptions = [];
  scope.isOnlyCredit = false;
  scope.showRadio = true;
  scope.isCreditCard = false;
  scope.isCreditCard = false;
  scope.returnCodes = [];
  scope.selectedReturnCodes = undefined;
  scope.returnCodeHasError = false;
  scope.savingsProducts = [];

  scope.returnCodeInputHandler = function (value) {
    scope.returnCodeHasError = !value?.length;
    scope.selectedReturnCodes = value;
  };

  resourceFactory.chargeTemplateResource.get(function (data) {
    scope.template = data;
    scope.showChargePaymentByField = true;
    scope.chargeCalculationTypeOptions = data.chargeCalculationTypeOptions;
    scope.formData.chargeAppliesTo = scope.template.chargeAppliesToOptions[0]?.id;
    scope.chargeAppliesToSelected(scope.formData.chargeAppliesTo);

    scope.percentChargeCalculationTypeIds = scope.chargeCalculationTypeOptions?.filter(type =>
      type.code.includes('chargeCalculationType.percent')).map(type => type.id);

    // Formatting the name
    const paymentTypeLabels = { WIRE: 'Domestic Wire', SWIFT: 'International Wire' };
    scope.template.transferPaymentRailsTypes.forEach(
      paymentType => { paymentType.name = paymentTypeLabels[paymentType.name] || paymentType.name; }
    );

    scope.incomeAccountOptions = data.incomeOrLiabilityAccountOptions.incomeAccountOptions || [];
    scope.liabilityAccountOptions = data.incomeOrLiabilityAccountOptions.liabilityAccountOptions || [];
    scope.incomeAndLiabilityAccountOptions = scope.incomeAccountOptions.concat(scope.liabilityAccountOptions);
    scope.clientClassificationOptions = (data.clientClassificationOptions || []).map((classification) => {
      const displayName = `${classification.codeName.split('-')[1]} | ${classification.name}`;
      return { ...classification, displayName };
    });
  });

  resourceFactory.savingProductResource.getAllSavingProducts(function (data) {
    scope.savingsProducts = data;
  });

  resourceFactory.returnCodes.get(function (data) {
    const { allReturnCodeMap, allAssignedReturnCode: { usedReturnCodeList } } = data;
    const usableCodes = Object.keys(allReturnCodeMap).filter(code => !usedReturnCodeList.includes(code));
    scope.returnCodes = usableCodes.map(value => ({ value, label: `${value} - ${allReturnCodeMap[value]}` }));
  });

  scope.$watch('formData.numberOfExemptedFee', function (value) {
    scope.formData.numberOfExemptedFee = value || 0;
  });

  scope.chargeAppliesToSelected = function (chargeAppliesId) {
    switch (chargeAppliesId) {
      case 1:
        scope.showChargePaymentByField = true;
        scope.chargeCalculationTypeOptions = scope.template.loanChargeCalculationTypeOptions;
        scope.chargeTimeTypeOptions = scope.template.loanChargeTimeTypeOptions;
        scope.showGLAccount = false;
        scope.isTransfer = false;
        break;
      case 2:
        scope.setSavings();
        scope.isTransfer = false;
        break;
      case 3:
        scope.showChargePaymentByField = false;
        scope.chargeCalculationTypeOptions = scope.template.clientChargeCalculationTypeOptions;
        scope.chargeTimeTypeOptions = scope.template.clientChargeTimeTypeOptions;
        scope.addfeefrequency = false;
        scope.showGLAccount = true;
        scope.isTransfer = false;
        break;
      case 4:
        scope.showChargePaymentByField = false;
        scope.chargeCalculationTypeOptions = scope.template.shareChargeCalculationTypeOptions;
        scope.chargeTimeTypeOptions = scope.template.shareChargeTimeTypeOptions;
        scope.addfeefrequency = false;
        scope.showGLAccount = false;
        scope.showPenalty = false;
        scope.resetTransfer();
        break;
      case 5:
        scope.setSavings();
        scope.chargeTimeTypeOptions = scope.template.transferChargeTimeTypeOptions;
        scope.paymentRailOptions = scope.template.transferPaymentRailsTypes;
        scope.transferTypeOptions = scope.template.transferTypes;
        scope.paymentDirectionOptions = scope.template.paymentDirectionTypes;
        scope.isTransfer = true;
        scope.showdatefield = false;
        break;
      case 6:
        scope.setCreditCards();
        break;
    }
  };

  scope.setSavings = function () {
    scope.showChargePaymentByField = false;
    scope.chargeCalculationTypeOptions = scope.template.savingsChargeCalculationTypeOptions;
    scope.chargeTimeTypeOptions = scope.template.savingsChargeTimeTypeOptions;
    scope.addfeefrequency = false;
    scope.showGLAccount = false;
  };

  scope.setCreditCards = function () {
    scope.showChargePaymentByField = true;
    scope.chargeCalculationTypeOptions = scope.template.creditCardChargeCalculationTypeOptions;
    scope.chargeTimeTypeOptions = scope.template.creditCardChargeTimeTypeOptions;
    scope.addfeefrequency = false;
    scope.showGLAccount = false;
    scope.isTransfer = false;
    scope.isCreditCard = true;
  };

  scope.resetTransfer = function () {
    scope.paymentRailOptions = [];
    scope.transferTypeOptions = [];
    scope.paymentDirectionOptions = [];
    scope.isTransfer = false;
    delete this.formData.paymentRail;
    delete this.formData.paymentDirection;
    delete this.formData.transferType;
    delete this.formData.reverseOnTransferFail;
    delete this.formData.clientClassificationId;
  };
  scope.isUpfrontCharge = function () {
    if (scope.formData.chargeAppliesTo === 2) {
      if (scope.formData.chargeTimeType === 6 || scope.formData.chargeTimeType === 7 || scope.formData.chargeTimeType === 11) {
        return true;
      }
    }
    return false;
  };
  scope.refreshChargeDueDateCalculation = function () {
    if (scope.chargeDueDateOnAccountActivation === true) {
      scope.showdatefield = false;
    } else if (scope.chargeDueDateOnAccountActivation === false) {
      if (scope.repeatsEveryLabel !== 'label.input.weeks') {
        scope.showdatefield = true;
      }
    }
  };
  // when chargeAppliesTo is savings, below logic is
  // to display 'Due date' field, if chargeTimeType is
  // 'annual fee' or 'monthly fee'
  scope.chargeTimeChange = function (chargeTimeType) {
    scope.showFrequencyOptions = false;
    if (chargeTimeType === 9) {
      scope.showFrequencyOptions = true;
    }
    if (chargeTimeType === 21 || chargeTimeType === 22) {
      scope.isAtmWithdrawal = true;
    } else {
      scope.isAtmWithdrawal = false;
    }
    if (scope.showChargePaymentByField === false) {
      for (const i in scope.chargeTimeTypeOptions) {
        if (chargeTimeType === scope.chargeTimeTypeOptions[i].id) {
          if (scope.chargeTimeTypeOptions[i].value === 'Annual Fee' || scope.chargeTimeTypeOptions[i].value === 'Monthly Fee') {
            // scope.showdatefield = true
            scope.repeatsEveryLabel = 'label.input.months';
            // to show 'repeats every' field for monthly fee
            if (scope.chargeTimeTypeOptions[i].value === 'Monthly Fee') {
              scope.repeatEvery = true;
            } else {
              scope.repeatEvery = false;
            }
          } else if (scope.chargeTimeTypeOptions[i].value === 'Weekly Fee') {
            scope.repeatEvery = true;
            scope.showdatefield = false;
            scope.repeatsEveryLabel = 'label.input.weeks';
          } else {
            scope.showdatefield = false;
            scope.repeatEvery = false;
          }
        }
      }
    }
  };

  scope.applyUpfrontCharge = function () {
    if (scope.formData.chargeTimeType === 7 && scope.formData.applyUpfrontCharge) {
      scope.showRadio = false;
      scope.showdatefield = false;
      scope.chargeDueDateOnAccountActivation = true;
    } else {
      scope.chargeDueDateOnAccountActivation = false;
      scope.showRadio = true;
      scope.formData.applyEndOfPeriod = false;
    }
  };

  scope.applyEndOfPeriodCharge = function () {
    if (scope.formData.chargeTimeType === 7 && scope.formData.applyEndOfPeriod) {
      scope.showRadio = false;
      scope.showdatefield = false;
      scope.chargeDueDateOnAccountActivation = true;
    } else {
      scope.chargeDueDateOnAccountActivation = false;
      scope.showRadio = true;
      scope.formData.applyUpfrontCharge = false;
    }
  };

  scope.$watch('formData.ignoreChargesOnNegativeBalance', function (value) {
    if (!value) scope.formData.collectOnlyTotalDeferCharge = false;
  });

  scope.setChoice = function () {
    if (this.formData.active) {
      scope.choice = 1;
    } else if (!this.formData.active) {
      scope.choice = 0;
    }
  };

  scope.filterChargeCalculations = function (chargeTimeType) {
    const selectedChargeTime = scope.chargeTimeTypeOptions?.find((item) => item.id === chargeTimeType);

    if (selectedChargeTime?.code === 'chargeTimeType.physicalCardOrderingFee') {
      return { code: 'chargeCalculationType.flat' };
    }

    return function (item) {
      if (chargeTimeType === 12 && ((item.id === 3) || (item.id === 4))) {
        return false;
      }
      if (chargeTimeType !== 12 && item.id === 5) {
        return false;
      }
      return true;
    };
  };

  scope.resetMinMax = function () {
    delete this.formData.minCap;
    delete this.formData.maxCap;
  };

  scope.updateTransferType = function (id) {
    scope.isOnlyCredit = false;
    for (const i in scope.paymentRailOptions) {
      if (scope.paymentRailOptions[i].id === id) {
        if (!(scope.paymentRailOptions[i].name === 'ACH' || scope.paymentRailOptions[i].name === 'CARD')) {
          scope.formData.transferType = 'CREDIT';
          scope.isOnlyCredit = true;
        }
      }
    }
  };

  scope.isAllowMinMax = function (formData) {
    return scope.percentChargeCalculationTypeIds?.includes(formData.chargeCalculationType);
  };

  scope.submit = function () {
    // when chargeTimeType is 'annual' or 'monthly fee' then feeOnMonthDay added to
    // the formData
    if (scope.formData.chargeAppliesTo === 5 && scope.formData.chargeTimeType === 32) {
      scope.formData.returnCodeValues = scope.selectedReturnCodes;
      if (!scope.formData.returnCodeValues?.length) {
        scope.returnCodeHasError = true;
        return;
      }
    }

    if (!scope.isAllowMinMax(this.formData)) {
      scope.resetMinMax();
    }
    const isUpfront = scope.isUpfrontCharge();
    if (!isUpfront) {
      delete this.formData.applyUpfrontCharge;
      delete this.formData.applyEndOfPeriod;
    }
    if (scope.showChargePaymentByField === false) {
      if (scope.showdatefield === true) {
        const reqDate = dateFilter(scope.first.date, 'dd MMM');
        this.formData.monthDayFormat = 'dd MMM';
        this.formData.feeOnMonthDay = reqDate;
      }
    }
    this.formData.chargeDueDateOnAccountActivation = scope.chargeDueDateOnAccountActivation;
    if ((scope.formData.chargeAppliesTo === 1 || scope.formData.chargeAppliesTo === 3) && scope.addfeefrequency === 'false') {
      scope.formData.feeFrequency = null;
      scope.formData.feeInterval = null;
    }

    if (!scope.showChargePaymentByField) {
      delete this.formData.chargePaymentMode;
    }
    this.formData.active = this.formData.active || false;
    if (scope.formData.chargeAppliesTo !== 5) {
      this.formData.reverseOnTransferFail = false;
    }
    this.formData.locale = scope.optlang.code;
    this.formData.monthDayFormat = 'dd MMM';
    resourceFactory.chargeResource.save(this.formData, function (data) {
      location.path(`/viewcharge/${data.resourceId}`);
    });
  };
}

CreateChargeController.$inject = ['$scope', 'ResourceFactory', '$location', 'dateFilter', '$translate'];
