<template>
  <div class="row g-3">
    <div class="col-12 col-md-7">
      <div class="card">
        <div class="card-body">
          <bulk-import-form
            ref="modalForm"
            :item-options="itemOptions"
            :loading="loading"
            :title="$t('label.heading.clientImport')"
            @import-file="downloadTemplate"
          />
          <div class="d-flex justify-content-between align-items-center">
            <page-title>
              <i class="fa-solid fa-user-plus" />
              {{ $t('label.heading.downloadTemplate') }}
            </page-title>
            <button
              class="btn btn-light text-primary border text-uppercase"
              @click="modalForm.$children[0].show()"
            >
              <i class="fas fa-file-alt me-1" />
              {{ $t('label.anchor.templates') }}
            </button>
          </div>
          <hr>
          <p class="text-muted">
            {{ $t('label.uploadFileSelect', { variable: 'legal' }) }}
          </p>
          <div class="col-12 col-md-6">
            <input-select
              id="legal-form"
              v-model="uploadLegalForm"
              :label="$t('label.anchor.legalForm')"
              vertical
              required
              :options="legalFormItem"
              text-field="value"
              value-field="value"
            />
            <input-file
              id="file"
              v-model="file"
              vertical
              :accept="'.xls,.xlsx'"
              :disabled="!uploadLegalForm"
              :label="$t('label.anchor.selectExcelFile')"
              :loading="loading"
              @upload="uploadDocument"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-5">
      <div class="card">
        <div class="card-body">
          <UploadedDocument
            :imported-data="importDataItem"
            @upload-file="downloadDocument"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BulkImportForm from '@/components/organization/bulk-import/BulkImportForm.vue';
import UploadedDocument from '@/components/organization/bulk-import/UploadedDocument.vue';
import PageTitle from '@/components/shared/PageTitle.vue';
import apiService from '@/services/apiService';
import i18n from '@/plugins/i18n';
import { InputSelect, InputFile } from '@/components/shared/form-input';
import { downloadHelper } from '@/helper/downloadHelper';
import { $notify } from '@/plugins/prototypes';
import { onMounted, reactive, ref } from 'vue';
import { set, get } from '@vueuse/core';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';

const downloadTemplateName = ref('ClientTemplate.xls');
const uploadLegalForm = ref(null);
const modalForm = ref(null);
const loading = ref(false);
const staffItem = ref([]);
const officeItem = ref([]);
const legalFormItem = ref([]);
const importDataItem = ref([]);
const breadCrumnsStore = useBreadcrumbsStore();
const file = ref(null);

const downloadTemplateData = reactive({
  locale: 'en',
  dateFormat: 'dd MMMM yyyy'
});

const downloadDocumentData = reactive({
  importDocumentId: null
});

const itemOptions = ref([
  {
    label: i18n.t('label.anchor.staff'),
    item: staffItem,
    placeholder: i18n.t('label.anchor.placeHolderStaff'),
    textField: 'displayName',
    valueField: 'id',
    model: 'staffId',
    required: false
  },
  {
    label: i18n.t('label.anchor.office'),
    item: officeItem,
    placeholder: i18n.t('label.anchor.placeHolderOffice'),
    textField: 'name',
    valueField: 'id',
    model: 'officeId',
    required: false
  },
  {
    label: i18n.t('label.anchor.legalForm'),
    item: legalFormItem,
    placeholder: i18n.t('label.anchor.placeHolderLegalForm'),
    textField: 'value',
    valueField: 'value',
    model: 'legalForm',
    required: true
  }
]);

onMounted(() => getClientImportData());

const getClientImportData = async () => {
  const { data: { staffOptions, officeOptions, clientLegalFormOptions } } = await apiService.clients.template({ staffInSelectedOfficeOnly: true });
  const { data } = await apiService.imports.get({ entityType: 'client' });
  set(staffItem, staffOptions);
  set(officeItem, officeOptions);
  set(legalFormItem, clientLegalFormOptions);
  set(importDataItem, data);

  breadCrumnsStore.setBreadcrumbs([
    { text: i18n.t('label.anchor.organization'), href: '#/organization' },
    { text: i18n.t('label.anchor.bulkImport'), href: '#/organization/bulk-import' },
    { text: i18n.t('label.anchor.client') }
  ]);
};

const checkLegalFormType = (legalForm) => {
  return legalForm === 'ENTITY' ? 'CLIENTS_ENTTTY' : 'CLIENTS_PERSON';
};

const uploadDocument = async (file) => {
  set(loading, true);
  const uploadFormData = new FormData();
  uploadFormData.append('locale', 'en');
  uploadFormData.append('dateFormat', 'dd MMMM yyyy');
  uploadFormData.append('file', file);
  try {
    await apiService.clients.upload(uploadFormData, { legalFormType: `${checkLegalFormType(get(uploadLegalForm))}` });
    $notify.success('Upload Successfully');
  } catch (error) {
    $notify.error(error);
  } finally {
    set(loading, false);
  }
};

const downloadTemplate = async (data) => {
  set(loading, true);
  const { legalForm, officeId, staffId } = data;
  if (officeId) downloadTemplateData.officeId = officeId;
  if (staffId) downloadTemplateData.staffId = staffId;
  if (legalForm) downloadTemplateData.legalFormType = `${checkLegalFormType(legalForm)}`;

  const officeName = officeId ? get(officeItem).find((item) => item.id === officeId)?.name + '_' : '';
  const legalFormName = get(legalFormItem).find((item) => item.value === legalForm)?.value;
  const fileName = `${officeName}${legalFormName}_Client_Template.xls`;

  try {
    const response = await apiService.clients.downloadTemplate(downloadTemplateData, 'arraybuffer');
    downloadHelper(response.data, fileName);
    get(modalForm).$children[0].hide();
  } catch (error) {
    $notify.error(error);
  } finally {
    set(loading, false);
  }
};

const downloadDocument = async ({ name, importId }) => {
  downloadDocumentData.importDocumentId = importId;
  try {
    set(downloadTemplateName, name);
    const response = await apiService.imports.downloadDocument(downloadDocumentData, 'arraybuffer');
    downloadHelper(response.data, get(downloadTemplateName));
  } catch (error) {
    $notify.error(error);
  }
};
</script>
