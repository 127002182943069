import validators from './validators.js';

export default {
  validators,
  label: {
    anchor: {
      file: 'File',
      user: 'User',
      person: 'Person',
      entity: 'Entity',
      remove: 'Remove',
      loan: 'Loan',
      savingsAccount: 'Savings Account',
      creditAccount: 'Credit Account',
      staff: 'Staff',
      office: 'Office',
      charge: 'Charge',
      classification: 'Classification',
      numberOfExemptedFee: 'Number Of Exempted Fee',
      exemptedFeeAmount: 'Exempted Fee Amount',
      exemptedFee: 'Exempted FeeAmount',
      bulkImport: 'Bulk Import',
      selectAnOption: 'Select an Option',
      action: 'Action',
      organization: 'Organization',
      placeHolderStaff: '--Select Staff--',
      placeHolderOffice: '--Select Office--',
      placeHolderLegalForm: '--Select Legal Form--',
      selectPrimaryAccount: 'Select Primary Account',
      standingInstructionHistory: 'Standing Instructions History',
      restrictedAndProhibitedCountries: 'Restricted & Prohibited Country',
      amlRules: 'AML Rules',
      paymentsManagement: 'Payments Management',
      editCreditApplication: 'Edit Credit Application',
      terms: 'Terms',
      fieldConfigurations: 'Field Configurations',
      manageOtpProtectedOperation: 'Manage OTP Protected Operation',
      updateDefaultAccount: 'Update Default Account',
      assignStaff: 'Assign Staff',
      switchClassification: 'Switch Classification',
      copy: 'Copy %{variable}',
      viewCreditAccount: 'View Credit Account',
      undoCharge: 'Undo Charge',
      reversalCharge: 'Reversal Charge',
      editCreditAccount: 'Edit Credit Account',
      externalId: 'External id',
      nominalAnnualInterest: 'Nominal annual interest',
      nominalAnnualCashAdvanceInterest: 'Nominal annual cash advance interest ',
      nominalDifferentialRate: 'Nominal Differential rate',
      currency: 'Currency',
      decimalPlaces: 'Decimal Places',
      isWithholdTaxApplicable: 'Is Withhold tax applicable',
      taxGroup: 'Tax Group',
      floatingInterestRate: 'Floating Interest Rate',
      isBaseLendingRate: 'Is Base Lending Rate?',
      interestCompoundingPeriod: 'Interest compounding period',
      nominalAnnualPenaltyInterest: 'Nominal annual penalty interest',
      interestPostingPeriod: 'Interest posting period',
      dayInYears: 'Days in year',
      cashLimit: 'Cash Limit',
      creditLimit: 'Credit limit',
      currencyInMultiplesOf: 'Currency in multiples of ',
      interestCalculatedUsing: 'Interest calculated using ',
      submittedOn: 'Submitted on',
      currentAccount: 'Current account',
      product: 'Product',
      cardProduct: 'Card Product',
      copied: 'Copied %{variable} To Clipboard',
      createTemplate: 'Create Template',
      filterByName: 'Filter by Name',
      filterById: 'Filter by ID',
      filterByClientId: 'Filter by Client ID',
      editTemplate: 'Edit Template',
      startpage: 'Start Page',
      accessAttempt: 'Access Attempts',
      subscribers: 'Subscribers',
      manageFailNotification: 'Retry Failed Notification',
      homepage: 'Home Page',
      loginpage: 'Login Page',
      manageusers: 'Manage Users',
      manageroles: 'Manage Roles',
      logout: 'Sign Out',
      searchBy: 'Search By',
      searchByName: 'Search by name',
      KYCStatus: 'KYC Status',
      home: 'Home',
      all: 'All',
      select: 'Select',
      loans: 'Loans',
      savings: 'Accounts',
      funds: 'Funds',
      admin: 'Admin',
      reports: 'Reports',
      profile: 'Profile',
      settings: 'Settings',
      navigation: 'Navigation',
      dashhome: 'Dash Home',
      collectionsheet: 'Collection Sheet',
      client: 'Client',
      importemployees: 'Import Employees',
      group: 'Group',
      center: 'Center',
      frequentpostings: 'Frequent Postings',
      addjournalentries: 'Add Journal Entries',
      closingentries: 'Closing Entries',
      usermanual: 'User Manual',
      functionalspec: 'Functional Specifications',
      developerzone: 'Developer Zone',
      usersgroup: 'User Group',
      developergroup: 'Developer Group',
      keydesignprinciples: 'Key design principles',
      workingwithcode: 'Working with code',
      donate: 'Donate',
      irc: 'IRC',
      dashboard: 'Dashboard',
      help: 'Help',
      about: 'About',
      advsearch: 'Advance search',
      keyboardshortcut: 'Keyboard Shortcuts',
      save: 'Save',
      payments: 'Payments',
      addcard: 'Add Card',
      delete: 'Delete',
      activate: 'Activate',
      close: 'Close',
      deposit: 'Deposit',
      assignstaff: 'Assign Staff',
      updatedefaultaccount: 'Update Default Savings Account',
      importclients: 'Clients',
      importcenters: 'Centers',
      importoffices: 'Offices',
      editpermissions: 'Edit Permissions',
      paycharge: 'Pay Charge',
      clients: 'Clients',
      createclient: 'Create Client',
      viewclient: 'View Client',
      transferclient: 'Transfer Client',
      editclient: 'Edit Client',
      uploaddocument: 'Upload Document',
      addclientidentities: 'Add Client Identities',
      clientscreenreport: 'Client screen reports',
      acceptclienttransfer: 'Accept Transfer Client',
      rejecttransfer: 'Reject Transfer Client',
      undotransfer: 'Undo Transfer Client',
      reactivate: 'Reactivate',
      undoreject: 'Undo Rejection',
      undowithdrawn: 'Undo Withdrawal',
      groups: 'Groups',
      viewgroup: 'View Group',
      attachmeeting: 'Attach Meeting',
      editmeeting: 'Edit Meeting',
      creategroup: 'Create Group',
      cardActivation: 'Card Activation',
      centers: 'Centers',
      createExternalCard: 'Create External Card',
      viewcenter: 'View Center',
      createcenter: 'Create Center',
      editcenter: 'Edit Center',
      managegroups: 'Manage Groups',
      accounting: 'Accounting',
      coa: 'Chart of Accounts',
      accountingrules: 'Accounting Rules',
      accountsclosure: 'Account Closure',
      addrule: 'Add Rule',
      createclosure: 'Create Closure',
      createglaccount: 'Create GL Account',
      addjournalentry: 'Add Journal Entry',
      editaccountingrule: 'Edit Accounting Rule',
      editglaccount: 'Edit GL Account',
      searchjournalentries: 'Search Journal Entries',
      accountclosure: 'Account Closure',
      viewclosure: 'View Closure',
      viewtransaction: 'View Transaction',
      viewcardauthorization: 'View Card Authorization',
      viewaccounttransferdetails: 'View Account Transfer Details',
      accounttransfers: 'Account Transfers',
      addfinancialmapping: 'Add Financial Activity Mapping',
      viewfinancialmapping: 'View Financial Activity Mapping',
      editfinancialmapping: 'Edit Financial Activity Mapping',
      journalentries: 'Journal Entries',
      viewjournalentries: 'View Journal Entries',
      manageReports: 'Manage Reports',
      createreport: 'Create Report',
      editreport: 'Edit Report',
      runreport: 'Run Report',
      viewloanaccount: 'View Loan Account',
      addloancharge: 'Add Loan Charge',
      addloancollateral: 'Add Loan Collateral',
      assignloanofficer: 'Assign Loan Officer',
      repaymentinfo: 'Repayment Info',
      backtoloaninfo: 'Back to loan information',
      editloancharge: 'Edit Loan Charge',
      loanviewcollateral: 'Loan View Collateral',
      editloancollateral: 'Edit Loan Collateral',
      approve: 'Approve',
      reject: 'Reject',
      withdrawnByApplicant: 'Withdraw by Applicant',
      withdraw: 'Withdraw',
      undoapproval: 'Undo Approval',
      undodisbursal: 'Undo Disbursal',
      disburse: 'Disburse',
      disbursetosavings: 'Disburse To Account',
      repayment: 'Repayment',
      prepayment: 'Prepayment',
      waiveinterest: 'Waive Interest',
      waivecharge: 'Waive Charge',
      writeoff: 'Write Off',
      'close-rescheduled': 'Close Rescheduled',
      modifytransaction: 'Modify Transaction',
      deleteloancharge: 'Delete Loan Charge',
      unassignloanofficer: 'Unassign Loan Officer',
      showpaymentdetails: 'Show Payment Details',
      viewcharge: 'View Charge',
      viewcollateral: 'View Collateral',
      viewschedule: 'Schedule',
      loanapplication: 'New Loan Application',
      modifyloanapplication: 'Modify Loan Application',
      loanscreenreport: 'Loan screen report',
      recoverypayment: 'Recovery Repayment',
      prepayloan: 'Prepay Loan',
      viewsavingsaccount: 'View Account',
      assignsavingsofficer: 'Assign Staff',
      unassignsavingsofficer: 'Unassign Staff',
      rescheduleLoan: 'Reschedule Loan',
      reschedulerequest: 'Reschedule Request',
      addsavingcharges: 'Add Charges',
      viewsavingaccount: 'View Account',
      editsavingapplication: 'Edit Account Application',
      savingapplication: 'Account Application',
      editfixeddepositapplication: 'Edit Fixed Deposit Application',
      viewfixeddepositaccount: 'View Fixed Deposit Account',
      fixeddepositapplication: 'Fixed Deposit Application',
      recurringdepositapplication: 'Recurring Deposit Application',
      viewrecurringdepositaccount: 'View Recurring Deposit Account',
      editrecurringdepositapplication: 'Edit Recurring Deposit Application',
      sharesapplication: 'Shares Application',
      viewshareaccount: 'View Share Account',
      users: 'Users',
      createuser: 'Create User',
      edituser: 'Edit User',
      globalconfigurations: 'Global Configurations',
      editconfiguration: 'Edit Configuration',
      importusers: 'Users',
      importgroups: 'Groups',
      importloanaccounts: 'Loan Accounts',
      importfdaccounts: 'Fixed Deposit Accounts',
      importrdaccounts: 'Recurring Deposit Accounts',
      importcoa: 'Chart of Accounts',
      employees: 'Employees',
      importloanrepayments: 'Loan Repayments',
      importsavingsaccounts: 'Accounts',
      importsavingstransactions: 'Account Transactions',
      importfdtransactions: 'Fixed Deposit Transactions',
      importrdtransactions: 'Recurring Deposit Transactions',
      importjournalentries: 'Journal Entries',
      importshareaccounts: 'Share Accounts',
      importguarantor: 'Guarantors',
      bulkloanreassignment: 'Bulk Loan Reassignment',
      bulkjlgloanapplication: 'Bulk JLG Loan Application',
      manageemployees: 'Manage Employees',
      createemployee: 'Create Employee',
      createholiday: 'Create Holiday',
      manageholidays: 'Manage Holidays',
      editholiday: 'Edit Holiday',
      createoffice: 'Create Office',
      currencyconfig: 'Currency Configuration',
      editcurrencyconfig: 'Edit Currency Configuration',
      editemployee: 'Edit Employee',
      editoffice: 'Edit Office',
      managefunds: 'Manage Funds',
      manageoffices: 'Manage Offices',
      editrole: 'Edit Role',
      createtaxcomponent: 'Create Tax Component',
      managetaxcomponents: 'Manage Tax Components',
      edittaxcomponent: 'Edit Tax Component',
      viewtaxcomponent: 'View Tax Component',
      manageTaxConfigurations: 'Manage Tax Configurations',
      managetaxgroup: 'Manage Tax Groups',
      createtaxgroup: 'Create Tax Group',
      edittaxgroup: 'Edit Tax Group',
      viewtaxgroup: 'View Tax Group',
      templates: 'Templates',
      createtemplate: 'Create Template',
      edittemplate: 'Edit Template',
      createsubscriber: 'Create Subscriber',
      editsubscriber: 'Edit Subscriber',
      system: 'System',
      manageCodes: 'Manage Codes',
      confirmDelete: 'Confirm Delete',
      areYouSureYouWantToDelete: 'Are you sure you want to delete',
      addCode: 'Add Code',
      codeName: 'Code Name',
      managehooks: 'Manage Hooks',
      manageNotifications: 'Manage Notifications',
      createhook: 'Create Hook',
      entityToEntityMapping: 'Entity To Entity Mapping',
      edithook: 'Edit Hook',
      audit: 'Audit',
      manageDataTables: 'Manage Data Tables',
      manageDataTableWorkFlow: 'Manage Data Tables Workflow',
      makedatatableentry: 'Make Data tables',
      createdatatable: 'Create Data table',
      editDataTable: 'Edit Data table',
      editcode: 'Edit Code',
      editdatatable: 'Edit Data table',
      manageschedulerjobs: 'Manage Scheduler Jobs',
      editschedulerjob: 'Edit Scheduler Jobs',
      viewauditentry: 'View Audit Entry',
      tasks: 'Checker Inbox & Tasks',
      transactionandmonitoring: 'Transaction and Monitoring',
      clientMonitoring: 'Client Monitoring',
      transfers: 'Transfers',
      viewtransfer: 'View Transfer',
      settlements: 'Settlements',
      viewcheckerinbox: 'View Checker Inbox',
      checkeractionperformed: 'Checker Action Performed',
      clickheretogeneratecron: 'Click Here To Generate Cron Expression',
      schedularjobhistory: 'Scheduler Job History',
      addrole: 'Add Role',
      viewroles: 'View Roles',
      viewmakercheckersettings: 'View Maker Checker Settings',
      editmakerchecker: 'Edit Maker Checker',
      viewrole: 'View Role',
      manageRoleAndPermissions: 'Manage Roles and Permissions',
      configureMakerCheckerTask: 'Configure Maker Checker Tasks',
      auditTrails: 'Audit Trails',
      schedulerJobs: 'Scheduler Jobs',
      configuration: 'Configurations',
      addcodevalue: 'Add Code Value',
      managemembers: 'Manage Members',
      accountNumberPreferences: 'Account Number Preferences',
      addAccountNumberPreferences: 'Add Account Number Preferences',
      viewAccountNumberPreferences: 'View Account Number Preferences',
      editAccountNumberPreferences: 'Edit Account Number Preferences',
      viewEntityMapping: 'Entity Mapping',
      externalServices: 'External Services',
      addressConfiguration: 'Address configuration',
      fieldconfiguration: 'Entity Field Configuration',
      products: 'Products',
      charges: 'Charges',
      floatingRates: 'Floating Rates',
      savingsChargeClassificationMappings: 'Savings Charge Classification Mappings',
      createfloatingrate: 'Create Floating Rate',
      floatingrate: 'Floating Rate',
      loanProducts: 'Loan Products',
      revolvingLoanProducts: 'Revolving Loan Products',
      cardProducts: 'Card products',
      creditProducts: 'Credit Products',
      createloanproduct: 'Create Loan Products',
      createrevolvingloanproduct: 'Create Revolving Loan Products',
      createcardproduct: 'Create Card Products',
      createcharge: 'Create Charge',
      savingProducts: 'Current and Savings Account Products',
      shareProducts: 'Share Products',
      createshareproduct: 'Create Share Product',
      editshareproduct: 'Edit Share Product',
      dividends: 'Dividends',
      dividendlisting: 'Dividend Details',
      createsavingproduct: 'Create Account Product',
      editcharge: 'Edit Charge',
      editloanproduct: 'Edit Loan Product',
      editsavingproduct: 'Edit Account Product',
      productsMix: 'Products Mix',
      productmix: 'Product Mix',
      addproductmix: 'Add Product Mix',
      viewproductmix: 'View Product Mix',
      fixedDepositProducts: 'Fixed Deposit Products',
      interestratechartviewedit: 'View / Edit Interest Rate Charts',
      interestratecharts: 'Interest Rate Charts',
      createprovisioningcriteria: 'Loan Provisioning Criteria',
      provisioningcriteria: 'Provisioning Criteria',
      viewprovisioningcriteria: 'View Provisioning Criteria',
      viewprovisioningeentries: 'View Provisioning Entries',
      createprovisioningeentries: 'Create Provisioning Entries',
      provisionigetriespreview: 'Loan Loss Provisioning Preview',
      createfixeddepositproduct: 'Create Fixed Deposit Product',
      editfixeddepositproduct: 'Edit Fixed Deposit',
      createinterestratechart: 'Create Interest Rate Chart',
      recurringDepositProducts: 'Recurring Deposit Products',
      editrecurringdepositproduct: 'Edit Recurring Deposit Product',
      createrecurringdepositproduct: 'Create Recurring Deposit Product',
      smscampaings: 'SMS Campaigns',
      createcampaign: 'Create Campaign',
      bulkimport: 'Bulk import',
      code: 'Code',
      editdisbursedate: 'Edit Disbursement',
      adddisbursedetails: 'Add Disbursement',
      deletedisbursedetails: 'Delete Disbursement',
      legalForm: 'Legal Form',
      selectExcelFile: 'Select Excel File',
      completed: 'Completed',
      notCompleted: 'Not Completed',
      standinginstruction: 'Standing Instruction',
      officeglaccount: 'Office GL Account',
      createofficeglaccount: 'Create Office GL Account Mapping',
      editofficeglaccount: 'Edit Office GL Account Mapping',
      viewofficeglaccount: 'View Office GL Account Mapping',
      guarantordetails: 'Guarantor details',
      onholdtransaction: 'On Hold Transaction',
      recoverguarantee: 'Recover from guarantors',
      tellercashmanagement: 'Teller / Cashier Management',
      tellers: 'Tellers',
      codeValueName: 'Code Value Name',
      description: 'Description',
      position: 'Position',
      identifier: 'Identifier',
      isActive: 'is Active',
      cashiers: 'Cashiers',
      editCode: 'Edit Code',
      createTeller: 'Create Teller',
      createCashier: 'Create Cashier',
      cashiertransactions: 'Transactions',
      editteller: 'Edit Teller',
      editcashier: 'Edit Cashier',
      individualcollectionsheet: 'Individual Collection Sheet',
      workingDays: 'Working Days',
      passwordPreferences: 'Password Preferences',
      paymentTypes: ' Payment Type',
      createpaymenttype: 'Create Payment Type',
      viewManageTiers: 'View Manage Tiers',
      addTiers: 'Add Tiers',
      editTiers: 'Edit Tiers',
      manage: 'Manage',
      editpaymenttype: 'Edit Payment Type',
      editExternalServicesConfiguration: 'Edit External Service Configuration',
      addnewcb: 'Add New Credit Bureau',
      withdrawal: 'Withdrawal',
      changeType: 'Change Type',
      productName: 'Product Name',
      userName: 'UserName',
      reason: 'Reason',
      replaceCard: 'REPLACE CARD',
      associatedUser: 'Associated User',
      selectedPrimaryAccount: 'Selected Primary Account',
      mapcblp: 'Map Credit Bureau to Loan Product',
      cbconfigured: 'Credit Bureaus available for mapping',
      cblpmapping: 'Credit Bureau - Loan Product Mapping',
      single: 'Single',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      pos: 'Pos',
      atm: 'Atm',
      viewexternalservices: 'View External Service Configuration',
      viewCreditBureauSummary: 'View Credit Bureau configuration Summary',
      S3: 'S3 Amazon Service',
      totalRecord: 'Total Records',
      successCount: 'Success Count',
      failureCount: 'Failure Count',
      uploaded: 'Uploaded',
      'post-interest-on': 'Post Interest As On',
      SMTP: 'Email Service',
      editcampaign: 'Edit Campaign',
      entitydatatablechecks: 'Entity Data Table Checks',
      adhoc: 'AdHocQuery',
      createadhoc: 'Create AdHocQuery',
      editadhoc: 'Edit AdHocQuery',
      twoFactorConfig: 'Two-Factor Configuration',
      viewtwofactorconfig: 'View Two-Factor Configuration',
      edittwofactorconfig: 'Edit Two-Factor Configuration',
      revolvingloanapplication: 'New Revolving Loan Application',
      statementsAndDocuments: 'Statements and Documents',
      merchangeCharges: 'Manage MCC Charge Configuration',
      manageorganization: 'Manage Organization',
      transferLimits: 'Transfer Limits',
      updatetransferlimits: 'Update Transfer Limits',
      createtransferlimits: 'Create Transfer Limits',
      viewtransferlimits: 'View Transfer Limits',
      updatetransferlimimts: 'Update Transfer Limits',
      createtransferclientlimits: 'Create Transfer Client Limits',
      viewtransferclientlimits: 'View Transfer Client Limits',
      updatetransferclientlimits: 'Update Transfer Client Limits',
      achProcessing: 'ACH Processing',
      processSubledgerFiles: 'Process Subledger Files',
      processSubledgerReturns: 'Process Subledger Returns',
      cardLimitMaximum: 'Card Limit Maximum $ {amount} only',
      primaryAccount: 'Primary Account',
      associatedClients: 'Associated Clients',
      selectFailedNotification: 'Select Failed Notification',
      createEmployee: 'Create Employee',
      'ach-processing': 'ACH Processing',
      addCodeValue: 'Add Code Value',
      editCodeValue: 'Edit Code Value',
      selectTier: 'Select Tier',
      active: 'Active',
      history: 'History',
      'manage-organization': 'Manage Organization',
      'payments-management': 'Payments Management',
      txmonitoring: 'Transaction Monitoring',
      manageRoles: 'Manage Roles',
      viewRole: 'View Role',
      'transaction-monitoring': 'Transaction Monitoring',
      'manage-roles': 'Manage Roles',
      'view-role': 'View Role',
      'edit-nickname': 'Edit Saving Account Nickname',
      'saving-accounts': 'Saving Accounts',
      addAddress: 'Add Address',
      editAddress: 'Edit Address',
      viewClient: 'View Client',
      prepaidCard: 'Prepaid Card',
      viewPrepaidCard: 'View Prepaid Card',
      createPrepaidCard: 'Create Prepaid Card',
      viewSubClient: 'View Sub-Client',
      'create-role': 'Create Role',
      'notification-of-change': 'Notification Of Change',
      'assign-staff': 'Assign Staff',
      'unassign-staff': 'Unassign Staff',
      addCharges: 'Add Charges',
      fundTransfer: 'Fund Transfer',
      'edit-overdraft-limit': 'Edit Overdraft Limit',
      defineVelocityRules: 'Define Velocity Rules',
      manageTiers: 'Manage Tiers',
      edit: 'Edit',
      add: 'Add',
      'client-classification-limits': 'Client Classification Limits',
      addClientClassificationLimits: 'Add Client Classification Limits',
      clientClassificationLimits: 'Client Classification Limits',
      clientClassificationLimitsDetails: 'Client Limits Classification w.r.t Card products',
      editClientClassificationLimits: 'Edit Client Classification Limits',
      viewSubAccount: 'View Sub Account',
      createSubledger: 'Create saving account subledger',
      'disabling-payment-rails': 'Disabling Payment Rails',
      generateDocUploadToken: 'Document Upload tokens',
      disablingPaymentRails: 'Disabling Payment Rails',
      editAccountNumber: 'Edit Account Number',
      viewAccount: 'View Account',
      'search-journal-entries': 'Search Journal entries',
      creditAccountClosure: 'Credit Card Account Closure',
      postInterestAsOn: 'Post Interest As On',
      isMasked: 'is Masked',
      createDispute: 'Create Dispute',
      disputeManagement: 'Dispute Management',
      'dispute-management': 'Dispute Management',
      create: 'Create',
      blockCountries: 'Block Countries',
      security: 'Security',
      enabledOnlinePayments: 'Enabled Online Payments',
      enabledContactlessPayments: 'Enabled contactless payments',
      enabledAtmWithdrawal: 'Enabled ATM withdrawal',
      enabledInternationalPayments: 'Enabled international payments',
      enabledPosPayments: 'Enabled POS payment',
      documentType: 'Document Type',
      uniqueId: 'Unique ID #',
      nationality: 'Nationality',
      documentId: 'Document Id',
      status: 'Status',
      expiryDate: 'Expiry Date',
      editUser: 'Edit User',
      viewUser: 'View User',
      shortName: 'Short Name',
      name: 'Name',
      scheduleClose: 'Schedule close',
      addOrEditPhoneNumber: '{action} phone number',
      updateClientKycVerificationStatus: 'Update client KYC status',
      inactive: 'Inactive',
      selectedTransaction: 'You have selected the following transactions:',
      createSubAccount: 'Create Sub Account',
      loanDataTable: 'Loan data table mapping',
      string: 'String',
      number: 'Number',
      decimal: 'Decimal',
      text: 'Text',
      integer: 'Integer',
      date: 'Date',
      datetime: 'Date & Time',
      boolean: 'Boolean',
      dropdown: 'Drop-down',
      fileupload: 'File Upload',
      multiselect: 'Multi Select',
      dataDisplayName: 'Data Table Display Name',
      dataTableName: 'Data Table name',
      applicationTableName: 'Application Table Name',
      resourcePath: 'Resource Path',
      multiRow: 'Multi Row',
      section: 'Section',
      sectionTitle: 'Section Title',
      sectionDescription: 'Section Description',
      parentColumn: 'Parent Columns',
      column: 'Column',
      mandatory: 'Mandatory',
      length: 'Length',
      regularExpression: 'Regular Expression',
      unmaskedSuffixLength: 'Unmasked Suffix Length',
      parentColumns: 'Parent Columns',
      dependOnColumn: 'Depend On Column',
      title: 'Title',
      displayName: 'Display Name',
      type: 'Type',
      actions: 'Actions',
      newColumn: 'New Column',
      addSection: 'Add Section',
      uploadClientDocument: 'Upload Client Document',
      makerCheckerTasks: 'Maker Checker Tasks',
      checkerThreshold: 'Checker Threshold',
      loanRoleMapping: 'Loan Role Mapping',
      'loan-role-mapping': 'Loan Role Mapping',
      complianceReview: 'Compliance Review',
      'compliance-review': 'Compliance Review',
      notes: 'Notes',
      pepFlag: 'PEP Flag',
      pepType: 'PEP Type',
      pepClass: 'PEP Class',
      pepSubType: 'PEP SubType',
      pepSource: 'PEP Source',
      offenceType: 'Offence Type',
      taxonomyKeywords: 'Taxonomy Keywords',
      taxonomyType: 'Taxonomy Type',
      source: 'Source',
      associatedWith: 'Associated With',
      riskParameters: 'Risk Parameters',
      inputs: 'Inputs',
      riskScore: 'Risk Score',
      riskLevel: 'Risk Level',
      viewComplianceReview: 'View Compliance Review'
    },
    input: {
      adhoc: {
        search: {
          loans: 'Loans'
        }
      },
      username: 'Username',
      password: 'Password',
      resetPassword: 'Reset Password',
      repeatPassword: 'Repeat Password',
      authenticationToken: 'Authentication token',
      name: 'Name',
      employeeDesignation: 'Employee Designation',
      email: 'Email',
      firstname: 'First name',
      lastname: 'Last name',
      socialSecurityNumber: 'Social Security Number',
      dateOfBirth: 'Date Of Birth',
      address: 'Address',
      addressType: 'Address Type',
      employeeAccountCardLimit: 'Employee Account Card limit',
      activeSelfServiceUser: 'Activate Self Service User',
      mobileNo: 'Mobile Number',
      role: 'Roles',
      logoUrl: 'Logo URL',
      addressLineOne: 'Address Line 1',
      addressLineTwo: 'Address Line 2',
      companyAndUnit: 'Company name / Unit number',
      addressLineThree: 'Address Line 3',
      stateOrProvince: 'State / Province',
      tier: 'Tier',
      city: 'City',
      postalCode: 'Postal Code',
      country: 'Country',
      zipCode: 'Zip Code',
      zipPostalCode: 'Zip/Postal Code ',
      qcComments: 'QC Comments',
      verifierComments: 'Verifier Comments',
      type: 'Type',
      depositDate: 'Deposit Date',
      closeReason: 'Closure reason',
      updateDefaultAccountReason: 'Default Account',
      switchClassificationReason: 'Client Classification',
      switchClassificationDate: 'Applicable Date',
      assignStaffReason: 'Staff',
      closeDate: 'Closure Date',
      withdrawReason: 'Withdrawal reason',
      withdrawalDate: 'Withdrawal Date',
      undowithdrawnDate: 'Undo Withdrawn Date',
      withdrawDate: 'Withdrawal Date',
      activateDate: 'Activation Date',
      reactivateDate: 'Reactivation Date',
      activateReason: 'Activation Reason',
      undorejectDate: 'Undo Rejection Date',
      rejectReason: 'Rejection reason',
      rejectDate: 'Rejection Date',
      metaDataKey: 'MetaData Key',
      metaDataValue: 'MetaData Value',
      templateName: 'Template name',
      riskRating: 'Risk Rating',
      riseRatingDate: 'Due Diligence date',
      trademark: 'Trademark',
      about: 'About',
      cardNumber: 'Card Number',
      expiryDate: 'Expiry Date',
      cvv: 'CVV',
      resetFirstLogin: 'Reset Password After First Login',
      autoGeneratePassword: 'Auto Generate Password',
      status: 'Status',
      selectCutOffTime: 'Select cut-off time',
      reason: 'Reason',
      reference: 'Reference',
      endDate: 'End Date',
      stopPaymentTill: 'Stop payment till',
      payee: 'Payee',
      to: 'To',
      amount: 'Amount',
      feeAmount: 'Fee amount',
      waiveFee: 'Waive fee',
      charge: 'Charge',
      paymentType: 'Payment Type',
      search: 'Search',
      transferType: 'Transfer Type',
      'post-interest-onDate': 'Post Interest As On Date',
      placeholder: 'Place Holder',
      createSubAccount: 'Create SubAccount',
      createSubAccountHint: 'Employee card account, no card will be issued',
      mobileNumber: 'Mobile Number',
      searchByNameAccNo: 'Find by name/mobile/client/externalId/identifier',
      searchByNameOrDescription: 'Find by name or description',
      description: 'Description',
      isSelfService: 'Self Service',
      savingAccountNickname: 'Saving Account Nickname',
      pleaseSelectOption: 'Please select an option',
      documentTypeId: 'Document Type Id',
      documentKey: 'Document Key',
      'search-permissions': 'Search permissions',
      approveDate: 'Approve Date',
      linkExistingClient: 'Link Existing Client',
      searchClient: 'Search Client',
      'assign-staff-date': 'Assign On',
      'unassign-staff-date': 'Unassign On',
      toStaff: 'To Staff',
      withdrawBalance: 'Withdraw Balance',
      postInterestValidationOnClosure: 'Post Interest Validation On Closure',
      transactionAmount: 'Transaction Amount',
      fromDayPastDue: 'From Days Past Due',
      toDayPastDue: 'To Days Past Due',
      days: 'days',
      nominalAnnualInterestRateOverdraft: 'Overdraft Interest Rate',
      overdraftLimit: 'Overdraft Limit',
      minOverdraftForInterestCalculation: 'Minimum Overdraft For Interest Calculation',
      charges: 'Charges',
      minCap: 'Min Cap',
      maxCap: 'Max Cap',
      chargeCalculation: 'Charge Calculation',
      chargeTimeType: 'Charge Time Type',
      dueForCollectionOn: 'Due For Collection On',
      dueOn: 'Due On',
      repeatsevery: 'Repeat every',
      transactionDate: 'Transaction Date',
      paymentTypeId: 'Payment Type',
      openingCardBalance: 'Opening Card Balance',
      openingCardBalanceHint: 'Maximum allowed limit is $ {limit}',
      selectCardStatus: 'Select Card Status',
      selectPrimaryAccount: 'Select Primary Account',
      legalForm: 'Legal Form',
      clientClassification: 'Client Classification',
      cardProduct: 'Card Product',
      daily: 'Daily',
      single: 'Single',
      weekly: 'Weekly',
      monthly: 'Monthly',
      text: 'Text',
      swtichToNormalInput: 'Switch to Normal Input',
      swtichToCkeditor: 'Switch to CKEditor',
      subledgerAccountPrefix: 'Subledger account prefix',
      fileUrl: 'File URL',
      totalCreditAmount: 'Total credit amount',
      totalDebitAmount: 'Total debit amount',
      totalReturnAmount: 'Total return amount',
      totalNumberOfReturns: 'Total number of returns',
      totalNumberOfCredits: 'Total number of credits',
      totalNumberOfDebits: 'Total number of debits',
      organizationEmail: 'Organization Email',
      organizationName: 'Organization Name',
      external: 'External',
      outgoingDirectory: 'Outgoing Directory',
      incomingDirectory: 'Incoming Directory',
      openBankingAccountPrefix: 'Open Banking Account Prefix',
      savingAccountNumber: 'Saving Account Number',
      payoffDate: 'Payoff Date',
      currentOutstandingBalance: 'Current Outstanding Balance',
      accruedInterest: 'Accrued Interest',
      totalPayoffAmount: 'Total Payoff Amount',
      savingAccounts: 'Saving Accounts',
      closureReasons: 'Closure Reason',
      note: 'Note',
      searchByNameUserEmailToAttach: 'Search by name, username, or email to attach user to client',
      disputeDate: 'Dispute Date',
      disputeReason: 'Dispute Reason',
      closureType: 'Closure type',
      closureReason: 'Closure reason',
      searchActivities: 'Search Activities',
      office: 'Office',
      kycStatus: 'KYC Status',
      middlename: 'Middlename',
      clientId: 'Client Id',
      externalId: 'External Id',
      countryCode: 'Country Code',
      phoneNumber: 'Phone Number',
      clientTypes: 'Client Types',
      submittedOn: 'Sumbitted On',
      isStaff: 'Is Staff',
      staff: 'Staff',
      isOptedForMLAStatus: 'Opted for MLA Status',
      mlaStatus: 'MLA Status',
      gender: 'Gender',
      activationDate: 'Activation Date',
      website: 'Website',
      incorporationDate: 'Incorporation Date',
      incorpValidityTillDate: 'Incorporation Validity Till Date',
      incorperationNumber: 'Incorporation Number',
      constitution: 'Constitution',
      remarks: 'Remarks',
      mainBusinessLine: 'Main Business Line',
      overridePasswordExpiryPolicy: 'Override Password Expiry Policy',
      resetPasswordAfterFirstLogin: 'Reset Password After First Login',
      selfServiceUser: 'Self Service User',
      roles: 'Roles',
      searchByNameOrShortName: 'Search by name or short name',
      newPassword: 'New Password',
      filterByAll: 'Filter by all',
      searchBy: 'Search by',
      all: 'All',
      mobile: 'Mobile',
      searchFor: 'Search for',
      backofficeUser: 'Backoffice users',
      filterBy: 'Filter by {filter}',
      yes: 'Yes',
      no: 'No',
      filterByNameShortName: 'Filter by name/short name',
      phoneNumberType: 'Phone Number Type',
      cardType: 'Card Type',
      disputeStatus: 'Dispute Status',
      kycVerificationType: 'KYC verification type',
      kycVerificationTypeDescription: 'KYC verification cannot be manually updated after client activation and fully verified',
      selectRange: 'Select Range',
      dateRange: 'Date Range',
      averageMonthlyBalance: 'Average Monthly Balance',
      issuedBy: 'Issued By',
      'schedule-closeDate': 'Scheduled closure date',
      periodicLimit: 'Periodic Limit',
      numberOfTransactions: 'Number of Transactions',
      NAT: 'NAT(National)',
      INT: 'INT (International Limit)',
      ONS: 'ONS (Same Network VISA/MASTER)',
      printTheDepartmentNameAtTheCard: 'Print the department name at the card',
      printTheDepartmentNameAtTheCardHint: 'If the department has a name, you can choose to have it printed on the card. However, if the name is not defined and the account uses the default product name, printing will be disabled.',
      accountName: 'Account Name',
      nickname: 'Nickname',
      primaryAccount: 'Primary Account',
      searchDisputes: 'Search By Dispute ID/Name/Card Number',
      categories: 'Categories',
      entity: 'Entity',
      dataTable: 'Data table',
      loanProduct: 'Loan product',
      currentPassword: 'Current Password',
      showPassword: 'Show password',
      yourPassword: 'Your password',
      userPassword: 'User\'s password',
      maxAmount: 'Max amount',
      filter: 'Filter',
      clientName: 'Client Name',
      pending: 'Pending',
      reject: 'Reject',
      completed: 'Completed',
      notes: 'Notes',
      permissionCode: 'Permission Code',
      productId: 'Product Id',
      threshold: 'Threshold',
      rejected: 'Rejected',
      approved: 'Approved',
      fromDate: 'From Date',
      toDate: 'To Date',
      transactionType: 'Transaction Type',
      transactionSubtype: 'Transaction Subtype',
      transferId: 'Transfer Id',
      creditorName: 'Creditor Name',
      debtorName: 'Debtor Name',
      transactionTransferType: 'Transaction Transfer Type',
      showInterestAccruals: 'Show Interest Accruals',
      reportName: 'Report Name',
      reportType: 'Report Type',
      reportSubType: 'Report Sub Type',
      reportCategory: 'Report Category',
      reportDescription: 'Report Description',
      userReport: 'User Report (UI)',
      ignoreNegativeBalance: 'Ignore Negative Balance'
    },
    button: {
      home: 'Home',
      export: 'Export',
      reject: 'Reject',
      dashboard: 'Dashboard',
      add: 'Add',
      remove: 'Remove',
      cancel: 'Cancel',
      edit: 'Edit',
      delete: 'Delete',
      activate: 'Activate',
      deactivate: 'Deactivate',
      clearAll: 'Clear All',
      clear: 'Clear',
      selectAll: 'Select All',
      viewActiveCardAuthorization: 'View Active Card Authorization',
      viewClosedCardAuthorization: 'View Closed Card Authorization',
      close: 'Close',
      confirm: 'Confirm',
      filter: 'Filter',
      save: 'Save',
      upload: 'Upload',
      submit: 'Submit',
      loading: 'Loading ...',
      viewHistory: 'View History',
      returnTransfer: 'Return Transfer',
      submitNoc: 'Submit NOC',
      signIn: 'Sign in',
      signingIn: 'Signing in',
      ok: 'Ok',
      requestToken: 'Request Token',
      search: 'Search',
      previous: 'Previous',
      createCard: 'Create Card',
      next: 'Next',
      addCodeValue: 'Add Code Values',
      manageClient: 'Manage Client',
      registrationSummary: 'Corporate Registration Summary',
      manageSubAccount: 'Manage Sub-Account',
      createSubAccount: 'Create Sub-Account',
      manageCard: 'Manage Card',
      createEmployeeAccount: 'Add Employee',
      viewDetail: 'View Detail',
      retry: 'Retry',
      routeToSubscriber: 'Route To View Subscriber',
      download: 'Download',
      editSecurity: 'Edit Security',
      options: 'Options',
      changeStatus: 'Change Status',
      editLimit: 'Edit Limit',
      createTemplate: 'Create Template',
      advancedoptions: 'Advanced Options',
      createClient: 'Create Client',
      importClients: 'Import Clients',
      addCode: 'Add Codes',
      editCode: 'Edit Code',
      addNewPrepaidCard: 'Add New Prepaid Card',
      deleteCode: 'Delete Code',
      disable: 'Disable',
      enable: 'Enable',
      showAllPermissions: 'Show All Permissions',
      advancedOptions: 'Advanced Options',
      initiateKyc: 'Initiate KYC',
      reInitiateKyc: 'Re-Initiate KYC',
      copySmsLink: 'Copy SMS link',
      socureResult: 'Socure verification result',
      addClientClassificationLimits: 'Add Client Classification Limits',
      kycSocureResult: 'KYC Verification Result',
      docuementSocureResult: 'Document Verification Result',
      create: 'Create',
      manageAgingBucket: 'Manage Aging Bucket',
      generateNewUploadToken: 'Generate New Upload Token',
      history: 'History',
      filters: 'Filters',
      resetFilters: 'Reset Filters',
      exportXlsExcel: 'Export XLS (Excel)',
      returnAll: 'Return All',
      payoffInquiry: 'Payoff Quote Inquiry',
      showAttachedUser: 'Show Attached User',
      proceed: 'Proceed',
      downloadPayoff: 'Download Payoff',
      viewDispute: 'View Dispute',
      back: 'Back',
      process: 'Process',
      disputeDetails: 'Dispute Details',
      showAttachments: 'Show Attachments',
      viewFile: 'View File',
      requestResetPassword: 'Request Reset Password',
      returnToLogin: 'Return To Login',
      resetPassword: 'Reset Password',
      undoCharge: 'Undo Charge',
      createSavingProduct: 'Create Saving Product',
      orderPhysicalCard: 'Order Physical Card',
      potentialMatch: 'Potential Match',
      falsePositive: 'False Positive',
      view: 'View',
      manageUserPassword: 'Manage User Password',
      updatePassword: 'Update Password',
      requestNewTemporaryPassword: 'Request New Temporary Password',
      createCreditProduct: 'Create Credit Product',
      updateStatus: 'Update status',
      viewJournalEntries: 'View Journal Entries',
      raiseDispute: 'Raise Dispute',
      calculate: 'Calculate',
      addAddress: 'Add address',
      addPhoneNumber: 'Add phone number',
      yes: 'Yes',
      no: 'No',
      editPeriodicLimit: 'Edit Periodic Limit',
      logs: 'Logs',
      rescreenPost: 'Rescreen Post',
      details: 'Details',
      newLoanDataTableMapping: 'New loan datatable mapping',
      updateUserPassword: 'Update user password',
      createCheckerThreshold: 'Create Checker Threshold',
      addRoleMapping: 'Add Role Mapping',
      identityReview: 'Identity Review',
      identityReviewHint: 'Please click on the link and complete the ID review',
      documentReview: 'Document Review',
      documentReviewHint: 'Please click on the link and complete the document review',
      approve: 'Approve',
      accept: 'Accept',
      reviewUpdates: 'Review Updates',
      documentVerification: 'Document verification',
      viewDetails: 'View Details',
      generatePdfReport: 'Generate PDF Report',
      exportCsv: 'Export CSV',
      uboReview: 'UBO Review',
      uboReviewHint: 'Please click on the link and complete the UBO review'
    },
    head: {
      codeName: 'Code Name',
      codeValueName: 'Code Value Name',
      twoFactorAuthentication: 'Two-Factor Authentication',
      description: 'Description',
      createPrepaidCard: 'Create Prepaid Card',
      position: 'Position',
      identifier: 'Identifier',
      systemDefined: 'System Defined',
      active: 'Active',
      inactive: 'Inactive',
      actions: 'Actions',
      selectUser: 'Select User',
      selectSavingsAccount: 'Select Savings Account',
      cardProducts: 'Card products',
      selectCard: 'Select Card',
      selectedCard: 'Selected card',
      userDetail: 'User detail',
      managePrepaidCard: 'Manage Prepaid Card',
      userName: 'User',
      primaryAccountDetail: 'Primary account detail',
      cardDetails: 'Card Details',
      selectCardProducts: 'Select Card Products',
      logoDisplay: 'Logo Image Display',
      createEmplyeeAccount: 'Create New Emplyee Account',
      transactionId: 'Transaction ID',
      bookingDate: 'Booking Date',
      valueDate: 'Value Date',
      office: 'Office',
      legalForm: 'Legal Form',
      createdBy: 'Created By',
      type: 'Type',
      account: 'Account',
      debit: 'Debit',
      credit: 'Credit',
      viewDetail: 'View Detail',
      manageOrganization: 'Manage Organization',
      createExternalCard: 'Create External Card',
      cardHistory: 'Card History',
      cardAuthorization: 'Card Authorization',
      authorizationId: 'Authorization Id',
      originalAuthId: 'Original AuthId',
      createdAt: 'Created At',
      subscriberId: 'Subscriber Id',
      entity: 'Entity',
      activity: 'Activity',
      expiryDate: 'Expiry Date',
      authType: 'Auth Type',
      transactionType: 'Transaction Type',
      amount: 'Amount',
      id: 'Id',
      clearedAmount: 'Cleared Amount',
      settledAmount: 'Settled Amount',
      merchant: 'Merchant',
      status: 'Status',
      statusNotes: 'Status Notes',
      avsResult: 'AVS Result',
      totalCreditCount: 'Total Number of Credits',
      totalCreditAmount: 'Total Value of Credits',
      totalDebitCount: 'Total Number of Debits',
      totalDebitAmount: 'Total Value of Debits',
      processingDate: 'Processing Date',
      fileName: 'File Name',
      numberOfCredits: 'Number of Credits',
      amountOfCredits: 'Amount of Credits',
      numberOfDebits: 'Number of Debits',
      amountOfDebits: 'Amount of Debits',
      action: 'Action',
      eventId: 'Event ID',
      username: 'Username',
      event: 'Event',
      date: 'Date',
      stopPayment: 'Stop Payment',
      manageEmployeeSubAccount: 'Manage Employee/Sub-Account',
      associatedClientsAndEmployees: 'Associated Clients and Employees',
      fundTransfers: 'Fund Transfers',
      createEmployeeAccount: 'Create Employee Account',
      reference: 'Reference',
      notificationOfChange: 'Notification of Change',
      savingAccountNo: 'Saving Account No',
      details: 'Details',
      WITHDRAWAL: 'ATM',
      PURCHASE: 'POS',
      days: 'Days',
      single: 'Single',
      weeks: 'Weeks',
      months: 'Months',
      searchJournalEntries: 'Search Journal Entries',
      masked: 'Masked',
      customerName: 'Customer Name',
      creditCardNumber: 'Credit Card Number',
      accountType: 'Account Type',
      accountNumber: 'Account Number',
      clientId: 'Client Id',
      creditCardName: 'Credit Card Name',
      clientName: 'Client Name',
      accountNo: 'Account No',
      creditCardNum: 'Credit Card Number',
      disputeId: 'Dispute ID',
      disputeReason: 'Dispute Reason',
      disputeDate: 'Dispute Date',
      noAttachment: 'No Attachment',
      disputeAmount: 'Dispute Amount',
      loading: 'Loading ...',
      cardType: 'Card Type',
      creditAccountId: 'Credit Account Id',
      name: 'Name',
      program: 'Program',
      match: 'Match',
      remarks: 'Remarks',
      address: 'Address',
      cityStateProvincePostalCode: 'City State Postal Code',
      country: 'Country',
      alternateName: 'Alternate Name',
      streetAddress: 'Street Address',
      city: 'City',
      state: 'State',
      effectiveDate: 'Effective Date',
      expirationDate: 'Expiration Date',
      phoneNumber: 'Phone number',
      firstname: 'First Name',
      lastname: 'Last Name',
      email: 'Email',
      transactionStatus: 'Transaction Status',
      avsDetails: 'AVS Details',
      shortName: 'Short Name',
      loanProducts: 'Loan Products',
      loanProductId: 'Loan Product ID',
      addressLineOne: 'Address Line 1',
      addressLineTwo: 'Address Line 2',
      addressLineThree: 'Address Line 3',
      stateOrProvince: 'State / Province',
      postalCode: 'Postal Code',
      phoneNumberType: 'Phone Number Type',
      countryCode: 'Country Code',
      productVersionId: 'Product Version Id',
      cardNumber: 'Card Number',
      primaryAccountNumber: 'Primary Account Number',
      savingsAccount: 'Savings Account',
      accountName: 'Account Name',
      dataTableName: 'Data table name',
      productName: 'Product name',
      product: 'Product',
      permissionCode: 'Permission Code',
      threshold: 'Threshold',
      role: 'Role',
      maxAmount: 'Max Amount',
      customerId: 'Customer ID',
      reviewType: 'Review Type',
      riskRating: 'Risk Rating',
      reasonForReview: 'Reason For Review',
      assessmentDate: 'Assessment Date',
      applicationStatus: 'Application Status',
      clientClassification: 'Client Classification',
      reviewSummary: 'Review Summary',
      parameter: 'Parameter',
      result: 'Result',
      timestamp: 'Timestamp',
      riskParameters: 'Risk Parameters',
      inputs: 'Inputs',
      riskScore: 'Risk Score',
      riskLevel: 'Risk Level',
      totalScore: 'Total Score',
      customerRiskProfile: 'Customer Risk Profile',
      subParameter: 'Sub Parameter',
      old: 'Old',
      new: 'New',
      source: 'Source',
      category: 'Category',
      profileMonitoring: 'Profile Monitoring',
      subStatus: 'Sub Status',
      reviewedBy: 'Reviewed By',
      parameterNamePassedToPentaho: 'Parameter Name Passed To Pentaho'
    },
    heading: {
      riskRatingHistory: 'Risk Rating History',
      dataTableName: 'Data Table Name',
      section: 'Section',
      sections: 'Section(s)',
      columns: 'Column(s)',
      createDataTable: 'Create Data table',
      editDataTable: 'Edit Data table',
      welcome: 'Welcome',
      cardActivation: 'Card Activation',
      passwordResetRequest: 'Password Reset Request',
      resetLinkHasBeenSent: 'Reset Link has been sent',
      riskRating: 'Risk Rating',
      confirmDelete: 'Confirm Delete',
      clientTemplates: 'Client Templates',
      downloadTemplate: 'Download Template',
      cardSystemIdentifier: 'Card System Identifier',
      isPhysicalCardActivated: 'Is Physical Card Activated',
      isItVirtualOnlyCard: 'Is It Virtual Only Card',
      embossedName: 'Embossed Name',
      pan: 'PAN',
      expires: 'Expires',
      network: 'Network',
      registeredOn: 'Registered On',
      activatedOn: 'Activated On',
      cardHistory: 'Card History',
      prepaidCard: 'Prepaid Card',
      cardHolder: 'Card Holder',
      firstname: 'First Name',
      lastname: 'Last Name',
      userId: 'User Id',
      limits: 'Limits',
      onlinePayment: 'Online Payment',
      contactlessPayment: 'Contactless Payment',
      pos: 'POS',
      producedOn: 'Produced On',
      shippedOn: 'Shipped on',
      physicalActivatedOn: 'Physical Activated on',
      editSecurity: 'Edit Security',
      accountNo: 'Account No',
      seconds: 'Single',
      weeks: 'Weekly',
      associatedWith: 'Associated With',
      days: 'Daily',
      months: 'Monthly',
      atm: 'ATM',
      country: 'Country',
      reversalCharge: 'Reversal Charge',
      undoCharge: 'Undo Charge',
      editClientDocument: 'Edit Client Document',
      blockedCountries: 'Blocked countries',
      atmWithdrawals: 'ATM Withdrawals',
      posPayment: 'POS Payment',
      internationalPayments: 'International Payments',
      deleteCode: 'Delete Code',
      balance: 'Balance',
      text: 'Text',
      metaData: 'Meta Data',
      template: 'Templates',
      listTemplate: 'List of Templates',
      uploadedDocument: 'Uploaded Document',
      clientImport: 'Client Import',
      confirmChangeCardStatus: 'Are you sure you want to change card status?',
      confirmChangeCardType: 'Are you sure you want to change card type?',
      confirmReplaceCard: 'Are you sure you want to replace this card?',
      addCode: 'Add Codes',
      editCode: 'Edit Codes',
      addCodeValue: 'Add Code Values',
      editCodeValue: 'Edit Code Values',
      security: 'Security',
      viewCodeValue: 'View Code Values',
      client: {
        assignStaff: 'Assign Staff',
        switchClassification: 'Client Switch Classification',
        updateDefaultAccount: 'Update Default Savings Account',
        activate: 'Activate Client',
        undoreject: 'Undo Rejection',
        undowithdrawn: 'Undo Withdrawl',
        reactivate: 'Reactivate Client',
        withdraw: 'Withdraw',
        close: 'Close',
        reject: 'Reject',
        addAddress: 'Add Address',
        editAddress: 'Edit Address',
        closeCreditAccount: 'Credit Card Account Closure',
        createCreditAccountDispute: 'Create New Dispute',
        nocReturnConfirmation: 'NOC Return Transaction Confirmation'
      },
      account: 'Account',
      disabled: 'Disabled',
      notification: 'Notifications',
      url: 'URL',
      frequentpostings: 'Frequent Postings',
      addjournalentries: 'Add Journal Entries',
      searchjournalentries: 'Search Journal Entries',
      chartofaccounts: 'Chart of Accounts',
      closingentries: 'Closing Entries',
      accountingrules: 'Accounting Rules',
      glcode: 'GL Code',
      acctype: 'Account Type',
      manualentriesallowed: 'Manual Entries Allowed',
      usedas: 'Used as',
      accountingclosures: 'Accounting Closures',
      viewaccountingclosures: 'View Accounting Closures',
      accountingclosuredate: 'Accounting Closure Date',
      closeaccountingcreatedby: 'Close Accounting Created by',
      accountingruletitle: 'Create an Accounting Rule',
      createglaccount: 'Create GL Account',
      editaccountingrule: 'Edit Accounting Rule',
      editglaccount: 'Edit GL Account',
      addjournalentry: 'Add Journal Entry',
      viewjournalentry: 'View Journal Entry',
      debittags: 'Debit Tags',
      uploadSuccess: 'Upload Success',
      debitaccount: 'Debit Account',
      credittags: 'Credit tags',
      creditaccount: 'Credit Account',
      transactionSubtype: 'Transaction Subtype',
      creditaccountdetails: 'Credit Account Details',
      debitaccountdetails: 'Debit Account Details',
      closuredate: 'Closure Date',
      closedby: 'Closed By',
      closurecreationdate: 'Closure Creation Date',
      deleteCodeValueCode: 'Delete Code Values Code`s',
      transactionnumber: 'Transaction Number',
      parentaccountname: 'Parent Account Name',
      tag: 'Tag',
      accountusage: 'Account Usage',
      viewaccounttransferdetails: 'View Account Transfer Details',
      transactiondetails: 'Transaction Details',
      cardAuthorizationDetails: 'Card Authorization Details',
      cardSettlementDetails: 'Card Settlement Details',
      transactionamount: 'Transaction Amount',
      transferredto: 'Transferred To',
      transferringfrom: 'Transferring From Details',
      accounttransfers: 'Account Transfers',
      applicant: 'Applicant',
      fromaccount: 'From Account',
      fromaccounttype: 'From Account Type',
      fromClient: 'From Client',
      toClient: 'To Client',
      executionTime: 'Execution Time',
      currency: 'Currency',
      threshold: 'Threshold',
      oneToAhundred: '0 to 100',
      matchRate: 'Match rate',
      limit: 'Limit',
      transferringto: 'Transferring To',
      receivedFrom: 'Received From',
      incomingWireTransfer: 'Incoming Wire Transfer',
      createclosure: 'Create Closure',
      amount: 'Amount',
      evaluationType: 'Evaluation Type',
      filterByClient: 'Client',
      filterByCounterparty: 'Counterparty',
      filterByCounterpartyStatus: 'Counterparty Status',
      filterByTransactionalRelationship: 'Counterparty Relation',
      filterByTransactionType: 'Payment Types',
      period: 'Period',
      restrictedCountries: 'Restricted Countries',
      holdAmountDetails: 'Hold Amount Details',
      transactionInformation: 'Transaction Information',
      heldAmount: 'Held Amount',
      holdAuthorizeTransactionId: 'Hold Authorize Transaction ID',
      status: 'Status',
      createdAt: 'Created At',
      pendingAmount: 'Pending Amount',
      clearedAmount: 'Cleared Amount',
      expiryDate: 'Expiry Date',
      id: 'ID',
      name: 'Name',
      clientNo: 'Client #',
      externalId: 'External Id',
      office: 'Office',
      submittedOn: 'Submitted On',
      onBoardingStatus: 'Onboarding Status',
      paymentSubType: 'Payment SubType',
      type: 'Type',
      Name: 'Name',
      isTrapdoor: 'Is Trapdoor',
      identifier: 'Identifier',
      placeholder: 'Placeholder',
      clients: 'List of Clients',
      description: 'Description',
      isSelfService: 'Self Service',
      enabledrole: 'Enabled',
      addrole: 'Add Role',
      permissions: 'Permissions',
      normalaccess: 'Normal Access',
      fullaccess: 'Full Access',
      allPermissions: 'All Permissions',
      createRole: 'Create Role',
      enabledRole: 'Enabled',
      addRole: 'Add Role',
      normalAccess: 'Normal Access',
      fullAccess: 'Full Access',
      savingAccounts: {
        'edit-nickname': 'Edit Saving Account Nickname',
        approve: 'Approve Account',
        activate: 'Activate Account',
        'assign-staff': 'Assign Staff',
        'unassign-staff': 'Unassign Staff',
        close: 'Close Account',
        'edit-overdraft-limit': 'Edit Overdraft Limit',
        'add-charge': 'Add Charge',
        withdrawal: 'Withdraw Money from Account',
        deposit: 'Deposit Money to Account',
        'post-interest-on': 'Post Interest As On',
        'edit-account-number': 'Edit Account Number',
        'schedule-close': 'Schedule close saving account'
      },
      confirmation: 'Confirmation',
      'edit-nickname': 'Edit Saving Account Nickname',
      approve: 'Approve Account',
      linkExistingClient: 'Link Existing Client',
      velocityRules: 'Velocity Rules',
      manageAgingBucket: 'Manage Aging Bucket',
      fromDaysPastDue: 'From Days Past Due',
      toDayPastDue: 'To Days Past Due',
      actions: 'Actions',
      edit: 'Edit',
      create: 'Create',
      generateDocUploadToken: 'Generate Upload Token',
      listOfSavingChargeClassification: 'List of Saving Charge Classification Mappings',
      documentUploadTokens: 'Document Upload Tokens',
      token: 'Token',
      isExpired: 'Is Expired',
      expireAt: 'Expired At',
      createdBy: 'Created By',
      enabled: 'Enabled',
      maker: 'Maker',
      entityName: 'Entity Name',
      action: 'Action',
      changes: 'Changes',
      timestamp: 'Timestamp',
      history: 'History',
      ipAddress: 'IP Address',
      address: 'Address',
      transactionType: 'Transaction Type',
      date: 'Value Date',
      pendingTransactions: 'Pending Transactions',
      transactionId: 'Transaction ID',
      transactionDate: 'Transaction Date',
      valueDate: 'Value Date',
      officeName: 'Office Name',
      debit: 'Debit',
      credit: 'Credit',
      returnAllPendingReturnAchConfirmation: 'Return All Pending Return ACH, Confirmation',
      confirmReturnAllPendingACH: 'Are you sure you want to return all pending ACH transfers?',
      username: 'Username',
      email: 'Email',
      editClient: 'Edit Client',
      bookingDate: 'Booking Date',
      transactiontype: 'Transaction Type',
      editLimit: 'Edit Limit',
      single: 'Single',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      subTransactionType: 'Transaction Subtype',
      reference: 'Reference',
      disputedTransactions: 'Disputed Transactions',
      transactionAmount: 'Transaction Amount',
      disputeAmount: 'Disputed Amount',
      disputeManagement: 'Dispute Management',
      cardNumber: 'Card Number',
      reportedDate: 'Reported Date',
      notes: 'Notes',
      transctionAmount: 'Transaction Amount',
      adjustAmount: 'Adjustment Amount',
      disputeDetails: 'Dispute Details',
      disputeAttachments: 'Dispute Attachments',
      disputeReason: 'Dispute Reason',
      note: 'Note',
      createUser: 'Create user',
      link: 'Link',
      currentAndSavingAccountProducts: 'Current and Saving Account Products',
      shortName: 'Short Name',
      confirmOrderPhysicalCardModal: 'Are you sure you want to order physical card?',
      viewFile: 'View File',
      createSavingChargeClassification: 'Create Saving Charge Classification Mapping',
      editSavingChargeClassification: 'Edit Saving Charge Classification Mapping',
      editUser: 'Edit user',
      achProcessing: 'ACH Processing',
      sameDayAchProcessing: 'SAME DAY ACH Processing',
      addresses: 'Addresses',
      altNames: 'Alternate Names',
      deniedPersons: 'Denied Persons',
      speciallyDesginatedNationals: 'Specially Designated Nationals',
      filename: 'Filename',
      isSelfServiceUser: 'Self Service User',
      roles: 'Roles',
      userDocuments: 'User Documents',
      deleteConfirm: 'Are you sure you want to delete?',
      mobile: 'Mobile',
      manageUserPassword: 'Manage User Password',
      resetToTemporaryPassword: 'Reset to Temporary Password',
      changePassword: 'Change Password',
      listOfUsers: 'List of Users',
      addClientIdentifier: 'Add Client Identifier',
      editClientIdentifier: 'Edit Client Identifier',
      createloanproduct: 'Create Loan Products',
      addOrEditPhoneNumber: '{action} phone number',
      creditCard: 'Credit Card',
      debitCard: 'Debit Card',
      open: 'OPEN',
      closed: 'CLOSED',
      shippingHistory: 'Shipping History',
      shippedBy: 'Shipped By',
      trackingId: 'Tracking ID',
      verifyClient: 'Verify client',
      showInterestAccruals: 'Show Accruals',
      loanAmountAndBalance: 'Loan Amount and Balance',
      totalCostOfLoan: 'Total Cost of Loan',
      installmentTotals: 'Installment Totals',
      paidDate: 'Paid Date',
      principalDue: 'Principal Due',
      balanceOfLoan: 'Balance of Loan',
      interest: 'Interest',
      fees: 'Fees',
      penalties: 'Penalties',
      due: 'Due',
      paid: 'Paid',
      inadvance: 'In Advance',
      late: 'Late',
      outstanding: 'Outstanding',
      waived: 'Waived',
      total: 'Total',
      phoneNumbers: 'Phone numbers',
      noAddressesAdded: 'No addresses added',
      noPhoneNumbersAdded: 'No phone numbers added',
      cancelTransfer: 'Cancel Transfer',
      editPeriodicLimit: 'Edit Periodic Limit',
      periodicLimit: 'Periodic Limit',
      numberOfTransactions: 'Number of Transactions',
      NAT: 'NAT(National)',
      INT: 'INT (International Limit)',
      ONS: 'ONS (Same Network VISA/MASTER)',
      logs: 'Logs',
      noLogs: 'No logs',
      reports: 'Reports',
      category: 'Category',
      provisioningEntries: 'Provisioning Entries',
      accruals: 'Accruals',
      operationalAccountMapping: 'Operational Account Mapping',
      migrateOpeningBalances: 'Migrate Opening Balances',
      accountsLinkedToFinancialActivity: 'Accounts Linked to Financial Activity',
      loanDataTable: 'Loan data table',
      createNewDataTable: 'Create new data table',
      uploadClientDocument: 'Upload Client Document',
      updateUserPassword: 'Update user password',
      createCheckerThreshold: 'Create Checker Threshold',
      editCheckerThreshold: 'Edit Checker Threshold',
      addRoleMapping: 'Add Role Mapping',
      editRoleMapping: 'Edit Role Mapping',
      reviewUpdates: 'Review Updates',
      noPendingReviews: 'No pending reviews',
      source: 'Source',
      complyAdvantage: 'Comply Advantage',
      message: 'Message',
      requestId: 'Request ID',
      createReport: 'Create Report',
      editReport: 'Edit Report',
      reportDetails: 'Report Details',
      sql: 'Sql',
      reportParameters: 'Report Parameters',
      s3Url: 'S3 Url',
      thereAreNoPendingUpdates: 'There are no pending updates'
    },
    subTitle: {
      theseArePreDefinedPostings: 'These are pre-defined postings',
      manualJournalEntryTransactionsRecordedInAJournal: 'Manual journal entry transactions recorded in a journal',
      advanceSearchOptionForJournalEntries: 'Advance search option for journal entries',
      listOfFinancialActivityAndGLAccountMappings: 'List of Financial Activity and GL Account Mappings',
      migrateOpeningBalancesSubTitle: 'Set or update office-level opening balances for GL Accounts',
      operationalAccountMappingSubTitle: 'List of Financial Activity and Organization Account Mappings',
      listOfTheAccountsUsedByTheOrganization: 'List of the accounts used by the organization',
      journalEntriesMadeAtTheEndOfAnAccountingPeriod: 'Journal entries made at the end of an accounting period',
      listsAllAccountingRules: 'Lists all accounting rules',
      accruesIncomeExpensesAndLiabilitiesAsOnTheProvidedDate: 'Accrues income, expenses and liabilities as on the provided date',
      createProvisioningEntries: 'Create Provisioning Entries'
    },
    cardStatus: {
      activate: 'ACTIVATE',
      suspend: 'SUSPEND',
      return: 'RETURNED',
      order: 'ORDER',
      terminate: 'TERMINATE',
      inactive: 'INACTIVE',
      reject: 'REJECT',
      created: 'CREATED',
      renew: 'RENEW',
      reactivate: 'REACTIVATE'
    },
    nav: {
      organization: 'Organization',
      manageOrganization: 'Manage Organization',
      paymentsManagement: 'Payments Management',
      achProcessing: 'ACH Processing',
      achProcessingHistory: 'History',
      transfers: 'Transfers'
    },
    error: {
      field: {
        required: 'Field is required',
        digitsRequired: 'Must be {digitNumber} digits'
      },
      invalidMobileNumber: 'Invalid Mobile Number',
      zipCodeNoMatch: `Zip code must be in format {format}`,
      patternNotMatch: 'Invalid Postal Code'
    },
    searchorpress: 'Click or press alt+x to Search',
    search: {
      scope: {
        all: 'All',
        clients: {
          and: {
            clientIdentifiers: 'Clients'
          }
        },
        shares: 'Shares',
        savings: 'Accounts',
        cards: 'Cards',
        cs: 'Clients, Accounts',
        oldAccounts: 'Old Accounts'
      },
      returnCode: 'Return Code',
      returnMessage: 'Return Message'
    },
    selectOption: 'Select your option',
    loading: 'Loading',
    employeeName: 'Employee Name',
    subAccount: 'Sub Account',
    displayName: 'Name',
    designation: 'Designation',
    isUBO: 'Is UBO?',
    isDirector: 'Is Director?',
    id: 'ID',
    isSigner: 'Is Signer?',
    userName: 'Username',
    clientStatus: 'Client Status',
    selfServiceUser: 'Self Service User',
    kycVerificationStatus: 'KYC Verification Status',
    kycVerificationSubStatus: 'KYC Verification Sub-Status',
    associatedClient: {
      summaryPage: {
        title: 'Registration Summary',
        description: 'Below is the summary of the data client has submitted',
        back: 'Back',
        noFileUploaded: 'No file uploaded!'
      }
    },
    status: 'Status',
    actions: 'Actions',
    entriesPerPage: 'Entries per page',
    resultsFor: 'Results for',
    manageRoles: {
      delete: 'Are you sure you want to delete this role?',
      enable: 'Are you sure you want to enable this role?',
      disable: 'Are you sure you want to disable this role?',
      errors: {
        name: 'Name is required!',
        description: 'Description is required!'
      }
    },
    adhocs: 'Define AdhocQuery for Organization',
    loanDataTable: 'Manage loan data table mapping',
    addnewofficeormodifyordeactivateofficeormodifyofficeheirarchy: 'Add new office or modify or deactivate office or modify office hierarchy',
    aemployeerepresentsloanofficerswithnoaccesstosystem: 'A employee represents loan officers with no access to systems',
    bulkimport: 'Bulk data import using excel spreadsheet templates for clients, offices, etc.',
    currenciesavailableacrossorganizationfordifferentproducts: 'Currencies available across organization for different products',
    createprovisioningcriteria: 'Define Loan Provisioning Criteria for Organization',
    defineholidaysforoffices: 'Define holidays for offices',
    easywaytoreassignalltheloanfromonelotoanotherlo: 'Easy way to reassign all the loan from one LO to another LO',
    entitydatatablechecks: 'Define Entity Data Table Checks for Organization',
    fundsareassociatedwithloans: 'Funds are associated with loans',
    manageOrganizationDescription: 'Manage Organization information',
    addNewExtraFieldsToAnyEntityInTheFormOfDataTable: 'Add new extra fields to any entity in the form of data table',
    codesAreUsedToDefineDropDownValues: 'Codes are used to define drop down values',
    defineOrModifyRolesAndAssociatedPermissions: 'Define or modify roles and associated permissions',
    defineOrModifyMakerCheckerTasks: 'Define or modify maker checker tasks',
    defineOrModifyNotifications: 'Define notifications',
    defineMappings: 'Define or modify entity to entity mappings',
    trackLogin: 'Track your login',
    manageOtpProtectedOperations: 'Manage your otp protected operations',
    auditLogsOfAllTheActivities: 'Audit logs of all the activities, such as create client, disburse loans etc',
    addNewReportAnd: 'Add new report and classify reports',
    scheduleaJobModifyJobs: 'Schedule a job, modify or delete jobs',
    globalConfigurationsAnd: 'Global configurations and Cache settings',
    fieldConfigurationsAndValidations: 'Field configurations and Field validations',
    accountNumberPreferences: 'Preferences for generating account numbers for client, loan and accounts',
    twoFactorConfig: 'Two-factor authentication configuration settings',
    merchangeCharges: 'Configure Merchant Category code charge Mapping',
    defineMaximunAmountTransfer: 'Define Maximun Amount Transfer',
    addNewWorkFlowDataTable: 'Define workflow of the data tables',
    passwordPreferences: 'Define standards for enforcing the usage of stronger passwords',
    tellercashmanagement: 'Manage Tellers / Cashiers and Cash Allocation and Settlement',
    workingDays: 'Define working days and configure behavior of payments due on holidays',
    addNewLoanProductor: 'Add new loan product or modify or inactivate loan product',
    addNewResolvingLoanProductor: 'Add new revolving loan product, modify or inactivate revolving loan product',
    addNewSavingProductor: 'Add, modify or inactivate Current and Savings account Products',
    addNewCreditProductor: 'Add new credit product or modify or inactivate credit product',
    addNewShareProductor: 'Add new share product or modify or inactivate share product',
    addNewCardProduct: 'Add new card product or modify or inactivate card product',
    defineCharges: 'Define Charges/Penalties for loan and deposit products',
    definesRulesForTakingMul: 'Defines rules for taking multiple rules',
    fixedDepositProduct: 'Add, modify or inactivate a Fixed deposit product',
    recurringDepositProduct: 'Add, modify or inactivate a Recurring Deposit product',
    taxConfiguration: 'Define Tax components and Tax groups',
    defineFloatingRates: 'Define floating rates for loan products',
    mappingOfSavingsChargesToClassification: 'Mapping of savings charges to classification',
    viewLoggedHistoryOfStandingInstructions: 'View logged history of standing instructions',
    paymentTypes: 'Manage payment types',
    manageAnitiMoneyLaunderingRules: 'Manage Anti-Money Laundering Rules',
    amlGroupRestrictedAndProhibitedCountriesConfiguration: 'AML Group restricted and prohibited countries configuration',
    triggerAchAndViewAchProcessingHistory: 'Trigger ACH and view ACH processing history',
    processSubledgerFiles: 'Process Subledger Files',
    processSubledgerReturns: 'Process Subledger Returns',
    uploadFileSelect: 'To upload file, please select {variable} form.',
    pleaseEnterTheAuthenticationToken: 'Please enter the authentication token from authentication app.',
    uploadedText: '{variable} Uploaded!',
    documentVerificationStatus: 'Document Verification Status',
    retrySuccess: 'Retry Successfully',
    replaceCardDamagedText: 'Replacing the card with DAMAGED option, will not terminate current card. Client should ACTIVATE the card if user wants to continue using it. Card can only be replaced with DAMAGED option once physical card is already activated.',
    placeholder: {
      searchLinkClient: 'Input at least 3 characters',
      offices: 'Offices',
      entries: 'Entries',
      fromDateToDate: 'From Date - To Date',
      transactionId: 'Transaction Id',
      glAccounts: 'Accounts - Code'
    },
    tier: 'Tier',
    clientTypes: 'Client Types',
    email: 'Email',
    disablePaymentRailsForWholeOrganization: 'Disable payment rails for whole organization',
    noData: 'No data',
    processed: 'Processed',
    notProcessed: 'Not Processed',
    tooltips: {
      overridePasswordExpiry: 'Determines if passwords do not expire for this user even if global configuration for password expiry is enabled'
    },
    resetPasswordInstruction: 'To reset you password, please follow instructions sent to your email address',
    sendViaEmailToMbanq: 'Send via Email to Mbanq',
    loginForbidden: 'You need to login to access this area',
    deleteChargeSuccess: 'Delete Charge Classification Successfully',
    editChargeSuccess: 'Edit Charge Classification Successfully',
    addChargeSuccess: 'Create Charge Classification Successfully',
    disputeProcessing: 'Dispute in Process',
    disputeClosed: 'Dispute Closed',
    screenedOn: 'Screen on',
    clientId: 'Client ID',
    clientName: 'Client Name',
    address: 'Address',
    threshold: 'Threshold',
    dateOfBirth: 'Date of birth',
    updateSecuritySuccessfully: 'Update Security Successfully',
    updateLimitSuccessfully: 'Update limit successfully',
    receivedIntoFromBankTrustedSource: 'Received into from Bank\'s trusted sources',
    basedOnCustomerDataAvailableWithOtherBanks: 'Based on customer\'s data available with other banks',
    confirmApproveCompliance: 'Click \'OK\' to confirm that you have carefully verified that customer profile',
    backApproveCompliance: 'Click \'Back\' to cancel and return to previous screen',
    confirmRejectCompliance: 'Click \'OK\' to confirm the rejection',
    backRejectCompliance: 'Click \'Back\' to cancel and return to previous screen',
    confirmDeleteDataTable: 'Are you sure want to delete this data table ?',
    timeStamp: 'Timestamp',
    finalIcraScore: 'Final ICRA Score',
    customerNpResidency: 'Customer NP Residency',
    productServiceType: 'Product/Service Type',
    purposeOfAccount: 'Purpose Of Account',
    anticipatedAccountActivityOutgoing: 'Anticipated Account Activity (purpose of account - outgoing)',
    anticipatedAccountActivityIncoming: 'Anticipated Account Activity (soruce of funds) - incoming',
    sanctionAndHighRiskCountryScreening: 'Sanction And High Risk Country Screening',
    pep: 'PEP',
    customerReputationCrime: 'Customer Reputation/Crime (adverse media)',
    sourceOfFunds: 'Source Of Funds',
    outOfProfileTransactionMonitoring: 'Out Of Profile Transaction Monitoring Result',
    updatedBy: 'Updated by',
    approvedBy: 'Approved by',
    riskLevel: 'Risk Level',
    riskScore: 'Risk Score',
    isPep: 'Is PEP',
    riskRatingDetails: 'Risk Rating Details'
  },
  text: {
    day: 'day',
    lastAchSubmmitted: 'Last ACH file submitted on {date}',
    serverDatetime: 'Server Date time: {date}',
    lastAchSubmmittedFilter: 'Outgoing ACH transfers History for the last',
    confirmSubmitTriggerACH: 'Are you sure you want to trigger ACH?',
    confirmGenerateNewUploadToken: 'Are you sure you want to generate new document upload token?',
    noteTransactionUnderProcessing: 'Note: These Transactions under processing',
    executionScheduledMarkAsCancelled: 'and all transfer status EXECUTION_SCHEDULED will be marked as CANCELLED'
  },
  messages: {
    rules: {
      amountMustBeGreaterThan: 'Amount must be greater than 0',
      invalidPhoneNumber: 'Invalid Phone Number',
      invalidCountryCode: 'Invalid Country Code'
    },
    error: {
      payeeAmountRequired: 'Payee or Amount is Required',
      endDateRequired: 'End Date is Required',
      maxAmountShouldGreaterThanMinAmount: 'Max Amount should be greater than Min Amount',
      repeatPasswordNotMatch: 'Password and repeat password do not match',
      passwordExpired: 'Your password has reached its expiration date as per the password expiration policy. To continue with the login process, please reset your password.'
    },
    managePrepaidCard: {
      noAvailableAccountsMessage: 'No available accounts'
    },
    success: {
      generateDocUploadToken: 'New upload token generated',
      payoffQuote: 'Request Payoff Quote Inquiry successfully',
      passwordHasBeenReset: 'Password has been reset successfully'
    },
    defaultNoOptionsMessage: 'Sorry, no matching options.',
    areYouSureYouWantToDelete: 'Are you sure you want to delete?',
    resetToTemporaryPassword: 'Request password reset for a user. User will receive instructions how to log in with temporary password, as well as information that will have to change his password after temporary will be used to log in.',
    phoneNumberUpdated: 'Phone number has been updated',
    newPhoneNumberAdded: 'New phone number has been added',
    kycVerificationUpdatedSuccessfully: 'KYC Verification status has been set to {type}',
    areYouSureYouWantToDeactivateCard: 'Are you sure you want to deactivate card?',
    terminateSessionConfirm: 'An active session is currently logged in with this user. Do you want to terminate it?',
    loanDatatableMapping: {
      createdDataMappingSuccess: 'Created datatable mapping successfully!',
      deletedSuccess: 'Deleted datatable mapping #{id} successfully!',
      noDataMappingYet: 'No datatable mapping yet'
    },
    noData: 'No data!',
    approveSuccess: 'Approved Successfully',
    rejectSuccess: 'Rejected Successfully'
  }
};
