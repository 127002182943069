<template>
  <div
    ref="repaymentTable"
    class="scrollable-body"
  >
    <table
      class="table table-striped"
    >
      <thead style="position: sticky; top: 0;">
        <tr>
          <th
            colspan="5"
            scope="colgroup"
          />
          <th
            colspan="2"
            scope="colgroup"
          >
            {{ $t('label.heading.loanAmountAndBalance') }}
          </th>
          <th
            colspan="3"
            scope="colgroup"
          >
            {{ $t('label.heading.totalCostOfLoan') }}
          </th>
          <th
            colspan="6"
            scope="colgroup"
          >
            {{ $t('label.heading.installmentTotals') }}
          </th>
        </tr>
        <tr>
          <th scope="col">
            #
          </th>
          <th scope="col">
            {{ $t('label.heading.days') }}
          </th>
          <th scope="col">
            {{ $t('label.heading.date') }}
          </th>
          <th scope="col">
            {{ $t('label.heading.paidDate') }}
          </th>
          <th scope="col">
            &nbsp;
          </th>

          <th scope="col">
            {{ $t('label.heading.principalDue') }}
          </th>
          <th scope="col">
            {{ $t('label.heading.balanceOfLoan') }}
          </th>

          <th scope="col">
            {{ $t('label.heading.interest') }}
          </th>
          <th scope="col">
            {{ $t('label.heading.fees') }}
          </th>
          <th scope="col">
            {{ $t('label.heading.penalties') }}
          </th>

          <th scope="col">
            {{ $t('label.heading.due') }}
          </th>
          <th scope="col">
            {{ $t('label.heading.paid') }}
          </th>
          <th scope="col">
            {{ $t('label.heading.inadvance') }}
          </th>
          <th scope="col">
            {{ $t('label.heading.late') }}
          </th>
          <th
            v-if="isWaived"
            scope="col"
          >
            {{ $t('label.heading.waived') }}
          </th>
          <th scope="col">
            {{ $t('label.heading.outstanding') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="period, index in displayData"
          :key="index"
          class="p-3"
        >
          <td>{{ period.period }}</td>
          <td>{{ period.daysInPeriod }}</td>
          <td>{{ dateStringToFormat(period.dueDate) }}</td>
          <td>{{ dateStringToFormat(period.obligationsMetOnDate) }}</td>
          <td>
            <div v-if="period.obligationsMetOnDate">
              <i class="fas fa-check" />
            </div>
          </td>
          <td>
            <span v-if="period.principalDue">
              {{ currencyFormatter(period.principalDue, currencySymbol) }}
            </span>
          </td>
          <td>
            {{ currencyFormatter(period.principalLoanBalanceOutstanding, currencySymbol) }}
          </td>
          <td>
            {{ currencyFormatter(period.interestOriginalDue, currencySymbol) }}
          </td>
          <td>
            {{ currencyFormatter(period.feeChargesDue, currencySymbol) }}
          </td>
          <td>
            {{ currencyFormatter(period.penaltyChargesDue, currencySymbol) }}
          </td>
          <td>
            {{ currencyFormatter(period.totalDueForPeriod, currencySymbol) }}
          </td>
          <td>
            {{ currencyFormatter(period.totalPaidForPeriod, currencySymbol) }}
          </td>
          <td>
            {{ currencyFormatter(period.totalPaidInAdvanceForPeriod, currencySymbol) }}
          </td>
          <td>
            {{ currencyFormatter(period.totalPaidLateForPeriod, currencySymbol) }}
          </td>
          <td v-if="isWaived">
            {{ currencyFormatter(period.totalWaivedForPeriod, currencySymbol) }}
          </td>
          <td>
            {{ currencyFormatter(period.totalOutstandingForPeriod, currencySymbol) }}
          </td>
        </tr>
      </tbody>
      <tfoot style="position: sticky; bottom: 0;">
        <tr>
          <th />
          <th>{{ data.loanTermInDays }}</th>
          <th colspan="3">
            {{ $t('label.heading.total') }}
          </th>
          <th>{{ currencyFormatter(data.totalPrincipalExpected, currencySymbol) }}</th>
          <th />
          <th>{{ currencyFormatter(data.totalInterestCharged, currencySymbol) }}</th>
          <th>{{ currencyFormatter(data.totalFeeChargesCharged, currencySymbol) }}</th>
          <th>{{ currencyFormatter(data.totalPenaltyChargesCharged, currencySymbol) }}</th>
          <th>{{ currencyFormatter(data.totalRepaymentExpected, currencySymbol) }}</th>
          <th>{{ currencyFormatter(data.totalRepayment, currencySymbol) }}</th>
          <th>{{ currencyFormatter(data.totalPaidInAdvance, currencySymbol) }}</th>
          <th>{{ currencyFormatter(data.totalPaidLate, currencySymbol) }} </th>
          <th v-if="isWaived">
            {{ currencyFormatter(data.totalWaived, currencySymbol) }}
          </th>
          <th>{{ currencyFormatter(data.totalOutstanding, currencySymbol) }}</th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useInfiniteScroll } from '@vueuse/core';
import { dateStringToFormat } from '@/helper/dateHelper';
import { currencyFormatter } from '@/helper/currencyHelper';

const props = defineProps({
  distance: {
    type: Number,
    default: 50
  },
  data: {
    type: Object,
    default: () => ({})
  }
});
const repaymentTable = ref(null);
const displayData = ref([]);
watch(() => props.data, () => {
  if (!props.data.periods.length) return;
  displayData.value = props.data.periods.slice(0, props.distance);
});
const isWaived = computed(() => props.data?.totalWaived > 0);
const currencySymbol = computed(() => props.data.currency?.displaySymbol || '');

useInfiniteScroll(
  repaymentTable,
  () => {
    const endIndex = displayData.value.length + props.distance;
    displayData.value.push(...props.data.periods.slice(displayData.value.length, Math.min(endIndex, props.data.periods.length)));
  },
  { distance: props.distance }
);

</script>

<style scoped>
.scrollable-body {
  display:block;
  overflow-y: auto;
  height: 800px;
}
</style>
