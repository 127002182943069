export function ViewOfficeController (scope, routeParams, route, location, resourceFactory) {
  scope.charges = [];

  resourceFactory.officeResource.get({ officeId: routeParams.id }, function (data) {
    scope.office = data;
  });

  resourceFactory.DataTablesResource.getAllDataTables({ apptable: 'm_office' }, function (data) {
    scope.officedatatables = data;
  });
  scope.dataTableChange = function (officedatatable) {
    resourceFactory.DataTablesResource.getTableDetails({
      datatablename: officedatatable.registeredTableName,
      entityId: routeParams.id,
      genericResultSet: 'true'
    }, function (data) {
      scope.datatabledetails = data;
      scope.datatabledetails.isData = data.data.length > 0;
      scope.datatabledetails.isMultirow = data.columnHeaders[0].columnName === 'id';
      scope.showDataTableAddButton = !scope.datatabledetails.isData || scope.datatabledetails.isMultirow;
      scope.showDataTableEditButton = scope.datatabledetails.isData && !scope.datatabledetails.isMultirow;
      if (scope.datatabledetails.isData) {
        const columnHeaderIndexMap = data.columnHeaders.reduce((map, column, index) => {
          map[column.columnName] = index;
          return map;
        }, {});
        if (scope.datatabledetails.isMultirow) {
          scope.datatabledetails.data = data.data.map(entry => {
            const row = entry.row.map((value, index) => {
              const column = data.columnHeaders[columnHeaderIndexMap[data.columnHeaders[index].columnName]];
              if (column.columnType === 'multiselect' || column.columnType === 'dropdown') {
                const ids = value.split(',');
                return column.columnValues
                  .filter(obj => ids.includes(obj.id.toString()))
                  .map(obj => obj.value)
                  .toString();
              }
              return value;
            });
            return { row };
          });
        } else {
          const row = data.data[0].row;
          scope.datatabledetails.singleRow = data.columnHeaders.map((column, index) => {
            const value = row[index];
            const columnValue = column.columnValues.find(obj => obj.id === value);
            return { key: column.columnName, value: columnValue ? columnValue.value : value };
          });
        }
      }
    });
  };

  scope.deleteAll = function (apptableName, entityId) {
    resourceFactory.DataTablesResource.delete({ datatablename: apptableName, entityId, genericResultSet: 'true' }, {}, function () {
      route.reload();
    });
  };

  scope.viewDataTable = function (registeredTableName, data) {
    if (scope.datatabledetails.isMultirow) {
      location.path(`/viewdatatableentry/${registeredTableName}/${scope.office.id}/${data.row[0]}`);
    } else {
      location.path(`/viewsingledatatableentry/${registeredTableName}/${scope.office.id}`);
    }
  };
}

ViewOfficeController.$inject = ['$scope', '$routeParams', '$route', '$location', 'ResourceFactory'];
