<template>
  <BaseModal
    id="view-pep"
    hide-footer
    modal-size="lg"
  >
    <template #body>
      <base-table
        :items="items"
        :fields="pepFields"
      />
    </template>
  </BaseModal>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import BaseTable from '@/components/shared/data-grid/BaseTable.vue';
import i18n from '@/plugins/i18n';

defineProps({
  items: {
    type: Array,
    default: () => []
  }
});

const pepFields = [
  { key: 'source', label: i18n.t('label.heading.source') },
  { key: 'complyAdvantage', label: i18n.t('label.heading.complyAdvantage') }
];
</script>
