import _ from 'underscore';

export function TaskController (scope, resourceFactory, route, dateFilter, $uibModal, $location) {
  scope.clients = [];
  scope.loans = [];
  scope.offices = [];
  const idToNodeMap = {};
  scope.formData = {};
  scope.loanTemplate = {};
  scope.loanDisbursalTemplate = {};
  scope.date = {};
  scope.checkData = [];
  scope.isCollapsed = true;
  scope.approveData = {};
  scope.restrictDate = new Date();
  // this value will be changed within each specific tab
  scope.requestIdentifier = 'loanId';
  scope.errorMsg = '';

  scope.itemsPerPage = 15;

  scope.loanRescheduleData = [];
  scope.checkForBulkLoanRescheduleApprovalData = [];
  scope.rescheduleData = function () {
    resourceFactory.loanRescheduleResource.getAll({ command: 'pending' }, function (data) {
      scope.loanRescheduleData = data;
    });
  };
  scope.rescheduleData();

  resourceFactory.checkerInboxResource.get({ templateResource: 'searchtemplate' }, function (data) {
    scope.checkerTemplate = data;
  });
  resourceFactory.checkerInboxResource.search(function (data) {
    scope.searchData = data;
  });
  scope.viewUser = function (item) {
    scope.userTypeahead = true;
    scope.formData.user = item.id;
  };
  scope.checkerInboxAllCheckBoxesClicked = function () {
    const newValue = !scope.checkerInboxAllCheckBoxesMet();
    if (!angular.isUndefined(scope.searchData)) {
      for (let i = scope.searchData.length - 1; i >= 0; i -= 1) {
        scope.checkData[scope.searchData[i].id] = newValue;
      }
    }
  };
  scope.checkerInboxAllCheckBoxesMet = function () {
    let checkBoxesMet = 0;
    if (!angular.isUndefined(scope.searchData)) {
      _.each(scope.searchData, function (data) {
        if (_.has(scope.checkData, data.id)) {
          if (scope.checkData[data.id] === true) {
            checkBoxesMet += 1;
          }
        }
      });
      return (checkBoxesMet === scope.searchData.length);
    }
  };
  scope.clientApprovalAllCheckBoxesClicked = function (officeName) {
    const newValue = !scope.clientApprovalAllCheckBoxesMet(officeName);
    if (!angular.isUndefined(scope.groupedClients[officeName])) {
      for (let i = scope.groupedClients[officeName].length - 1; i >= 0; i -= 1) {
        scope.approveData[scope.groupedClients[officeName][i].id] = newValue;
      }
    }
  };
  scope.clientApprovalAllCheckBoxesMet = function (officeName) {
    let checkBoxesMet = 0;
    if (!angular.isUndefined(scope.groupedClients[officeName])) {
      _.each(scope.groupedClients[officeName], function (data) {
        if (_.has(scope.approveData, data.id)) {
          if (scope.approveData[data.id] === true) {
            checkBoxesMet++;
          }
        }
      });
      return (checkBoxesMet === scope.groupedClients[officeName].length);
    }
  };
  scope.loanApprovalAllCheckBoxesClicked = function (office) {
    const newValue = !scope.loanApprovalAllCheckBoxesMet(office);
    if (!angular.isUndefined(scope.offices)) {
      for (let i = office.loans.length - 1; i >= 0; i -= 1) {
        scope.loanTemplate[office.loans[i].id] = newValue;
      }
    }
  };
  scope.loanApprovalAllCheckBoxesMet = function (office) {
    let checkBoxesMet = 0;
    if (!angular.isUndefined(scope.offices)) {
      _.each(office.loans, function (data) {
        if (_.has(scope.loanTemplate, data.id)) {
          if (scope.loanTemplate[data.id] === true) {
            checkBoxesMet++;
          }
        }
      });
      return (checkBoxesMet === office.loans.length);
    }
  };
  scope.loanDisbursalAllCheckBoxesClicked = function () {
    const newValue = !scope.loanDisbursalAllCheckBoxesMet();
    if (!angular.isUndefined(scope.loans)) {
      for (let i = scope.loans.length - 1; i >= 0; i -= 1) {
        scope.loanDisbursalTemplate[scope.loans[i].id] = newValue;
      }
    }
  };
  scope.loanDisbursalAllCheckBoxesMet = function () {
    let checkBoxesMet = 0;
    if (!angular.isUndefined(scope.loans)) {
      _.each(scope.loans, function (data) {
        if (_.has(scope.loanDisbursalTemplate, data.id)) {
          if (scope.loanDisbursalTemplate[data.id] === true) {
            checkBoxesMet += 1;
          }
        }
      });
      return (checkBoxesMet === scope.loans.length);
    }
  };
  scope.approveOrRejectChecker = function (action) {
    if (scope.checkData) {
      $uibModal.open({
        templateUrl: 'approvechecker.html',
        controller: CheckerApproveCtrl,
        resolve: {
          action () {
            return action;
          }
        }
      });
    }
  };
  const CheckerApproveCtrl = function ($scope, $uibModalInstance, action) {
    $scope.approve = function () {
      const checkerParams = { command: action };
      const checkerData = { ids: Object.keys(scope.checkData).filter(key => scope.checkData[key]) };

      resourceFactory.checkerInboxResource.save(checkerParams, checkerData, function () {
        scope.search();
        scope.checkData = [];
      });
      $scope.cancel();
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.deleteChecker = function () {
    if (scope.checkData) {
      $uibModal.open({
        templateUrl: 'deletechecker.html',
        controller: CheckerDeleteCtrl
      });
    }
  };
  const CheckerDeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      const checkerData = { ids: Object.keys(scope.checkData).filter(key => scope.checkData[key]) };

      resourceFactory.checkerInboxResource.delete({}, checkerData, function () {
        scope.search();
        scope.checkData = [];
      });
      $scope.cancel();
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.approveClient = function () {
    if (scope.approveData) {
      $uibModal.open({
        templateUrl: 'approveclient.html',
        controller: ApproveClientCtrl,
        resolve: {
          items () {
            return scope.approveData;
          }
        }
      });
    }
  };

  const ApproveClientCtrl = function ($scope, $uibModalInstance, items) {
    $scope.restrictDate = new Date();
    $scope.date = {};
    $scope.date.actDate = new Date();
    $scope.approve = function (act) {
      const activate = {};
      activate.activationDate = dateFilter(act, scope.df);
      activate.dateFormat = scope.df;
      activate.locale = scope.optlang.code;
      let totalClient = 0;
      let clientCount = 0;
      _.each(items, function (value) {
        if (value === true) {
          totalClient++;
        }
      });

      scope.batchRequests = [];
      scope.requestIdentifier = 'clientId';

      let reqId = 1;
      _.each(items, function (value, key) {
        if (value === true) {
          scope.batchRequests.push({
            requestId: reqId++,
            relativeUrl: `clients/${key}?command=activate`,
            method: 'POST',
            body: JSON.stringify(activate)
          });
        }
      });

      resourceFactory.batchResource.post(scope.batchRequests, function (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].statusCode === '200') {
            clientCount++;
            if (clientCount === totalClient) {
              route.reload();
            }
          }
        }
      });

      scope.approveData = {};
      $uibModalInstance.close('delete');
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.routeTo = function (id) {
    $location.path(`viewcheckerinbox/${id}`);
  };

  scope.routeToClient = function (id) {
    $location.path(`viewclient/${id}`);
  };

  function getAllOffice () {
    resourceFactory.officeResource.getAllOffices(function (data) {
      scope.offices = data;
      for (const i in data) {
        data[i] = {
          ...data[i],
          loans: []
        };
        idToNodeMap[data[i].id] = data[i];
      }

      scope.loanResource = function () {
        resourceFactory.loanResource.getAllLoans({ limit: '1000', sqlSearch: 'l.loan_status_id in (100,200)' }, function (loanData) {
          scope.loans = loanData.pageItems;
          for (const i in scope.loans) {
            if (scope.loans[i].status.pendingApproval) {
              let tempOffice;
              if (scope.loans[i].clientOfficeId) {
                tempOffice = idToNodeMap[scope.loans[i].clientOfficeId];
                tempOffice.loans.push(scope.loans[i]);
              } else if (scope.loans[i].group) {
                tempOffice = idToNodeMap[scope.loans[i].group.officeId];
                tempOffice.loans.push(scope.loans[i]);
              }
            }
          }

          const finalArray = [];
          for (const i in scope.offices) {
            if (scope.offices[i].loans && scope.offices[i].loans.length > 0) {
              finalArray.push(scope.offices[i]);
            }
          }
          scope.offices = finalArray;
        });
      };

      scope.loanResource();
    });
  }
  getAllOffice();

  resourceFactory.clientResource.getAllClients({ clientStatus: 'PENDING' }, function (data) {
    scope.groupedClients = _.groupBy(data.pageItems, 'officeName');
  });

  scope.search = function () {
    scope.isCollapsed = true;
    const params = {
      actionName: scope.formData?.action || undefined,
      entity: scope.formData?.entity || undefined,
      resourceId: scope.formData?.resourceId || undefined,
      makerId: scope.formData?.user || undefined,
      makerDateTimeFrom: scope.date?.from ? dateFilter(scope.date.from, 'yyyy-MM-dd') : undefined,
      makerDateTimeto: scope.date?.to ? dateFilter(scope.date.to, 'yyyy-MM-dd') : undefined
    };
    resourceFactory.checkerInboxResource.search(params, function (data) {
      scope.searchData = data;
      if (scope.userTypeahead) {
        scope.formData.user = '';
        scope.userTypeahead = false;
        scope.user = '';
      }
    });
  };

  scope.approveLoan = function () {
    if (scope.loanTemplate) {
      $uibModal.open({
        templateUrl: 'approveloan.html',
        controller: ApproveLoanCtrl
      });
    }
  };

  const ApproveLoanCtrl = function ($scope, $uibModalInstance) {
    $scope.approve = function () {
      scope.bulkApproval();
      getAllOffice();
      $uibModalInstance.close('approve');
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.bulkApproval = function () {
    scope.formData.approvedOnDate = dateFilter(new Date(), scope.df);
    scope.formData.dateFormat = scope.df;
    scope.formData.locale = scope.optlang.code;
    let selectedAccounts = 0;
    let approvedAccounts = 0;
    _.each(scope.loanTemplate, function (value) {
      if (value === true) {
        selectedAccounts++;
      }
    });

    scope.batchRequests = [];
    scope.requestIdentifier = 'loanId';

    let reqId = 1;
    _.each(scope.loanTemplate, function (value, key) {
      if (value === true) {
        scope.batchRequests.push({
          requestId: reqId++,
          relativeUrl: `loans/${key}?command=approve`,
          method: 'POST',
          body: scope.formData
        });
      }
    });

    resourceFactory.batchResource.post(scope.batchRequests, function (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].statusCode === '200') {
          approvedAccounts++;
          scope.loanTemplate[data[i].body.loanId] = false;
          if (selectedAccounts === approvedAccounts) {
            scope.loanResource();
          }
        }
      }
    }, function (error) {
      scope.errorMsg = error.data.defaultUserMessage;
    });
  };

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };

  scope.disburseLoan = function () {
    if (scope.loanDisbursalTemplate) {
      $uibModal.open({
        templateUrl: 'disburseloan.html',
        controller: DisburseLoanCtrl
      });
    }
  };

  const DisburseLoanCtrl = function ($scope, $uibModalInstance) {
    $scope.disburse = function () {
      scope.bulkDisbursal();
      route.reload();
      $uibModalInstance.close('disburse');
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.bulkDisbursal = function () {
    scope.formData.actualDisbursementDate = dateFilter(new Date(), scope.df);
    scope.formData.dateFormat = scope.df;
    scope.formData.locale = scope.optlang.code;

    let selectedAccounts = 0;
    let approvedAccounts = 0;
    _.each(scope.loanDisbursalTemplate, function (value) {
      if (value === true) {
        selectedAccounts++;
      }
    });

    scope.batchRequests = [];
    scope.requestIdentifier = 'loanId';

    let reqId = 1;
    _.each(scope.loanDisbursalTemplate, function (value, key) {
      if (value === true) {
        scope.batchRequests.push({
          requestId: reqId++,
          relativeUrl: `loans/${key}?command=disburse`,
          method: 'POST',
          body: JSON.stringify(scope.formData)
        });
      }
    });

    resourceFactory.batchResource.post(scope.batchRequests, function (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].statusCode === '200') {
          approvedAccounts++;
          data[i].body = JSON.parse(data[i].body);
          scope.loanDisbursalTemplate[data[i].body.loanId] = false;
          if (selectedAccounts === approvedAccounts) {
            scope.loanResource();
          }
        }
      }
    });
  };
  scope.approveBulkLoanReschedule = function () {
    if (scope.checkForBulkLoanRescheduleApprovalData) {
      $uibModal.open({
        templateUrl: 'loanreschedule.html',
        controller: ApproveBulkLoanRescheduleCtrl
      });
    }
  };

  const ApproveBulkLoanRescheduleCtrl = function ($scope, $uibModalInstance) {
    $scope.approveLoanReschedule = function () {
      scope.bulkLoanRescheduleApproval();
      route.reload();
      $uibModalInstance.close('approveLoanReschedule');
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
  scope.checkerInboxAllCheckBoxesClickedForBulkLoanRescheduleApproval = function () {
    const newValue = !scope.checkerInboxAllCheckBoxesMetForBulkLoanRescheduleApproval();
    scope.checkForBulkLoanRescheduleApprovalData = [];
    if (!angular.isUndefined(scope.loanRescheduleData)) {
      for (let i = scope.loanRescheduleData.length - 1; i >= 0; i -= 1) {
        scope.checkForBulkLoanRescheduleApprovalData[scope.loanRescheduleData[i].id] = newValue;
      }
    }
  };
  scope.checkerInboxAllCheckBoxesMetForBulkLoanRescheduleApproval = function () {
    let checkBoxesMet = 0;
    if (!angular.isUndefined(scope.loanRescheduleData)) {
      _.each(scope.loanRescheduleData, function (data) {
        if (_.has(scope.checkForBulkLoanRescheduleApprovalData, data.id)) {
          if (scope.checkForBulkLoanRescheduleApprovalData[data.id] === true) {
            checkBoxesMet++;
          }
        }
      });
      return (checkBoxesMet === scope.loanRescheduleData.length);
    }
  };
  scope.bulkLoanRescheduleApproval = function () {
    scope.formData.approvedOnDate = dateFilter(new Date(), scope.df);
    scope.formData.dateFormat = scope.df;
    scope.formData.locale = scope.optlang.code;
    // let selectedAccounts = 0;
    // _.each(scope.checkForBulkLoanRescheduleApprovalData, function (value) {
    //   if (value === true) {
    //     selectedAccounts += 1;
    //   }
    // });
    scope.batchRequests = [];
    scope.requestIdentifier = 'RESCHEDULELOAN';
    let reqId = 1;
    _.each(scope.checkForBulkLoanRescheduleApprovalData, function (value, key) {
      if (value === true) {
        const url = `rescheduleloans/${key}?command=approve`;
        const bodyData = JSON.stringify(scope.formData);
        const batchData = { requestId: reqId++, relativeUrl: url, method: 'POST', body: bodyData };
        scope.batchRequests.push(batchData);
      }
    });
    resourceFactory.batchResource.post(scope.batchRequests, function (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].statusCode === '200') {
          data[i].body = JSON.parse(data[i].body);
          scope.checkForBulkLoanRescheduleApprovalData[data[i].body.resourceId] = false;
        }
      }
    });
  };
}

TaskController.$inject = ['$scope', 'ResourceFactory', '$route', 'dateFilter', '$uibModal', '$location'];
