<template>
  <div class="btn-group">
    <button
      v-if="validEditStatus.includes(cardItem?.status?.toLowerCase())"
      v-has-permission="'UPDATE_LIMITS_CARD'"
      class="btn btn-primary"
      @click="editLimit"
    >
      <i
        class="fas fa-pencil-alt me-1"
      />
      {{ $t('label.button.editPeriodicLimit') }}
    </button>
    <base-modal
      ref="editPeriodicLimitModal"
      on-submit="onSubmit"
      modal-size="xl"
      scrollable
      hide-footer
      @hidden="resetForm"
      @on-show="populateData"
    >
      <template #header>
        <div>
          <h4>{{ $t('label.heading.editPeriodicLimit') }}</h4>
        </div>
      </template>
      <template #body>
        <form-wrapper
          ref="formPeriodicLimit"
          @submit="onSubmit"
        >
          <div
            v-for="rule in velocityRules"
            :key="rule.timeUnit"
          >
            {{ rule.timeUnit }}
            <hr>
            <div class="row form-group align-items-center">
              <label class="control-label col-2 pb-3">
                {{ $t('label.input.periodicLimit') }}
              </label>
              <input-amount
                id="periodic-limit"
                v-model="rule.value"
                class="col-5"
              />
            </div>
            <div
              v-for="(territorialLimit, index) in rule.territorialLimits"
              :key="index"
              class="row form-group align-items-center"
            >
              <label class="control-label col-2">
                {{ $t(`label.input.${territorialLimit.territorialityType}`) }}
              </label>
              <input-amount
                id="transaction-amount"
                v-model="territorialLimit.transactionAmount"
                vertical
                class="col-5"
                :label="$t('label.input.transactionAmount')"
              />
              <input-text
                :id="`number-of-transactions-${territorialityType}`"
                v-model="territorialLimit.numberOfTransactions"
                vertical
                type="number"
                class="col-5"
                :label="$t('label.input.numberOfTransactions')"
              />
            </div>
          </div>
          <div class="pull-right">
            <button
              type="button"
              class="btn btn-default"
              @click.prevent="cancelModal"
            >
              {{ $t('label.button.cancel') }}
            </button>
            <button
              type="submit"
              class="btn btn-primary"
            >
              {{ $t('label.button.save') }}
            </button>
          </div>
        </form-wrapper>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import { validEditStatus } from '@/utils/dictionaries/cardButtonStatuses.dictionary';
import { InputText, InputAmount, FormWrapper } from '@/components/shared/form-input/index';
import BaseModal from '@/components/shared/BaseModal.vue';
import { ref } from 'vue';
import { get } from '@vueuse/core';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import i18n from '@/plugins/i18n';
import { sortByListOfItems } from '@/utils/common.js';
const editPeriodicLimitModal = ref('');
const formPeriodicLimit = ref(null);
const emit = defineEmits(['onStatusChanged']);

const velocityRules = ref([
  {
    controls: [
      'PERIODIC_LIMIT'
    ],
    type: 'AMOUNT',
    value: '',
    timePeriod: 1,
    timeUnit: 'DAYS',
    territorialLimits: [
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'INT'
      },
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'NAT'
      },
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'ONS'
      }
    ]
  },
  {
    controls: [
      'PERIODIC_LIMIT'
    ],
    type: 'AMOUNT',
    value: '',
    timePeriod: 1,
    timeUnit: 'WEEKS',
    territorialLimits: [
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'ONS'
      },
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'NAT'
      },
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'INT'
      }
    ]
  },
  {
    controls: [
      'PERIODIC_LIMIT'
    ],
    type: 'AMOUNT',
    value: '',
    timePeriod: 1,
    timeUnit: 'MONTHS',
    territorialLimits: [
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'ONS'
      },
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'NAT'
      },
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'INT'
      }
    ]
  },
  {
    controls: [
      'PERIODIC_LIMIT'
    ],
    type: 'AMOUNT',
    value: '',
    timePeriod: 1,
    timeUnit: 'YEARS',
    territorialLimits: [
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'ONS'
      },
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'NAT'
      },
      {
        transactionAmount: '',
        numberOfTransactions: '',
        territorialityType: 'INT'
      }
    ]
  }
]);
const props = defineProps({
  cardItem: {
    type: Object,
    default: () => ({})
  },
  cardType: {
    type: String,
    default: ''
  }
});

const populateData = () => {
  if (props.cardItem.velocityRules.length) {
    velocityRules.value = JSON.parse(JSON.stringify(
      sortByListOfItems(props.cardItem.velocityRules, 'timeUnit', ['DAYS', 'WEEKS', 'MONTHS', 'YEARS'])
    ));
  }
};
const resetForm = () => {
  formPeriodicLimit.value.reset();
};

const editLimit = () => {
  get(editPeriodicLimitModal).show();
};

const cancelModal = () => {
  get(editPeriodicLimitModal).hide();
};

const onSubmit = async () => {
  try {
    await apiService.cardsResource.update(props.cardItem.id, { velocityRules: velocityRules.value }, { command: 'update_limits' });
    $notify.success(i18n.t('label.updateLimitSuccessfully'));
    emit('onStatusChanged');
    cancelModal();
  } catch (error) {
    $notify.error(error);
  }
};
</script>
