<template>
  <form-wrapper ref="form">
    <div class="d-flex">
      <h5 class="col-sm-3">
        {{ $t('label.heading.reportDetails') }}
      </h5>

      <div class="col">
        <input-text
          v-model="formData.reportName"
          :label="$t('label.input.reportName')"
          :disabled="isCoreReport"
          class="col-md-5"
          vertical
          required
        />
        <input-select
          v-model="formData.reportType"
          :options="template.allowedReportTypes"
          :label="$t('label.input.reportType')"
          :disabled="isCoreReport"
          class="col-md-5"
          not-clearable
          vertical
          required
        />
        <input-select
          v-model="formData.reportSubType"
          :options="template.allowedReportSubTypes"
          :disabled="['Table', 'Pentaho'].includes(formData.reportType) || isCoreReport"
          :label="$t('label.input.reportSubType')"
          class="col-md-5"
          vertical
        />
        <input-check-box
          id="useReport"
          v-model="formData.useReport"
          :label="$t('label.input.userReport')"
          :disabled="isCoreReport"
          class="col-md-5 pb-3"
          no-padding
          reverse
        />
        <input-text
          v-model="formData.reportCategory"
          :label="$t('label.input.reportCategory')"
          :disabled="isCoreReport"
          class="col-md-5"
          vertical
        />
        <input-text
          v-model="formData.description"
          :label="$t('label.input.description')"
          :disabled="isCoreReport"
          class="col-md-5"
          type="textarea"
          vertical
        />
      </div>
    </div>
    <template v-if="formData.reportType !== 'Pentaho'">
      <hr>
      <div class="d-flex">
        <h5 class="col-sm-3">
          {{ $t('label.heading.sql') }}
          <span class="required">*</span>
        </h5>
        <div class="col">
          <input-text
            v-model="formData.reportSql"
            :disabled="isCoreReport"
            class="col-md-5"
            type="textarea"
            vertical
            required
          />
        </div>
      </div>
    </template>
    <hr>
    <div class="d-flex">
      <h5 class="col-sm-3">
        {{ $t('label.heading.reportParameters') }}
      </h5>
      <div class="col">
        <div
          v-if="!isCoreReport"
          class="d-flex"
        >
          <input-select
            v-model="selectedReportParameter"
            :options="template.allowedParameters"
            class="col-md-5 me-3"
            value-field="id"
            text-field="parameterName"
            placeholder="Select parameter"
            vertical
          />
          <div>
            <button
              :disabled="!selectedReportParameter"
              class="btn btn-primary"
              @click="newReportParameterHandler"
            >
              <i class="fas fa-plus me-2" />{{ $t('label.button.add') }}
            </button>
          </div>
        </div>
        <base-table
          v-show="reportParameters.length"
          :items="reportParameters"
          :fields="reportParameterColumns"
        >
          <template #selectedParameterName="{ item }">
            {{ item.selectedParameterName }}
          </template>
          <template #reportParameterName="{ item }">
            <input-text
              v-if="!isCoreReport"
              :key="item.key"
              v-model="item.reportParameterName"
              :disabled="isCoreReport"
              vertical
              required
            />
            <div v-else>
              {{ item.reportParameterName }}
            </div>
          </template>
          <template
            v-if="!isCoreReport"
            #actions="{ item }"
          >
            <button
              class="btn btn-light"
              type="button"
              @click="() => reportParameters.splice(reportParameters.indexOf(item), 1)"
            >
              <i class="fas fa-close" />
            </button>
          </template>
        </base-table>
      </div>
    </div>
    <template v-if="formData.reportType === 'Pentaho'">
      <hr>
      <div class="d-flex">
        <h5 class="col-sm-3">
          {{ $t('label.heading.s3Url') }}
          <span class="required">*</span>
        </h5>
        <div class="col">
          <input-text
            v-model="formData.fileUrl"
            class="col-md-5"
            vertical
            required
          />
        </div>
      </div>
    </template>
    <div class="offset-sm-3 d-flex">
      <a
        class="btn btn-default"
        :href="isEdit ? `#/system/viewreport/${reportId}` : '#/system/reports'"
      >
        {{ $t('label.button.cancel') }}
      </a>
      <button
        v-has-permission="'UPDATE_REPORT'"
        class="btn btn-primary"
        @click="submitHandler"
      >
        {{ $t('label.button.save') }}
      </button>
    </div>
  </form-wrapper>
</template>

<script setup>
import {
  InputText,
  InputSelect,
  InputCheckBox,
  FormWrapper
} from '@/components/shared/form-input/index';
import BaseTable from '@/components/shared/data-grid/BaseTable.vue';
import i18n from '@/plugins/i18n';
import { onMounted, ref } from 'vue';
import { get, set } from '@vueuse/core';
import { $notify } from '@/plugins/prototypes';
import apiService from '@/services/apiService';

const props = defineProps({
  reportId: {
    type: String,
    default: null
  },
  isEdit: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(['on-submit']);

const reportParameterColumns = [
  { key: 'selectedParameterName', label: i18n.t('label.head.parameter') },
  { key: 'reportParameterName', label: i18n.t('label.head.parameterNamePassedToPentaho') },
  { key: 'actions', label: i18n.t('label.head.actions') }
];

const form = ref();
const formData = ref({
  reportName: '',
  reportType: '',
  reportSubType: '',
  reportCategory: '',
  description: '',
  useReport: false,
  reportSql: '',
  fileUrl: ''
});
const template = ref({
  allowedReportTypes: [],
  allowedReportSubTypes: [],
  allowedParameters: []
});
const reportParameters = ref([]);
const selectedReportParameter = ref('');
const isCoreReport = ref(false);

onMounted(async () => {
  if (props.isEdit) await getReportDetails();
  else await getTemplate();
});

async function getTemplate () {
  try {
    const { data: response } = await apiService.report.template();
    set(template, response);
  } catch (error) {
    $notify.error(error.message);
  }
}

async function getReportDetails () {
  try {
    const {
      data: {
        allowedReportTypes,
        allowedReportSubTypes,
        allowedParameters,
        ...reportDetails
      }
    } = await apiService.report.get(props.reportId, { template: true });

    set(template, {
      allowedParameters,
      allowedReportTypes,
      allowedReportSubTypes
    });

    set(formData, {
      reportName: reportDetails.reportName,
      reportType: reportDetails.reportType,
      reportSubType: reportDetails.reportSubType,
      reportCategory: reportDetails.reportCategory,
      description: reportDetails.description,
      useReport: reportDetails.useReport,
      reportSql: reportDetails.reportSql || '',
      fileUrl: reportDetails.fileUrl || ''
    });

    set(reportParameters, reportDetails.reportParameters.map(item => ({
      key: Date.now(),
      parameterId: item.id,
      reportParameterName: item.reportParameterName,
      selectedParameterName: item.parameterName
    })));
    set(isCoreReport, reportDetails.coreReport);
  } catch (error) {
    $notify.error(error);
  }
}

function newReportParameterHandler () {
  // Using current date as the key to prevent the reactivity issue
  const key = Date.now();
  const selectParemeter = get(template).allowedParameters.find(parameter => parameter.id === get(selectedReportParameter));
  const newParameter = {
    key,
    parameterId: selectParemeter.id,
    reportParameterName: '',
    selectedParameterName: selectParemeter.parameterName
  };

  get(reportParameters).push(newParameter);
  set(selectedReportParameter, '');
}

function submitHandler () {
  const formattedReportParameters = get(reportParameters).map(parameter => ({
    parameterId: parameter.parameterId,
    reportParameterName: parameter.reportParameterName
  }));

  const report = {
    ...get(formData),
    reportParameters: formattedReportParameters
  };

  delete report.key;
  if (get(formData).reportType === 'Table') delete report.reportSubType;
  if (get(formData).reportType === 'Pentaho') {
    delete report.reportSubType;
    delete report.reportSql;
  }

  emits('submit', get(report));
}
</script>
