import SystemList from '@/pages/system/index.vue';
import ListCodeSystem from '@/pages/system/codes/index.vue';
import ViewCodeSystem from '@/pages/system/codes/view.vue';
import CreateCodeValueSystem from '@/pages/system/codes/code-values/create';
import EditCodeValueSystem from '@/pages/system/codes/code-values/edit';
import ViewRole from '@/pages/system/manage-roles/view.vue';
import CreateRole from '@/pages/system/manage-roles/create.vue';
import ManageRoles from '@/pages/system/manage-roles/index.vue';
import CreateDataTable from '@/pages/system/manage-data-tables/create.vue';
import EditDataTable from '@/pages/system/manage-data-tables/edit.vue';
import CheckerThreshold from '@/pages/system/checker-threshold.vue';
import LoanRoleMapping from '@/pages/system/loan-role-mapping/index.vue';
import ViewDataTable from '@/pages/system/manage-data-tables/view.vue';
import ManageDataTables from '@/pages/system/manage-data-tables/index.vue';
import CreateReport from '@/pages/system/manage-reports/create.vue';
import EditReport from '@/pages/system/manage-reports/edit.vue';

export const systemRoutes = [
  {
    path: '/system',
    name: 'SystemList',
    component: SystemList
  },
  {
    path: '/system/code',
    name: 'ListCodeSystem',
    component: ListCodeSystem
  },
  {
    path: '/system/code/:codeId/view',
    name: 'ViewCodeSystem',
    component: ViewCodeSystem
  },
  {
    path: '/system/code/:codeId/code-values/add',
    name: 'CreateCodeValueSystem',
    component: CreateCodeValueSystem
  },
  {
    path: '/system/code/:codeId/code-values/:codeValueId/edit',
    name: 'EditCodeValueSystem',
    component: EditCodeValueSystem
  },
  {
    path: '/system/manage-roles/:id(\\d+)',
    name: 'ViewRole',
    component: ViewRole
  },
  {
    path: '/system/manage-roles/create',
    name: 'CreateRole',
    component: CreateRole
  },
  {
    path: '/system/manage-roles',
    name: 'ManageRoles',
    component: ManageRoles
  },
  {
    path: '/system/manage-roles/loan-role-mapping',
    name: 'LoanRoleMapping',
    component: LoanRoleMapping
  },
  {
    path: '/system/datatables/create',
    name: 'CreateDataTable',
    component: CreateDataTable
  },
  {
    path: '/system/datatables/:dataTableName/edit',
    name: 'EditDataTable',
    component: EditDataTable
  },
  {
    path: '/system/mc-tasks/checker-threshold',
    name: 'CheckerThreshold',
    component: CheckerThreshold
  },
  {
    path: '/system/datatables/:dataTableName',
    name: 'ViewDataTable',
    component: ViewDataTable
  },
  {
    path: '/system/datatables',
    name: 'ManageDataTables',
    component: ManageDataTables
  },
  {
    path: '/system/mc-tasks/checker-threshold',
    name: 'CheckerThreshold',
    component: CheckerThreshold
  },
  {
    path: '/system/reports/create',
    name: 'CreateReport',
    component: CreateReport
  },
  {
    path: '/system/reports/:reportId/edit',
    name: 'EditReport',
    component: EditReport
  }
];
