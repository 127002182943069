<template>
  <MainContentLayout>
    <template #title>
      {{ $t('label.heading.verifyClient') }}
    </template>
    <div class="row">
      <div class="col col-lg-6">
        <InputSelect
          v-model="kycVerificationType"
          :label="$t('label.input.kycVerificationType')"
          :options="statusOptions"
          :description="$t('label.input.kycVerificationTypeDescription')"
          text-field="option"
          value-field="option"
          not-clearable
        />
        <div class="d-flex gap-2">
          <button
            class="offset-5 offset-lg-6 btn btn-outline-secondary"
            @click="$router.go(-1)"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            class="btn btn-primary"
            @click="updateKycStatus"
          >
            {{ $t('label.button.updateStatus') }}
          </button>
        </div>
      </div>
    </div>
  </MainContentLayout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import InputSelect from '@/components/shared/form-input/InputSelect.vue';
import i18n from '@/plugins/i18n';
import apiService from '@/services/apiService';
import router from '@/router/index';
import { onMounted, ref } from 'vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { $notify } from '@/plugins/prototypes';
import { get } from '@vueuse/core';

const statusOptions = [
  {
    option: 'FULL'
  },
  {
    option: 'PARTIAL'
  }
];

const kycVerificationType = ref('FULL');
const breadcrumbs = useBreadcrumbsStore();
const { clientId } = router.currentRoute.params;

onMounted(async () => {
  const { data: { displayName } } = await apiService.clients.get(clientId);
  breadcrumbs.setBreadcrumbs([
    { text: i18n.t('label.anchor.clients'), href: '#/clients' },
    { text: displayName, href: `#/viewclient/${clientId}` },
    { text: i18n.t('label.anchor.updateClientKycVerificationStatus') }
  ]);
});

async function updateKycStatus () {
  try {
    await apiService.clients.verify(clientId, { kycVerificationType: get(kycVerificationType) });
    location.assign(`#/viewclient/${clientId}`);
    $notify.success(i18n.t('messages.kycVerificationUpdatedSuccessfully', { type: get(kycVerificationType) }));
  } catch (error) {
    $notify.error(error);
  }
}

</script>
