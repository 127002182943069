<template>
  <main-content-layout>
    <template #title>
      <div class="d-flex">
        <div>{{ $t('label.head.searchJournalEntries') }}</div>
      </div>
    </template>
    <button
      class="btn btn-primary mb-2"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#filtering"
      aria-expanded="true"
      aria-controls="filtering"
    >
      {{ $t('label.button.filters') }} <i class="fa fa-filter fa-xs" />
    </button>
    <button
      class="btn btn-primary mb-2 ms-2"
      @click="exportExcel"
    >
      {{ $t('label.button.exportXlsExcel') }}
    </button>
    <div
      id="filtering"
      ref="collapse"
      class="collapse"
    >
      <div class="row">
        <div class="col-12 col-sm-4 col-md-3">
          <input-select
            ref="glAccount"
            v-model="formData.glAccount"
            :placeholder="$t('label.placeholder.glAccounts')"
            :options="template.glAccounts"
            value-field="id"
          >
            <template #selected-option="{item}">
              {{ item.name }} - {{ item.glCode }}
            </template>
            <template #option="{item}">
              {{ item.name }} - {{ item.glCode }}
            </template>
          </input-select>
        </div>
        <div class="col-12 col-sm-4 col-md-3">
          <input-select
            v-model="formData.officeId"
            :placeholder="$t('label.placeholder.offices')"
            :options="template.offices"
            value-field="id"
          />
        </div>
        <div class="col-12 col-sm-4 col-md-3">
          <input-select
            v-model="formData.manualEntriesOnly"
            :placeholder="$t('label.placeholder.entries')"
            :options="template.entries"
            text-field="option"
            value-field="value"
          />
        </div>
        <div class="col-12 col-md-6">
          <input-date-picker
            v-model="dateRange"
            :placeholder="$t('label.placeholder.fromDateToDate')"
            range
            @input="dateRangeUpdate"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <input-text
            v-model="formData.transactionId"
            :placeholder="$t('label.placeholder.transactionId')"
          />
        </div>
        <div class="col-6">
          <button
            class="btn btn-primary"
            @click="search"
          >
            {{ $t('label.button.search') }}
          </button>
          <button
            type="reset"
            class="btn text-primary"
            @click="resetFilters"
          >
            {{ $t('label.button.resetFilters') }}
          </button>
        </div>
      </div>
    </div>
    <journal-entries-table
      v-if="tableVisible"
      :transactions="transactions"
      :total-records="totalRecords"
      :limit="defaultParams.limit"
      @pagination-update="paginationUpdate"
      @entries-per-page="updateEntriesPerPage"
      @row-click="gotoTransaction"
    />
  </main-content-layout>
</template>
<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import { InputSelect, InputDatePicker, InputText } from '@/components/shared/form-input';
import { onMounted, reactive, ref } from 'vue';
import apiService from '@/services/apiService';
import JournalEntriesTable from '@/components/accounting/JournalEntriesTable.vue';
import { downloadHelper } from '@/helper/downloadHelper';
import router from '@/router';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import i18n from '@/plugins/i18n';

const breadCrumnsStore = useBreadcrumbsStore();
const {
  savingsId,
  loanId,
  creditAccountId,
  cardId,
  fromDate,
  glAccount,
  manualEntriesOnly,
  officeId,
  toDate,
  transactionId
} = router.currentRoute.query;

const defaultParams = reactive({
  dateFormat: 'dd MMMM yyyy',
  locale: 'en',
  limit: '15',
  offset: 0
});

const formData = reactive({
  glAccount: Number(glAccount) || null,
  officeId: Number(officeId) || null,
  manualEntriesOnly: manualEntriesOnly || null,
  transactionId: transactionId || null
});

const template = reactive({
  glAccounts: [],
  offices: [],
  entries: [
    { option: 'Manual Entries', value: true },
    { option: 'System Entries', value: false }
  ]
});

const dateRange = ref([]);
const totalRecords = ref(0);
const transactions = ref([]);

const tableVisible = ref(false);
const collapse = ref(null);
let thisCollapse = null;

onMounted(async () => {
  thisCollapse = new bootstrap.Collapse(collapse.value);
  const { data: glAccounts } = await apiService.accounting.getAllAccountsCOAS();
  template.glAccounts = glAccounts;
  const { data: offices } = await apiService.officeResource.getAllOffices();
  template.offices = offices;

  if (fromDate && toDate) {
    dateRange.value = [fromDate, toDate];
    formData.fromDate = fromDate;
    formData.toDate = toDate;
  }
  if (loanId) {
    breadCrumnsStore.setBreadcrumbs([
      { text: i18n.t('label.anchor.viewAccount'), href: `#/viewloanaccount/${loanId}` },
      { text: i18n.t('label.anchor.search-journal-entries') }
    ]);
  }

  if (savingsId) {
    breadCrumnsStore.setBreadcrumbs([
      { text: i18n.t('label.anchor.viewAccount'), href: `#/viewsavingaccount/${savingsId}` },
      { text: i18n.t('label.anchor.search-journal-entries') }
    ]);
  }

  if (cardId) {
    breadCrumnsStore.setBreadcrumbs([
      { text: i18n.t('label.anchor.viewAccount'), href: `#/viewcreditaccount/${cardId}` },
      { text: i18n.t('label.anchor.search-journal-entries') }
    ]);
  }
  search();
});

const dateRangeUpdate = (value) => {
  formData.fromDate = value[0];
  formData.toDate = value[0];
};

const filteredData = () => {
  return Object.fromEntries(
    Object.entries(formData)
      .filter((value) => value[1])
  );
};

const search = async () => {
  tableVisible.value = true;
  const params = filteredData();
  Object.assign(params, { savingsId, loanId, creditAccountId, cardId });

  router.push({ name: 'SearchJournalEntries', query: params }).catch(() => {});

  const { data: { pageItems, totalFilteredRecords } } = await apiService.accounting.getJournalEntries({ ...params, ...defaultParams });
  transactions.value = pageItems;
  totalRecords.value = totalFilteredRecords;
  thisCollapse.hide();
};

const resetFilters = () => {
  formData.glAccount = null;
  formData.officeId = null;
  formData.manualEntriesOnly = null;
  formData.transactionId = null;
  dateRange.value = null;
  formData.fromDate = null;
  formData.toDate = null;
};

const gotoTransaction = (id) => {
  const query = location.hash.split('?')[1];
  location.assign(`#/viewtransactions/${id}?${query}`);
};

const paginationUpdate = (offset) => {
  defaultParams.offset = offset;
  search();
};

const updateEntriesPerPage = (limit) => {
  defaultParams.limit = limit;
  search();
};

const exportExcel = async () => {
  const params = filteredData();
  let journalentriesReportFileName = `Journal Entries Report.xls`;
  const { fromDate, toDate } = formData;

  if (fromDate && toDate) {
    journalentriesReportFileName = `Journal Entries Report from ${fromDate}, to ${toDate}.xls`;
  }
  const additionalParams = {
    savingsId: savingsId || undefined,
    loanId: loanId || undefined,
    creditAccountId: creditAccountId || undefined,
    dateFormat: 'dd MMMM yyyy',
    locale: 'en'
  };
  Object.assign(params, additionalParams);
  const { data } = await apiService.accounting.downloadJournalEntries(params);
  downloadHelper(data, journalentriesReportFileName, 'application/vnd.ms-excel');
};

</script>
