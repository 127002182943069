import SearchJournalEntries from '@/pages/accounting/searchJournalEntries.vue';
import Accounting from '@/pages/accounting/index.vue';
export const acocuntingRoutes = [
  {
    path: '/accounting',
    name: 'Accounting',
    component: Accounting
  },
  {
    path: '/accounting/search-journal-entries',
    name: 'SearchJournalEntries',
    component: SearchJournalEntries
  }
];
