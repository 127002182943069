export function ViewAMLRuleGroupController (scope, routeParams, resourceFactory, location) {
  const ruleId = routeParams.id;

  scope.transferDirections = { RECEIVED: 'INCOMING', ORIGINATED: 'OUTGOING' };

  function loadRuleGroup () {
    resourceFactory.amlRulesResource.get({ ruleId }, scope.formData, function (resAmlRule) {
      resourceFactory.amlRulesResource.get({ ruleId, action: 'limitrule' }, scope.formData, function (resLimitRule) {
        resourceFactory.amlRulesResource.get({ ruleId, action: 'periodlimitrule' }, scope.formData, function (resPeriodLimitRule) {
          scope.ruleGroup = { ...resAmlRule, limitRules: [...resLimitRule.pageItems], periodLimitRules: [...resPeriodLimitRule.pageItems] };

          resourceFactory.paymentTypeResource.getAll(function (data) {
            scope.paymentTypes = data;
          }, handleResponseError);

          // Get Client Classification Codes
          resourceFactory.codeValue.get({ codeName: 'ClientClassification-PERSON' }, function (data) {
            // Store All Code Values For CLassification
            const codeValues = data;

            scope.ruleGroup.limitRules.map(limitRule => {
              // Mapping Payment Type
              try {
                limitRule.paymentType = scope.paymentTypes.find(paymentType => paymentType.id === limitRule.paymentType).name;
              } catch (error) {
                // If paymenttype does not exist
                limitRule.paymentType = 'ALL';
              }

              // Mapping Client Classfication
              try {
                limitRule.clientClassification = codeValues.find(codeValue => codeValue.id === limitRule.clientClassificationId).name;
              } catch (error) {
                limitRule.clientClassification = 'ALL';
              }
              return limitRule;
            });

            scope.ruleGroup.periodLimitRules.map(periodLimitRule => {
              try {
                periodLimitRule.paymentTypeId = scope.paymentTypes.find(paymentType => paymentType.id === periodLimitRule.paymentTypeId).name;
              } catch (error) {
                periodLimitRule.paymentTypeId = 'ALL';
              }
              try {
                periodLimitRule.clientClassification = codeValues.find(codeValue => codeValue.id === periodLimitRule.clientClassificationId).name;
              } catch (error) {
                periodLimitRule.clientClassification = 'ALL';
              }
              return periodLimitRule;
            });
          }, handleResponseError);
        }, handleResponseError);
      }, handleResponseError);
    }, handleResponseError);
  }

  loadRuleGroup();

  // Actions on Rule Group
  scope.routeToEditRuleGroup = function (id) {
    location.path(`/payments/aml/editrulegroup/${id}`);
  };

  // Actions on Rules
  scope.routeToViewLimitRule = function (id) {
    location.path(`/payments/aml/rulegroups/${ruleId}/limitrules/${id}`);
  };

  scope.routeToViewPeriodLimitRule = function (id) {
    location.path(`/payments/aml/rulegroups/${ruleId}/periodlimitrules/${id}`);
  };

  scope.routeToCreateLimitRule = function () {
    location.path(`/payments/aml/rulegroups/${ruleId}/createlimitrule`);
  };

  scope.routeToCreatePeriodLimitRule = function () {
    location.path(`/payments/aml/rulegroups/${ruleId}/createperiodlimitrule`);
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }
}

ViewAMLRuleGroupController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
