import _ from 'underscore';

export function MakeDataTableEntryController (scope, location, routeParams, resourceFactory, dateFilter, API_VERSION, $rootScope, Upload) {
  scope.tableName = routeParams.tableName;
  scope.entityId = routeParams.entityId;
  scope.fromEntity = routeParams.fromEntity;
  scope.columnHeaders = [];
  scope.columnData = {};
  scope.formDat = {};
  scope.formData = {};
  scope.tf = 'HH:mm';
  scope.files = {};
  scope.sectionData = [];
  scope.parentColumnOptions = [];

  scope.getSection = function (sectionId) {
    for (const i in scope.sectionData) {
      if (scope.sectionData[i].id === sectionId) {
        return scope.sectionData[i];
      }
    }
  };
  resourceFactory.DataTablesResource.getTableDetails({ datatablename: scope.tableName, entityId: scope.entityId, genericResultSet: 'true' },
    function (data) {
      // debugger

      let colName = data.columnHeaders[0].columnName;
      if (colName === 'id') {
        data.columnHeaders.splice(0, 1);
      }
      scope.datatableDisplayName = data.datatableDisplayName;
      colName = data.columnHeaders[0].columnName;
      if (colName === 'client_id' || colName === 'office_id' || colName === 'group_id' || colName === 'center_id' || colName === 'loan_id' || colName === 'savings_account_id' || colName === 'credit_account_id') {
        data.columnHeaders.splice(0, 1);
        scope.isCenter = colName === 'center_id';
      }
      for (const i in data.sections) {
        scope.sectionData[data.sections[i].position] = {
          title: data.sections[i].title,
          description: data.sections[i].description,
          id: data.sections[i].id,
          columnHeaders: [],
          hide: false,
          parentNameAttribute: data.sections[i].parentNameAttribute
        };
      }
      for (const i in data.columnHeaders) {
        if (data.columnHeaders[i].columnDisplayType === 'DATETIME') {
          scope.columnData[data.columnHeaders[i].columnName] = {};
        }
        data.columnHeaders[i].hide = false;
        const temp = scope.getSection(data.columnHeaders[i].sectionId);
        temp.columnHeaders.push(data.columnHeaders[i]);
        if (data.columnHeaders[i].parentNameAttribute !== undefined && scope.parentColumnOptions.indexOf(data.columnHeaders[i].parentNameAttribute) === -1) {
          scope.parentColumnOptions.push(data.columnHeaders[i].parentNameAttribute);
        }
      }
      scope.columnHeaders = data.columnHeaders;
    }
  );

  function updateShowHideField (colName, state) {
    for (const i in scope.sectionData) {
      if (scope.sectionData[i].parentNameAttribute !== undefined && scope.sectionData[i].parentNameAttribute === colName) {
        scope.sectionData[i].hide = !state;
      }
      for (const j in scope.sectionData[i].columnHeaders) {
        if (scope.sectionData[i].columnHeaders[j].parentNameAttribute !== undefined &&
          scope.sectionData[i].columnHeaders[j].parentNameAttribute === colName) {
          scope.sectionData[i].columnHeaders[j].hide = !state;
        }
      }
    }
  }

  scope.containsValue = function (colName, state) {
    const hasValue = scope.parentColumnOptions.indexOf(colName) !== -1;
    if (hasValue) {
      updateShowHideField(colName, state);
    }
  };
  // return input type
  scope.fieldType = function (type) {
    let fieldType = '';
    if (type) {
      if (type === 'CODELOOKUP' || type === 'CODEVALUE') {
        fieldType = 'SELECT';
      } else if (type === 'DATE') {
        fieldType = 'DATE';
      } else if (type === 'DATETIME') {
        fieldType = 'DATETIME';
      } else if (type === 'BOOLEAN') {
        fieldType = 'BOOLEAN';
      } else if (type === 'INTEGER') {
        fieldType = 'INTEGER';
      } else if (type === 'DECIMAL') {
        fieldType = 'DECIMAL';
      } else {
        fieldType = 'TEXT';
      }
    }

    return fieldType;
  };

  scope.dateTimeFormat = function () {
    for (const i in scope.columnHeaders) {
      if (scope.columnHeaders[i].columnDisplayType === 'DATETIME') {
        return `${scope.df} ${scope.tf}`;
      }
    }
    return scope.df;
  };

  scope.cancel = function () {
    if (scope.fromEntity === 'client') {
      location.path(`/viewclient/${routeParams.entityId}`).search({});
    } else if (scope.fromEntity === 'group') {
      location.path(`/viewgroup/${routeParams.entityId}`).search({});
    } else if (scope.fromEntity === 'center') {
      location.path(`/viewcenter/${routeParams.entityId}`).search({});
    } else if (scope.fromEntity === 'loan') {
      location.path(`/viewloanaccount/${routeParams.entityId}`).search({});
    } else if (scope.fromEntity === 'savings') {
      location.path(`/viewsavingaccount/${routeParams.entityId}`).search({});
    } else if (scope.fromEntity === 'office') {
      location.path(`/viewoffice/${routeParams.entityId}`).search({});
    } else if (scope.fromEntity === 'creditaccount') {
      location.path(`/viewcreditaccount/${routeParams.cardId}`).search({});
    }
  };

  scope.onFileSelect = function (files, index, columnName) {
    const tempFile = files[0];
    const fileTemplate = {};
    fileTemplate.file = tempFile;
    fileTemplate.columnName = columnName;
    scope.files[index] = fileTemplate;
    scope.columnData[columnName] = 'PLACE_HOLDER';
  };

  scope.submit = function () {
    scope.tableData = [];
    scope.fileColumns = {};
    scope.tempfiles = [];
    for (const i in scope.files) {
      scope.fileColumns[i] = scope.files[i].columnName;
      scope.tempfiles.push(scope.files[i]);
    }

    scope.columnData.locale = scope.optlang.code;
    scope.columnData.dateFormat = scope.dateTimeFormat();
    // below logic, for the input field if data is not entered, this logic will put "", becausedoc
    // if no data entered in input field , that field name won't send to server side.
    for (let i = 0; i < scope.columnHeaders.length; i += 1) {
      if (!_.contains(_.keys(this.columnData), scope.columnHeaders[i].columnName)) {
        scope.columnData[scope.columnHeaders[i].columnName] = '';
      }
      if (scope.columnHeaders[i].columnDisplayType === 'DATE') {
        if (this.formDat[scope.columnHeaders[i].columnName]) {
          scope.columnData[scope.columnHeaders[i].columnName] = dateFilter(this.formDat[scope.columnHeaders[i].columnName], scope.columnData.dateFormat);
        } else {
          scope.columnData[scope.columnHeaders[i].columnName] = '';
        }
      } else if (scope.columnHeaders[i].columnDisplayType === 'DATETIME') {
        if (!this.formDat[scope.columnHeaders[i].columnName].date && !this.formDat[scope.columnHeaders[i].columnName].time) {
          scope.columnData[scope.columnHeaders[i].columnName] = '';
        } else {
          scope.columnData[scope.columnHeaders[i].columnName] = `${dateFilter(this.formDat[scope.columnHeaders[i].columnName].date, scope.df)} ${dateFilter(this.formDat[scope.columnHeaders[i].columnName].time, scope.tf)}`;
        }
      }
      if (scope.columnHeaders[i].columnType === 'multiselect') {
        if (!this.formDat && this.formDat[scope.columnHeaders[i].columnName] && !this.formDat[scope.columnHeaders[i].columnName]?.values) {
          scope.columnData[scope.columnHeaders[i].columnName] = '';
        } else {
          scope.columnData[scope.columnHeaders[i].columnName] = this.formDat[scope.columnHeaders[i].columnName]?.values.toString();
        }
      }
    }
    // scope.columnData.dateFormat = scope.df
    // scope.columnData.locale = 'en_GB'
    Upload.upload({
      url: `${$rootScope.hostUrl + API_VERSION}/datatables/${routeParams.tableName}/${routeParams.entityId}?genericResultSet=true`,
      data: { tableData: JSON.stringify(scope.columnData), file: scope.tempfiles }
    }).then(function (data) {
      let destination = '';
      if (data.data.loanId) {
        destination = `/viewloanaccount/${data.data.loanId}`;
      } else if (data.data.savingsId) {
        destination = `/viewsavingaccount/${data.data.savingsId}`;
      } else if (data.data.clientId) {
        destination = `/viewclient/${data.data.clientId}`;
      } else if (data.data.groupId) {
        if (scope.isCenter) {
          destination = `/viewcenter/${data.data.groupId}`;
        } else {
          destination = `/viewgroup/${data.data.groupId}`;
        }
      } else if (data.data.officeId) {
        destination = `/viewoffice/${data.data.officeId}`;
      } else if (scope.fromEntity === 'creditaccount') {
        destination = `/viewcreditaccount/${routeParams.cardId}`;
      }
      location.path(destination);
    });
  };
}

MakeDataTableEntryController.$inject = ['$scope', '$location', '$routeParams', 'ResourceFactory', 'dateFilter', 'API_VERSION', '$rootScope', 'Upload'];
