export function ViewAccountTransferDetailsController ($scope, resourceFactory, location, routeParams) {
  $scope.isFromViewSavings = location.search().fromViewSavings;

  resourceFactory.accountTransferResource.get({ id: routeParams.id }, function (data) {
    $scope.transferData = data;
    $scope.isRepayment = data.fromAccountType.code === 'accountType.savings' && data.toAccountType.code === 'accountType.loan';
  });
}

ViewAccountTransferDetailsController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
