<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.anchor.defineVelocityRules') }}
    </template>
    <form-wrapper
      @submit="submitForm()"
    >
      <div class="col-12 col-md-6">
        <h4>{{ employeeTier?.name }}</h4>
        <dynamic-fields
          :items="displayedCards"
          @on-add-fields="addFields"
          @on-remove-fields="removeFields"
        >
          <template #content="{item}">
            <div class="col">
              <input-select
                v-model="item.cardProduct"
                class="col-6"
                :label="$t('label.input.cardProduct')"
                :options="getAvailableCardProducts(item.cardProduct)"
                value-field="id"
                required
                :disabled="!item.productId && item.isCardProductAlreadySelected"
              />
            </div>
            <div class="h5">
              {{ $t('label.heading.velocityRules') }}
            </div>

            <div>{{ $t('label.heading.atm') }}</div>
            <hr>
            <div>{{ $t('label.heading.amount') }}</div>
            <div class="row">
              <div class="col-6">
                <input-text
                  v-model="item.WITHDRAWAL.SECONDS"
                  type="number"
                  :label="$t('label.input.single')"
                  :disabled="!item.isUpdatable"
                />
                <input-text
                  v-model="item.WITHDRAWAL.WEEKS"
                  type="number"
                  :label="$t('label.input.weekly')"
                  :disabled="!item.isUpdatable"
                />
              </div>
              <div class="col-6">
                <input-text
                  v-model="item.WITHDRAWAL.DAYS"
                  type="number"
                  :label="$t('label.input.daily')"
                  :disabled="!item.isUpdatable"
                />
                <input-text
                  v-model="item.WITHDRAWAL.MONTHS"
                  type="number"
                  :label="$t('label.input.monthly')"
                  :disabled="!item.isUpdatable"
                />
              </div>
            </div>

            <div>{{ $t('label.heading.pos') }}</div>
            <hr>
            <div>{{ $t('label.heading.amount') }}</div>
            <div class="row">
              <div class="col-6">
                <input-text
                  v-model="item.PURCHASE.SECONDS"
                  type="number"
                  :label="$t('label.input.single')"
                  :disabled="!item.isUpdatable"
                />
                <input-text
                  v-model="item.PURCHASE.WEEKS"
                  type="number"
                  :label="$t('label.input.weekly')"
                  :disabled="!item.isUpdatable"
                />
              </div>
              <div class="col-6">
                <input-text
                  v-model="item.PURCHASE.DAYS"
                  type="number"
                  :label="$t('label.input.daily')"
                  :disabled="!item.isUpdatable"
                />
                <input-text
                  v-model="item.PURCHASE.MONTHS"
                  type="number"
                  :label="$t('label.input.monthly')"
                  :disabled="!item.isUpdatable"
                />
              </div>
            </div>
          </template>
        </dynamic-fields>

        <div class="offset-md-5 mt-4">
          <button
            class="btn btn-default"
            @click.prevent="$router.push(`/clients/${clientId}/manage-tiers/${tierId}`)"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            class="btn btn-primary"
          >
            {{ $t('label.button.save') }}
          </button>
        </div>
      </div>
    </form-wrapper>
  </main-content-layout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import { InputText, InputSelect, FormWrapper } from '@/components/shared/form-input/index.js';
import DynamicFields from '@/components/shared/DynamicFields.vue';
import { ref, onMounted, computed } from 'vue';
import router from '@/router';
import i18n from '@/plugins/i18n';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { get, set } from '@vueuse/core';
import { cloneDeep as _cloneDeep } from 'lodash';

const breadCrumbsStore = useBreadcrumbsStore();
const { clientId, tierId } = router.currentRoute.params;
const cardVelocityRulesTemplate = {
  cardProduct: null,
  isUpdatable: true,
  WITHDRAWAL: {
    SECONDS: '',
    DAYS: '',
    WEEKS: '',
    MONTHS: ''
  },
  PURCHASE: {
    SECONDS: '',
    DAYS: '',
    WEEKS: '',
    MONTHS: ''
  }
};

const cardProductsOptions = ref([]);
const cards = ref([]);
const newCards = ref([]);
const isEdit = ref(false);
const employeeTier = ref();
const isNewTier = ref(false);

function getAvailableCardProducts (currentSelectedId) {
  const usedProductIds = get(displayedCards).map(product => product.cardProduct?.id || product.cardProduct);
  return get(cardProductsOptions).filter((product) => currentSelectedId === product.id || !usedProductIds.includes(product.id));
}

const displayedCards = computed(() => {
  return [...get(cards), ...get(newCards)];
});

onMounted(async () => {
  await Promise.all([
    setUpBreadcrumbs(),
    getTemplates()
  ]);

  fetchExistingTierData();
});

async function setUpBreadcrumbs () {
  try {
    const { data: { displayName } } = await apiService.clients.get(clientId);
    const breadcrumbs = [
      {
        text: i18n.t('label.anchor.clients'),
        href: '#/clients'
      },
      {
        text: displayName,
        href: `#/viewclient/${clientId}`
      }

    ];

    if (router.currentRoute.name === 'EditTierLimits') {
      set(isEdit, true);

      breadcrumbs.push({
        text: i18n.t('label.anchor.manageTiers'),
        href: `#/clients/${clientId}/manage-tiers/${tierId}`
      },
      {
        text: i18n.t('label.anchor.edit')
      });
    } else {
      breadcrumbs.push({
        text: i18n.t('label.anchor.add')

      });
    }

    breadCrumbsStore.setBreadcrumbs(breadcrumbs);
  } catch (error) {
    $notify.error(error);
  }
}

async function getTemplates () {
  try {
    const { data: { cardProductOptions } } = await apiService.clientClassification.template();
    set(cardProductsOptions, cardProductOptions);
  } catch (error) {
    $notify.error(error);
  }
}

async function fetchExistingTierData () {
  const { data: { employeeTierData, productVelocityRules } } = await apiService.tier.getTierVelocity(clientId, tierId);
  set(employeeTier, employeeTierData);

  if (productVelocityRules) {
    set(cards, formFormatted(productVelocityRules));
  } else {
    set(isNewTier, true);
    get(newCards).push({ ...cardVelocityRulesTemplate });
  }
}

function formFormatted (input) {
  return input.map((item) => {
    const { id: cardProduct } = get(cardProductsOptions).find((product) => product.id === item.cardProductId);

    const result = {
      cardProduct,
      WITHDRAWAL: {
        SECONDS: '',
        DAYS: '',
        WEEKS: '',
        MONTHS: ''
      },
      PURCHASE: {
        SECONDS: '',
        DAYS: '',
        WEEKS: '',
        MONTHS: ''
      },
      isCardProductAlreadySelected: true,
      isUpdatable: !get(employeeTier).isTierInUse,
      unRemovable: get(employeeTier).isTierInUse
    };

    item.velocityRules.forEach((rule) => {
      const { controls: [control], timeUnit, value } = rule;

      result[control][timeUnit] = value;
    });

    return result;
  });
}

function formatToCoreFormat (input) {
  const velocityRules = [
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'SECONDS', type: 'AMOUNT' },
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'DAYS', type: 'AMOUNT' },
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'WEEKS', type: 'AMOUNT' },
    { controls: ['PURCHASE'], timePeriod: 1, timeUnit: 'MONTHS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'SECONDS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'DAYS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'WEEKS', type: 'AMOUNT' },
    { controls: ['WITHDRAWAL'], timePeriod: 1, timeUnit: 'MONTHS', type: 'AMOUNT' }
  ];

  const output = input.map(item => {
    const result = {
      cardProductId: item.cardProduct || null,
      velocityRules: velocityRules.map(rule => ({
        ...rule,
        value: item[rule.controls[0]][rule.timeUnit]
      }))
        .filter(rule => get(rule) !== '')
    };

    delete result.isUpdatable;
    delete result.unRemovable;

    return result;
  });

  return output;
}

async function submitForm () {
  try {
    let formattedFormData = [];

    if (!get(employeeTier).isTierInUse) {
      const form = get(isNewTier) ? get(newCards) : get(displayedCards);
      formattedFormData = formatToCoreFormat(form);
    } else {
      formattedFormData = formatToCoreFormat(get(newCards));
    }

    if (get(isNewTier)) {
      await apiService.tier.addTierVelocity(clientId, tierId, { productVelocityRules: formattedFormData });
    } else await apiService.tier.updateTierVelocity(clientId, tierId, { productVelocityRules: formattedFormData });

    router.push(`/clients/${clientId}/manage-tiers/${tierId}`);
  } catch (e) {
    $notify.error(e);
  }
}

function addFields () {
  get(newCards).push(_cloneDeep(cardVelocityRulesTemplate));
}

function removeFields (index) {
  if (index >= get(cards).length) get(newCards).splice(index - get(cards).length, 1);
  else get(cards).splice(index, 1);
}
</script>
