<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.anchor.checkerThreshold') }}
    </template>
    <template #actions>
      <button
        v-has-permission="'CREATE_CHECKER_THRESHOLD'"
        class="btn btn-primary"
        @click="addOrEditThresholdModal.show()"
      >
        <i class="fas fa-plus me-2" />{{ $t('label.button.createCheckerThreshold') }}
      </button>
    </template>
    <data-grid
      :items="items"
      :fields="fields"
      hide-entries-per-page
    >
      <template #actions="{item}">
        <div class="d-flex">
          <button
            v-has-permission="'UPDATE_CHECKER_THRESHOLD'"
            class="btn btn-primary me-1"
            @click="showEditThreshold(item)"
          >
            <i class="fas fa-edit" />
          </button>
          <button
            v-has-permission="'DELETE_CHECKER_THRESHOLD'"
            class="btn btn-danger"
            @click="deleteThreshold(item)"
          >
            <i class="fas fa-trash" />
          </button>
        </div>
      </template>
    </data-grid>

    <base-modal
      ref="addOrEditThresholdModal"
      hide-footer
      @hidden="resetForm"
    >
      <template #header>
        <h4>{{ $t(`label.heading.${isEditing ? 'editCheckerThreshold' : 'createCheckerThreshold'}`) }}</h4>
      </template>
      <template #body>
        <form-wrapper @submit="submit">
          <input-text
            v-model="formData.permissionCode"
            vertical
            required
            disabled
            :label="$t('label.input.permissionCode')"
          />
          <input-text
            v-model="formData.productId"
            vertical
            type="number"
            :label="$t('label.input.productId')"
          />
          <input-text
            v-model="formData.amount"
            vertical
            required
            type="number"
            :label="$t('label.input.threshold')"
          />
          <div class="pull-right">
            <button
              type="button"
              class="btn btn-default"
              @click.prevent="addOrEditThresholdModal.hide()"
            >
              {{ $t('label.button.cancel') }}
            </button>
            <button
              type="submit"
              class="btn btn-primary"
            >
              {{ $t('label.button.save') }}
            </button>
          </div>
        </form-wrapper>
      </template>
    </base-modal>
  </main-content-layout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import DataGrid from '@/components/shared/data-grid/index.vue';
import i18n from '@/plugins/i18n';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { onMounted, ref } from 'vue';
import { $notify } from '@/plugins/prototypes';
import apiService from '@/services/apiService';
import BaseModal from '@/components/shared/BaseModal.vue';
import { InputText, FormWrapper } from '@/components/shared/form-input/index.js';
import prompt from '@/plugins/prompt';

const addOrEditThresholdModal = ref(null);
const fields = ref([
  { key: 'id', label: i18n.t('label.head.id') },
  { key: 'loanProductId', label: i18n.t('label.head.loanProductId') },
  { key: 'permissionCode', label: i18n.t('label.head.permissionCode') },
  { key: 'amount', label: i18n.t('label.head.threshold') },
  { key: 'actions', label: i18n.t('label.head.actions') }
]);
const items = ref([]);
const formData = ref({
  permissionCode: 'APPROVE_LOAN'
});
const isEditing = ref(false);
const selectedMapId = ref(null);

onMounted(() => {
  const breadCrumbsStore = useBreadcrumbsStore();
  breadCrumbsStore.setBreadcrumbs([
    { text: i18n.t('label.anchor.system'), href: '#/system' },
    { text: i18n.t('label.anchor.makerCheckerTasks'), href: '#/admin/viewmctasks' },
    { text: i18n.t('label.anchor.checkerThreshold') }
  ]);
  getCheckerThreshold();
});

const getCheckerThreshold = async () => {
  try {
    const { data } = await apiService.checkerThresholds.get();
    items.value = data;
  } catch (error) {
    $notify.error(error);
  }
};

const resetForm = () => {
  formData.value = {
    permissionCode: 'APPROVE_LOAN',
    productId: null,
    amount: null
  };
  isEditing.value = false;
  selectedMapId.value = null;
};

const showEditThreshold = (item) => {
  addOrEditThresholdModal.value.show();
  isEditing.value = true;
  formData.value = {
    permissionCode: item.permissionCode,
    productId: item.loanProductId,
    amount: item.amount
  };
  selectedMapId.value = item.id;
};

const deleteThreshold = async (item) => {
  const confirm = await prompt.okText(i18n.t('label.button.yes')).confirm(i18n.t('messages.areYouSureYouWantToDelete'));
  if (confirm === false) return;
  try {
    await apiService.checkerThresholds.delete(item.id);
    await getCheckerThreshold();
  } catch (error) {
    $notify.error(error);
  }
};

const submit = async () => {
  try {
    if (isEditing.value) {
      await apiService.checkerThresholds.put(selectedMapId.value, formData.value);
    } else {
      await apiService.checkerThresholds.post(formData.value);
    }
    addOrEditThresholdModal.value.hide();
    await getCheckerThreshold();
  } catch (error) {
    $notify.error(error);
  }
};
</script>
