import ManageOrganization from '@/pages/organization/manage-organization';
import Organization from '@/pages/organization/index.vue';
import BulkImportClients from '@/pages/organization/bulk-import/clients.vue';
import BulkImport from '@/pages/organization/bulk-import/index.vue';
import LoanDataTableMapping from '@/pages/organization/loan-data-table-mapping/index.vue';

export const organizationRoutes = [
  {
    path: '/organization',
    name: 'OrganizationPage',
    component: Organization
  },
  {
    path: '/organization/manage-organization',
    name: 'ManageOrganizationPage',
    component: ManageOrganization
  },
  {
    path: '/organization/bulk-import',
    name: 'BulkImport',
    component: BulkImport
  },
  {
    path: '/organization/bulk-import/clients',
    name: 'BulkImportClients',
    component: BulkImportClients
  },
  {
    path: '/organization/loan-data-table',
    name: 'LoanDataTable',
    component: LoanDataTableMapping
  }
];
