<template>
  <span>
    <button
      v-has-permission="'UPDATE_USER'"
      class="btn btn-primary"
      @click="()=> {$refs['managePasswordModal'].show()}"
    >
      <i class="fas fa-gear" />
      {{ $t('label.button.updateUserPassword') }}
    </button>
    <base-modal
      id="managePasswordModal"
      ref="managePasswordModal"
      v-has-permission="'UPDATE_USER'"
      modal-size="md"
      hide-footer
      show-button-close
    >
      <template #header>
        <h4>{{ $t('label.heading.updateUserPassword') }}</h4>
      </template>
      <template #body>
        <form-wrapper
          ref="changePasswordForm"
          name="changePasswordForm"
          @submit="changePassword"
        >
          <input-text
            id="password"
            v-model="formData.password"
            vertical
            :type="passwordShown ? 'text' : 'password'"
            required
            hide-password-toggle
            minlength="6"
            :label="$t('label.input.newPassword')"
          />
          <input-text
            id="repeatPassword"
            v-model="formData.repeatPassword"
            vertical
            :type="passwordShown ? 'text' : 'password'"
            required
            hide-password-toggle
            minlength="6"
            :label="$t('label.input.repeatPassword')"
          />
          <input-check-box
            id="showPassword"
            v-model="passwordShown"
            :label="$t('label.input.showPassword')"
            class="mb-3"
            reverse
            no-padding
          />
          <div class="modal-footer p-0 pt-2 d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <button
                type="button"
                class="btn btn-link px-0"
                :disabled="loading"
                @click="resetToTemporaryPassword"
              >
                {{ $t('label.button.requestNewTemporaryPassword') }}
              </button>
              <span
                v-tooltips.hover.bottom="$t('messages.resetToTemporaryPassword')"
                class="d-inline-block"
                tabindex="0"
                data-toggle="tooltip"
              >
                <i class="fas fa-question-circle ms-2" />
              </span>
            </div>
            <div>
              <button
                class="btn btn-primary"
                :disabled="loading"
                type="submit"
              >
                {{ $t('label.button.updatePassword') }}
              </button>
            </div>
          </div>
        </form-wrapper>
      </template>
    </base-modal>
  </span>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { FormWrapper, InputText, InputCheckBox } from '@/components/shared/form-input/index.js';
import { ref, computed } from 'vue';
import { useSharedStore } from '@/stores/shared';
import router from '@/router/index';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import { useUserStore } from '@/stores/user';

const sharedStore = useSharedStore();
const userStore = useUserStore();

const loading = computed(() => sharedStore.loading);
const managePasswordModal = ref(null);
const formData = ref({
  password: '',
  repeatPassword: ''
});
const { userId } = router.currentRoute.params;
const changePasswordForm = ref(null);
const passwordShown = ref(false);

const resetToTemporaryPassword = async () => {
  try {
    await apiService.userResource.resetPassword(userId);

    managePasswordModal.value.hide();
  } catch (error) {
    $notify.error(error);
  }
};

const changePassword = async () => {
  try {
    const { data } = await apiService.userResource.update(userId, formData.value);
    if (data.resourceId === userStore.userDetails?.userId) {
      userStore.logout();
    }
    managePasswordModal.value.hide();
    changePasswordForm.value.reset();
  } catch (error) {
    $notify.error(error);
  }
};
</script>
