export function ViewRecurringDepositProductController (scope, routeParams, location, anchorScroll, resourceFactory, $uibModal) {
  resourceFactory.recurringDepositProductResource.get({ productId: routeParams.productId }, function (data) {
    scope.depositproduct = data;
    scope.chartSlabs = scope.depositproduct.activeChart.chartSlabs;
    scope.hasAccounting = (data.accountingRule.id === 2 || data.accountingRule.id === 3);
  });

  scope.scrollto = function (link) {
    location.hash(link);
    anchorScroll();
  };
}

ViewRecurringDepositProductController.$inject = ['$scope', '$routeParams', '$location', '$anchorScroll', 'ResourceFactory', '$uibModal'];
