<template>
  <BaseModal
    id="modal-adverse-media"
    ref="adverseMediaModal"
    hide-footer
    modal-size="lg"
  >
    <template #body>
      <FormWrapper @submit="submit">
        <div class="row">
          <input-select
            v-model="formData.input.offence_type"
            :label="$t('label.anchor.offenceType')"
            :options="allAdverseMediaOptions.customerNPReputationOffenceOptions"
            class="col-sm-6"
            vertical
            value-field="name"
          />
          <input-select
            v-model="formData.input.input"
            :label="$t('label.anchor.taxonomyKeywords')"
            :options="allAdverseMediaOptions.customerNPReputationTaxonomyKeywordOptions"
            class="col-sm-6"
            vertical
            value-field="name"
          />
          <input-select
            v-model="formData.input.taxonomy_type"
            :label="$t('label.anchor.taxonomyType')"
            :options="allAdverseMediaOptions.customerNPReputationTaxonomyTypeOptions"
            class="col-sm-6"
            vertical
            value-field="name"
          />
          <input-select
            v-model="formData.input.source"
            :label="$t('label.anchor.source')"
            :options="allAdverseMediaOptions.customerNPReputationSourceOptions"
            class="col-sm-6"
            disabled
            vertical
            value-field="name"
          />
          <input-text
            v-model="formData.note"
            :label="$t('label.anchor.notes')"
            required
            vertical
            value-field="name"
            :place-holder="$t('label.basedOnCustomerDataAvailableWithOtherBanks')"
            class="mb-5"
          />
        </div>
        <div class="pull-right">
          <button
            type="button"
            class="btn btn-default py-1 px-4"
            @click="cancel()"
          >
            {{ $t('label.button.back') }}
          </button>
          <button
            type="submit"
            class="btn btn-primary py-1 px-4"
          >
            {{ $t('label.button.save') }}
          </button>
        </div>
      </FormWrapper>
    </template>
  </BaseModal>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { get } from '@vueuse/core';
import { InputText, FormWrapper, InputSelect } from '../../shared/form-input/index';
import BaseModal from '@/components/shared/BaseModal.vue';

const props = defineProps({
  allAdverseMediaOptions: {
    type: Object,
    default: () => ({})
  },
  item: {
    type: Object,
    default: () => ({})
  }
});
const adverseMediaModal = ref(null);
const formData = reactive({
  note: '',
  name: props.item?.name,
  input: {
    input: '',
    offence_type: '',
    taxonomy_type: '',
    source: 'rame'
  }
});
const emit = defineEmits(['on-submit']);
const onResetForm = () => {
  Object.assign(formData, {
    note: '',
    name: props.item?.name,
    input: {
      input: '',
      offence_type: '',
      taxonomy_type: '',
      source: 'rame'
    }
  });
};
const cancel = () => {
  get(adverseMediaModal).hide();
  onResetForm();
};
const submit = () => {
  emit('on-submit', formData);
};

defineExpose({ onResetForm });
</script>
