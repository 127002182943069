<template>
  <div :id="id">
    <base-table
      ref="customTable"
      :items="items"
      :fields="fields"
      :loading="isLoading"
      :clickable="clickable"
      :bordered="bordered"
      :multiselect="multiselect"
      @sort-column="sortColumn"
      @click-row="rowClicked"
      @row-selected="rowSelected"
    >
      <template
        v-if="$slots.checkbox"
        #checkbox="{ item }"
      >
        <slot
          name="checkbox"
          :item="item"
        />
      </template>
      <template
        v-for="(slotName) in scopedSlots"
        #[slotName]="{ item, rowIndex }"
      >
        <slot
          :name="slotName"
          :item="item"
          :row-index="rowIndex"
        />
      </template>
      <template
        v-if="$slots.footer"
        #footer="{ item }"
      >
        <slot
          name="footer"
          :item="item"
        />
      </template>
    </base-table>
    <div
      v-if="!items.length"
      class="text-center text-muted mb-3"
    >
      {{ emptyTablePlaceholder || $t('messages.noData') }}
    </div>
    <div class="d-flex justify-content-between">
      <div
        v-if="!hideEntriesPerPage"
        class="col-3 pull-left d-flex"
      >
        <select
          v-model="selectedEntry"
          class="w-auto form-select pull-left"
          aria-label="entries-per-page"
        >
          <option
            v-for="(entry, i) in entriesPerPage"
            :key="i"
            :value="entry"
          >
            {{ entry }}
          </option>
        </select>
        <span class="my-auto mx-2">
          {{ $t('label.entriesPerPage') }}
        </span>
      </div>
      <pagination
        v-if="!useArrowPaginator"
        v-model="currentPage"
        class="pull-right ms-auto"
        :total-rows="totalRecords"
        :limit-per-page="limit"
      />
      <arrow-paginator
        v-else
        v-model="currentPage"
        :current-items-length="items.length"
        :entries-per-page="selectedEntry"
      />
    </div>
  </div>
</template>

<script>
import Pagination from './Pagination.vue';
import BaseTable from './BaseTable.vue';
import ArrowPaginator from './ArrowPaginator.vue';

export default {
  name: 'DataGrid',
  components: {
    Pagination,
    BaseTable,
    ArrowPaginator
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    // temporary prop for progressive enhancement of using arrow paginator
    // TODO: once all the items that use previous pagination are depreceated we will remove all usages of this prop
    // and default to arrow paginator: https://mbanqcloud.atlassian.net/browse/MC-6635
    useArrowPaginator: {
      type: Boolean,
      default: false
    },
    totalRecords: {
      type: Number,
      default: null
    },
    limit: {
      type: [Number, String],
      default: 15
    },
    fields: {
      type: Array,
      default: () => []
    },
    entriesPerPage: {
      type: Array,
      default: () => [15, 30, 60]
    },
    clickable: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    hideEntriesPerPage: {
      type: Boolean,
      default: false
    },
    saveSelectedOnPageChange: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'data-grid'
    },
    emptyTablePlaceholder: {
      type: String,
      default: ''
    },
    bordered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentPage: 1,
      isLoading: false,
      selectedEntry: this.entriesPerPage[0],
      scopedSlots: Object.keys(this.$scopedSlots)
    };
  },
  watch: {
    currentPage: {
      handler: function (value) {
        const offset = this.limit * (value - 1);
        if (!this.saveSelectedOnPageChange) this.unSaveSelectedOnPageChange();
        this.$emit('pagination-update', offset);
      }
    },
    selectedEntry: {
      handler: function (value) {
        if (!this.saveSelectedOnPageChange) this.unSaveSelectedOnPageChange();
        this.$emit('entries-per-page', value);
      }
    }
  },
  methods: {
    toggleLoading (state) {
      this.isLoading = state;
    },
    sortColumn (sort) {
      this.unSaveSelectedOnPageChange();
      this.$emit('sort-column', sort);
    },
    rowClicked (item) {
      this.$emit('click-row', item);
    },
    rowSelected (selectedRows) {
      this.$emit('row-selected', selectedRows);
    },
    unSaveSelectedOnPageChange () {
      this.$refs.customTable.selectedRows = [];
      this.$emit('row-selected', this.$refs.customTable.selectedRows);
    },
    selectAllRow (items) {
      this.$refs.customTable.handleAllSelectedRows(items);
    },
    clearSelectedRow () {
      this.$refs.customTable.clearSelectedRows();
    }
  }
};
</script>

<style scoped>
  th.sortable {
    position: relative;
    cursor: pointer;
  }

  th.sortable::after {
    font-family: 'Font Awesome 5 Free';
    content: "\f0dc";
    position: absolute;
    right: 8px;
    color: #999;
  }

  th.sortable.asc::after {
    content: "\f0d8";
  }

  th.sortable.desc::after {
    content: "\f0d7";
  }

  th.sortable:hover::after {
    color: #333;
  }
</style>
