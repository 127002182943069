<template>
  <span>
    <button
      class="btn btn-secondary"
      :disabled="loading"
      @click="viewHistory()"
    >
      {{ $t('label.button.history') }}
    </button>

    <base-modal
      ref="historyModal"
      modal-size="xl"
      show-button-close
      hide-footer
      scrollable
    >
      <template #header>
        <h4>{{ $t('label.heading.history') }}</h4>
      </template>
      <template #body>
        <data-grid
          :items="histories"
          :fields="fields"
          hide-entries-per-page
        />
      </template>
    </base-modal>
  </span>
</template>

<script setup>
import { ref, computed } from 'vue';
import i18n from '@/plugins/i18n';
import DataGrid from '@/components/shared/data-grid/index.vue';
import BaseModal from '@/components/shared/BaseModal.vue';
import apiService from '@/services/apiService';
import router from '@/router';
import { useSharedStore } from '@/stores/shared';

const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);
const { clientId } = router.currentRoute.params;
const props = defineProps({
  resourceId: {
    type: Number,
    default: null
  }
});

const fields = ref([
  { key: 'id', label: i18n.t('label.heading.name') },
  { key: 'maker', label: i18n.t('label.heading.maker') },
  { key: 'entityName', label: i18n.t('label.heading.entityName') },
  { key: 'actionName', label: i18n.t('label.heading.action') },
  { key: 'commandAsJson', label: i18n.t('label.heading.changes') },
  { key: 'madeOnDate', label: i18n.t('label.heading.timestamp') },
  { key: 'ipAddress', label: i18n.t('label.heading.ipAddress') }
]);

const historyModal = ref(null);
const histories = ref([]);
const viewHistory = async () => {
  let params = { clientId, entityName: 'DISABLING_TRANSFER_OPTION_CLIENT', includeJson: true };
  if (!clientId) {
    params = {
      ...params,
      entityName: 'DISABLING_TRANSFER_OPTION_INSTANCE',
      resourceId: props.resourceId
    };
  }
  const { data } = await apiService.audits.getAll(params);
  histories.value = data;
  historyModal.value.show();
};
</script>
