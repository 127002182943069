<template>
  <div>
    <button
      class="btn btn-primary"
      :disabled="disabled"
      @click="rejectComplianceModal.show()"
    >
      {{ $t('label.button.reject') }}
    </button>
    <BaseModal
      id="modal-reject-compliance"
      ref="rejectComplianceModal"
      hide-footer
      modal-size="lg"
    >
      <template #body>
        <FormWrapper @submit="submit">
          <div>
            <p class="fs-5">
              {{ $t('label.confirmRejectCompliance') }}
            </p>
            <p class="fs-5">
              {{ $t('label.backRejectCompliance') }}
            </p>
            <input-text
              v-model="rejectNotes"
              type="textarea"
              :label="$t('label.anchor.notes')"
              required
              vertical
              class="col-sm-6 mb-2"
            />
          </div>
          <div class="pull-right">
            <button
              type="button"
              class="btn btn-default py-1 px-4"
              @click="cancel()"
            >
              {{ $t('label.button.back') }}
            </button>
            <button
              type="submit"
              class="btn btn-primary py-1 px-4"
            >
              {{ $t('label.button.ok') }}
            </button>
          </div>
        </FormWrapper>
      </template>
    </BaseModal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { get, set } from '@vueuse/core';
import { InputText, FormWrapper } from '@/components/shared/form-input/index';
import BaseModal from '@/components/shared/BaseModal.vue';

defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
});

const rejectNotes = ref('');
const rejectComplianceModal = ref(null);
const emit = defineEmits(['on-submit']);
const cancel = () => {
  set(rejectNotes, '');
  get(rejectComplianceModal).hide();
};
const submit = () => {
  emit('on-submit', get(rejectNotes));
};
</script>
