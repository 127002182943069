<template>
  <div
    :id="id"
    ref="baseModal"
    class="modal fade"
    tabindex="-1"
    :aria-labelledby="id"
    aria-hidden="true"
  >
    <div :class="classesModalDialog">
      <div
        v-if="visible"
        class="modal-content"
      >
        <div
          v-if="$slots.header || showButtonClose"
          class="modal-header"
        >
          <slot name="header" />
          <button
            v-if="showButtonClose"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div
          v-if="$slots.body"
          class="modal-body"
        >
          <slot name="body" />
        </div>
        <div
          v-if="!hideFooter"
          class="modal-footer"
        >
          <slot name="footer">
            <button
              type="button"
              class="btn btn-default"
              data-bs-dismiss="modal"
              @click="cancel"
            >
              {{ $t('label.button.cancel') }}
            </button>
            <button
              class="btn btn-primary"
              :disabled="loading"
              @click="save"
            >
              {{ $t('label.button.submit') }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useSharedStore } from '@/stores/shared';

const sharedStore = useSharedStore();
const props = defineProps({
  id: {
    type: String,
    default: 'base-modal'
  },
  hideFooter: {
    type: Boolean,
    default: false
  },
  showButtonClose: {
    type: Boolean,
    default: false
  },
  modalSize: {
    type: String,
    default: 'default' // options: 'sm', 'lg', and 'xl'
  },
  scrollable: {
    type: Boolean,
    default: false
  },
  options: {
    type: Object,
    default: () => ({})
  }
});

const baseModal = ref(null);
let thisModalObj = null;
const visible = ref(false);
const loading = computed(() => sharedStore.loading);

onMounted(() => {
  thisModalObj = new bootstrap.Modal(baseModal.value, props.options);
  baseModal.value.addEventListener('hidden.bs.modal', () => {
    emit('hidden');
    visible.value = false;
  });
  baseModal.value.addEventListener('show.bs.modal', () => {
    emit('on-show');
    visible.value = true;
  });
});

const _show = () => {
  thisModalObj.show();
};

const _hide = () => {
  thisModalObj.hide();
};
const emit = defineEmits(['on-cancel', 'on-submit', 'hidden', 'on-show']);

const cancel = () => {
  emit('on-cancel');
};
const save = () => {
  emit('on-submit');
};
const classesModalDialog = computed(() => [
  'modal-dialog',
  {
    'modal-sm': props.modalSize === 'sm',
    'modal-lg': props.modalSize === 'lg',
    'modal-xl': props.modalSize === 'xl',
    'modal-dialog-scrollable': props.scrollable
  }
]);

defineExpose({ show: _show, hide: _hide, visible });
</script>
