import _ from 'underscore';
import { getRandomString, getClonnedName } from '../../../common-methods';
import router from '@/router/index';

export function CreateCreditProductController (scope, resourceFactory, location, WizardHandler) {
  const searchParams = location.search();

  if (searchParams.clone) {
    getCreditProductById(searchParams.clone);
  }

  scope.formData = {};
  scope.creditproduct = {};
  scope.charges = [];
  scope.showOrHideValue = 'show';
  scope.configureFundOptions = [];
  scope.specificIncomeaccounts = [];
  scope.penaltySpecificIncomeaccounts = [];
  scope.configureFundOption = {};
  scope.isClicked = false;
  scope.accountingOptions = ['None', 'Cash', 'Accrual(Periodic)', 'Accrual(Upfront)'];
  scope.floatingRateOptions = [];
  scope.creditInterestTypes = [];
  scope.purchaseFloatingRateData = {};
  scope.cashAdvanceFloatingRateData = {};
  scope.purchaseFloatingRateData.isFloatingInterestRateCalculationAllowed = false;
  scope.cashAdvanceFloatingRateData.isFloatingInterestRateCalculationAllowed = false;

  if (!searchParams.clone) {
    resourceFactory.creditProductResource.get({ creditProductId: 'template' }, function (data) {
      scope.product = data;
      scope.product.chargeOptions = scope.product?.chargeOptions || [];
      scope.assetAccountOptions = scope.product.accountingMappingOptions?.assetAccountOptions || [];
      scope.liabilityAccountOptions = scope.product.accountingMappingOptions?.liabilityAccountOptions || [];
      scope.incomeAccountOptions = scope.product.accountingMappingOptions?.incomeAccountOptions || [];
      scope.expenseAccountOptions = scope.product.accountingMappingOptions?.expenseAccountOptions || [];

      scope.formData.currencyCode = data.currencyOptions?.[0].code;
      scope.formData.digitsAfterDecimal = data.currencyOptions?.[0].decimalPlaces;
      scope.formData.interestCompoundingPeriodType = data.interestCompoundingPeriodType?.id;
      scope.formData.interestPostingPeriodType = data.interestPostingPeriodType?.id;
      scope.formData.interestCalculationType = data.interestCalculationType?.id;
      scope.formData.interestCalculationDaysInYearType = data.interestCalculationDaysInYearType?.id;
      scope.formData.accountingRule = 1;
      scope.formData.isSecuredCreditProduct = false;
      scope.creditproduct = angular.copy(scope.formData);
      scope.floatingRateOptions = data.floatingRateOptions;
      scope.creditInterestTypes = data.creditInterestTypes;
    });
  }

  scope.isAccountingEnabled = function () {
    if (scope.formData.accountingRule === 2 || scope.formData.accountingRule === 3 || scope.formData.accountingRule === 4) {
      return true;
    }
    return false;
  };

  scope.isAccrualAccountingEnabled = function () {
    if (scope.formData.accountingRule === 3 || scope.formData.accountingRule === 4) {
      return true;
    }
    return false;
  };

  scope.$watch(
    'formData',
    function (newVal) {
      scope.creditproduct = angular.extend(scope.creditproduct, newVal);
    },
    true
  );

  scope.goNext = function (form) {
    WizardHandler.wizard().checkValid(form);
    scope.isClicked = true;
  };

  scope.formValue = function (array, model, findattr, retAttr) {
    if (model !== undefined) {
      findattr = findattr || 'id';
      retAttr = retAttr || 'value';
      try {
        return array.find(obj => obj[findattr] === model)[retAttr];
      } catch (e) {
        console.warn(e);
      }
    }
  };
  // $rootScope.formValue is used which is defined in CreateLoanProductController.js

  // advanced accounting rule
  scope.showOrHide = function (showOrHideValue) {
    if (showOrHideValue === 'show') {
      scope.showOrHideValue = 'hide';
    }

    if (showOrHideValue === 'hide') {
      scope.showOrHideValue = 'show';
    }
  };

  scope.chargeSelected = function (chargeId) {
    if (chargeId) {
      resourceFactory.chargeResource.get(
        { chargeId, template: 'true' },
        this.formData,
        function (data) {
          data.chargeId = data.id;
          data.isMandatory = false;
          scope.charges.push(data);
          // to charge select box empty
          scope.chargeId = '';
        }
      );
    }
  };

  scope.deleteCharge = function (index) {
    scope.charges.splice(index, 1);
  };

  scope.addConfigureFundSource = function () {
    if (
      scope.product.paymentTypeOptions &&
            scope.product.paymentTypeOptions.length > 0 &&
            scope.assetAccountOptions &&
            scope.assetAccountOptions.length > 0
    ) {
      scope.configureFundOptions.push({
        paymentTypeId: scope.product.paymentTypeOptions[0].id,
        fundSourceAccountId: scope.assetAccountOptions[0].id,
        paymentTypeOptions: scope.product.paymentTypeOptions,
        assetAccountOptions: scope.assetAccountOptions
      });
    }
  };

  scope.mapFees = function () {
    if (
      scope.product.chargeOptions &&
            scope.product.chargeOptions.length > 0 &&
            scope.incomeAccountOptions &&
            scope.incomeAccountOptions.length > 0
    ) {
      scope.specificIncomeaccounts.push({
        chargeId: scope.product.chargeOptions[0].id,
        incomeAccountId: scope.incomeAccountOptions[0].id,
        chargeOptions: scope.product.chargeOptions,
        incomeAccountOptions: scope.product.accountingMappingOptions.incomeAccountOptions
      });
    }
  };

  scope.mapPenalty = function () {
    if (
      scope.product.penaltyOptions &&
            scope.product.penaltyOptions.length > 0 &&
            scope.incomeAccountOptions &&
            scope.incomeAccountOptions.length > 0
    ) {
      scope.penaltySpecificIncomeaccounts.push({
        chargeId: scope.product.penaltyOptions[0].id,
        incomeAccountId: scope.incomeAccountOptions[0].id,
        penaltyOptions: scope.product.penaltyOptions,
        incomeAccountOptions: scope.incomeAccountOptions
      });
    }
  };

  scope.deleteFund = function (index) {
    scope.configureFundOptions.splice(index, 1);
  };

  scope.deleteFee = function (index) {
    scope.specificIncomeaccounts.splice(index, 1);
  };

  scope.deletePenalty = function (index) {
    scope.penaltySpecificIncomeaccounts.splice(index, 1);
  };

  scope.cancel = function () {
    router.push('/products/credit');
  };

  scope.submit = function () {
    scope.paymentChannelToFundSourceMappings = [];
    scope.feeToIncomeAccountMappings = [];
    scope.penaltyToIncomeAccountMappings = [];
    scope.chargesSelected = [];

    let temp = '';

    // configure fund sources for payment channels
    for (const i in scope.configureFundOptions) {
      temp = {
        paymentTypeId: scope.configureFundOptions[i].paymentTypeId,
        fundSourceAccountId: scope.configureFundOptions[i].fundSourceAccountId
      };
      scope.paymentChannelToFundSourceMappings.push(temp);
    }

    // map fees to specific income accounts
    for (const i in scope.specificIncomeaccounts) {
      temp = {
        chargeId: scope.specificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.specificIncomeaccounts[i].incomeAccountId
      };
      scope.feeToIncomeAccountMappings.push(temp);
    }

    // map penalties to specific income accounts
    for (const i in scope.penaltySpecificIncomeaccounts) {
      temp = {
        chargeId: scope.penaltySpecificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.penaltySpecificIncomeaccounts[i].incomeAccountId
      };
      scope.penaltyToIncomeAccountMappings.push(temp);
    }

    for (const i in scope.charges) {
      temp = {
        id: scope.charges[i].id,
        isMandatory: scope.charges[i].isMandatory
      };
      scope.chargesSelected.push(temp);
    }

    this.formData.paymentChannelToFundSourceMappings = scope.paymentChannelToFundSourceMappings;
    this.formData.feeToIncomeAccountMappings = scope.feeToIncomeAccountMappings;
    this.formData.penaltyToIncomeAccountMappings = scope.penaltyToIncomeAccountMappings;
    this.formData.charges = scope.chargesSelected;
    this.formData.locale = scope.optlang.code;
    if (this.formData.isLinkedToFloatingInterestRates) {
      delete scope.formData.nominalAnnualInterestRate;
      delete scope.formData.nominalCashAdvanceInterestRate;
      this.formData.floatingInterestRates = [
        {
          creditInterestType: '0',
          floatingRateId: scope.purchaseFloatingRateData.floatingRateId,
          interestRateDifferential: scope.purchaseFloatingRateData.interestRateDifferential,
          minDifferentialRate: scope.purchaseFloatingRateData.minInterestRateDifferentialRate,
          defaultDifferentialRate: scope.purchaseFloatingRateData.defaultInterestRateDifferentialRate,
          maxDifferentialRate: scope.purchaseFloatingRateData.maxInterestRateDifferentialRate,
          isFloatingInterestRateCalculationAllowed: scope.purchaseFloatingRateData.isFloatingInterestRateCalculationAllowed
        },
        {
          creditInterestType: '1',
          floatingRateId: scope.cashAdvanceFloatingRateData.floatingRateId,
          interestRateDifferential: scope.cashAdvanceFloatingRateData.interestRateDifferential,
          minDifferentialRate: scope.cashAdvanceFloatingRateData.minInterestRateDifferentialRate,
          defaultDifferentialRate: scope.cashAdvanceFloatingRateData.defaultInterestRateDifferentialRate,
          maxDifferentialRate: scope.cashAdvanceFloatingRateData.maxInterestRateDifferentialRate,
          isFloatingInterestRateCalculationAllowed: scope.cashAdvanceFloatingRateData.isFloatingInterestRateCalculationAllowed
        }
      ];
    }

    resourceFactory.creditProductResource.save(this.formData, function (data) {
      location.path(`/viewcreditproduct/${data.resourceId}`);
    });
  };

  function getCreditProductById (creditProductId) {
    scope.formData = {};
    scope.charges = [];
    scope.showOrHideValue = 'show';
    scope.configureFundOptions = [];
    scope.specificIncomeaccounts = [];
    scope.penaltySpecificIncomeaccounts = [];
    scope.configureFundOption = {};
    scope.floatingRateData = [];
    scope.purchaseFloatingRateData = {};
    scope.cashAdvanceFloatingRateData = {};

    resourceFactory.creditProductResource.get({ creditProductId, template: 'true' }, (data) => {
      const creditName = getClonnedName(data.name);
      const randomString = getRandomString(4);
      scope.product = data;
      scope.assetAccountOptions = scope.product.accountingMappingOptions.assetAccountOptions || [];
      scope.liabilityAccountOptions = scope.product.accountingMappingOptions.liabilityAccountOptions || [];
      scope.incomeAccountOptions = scope.product.accountingMappingOptions.incomeAccountOptions || [];
      scope.expenseAccountOptions = scope.product.accountingMappingOptions.expenseAccountOptions || [];
      scope.reserveProductOptions = scope.product.reserveProductOptions || [];
      scope.floatingRateOptions = data.floatingRateOptions;
      scope.charges = data.charges;

      scope.formData = {
        // details tab
        name: creditName,
        shortName: randomString,
        description: data.description,

        // currency tab
        currencyCode: data.currency.code,
        inMultiplesOf: data.currency.inMultiplesOf,
        digitsAfterDecimal: data.currency.decimalPlaces,

        // terms tab
        nominalAnnualInterestRate: data.nominalAnnualInterestRate,
        nominalAnnualPenaltyInterestRate: data.nominalAnnualPenaltyInterestRate,
        nominalCashAdvanceInterestRate: data.nominalCashAdvanceInterestRate,
        gracePeriod: data.gracePeriod,
        interestCompoundingPeriodType: data.interestCompoundingPeriodType?.id || '',
        interestPostingPeriodType: data.interestPostingPeriodType?.id || '',
        interestCalculationType: data.interestCalculationType?.id || '',
        interestCalculationDaysInYearType: data.interestCalculationDaysInYearType?.id || '',
        minimumPayCalculationRate: data.minimumPayCalculationRate,
        minimumPayFixedAmount: data.minimumPayFixedAmount,
        graceForArrearsAging: data.graceForArrearsAging,

        // settings tab
        isSecuredCreditProduct: data.isSecuredCreditProduct,
        reserveProductId: data.isSecuredCreditProduct ? data.reserveProduct.id : undefined,
        statementDay: data.statementDay,

        // accounting tab
        accountingRule: data.accountingRule.id,
        isLinkedToFloatingInterestRates: data.isLinkedToFloatingInterestRates
      };

      // Accounting
      if (scope.formData.accountingRule === 2 || scope.formData.accountingRule === 3 || scope.formData.accountingRule === 4) {
        scope.formData.fundSourceAccountId = scope.product.accountingMappings.fundSourceAccount.id;
        scope.formData.creditPortfolioAccountId = scope.product.accountingMappings.creditPortfolioAccount.id;
        if (scope.formData.accountingRule === 3 || scope.formData.accountingRule === 4) {
          scope.formData.receivableInterestAccountId = scope.product.accountingMappings.receivableInterestAccount.id;
          scope.formData.receivableFeeAccountId = scope.product.accountingMappings.receivableFeeAccount.id;
          scope.formData.receivablePenaltyAccountId = scope.product.accountingMappings.receivablePenaltyAccount.id;
        }

        scope.formData.transfersInSuspenseAccountId = scope.product.accountingMappings.transfersInSuspenseAccount.id;
        scope.formData.disputesInSuspenseAccountId = scope.product.accountingMappings.disputesInSuspenseAccount.id;
        scope.formData.interestOnIncomeAccountId = scope.product.accountingMappings.interestOnIncomeAccount.id;
        scope.formData.incomeFromFeeAccountId = scope.product.accountingMappings.incomeFromFeeAccount.id;
        scope.formData.incomeFromPenaltyAccountId = scope.product.accountingMappings.incomeFromPenaltyAccount.id;
        scope.formData.incomeFromRecoveryAccountId = scope.product.accountingMappings.incomeFromRecoveryAccount.id;
        scope.formData.writeOffAccountId = scope.product.accountingMappings.writeOffAccount.id;
        scope.formData.overpaymentLiabilityAccountId = scope.product.accountingMappings.overpaymentLiabilityAccount.id;
        scope.creditproduct.accountingMappings = scope.product.accountingMappings;
      }

      // Advanced accounting rule
      if (data.paymentChannelToFundSourceMappings || data.feeToIncomeAccountMappings || data.penaltyToIncomeAccountMappings) {
        scope.showOrHideValue = 'hide';
      } else {
        scope.showOrHideValue = 'show';
      }

      scope.floatingRateDatas = data.creditAccountFloatingData; // change the name depending on response
      if (scope.formData.isLinkedToFloatingInterestRates) {
        for (const floatingRateData of scope.floatingRateDatas) {
          if (floatingRateData.creditInterestType.id === 0) {
            scope.purchaseFloatingRateData.floatingRateId = floatingRateData.floatingRateId;
            scope.purchaseFloatingRateData.interestRateDifferential = floatingRateData.interestRateDifferential;
            scope.purchaseFloatingRateData.minInterestRateDifferentialRate = floatingRateData.minDifferentialRate;
            scope.purchaseFloatingRateData.defaultInterestRateDifferentialRate = floatingRateData.defaultDifferentialRate;
            scope.purchaseFloatingRateData.maxInterestRateDifferentialRate = floatingRateData.maxDifferentialRate;
            scope.purchaseFloatingRateData.isFloatingInterestRateCalculationAllowed = floatingRateData.isFloatingInterestRateCalculationAllowed;
          }

          if (floatingRateData.creditInterestType.id === 1) {
            scope.cashAdvanceFloatingRateData.floatingRateId = floatingRateData.floatingRateId;
            scope.cashAdvanceFloatingRateData.interestRateDifferential = floatingRateData.interestRateDifferential;
            scope.cashAdvanceFloatingRateData.minInterestRateDifferentialRate = floatingRateData.minDifferentialRate;
            scope.cashAdvanceFloatingRateData.defaultInterestRateDifferentialRate = floatingRateData.defaultDifferentialRate;
            scope.cashAdvanceFloatingRateData.maxInterestRateDifferentialRate = floatingRateData.maxDifferentialRate;
            scope.cashAdvanceFloatingRateData.isFloatingInterestRateCalculationAllowed = floatingRateData.isFloatingInterestRateCalculationAllowed;
          }
        }
      }

      _.each(scope.product.paymentChannelToFundSourceMappings, function (fundSource) {
        scope.configureFundOptions.push({
          paymentTypeId: fundSource.paymentType.id,
          fundSourceAccountId: fundSource.fundSourceAccount.id,
          paymentTypeOptions: scope.product.paymentTypeOptions,
          assetAccountOptions: scope.assetAccountOptions
        });
      });

      _.each(scope.product.feeToIncomeAccountMappings, function (fees) {
        scope.specificIncomeaccounts.push({
          chargeId: fees.charge.id,
          incomeAccountId: fees.incomeAccount.id,
          chargeOptions: scope.product.chargeOptions,
          incomeAccountOptions: scope.incomeAccountOptions
        });
      });

      _.each(scope.product.penaltyToIncomeAccountMappings, function (penalty) {
        scope.penaltySpecificIncomeaccounts.push({
          chargeId: penalty.charge.id,
          incomeAccountId: penalty.incomeAccount.id,
          penaltyOptions: scope.product.penaltyOptions,
          incomeAccountOptions: scope.incomeAccountOptions
        });
      });

      scope.addConfigureFundSource = function () {
        if (
          scope.product.paymentTypeOptions &&
                scope.product.paymentTypeOptions.length > 0 &&
                scope.assetAccountOptions &&
                scope.assetAccountOptions.length > 0
        ) {
          scope.configureFundOptions.push({
            paymentTypeId: scope.product.paymentTypeOptions[0].id,
            fundSourceAccountId: scope.assetAccountOptions[0].id,
            paymentTypeOptions: scope.product.paymentTypeOptions,
            assetAccountOptions: scope.assetAccountOptions
          });
        }
      };

      scope.mapFees = function () {
        if (
          scope.product.chargeOptions &&
                scope.product.chargeOptions.length > 0 &&
                scope.incomeAccountOptions &&
                scope.incomeAccountOptions.length > 0
        ) {
          scope.specificIncomeaccounts.push({
            chargeId: scope.product.chargeOptions[0].id,
            incomeAccountId: scope.incomeAccountOptions[0].id,
            chargeOptions: scope.product.chargeOptions,
            incomeAccountOptions: scope.product.accountingMappingOptions.incomeAccountOptions
          });
        }
      };

      scope.mapPenalty = function () {
        if (
          scope.product.penaltyOptions &&
                scope.product.penaltyOptions.length > 0 &&
                scope.incomeAccountOptions &&
                scope.incomeAccountOptions.length > 0
        ) {
          scope.penaltySpecificIncomeaccounts.push({
            chargeId: scope.product.penaltyOptions[0].id,
            incomeAccountId: scope.incomeAccountOptions[0].id,
            penaltyOptions: scope.product.penaltyOptions,
            incomeAccountOptions: scope.incomeAccountOptions
          });
        }
      };
    });
  }
}
CreateCreditProductController.$inject = ['$scope', 'ResourceFactory', '$location', 'WizardHandler'];
