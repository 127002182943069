<template>
  <div>
    <button
      class="btn btn-primary"
      :disabled="disabled"
      @click="approveComplianceModal.show()"
    >
      {{ $t('label.button.approve') }}
    </button>
    <BaseModal
      id="modal-approve-compliance"
      ref="approveComplianceModal"
      hide-footer
      modal-size="lg"
    >
      <template #body>
        <FormWrapper @submit="submit">
          <div>
            <p class="fs-5">
              {{ $t('label.confirmApproveCompliance') }}
            </p>
            <p class="fs-5">
              {{ $t('label.backApproveCompliance') }}
            </p>
            <input-text
              v-model="approveNotes"
              type="textarea"
              :label="$t('label.anchor.notes')"
              required
              vertical
              class="col-sm-6 mb-2"
            />
          </div>
          <div class="pull-right">
            <button
              type="button"
              class="btn btn-default py-1 px-4"
              @click="cancel()"
            >
              {{ $t('label.button.back') }}
            </button>
            <button
              type="submit"
              class="btn btn-primary py-1 px-4"
            >
              {{ $t('label.button.ok') }}
            </button>
          </div>
        </FormWrapper>
      </template>
    </BaseModal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { get, set } from '@vueuse/core';
import { InputText, FormWrapper } from '../../shared/form-input/index';
import BaseModal from '@/components/shared/BaseModal.vue';

defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
});

const approveNotes = ref('');
const approveComplianceModal = ref(null);
const emit = defineEmits(['on-submit']);
const cancel = () => {
  set(approveNotes, '');
  get(approveComplianceModal).hide();
};
const submit = () => {
  emit('on-submit', get(approveNotes));
};
</script>
