export function DateFormat (dateFilter) {
  return (_input, format) => {
    if (!_input) { return ''; }

    let tDate;
    let newFormat;

    if (Array.isArray(_input)) {
      tDate = new Date(_input[0], _input[1] - 1, _input[2]);
      newFormat = 'dd MMMM yyyy';
    } else {
      tDate = new Date(_input);
      newFormat = 'dd MMMM yyyy, HH:mm:ss';
    }
    const dateFormat = format || newFormat;

    // return dateFilter(tDate, localStorageService.getFromLocalStorage('dateformat'));
    return isNaN(tDate) ? 'Invalid Date' : dateFilter(tDate, dateFormat);
  };
}

DateFormat.$inject = ['dateFilter'];
