<template>
  <div
    class="form-group align-items-center"
    :class="{ 'px-5': !noPadding && reverse,'pb-3': !noPadding }"
  >
    <div
      v-if="reverse"
      class="form-check"
    >
      <input
        :id="id"
        class="form-check-input"
        :name="id"
        type="checkbox"
        :checked="value"
        :disabled="disabled"
        @input="updateCheckBox"
      >
      <label
        class="form-check-label"
        :for="id"
      >
        <span
          v-if="required"
          class="required"
        >*</span>
        {{ label }}
      </label>
    </div>
    <div
      v-if="!reverse"
      class="row"
    >
      <label
        v-if="label"
        :class="[colAuto ? 'col-auto' : 'text-end col-sm-4', { 'text-secondary': disabled }]"
        :for="id"
      >
        {{ label }}
        <span
          v-if="required"
          class="required"
        >*</span>
        <span
          v-if="hint"
          v-tooltips="hint"
          class="fa fa-question-circle"
        />
      </label>
      <div :class="colAuto ? 'col-auto' : 'col-sm-8 d-flex align-items-center'">
        <input
          :id="id"
          :name="id"
          type="checkbox"
          :checked="value"
          :disabled="disabled"
          @input="updateCheckBox"
        >
      </div>
    </div>
    <div
      v-if="errorMessage"
      class="text-danger px-0"
      :class="{ 'offset-sm-4': !reverse }"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import formWrapperRegister from '@/components/shared/form-input/mixin.js';
export default ({
  mixins: [formWrapperRegister],
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    colAuto: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    reverse: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.localRule = [
      value => {
        const isRequiredValid = !this.required || value;
        return isRequiredValid || 'Required';
      }
    ];
  },
  methods: {
    updateCheckBox (value) {
      this.validatorRunner();
      this.$emit('input', value.target.checked);
    }
  }
});
</script>
