<template>
  <base-modal
    modal-size="xl"
    hide-footer
    :options="{ keyboard: false, backdrop: 'static' }"
    show-button-close
  >
    <template #body>
      <BaseTable
        :fields="fields"
        :items="items"
      />
    </template>
  </base-modal>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import BaseTable from '@/components/shared/data-grid/BaseTable.vue';
import i18n from '@/plugins/i18n';

defineProps({
  items: {
    type: Array,
    required: true
  }
});
const fields = [
  { key: 'riskParameters', label: i18n.t('label.anchor.riskParameters') },
  { key: 'inputs', label: i18n.t('label.anchor.inputs') },
  { key: 'riskScore', label: i18n.t('label.anchor.riskScore') },
  { key: 'riskLevel', label: i18n.t('label.anchor.riskLevel') }
];
</script>;
