<template>
  <form-wrapper
    class="col-lg-6 col-12"
    @submit="submit"
  >
    <div>
      <input-select
        id="userId"
        v-model="formData.userId"
        :label="$t('label.anchor.user')"
        :options="userOptions"
        :disabled="!!Object.keys(clientDocumentData).length"
        value-field="id"
      />
      <input-text
        id="name"
        v-model="formData.name"
        :label="$t('label.anchor.name')"
        required
      />
      <input-text
        id="description"
        v-model="formData.description"
        type="textarea"
        :label="$t('label.anchor.description')"
      />
      <input-select
        id="documentType"
        ref="documentTypeInput"
        v-model="formData.documentType"
        :label="$t('label.anchor.documentType')"
        :options="documentOptions"
        value-field="id"
        text-field="name"
        required
        @input="onDocumentTypeChange"
      />
      <input-text
        id="type"
        v-model="formData.type"
        :label="$t('label.anchor.type')"
      />
      <div>
        <input-file
          id="documentFile"
          v-model="formData.file"
          :label="$t('label.anchor.file')"
          instant-upload
          :required="!clientDocumentData?.fileName"
        />

        <div
          v-if="Object.keys(clientDocumentData).length"
          class="row"
        >
          <b class="offset-sm-4">
            {{ formData.file?.name || clientDocumentData.fileName }}
          </b>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center pt-3">
      <button
        class="btn btn-default me-3"
        @click="$emit('cancel')"
      >
        {{ $t('label.button.cancel') }}
      </button>

      <button
        id="save"
        type="submit"
        class="btn btn-primary"
      >
        {{ $t('label.button.save') }}
      </button>
    </div>
  </form-wrapper>
</template>

<script setup>
import apiService from '@/services/apiService';
import router from '@/router/index';
import { InputText, InputSelect, FormWrapper, InputFile } from '@/components/shared/form-input';
import { removeEmptyFields } from '@/utils/common';
import { reactive, onMounted, ref } from 'vue';
import { $notify } from '@/plugins/prototypes';
import { get, set } from '@vueuse/core';

const emit = defineEmits(['submit', 'cancel']);
const { clientId } = router.currentRoute.params;
const userOptions = ref([]);
const documentTypeInput = ref(null);
const documentOptions = ref([]);
const formData = reactive({
  documentType: '',
  name: '',
  file: null,
  type: '',
  userId: '',
  description: ''
});
const props = defineProps({
  clientDocumentData: {
    type: Object,
    default: () => ({})
  }
});

const onDocumentTypeChange = (type) => {
  formData.type = get(documentOptions).find(({ id }) => id === type)?.description || '';
};

onMounted(async () => {
  try {
    const { data: { pageItems } } = await apiService.clientResource.getAuthorizations({ clientId });
    const { data: { allowedDocumentTypes } } = await apiService.clients.getDocumentsTemplate(clientId);

    set(userOptions, pageItems.map(({ user }) => ({ name: `${user.firstname} ${user.lastname}`, id: user.id })));
    set(documentOptions, allowedDocumentTypes);

    if (Object.keys(props.clientDocumentData).length) {
      Object.assign(formData, {
        documentType: props.clientDocumentData?.documentType?.id || 5182,
        name: props.clientDocumentData?.name || '',
        type: props.clientDocumentData?.type || '',
        userId: get(userOptions).find(({ name }) => name === props.clientDocumentData?.userName)?.id || '',
        description: props.clientDocumentData?.description || ''
      });
      if (get(documentTypeInput)) get(documentTypeInput).updateInput(formData.documentType);
    }
  } catch (error) {
    $notify.error(error);
  }
});

const submit = () => {
  const { file, ...restData } = formData;
  const maxSize = 10 * 1024 * 1024; // 10MB
  const isFileMaxSize = file?.size > maxSize;
  const params = isFileMaxSize ? { command: 'largeFile' } : {};
  const payload = {
    ...restData,
    ...isFileMaxSize ? { fileName: file.name.replace(/\s/g, '') } : { file }
  };

  emit('submit', { formData: removeEmptyFields(payload), params, file });
};
</script>
