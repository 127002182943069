<template>
  <div>
    <h4 class="col-sm-12 d-flex justify-content-between align-items-center pt-4 pb-1">
      {{ $t('label.heading.sections') }}
    </h4>
    <div
      v-for="(section, sectionIndex) in sectionData"
      :key="sectionIndex"
      class="card mb-4"
    >
      <div class="card-body">
        <div class="d-flex align-items-center pb-4">
          <h5 class="mb-0">
            {{ $t('label.heading.section') }}
            {{ sectionIndex + 1 }}
          </h5>
          <button
            v-if="sectionIndex !== 0"
            type="button"
            class="btn btn-default text-danger"
            @click="removeSection(section, sectionIndex)"
          >
            <i class="fa fa-minus " />
            {{ $t('label.anchor.remove') }}
          </button>
        </div>

        <div class="row col-sm-6 pb-4">
          <InputText
            :id="`sectionTitle-${sectionIndex}`"
            v-model="section.title"
            required
            vertical
            class="col-sm-12 col-lg-6"
            :label="$t('label.anchor.sectionTitle')"
            @input="(value) => formatSectionName(value, section)"
          />

          <InputText
            :id="`sectionDescription-${sectionIndex}`"
            v-model="section.description"
            vertical
            class="col-sm-12 col-lg-6"
            :label="$t('label.anchor.sectionDescription')"
          />

          <InputSelect
            v-if="sectionIndex !== 0"
            :id="`sectionParentColumn-${sectionIndex}`"
            v-model="section.parentNameAttribute"
            vertical
            class="col-sm-12 col-lg-6"
            :options="parentColumnOptions"
            value-field="column"
            text-field="column"
            :disabled="!parentColumnOptions.length"
            append-to-body
            :label="$t('label.anchor.parentColumn')"
          >
            <template #option="{ item: optItem }">
              <div class="d-flex align-items-center">
                <h6 class="mb-0">
                  {{ $t('label.heading.section') }}
                  {{ optItem.identifier + 1 }}
                </h6>
                <span class="px-1">|</span>
                <b>{{ optItem.column }}</b>
              </div>
            </template>
            <template #selected-option="{ item: selectedItem }">
              <div class="d-flex align-items-center">
                <h6 class="mb-0">
                  {{ $t('label.heading.section') }}
                  {{ selectedItem.identifier + 1 }}
                </h6>
                <span class="px-1">|</span>
                <b>{{ selectedItem.column }}</b>
              </div>
            </template>
          </InputSelect>
        </div>

        <ColumnForm
          :section-columns="section.columns"
          :code-options="codeOptions"
          :section-index="sectionIndex"
          @on-column-change="(item) => onColumnChange(sectionIndex, item)"
        />
      </div>
    </div>

    <button
      type="button"
      class="btn btn-default border text-primary border-primary mt-4"
      @click="addSection()"
    >
      <i class="fa fa-plus " />
      {{ $t('label.anchor.addSection') }}
    </button>
  </div>
</template>

<script setup>
import ColumnForm from './ColumnForm.vue';
import router from '@/router/index';
import { set, get } from '@vueuse/core';
import { ref } from 'vue';
import { InputText, InputSelect } from '@/components/shared/form-input/index';
import { sectionFieldsData, formatName } from '@/components/system/manage-data-tables/DataTableCommons.js';

const props = defineProps({
  dataTableSections: {
    type: Array,
    default: () => []
  },
  codeOptions: {
    type: Array,
    required: true
  }
});
const emit = defineEmits(['on-section-change']);
const sectionData = ref([...props.dataTableSections]);
const dataTableNameParam = ref(router.currentRoute.params.dataTableName);
const tempParentColumnOptions = ref([]);
const parentColumnOptions = ref([]);
const dropSections = ref([]);
const dropColumnsOfSection = ref([]);

const formatSectionName = (value, section) => {
  if (get(dataTableNameParam) && section.id) return;
  formatName(value, section, 'name');
};

const addSection = () => {
  get(sectionData).push(JSON.parse(JSON.stringify(sectionFieldsData)));
  emit('on-section-change', { sections: get(sectionData) });
};

const removeSection = (section, sectionIndex) => {
  get(sectionData).splice(sectionIndex, 1);
  if (section?.id) {
    get(dropSections).push({ id: section.id });
    section.columns.forEach(column => addDropColumnsOfSection(column));
  }
  emit('on-section-change', { sections: get(sectionData), dropExistingColumns: get(dropColumnsOfSection), dropSections: get(dropSections) });
};

const addDropColumnsOfSection = (dropColumn) => {
  if (dropColumn.id && get(dropColumnsOfSection).findIndex(column => column.id === dropColumn.id) === -1) {
    get(dropColumnsOfSection).push({ id: dropColumn.id, name: dropColumn.name });
  }
};

const onColumnChange = (sectionIndex, { columns, dependOnColumnOptions, dropExistingColumns }) => {
  get(sectionData)[sectionIndex].columns = columns;
  dropExistingColumns.forEach(dropColumn => addDropColumnsOfSection(dropColumn));
  if (dependOnColumnOptions.length) {
    const currentSectionOptions = { identifier: sectionIndex, columnOptions: dependOnColumnOptions };
    const existingSectionIndex = get(tempParentColumnOptions).findIndex(option => option.identifier === sectionIndex);
    existingSectionIndex !== -1 ? get(tempParentColumnOptions)[existingSectionIndex] = currentSectionOptions : get(tempParentColumnOptions).push(currentSectionOptions);
    const flatParentColumn = get(tempParentColumnOptions).flatMap(({ columnOptions, identifier }) => columnOptions.map(column => ({ column, identifier }))).sort((a, b) => a.identifier - b.identifier);
    set(parentColumnOptions, flatParentColumn);
  }

  set(sectionData, get(sectionData).map(section => get(parentColumnOptions).includes(section.parentNameAttribute) ? section : { ...section, parentNameAttribute: '' }));
  emit('on-section-change', { sections: get(sectionData), dropExistingColumns: get(dropColumnsOfSection), dropSections: get(dropSections) });
};

</script>
