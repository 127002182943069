<template>
  <div>
    <b-breadcrumb
      v-if="!loading"
      :items="breadcrumbs"
    />
    <p v-else>
      ...
    </p>
  </div>
</template>
<script>
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { useSharedStore } from '@/stores/shared';
import { mapState } from 'pinia';

export default {
  computed: {
    ...mapState(useSharedStore, ['loading']),
    ...mapState(useBreadcrumbsStore, ['getBreadcrumbs']),
    breadcrumbs () {
      const getBreadcrumbs = this.getBreadcrumbs;
      if (getBreadcrumbs.length) return getBreadcrumbs; // check and return if breadcrumb was set on page (in store)

      // Generates breadcrumbs based on current route.
      // return an array of breadcrumb objects, each contain a "to" and "text" property.
      const pathArray = this.$route.path.split('/').filter(Boolean);
      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        const previousBreadcrumb = breadcrumbArray[idx - 1];
        const breadcrumb = {
          to: previousBreadcrumb ? `${previousBreadcrumb.to}/${path}` : `/${path}`,
          text: !this.$te(`label.anchor.${path}`) ? path : this.$t(`label.anchor.${path}`)
        };
        breadcrumbArray.push(breadcrumb);
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    }
  }
};
</script>
