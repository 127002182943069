import Vue from 'vue';
import { isString, isUndefined } from 'underscore';
import { getAngularRootScope } from '@/helper/angularRootScope';

Vue.directive('hasPermission', (el, binding) => {
  let permission = binding.value;

  if (isUndefined(permission)) {
    return;
  }

  if (!isString(permission)) {
    throw new Error('hasPermission value must be a string');
  }

  permission = permission.trim();
  const notPermissionFlag = permission[0] === '!';
  if (notPermissionFlag) {
    permission = permission.slice(1).trim();
  }

  const $angularRootScope = getAngularRootScope();

  function setVisibilityForPermission () {
    const hasPermission = $angularRootScope.hasPermission(permission);
    const show = (hasPermission && !notPermissionFlag) || (!hasPermission && notPermissionFlag);
    if (show) {
      el.style.removeProperty('display');
    } else {
      el.style.setProperty('display', 'none', 'important');
    }
  }

  setVisibilityForPermission();

  $angularRootScope.$on('permissionsChanged', setVisibilityForPermission);
});

export const havePermissions = (permissions) => {
  const $angularRootScope = getAngularRootScope();
  const adjustedPermissions = permissions.map(permission => {
    const trimmedPermission = permission.trim();
    const notPermissionFlag = trimmedPermission[0] === '!';
    const adjustedPermission = notPermissionFlag ? trimmedPermission.slice(1).trim() : trimmedPermission;
    return {
      permission: adjustedPermission,
      notPermissionFlag
    };
  });
  const hasPermission = adjustedPermissions.some(({ permission, notPermissionFlag }) => {
    return $angularRootScope.hasPermission(permission) !== notPermissionFlag;
  });
  return hasPermission;
};
