export function ViewLimitRuleController (scope, routeParams, resourceFactory, location) {
  const { ruleGroupId: ruleId, id: limitRuleId } = routeParams;
  scope.limitRule = {};
  scope.ruleGroup = {};
  scope.transferDirections = { RECEIVED: 'INCOMING', ORIGINATED: 'OUTGOING' };

  function loadData () {
    const params = {
      amlRule: { ruleId, fields: 'id,name' },
      limitRule: { ruleId, action: 'limitrule', actionId: limitRuleId }
    };

    resourceFactory.amlRulesResource.get(
      params.amlRule,
      function (resAmlRule) {
        resourceFactory.amlRulesResource.get(
          params.limitRule,
          function (resLimitRule) {
            scope.ruleGroup = resAmlRule;
            scope.limitRule = { ...resLimitRule };
            if (scope.limitRule.paymentType) {
              resourceFactory.paymentTypeResource.get(
                { paymentTypeId: scope.limitRule.paymentType },
                function (data) {
                  scope.limitRule.paymentType = data.name;
                }
                , handleResponseError);
            } else scope.limitRule.paymentType = 'ALL';

            if (scope.limitRule.clientClassificationId) {
              const codeName = scope.limitRule.legalForm === 1 ? 'ClientClassification-PERSON' : 'ClientClassification-ENTITY';
              resourceFactory.codeValue.get({ codeName }, {}, function (data) {
                const codeValues = data;
                scope.limitRule.clientClassification = codeValues.find(codeValue => codeValue.id === scope.limitRule.clientClassificationId).name;
              }, handleResponseError);
            } else scope.limitRule.clientClassification = 'ALL';
          },
          handleResponseError
        );
      });
  }

  loadData();

  // Actions on Rule Group
  scope.routeToEditLimitRule = function () {
    location.path(`/payments/aml/rulegroups/${ruleId}/editlimitrule/${limitRuleId}`);
  };

  scope.activateLimitRule = function () {
    const params = { ruleId, action: 'limitrule', actionId: limitRuleId };

    resourceFactory.amlRulesResource.update(
      params, { active: true },
      function () {
        scope.limitRule = { ...scope.limitRule, active: true };
      },
      handleResponseError
    );
  };

  scope.deactivateLimitRule = function () {
    const params = { ruleId, action: 'limitrule', actionId: limitRuleId };

    resourceFactory.amlRulesResource.update(
      params, { active: false },
      function () {
        scope.limitRule = { ...scope.limitRule, active: false };
      },
      handleResponseError
    );
  };

  function handleResponseError (error) {
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }
}

ViewLimitRuleController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
