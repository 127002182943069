export function ViewCreditAccountController (scope, routeParams, resourceFactory, paginatorService, location, $uibModal, route, dateFilter, $sce, http, API_VERSION, $rootScope) {
  scope.report = false;
  scope.hidePentahoReport = true;
  scope.showActiveCharges = true;
  scope.formData = {};
  scope.date = {};
  scope.creditaccountdetails = {};
  scope.transactionsPerPage = 15;
  scope.totalTransactions = 0;
  scope.creditAccountTransactions = [];
  scope.creditAccountPendingTransactions = [];
  scope.totalPendingTransactions = '';
  scope.accountBlockButtonText = '';
  scope.blockPermission = '';
  scope.blockedMerchants = [];
  scope.defaultDecimalPlace = 2;
  scope.showUnbilledTransaction = false;
  scope.showBilledTransaction = false;
  scope.floatingRates = [];
  scope.floatingRatesPerPage = 15;
  scope.totalFloatingRates = 0;
  scope.cardId = routeParams.id;
  scope.showInterestAccruals = false;

  scope.isDebit = function (savingsTransactionType) {
    return savingsTransactionType.isDebit === true;
  };

  scope.handleShowUnbilledTransaction = function (event) {
    const { checked } = event.target;
    if (checked) {
      scope.showUnbilledTransaction = true;
      scope.showBilledTransaction = false;
    } else if (!checked) {
      scope.showUnbilledTransaction = false;
    }
  };

  scope.handleShowBilledTransaction = function (event) {
    const { checked } = event.target;
    if (checked) {
      scope.showBilledTransaction = true;
      scope.showUnbilledTransaction = false;
    } else if (!checked) {
      scope.showBilledTransaction = false;
    }
  };

  scope.routeTo = function (transactionId) {
    location.path(`/viewcreditcardtrxn/${scope.cardId}/trxnId/${transactionId}`);
  };
  scope.routeToTransactions = function (transaction) {
    location.path(`/viewcreditcardauthorization/${transaction.cardAuthorization.account.cardToken}/authId/${transaction.cardAuthorization.id}`);
  };

  scope.convertDateArrayToObject = function (dateFieldName) {
    for (const i in scope.creditaccountdetails.transactions) {
      scope.creditaccountdetails.transactions[i][dateFieldName] = scope.creditaccountdetails.transactions[i].date;
    }
  };
  scope.isRecurringCharge = function (charge) {
    return charge.chargeTimeType.value === 'Monthly Fee' || charge.chargeTimeType.value === 'Annual Fee' || charge.chargeTimeType.value === 'Weekly Fee';
  };

  scope.undoCharge = function (transactionId) {
    location.path(`/savingaccount/${scope.cardId}/undoCharge/transactions/${transactionId}`);
  };

  scope.chargeBack = function (transactionId) {
    location.path(`/savingaccount/${scope.cardId}/chargeBack/transactions/${transactionId}`);
  };

  scope.changeStatus = function (accountId, status) {
    resourceFactory.cardsResource.changeStatus({ cardId: accountId, command: status }, () => {
      route.reload();
    });
  };

  const changeStatusCtrl = function ($scope, $uibModalInstance, items) {
    $scope.reason = null;

    $scope.changeStatus = function () {
      if (!$scope.reason) return;
      resourceFactory.cardsResource.changeStatus({ cardId: items.cardId, reason: $scope.reason, command: items.action }, () => {
        $uibModalInstance.close();
        route.reload();
      });
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.showChangeStatusModal = function (cardId, action) {
    $uibModal.open({
      templateUrl: 'changeStatus.html',
      controller: changeStatusCtrl,
      resolve: {
        items () {
          return {
            cardId,
            action
          };
        }
      },
      size: 'md'
    });
  };

  scope.changeType = function () {
    const upgradableToProductId = scope.cardInfo.product?.upgradableToProductId;
    resourceFactory.cardsResource.changeStatus({ cardId: routeParams.cardId, command: 'CHANGE_TYPE' }, { productId: upgradableToProductId });
  };

  scope.clickEvent = function (eventName, accountId) {
    eventName = eventName || '';
    switch (eventName) {
      case 'modifyapplication':
        location.path(`/clients/credit-account/${accountId}/edit`);
        break;
      case 'approve':
        location.path(`/creditaccount/${accountId}/approve`);
        break;
      case 'reject':
        location.path(`/creditaccount/${accountId}/reject`);
        break;
      case 'withdrawnbyclient':
        location.path(`/creditaccount/${accountId}/withdrawnByApplicant`);
        break;
      case 'delete':
        resourceFactory.creditAccountResource.delete({ accountId }, {}, function (data) {
          let destination = `/viewgroup/${data.groupId}`;
          if (data.clientId) destination = `/viewclient/${data.clientId}`;
          location.path(destination);
        });
        break;
      case 'adjustLimit':
        location.path(`/creditaccount/${accountId}/adjustLimit`);
        break;
      case 'makepayment':
        location.path(`/creditaccount/${accountId}/makepayment`);
        break;
      case 'card.editSecurities':
        location.path(`/viewcard/${accountId}/editSecurities`);
        break;
      case 'card.velocityRules':
        location.path(`/viewcard/${accountId}/editLimit`);
        break;
      case 'statementsAndDocuments':
        location.path(`/viewcreditaccount/${accountId}/billingCyclesAndStatements`);
        break;
      case 'refund':
        location.path(`/creditaccount/${accountId}/refund`);
        break;
      case 'closeaccount': {
        const clientId = scope.creditaccountdetails.creditAccountData.clientId;
        location.path(`/clients/${clientId}/credit-accounts/${accountId}/close`);
        break;
      }
    }
  };
  scope.filterData = {
    startDate: '',
    endDate: ''
  };
  scope.transactionTypes = [
    { id: 1, value: 'Deposit' },
    { id: 2, value: 'Withdrawal' },
    { id: 3, value: 'Interest posting' },
    { id: 4, value: 'Withdrawal fee' },
    { id: 5, value: 'Annual fee' },
    { id: 6, value: 'Waive Charge' },
    { id: 7, value: 'Pay Charge' },
    { id: 8, value: 'Dividend Payout' },
    { id: 12, value: 'Transfer initiated' },
    { id: 13, value: 'Transfer approved' },
    { id: 14, value: 'Transfer Withdrawn' },
    { id: 15, value: 'Transfer Rejected' },
    { id: 16, value: 'writtenoff' },
    { id: 17, value: 'Overdraft Interest' },
    { id: 18, value: 'Withhold Tax' },
    { id: 19, value: 'Escheat' },
    { id: 20, value: 'Amount on hold' },
    { id: 21, value: 'Release Amount' },
    { id: 22, value: 'Interest Payable Accrued' },
    { id: 23, value: 'Overdraft Interest Accrued' }
  ];
  scope.subTransactionTypes = [
    { id: 1, value: 'Card Transaction' },
    { id: 2, value: 'Settlement Return Credit' },
    { id: 3, value: 'Loan Disbursement' },
    { id: 4, value: 'Loan Repayment' },
    { id: 5, value: 'Card Authorise Payment' },
    { id: 6, value: 'Domestic ATM Withdrawal Fee' },
    { id: 7, value: 'International ATM Withdrawal Fee' },
    { id: 8, value: 'International Transaction Fee' }
  ];
  scope.getResultsPage = function (offset) {
    scope.formData.locale = scope.optlang.code;
    const dateFormat = 'yyyy-MM-dd';
    scope.filterData.startDate = dateFilter(scope.filterData.startDate, dateFormat);
    scope.filterData.endDate = dateFilter(scope.filterData.endDate, dateFormat);
    resourceFactory.creditCardTrxnsResource.getAllTransactions({
      cardId: routeParams.id,
      offset,
      limit: scope.transactionsPerPage,
      unbilledTransactionsOnly: scope.showUnbilledTransaction,
      billedTransactionsOnly: scope.showBilledTransaction,
      startDate: scope.filterData.startDate,
      endDate: scope.filterData.endDate,
      showInterestAccruals: scope.showInterestAccruals
    }, function (data) {
      scope.totalTransactions = data.totalFilteredRecords;
      scope.creditAccountTransactions = data.pageItems;
    });
  };
  scope.handleShowInterestAccrualsChange = function (checked) {
    scope.showInterestAccruals = checked;
    scope.searchTransactions();
  };

  scope.searchTransactions = function () {
    this.formData.locale = scope.optlang.code;
    const dateFormat = 'yyyy-MM-dd';
    scope.filterData.startDate = dateFilter(scope.filterData.startDate, dateFormat);
    scope.filterData.endDate = dateFilter(scope.filterData.endDate, dateFormat);
    const tempOffset = 0;
    resourceFactory.creditCardTrxnsResource.getAllTransactions({
      cardId: routeParams.id,
      offset: tempOffset,
      limit: scope.transactionsPerPage,
      unbilledTransactionsOnly: scope.showUnbilledTransaction,
      billedTransactionsOnly: scope.showBilledTransaction,
      startDate: scope.filterData.startDate,
      endDate: scope.filterData.endDate,
      showInterestAccruals: scope.showInterestAccruals,
      associationChildTransaction: true
    }, function (data) {
      scope.totalTransactions = data.totalFilteredRecords;
      scope.creditAccountTransactions = data.pageItems;
    });
  };

  scope.initPage = function () {
    resourceFactory.creditCardTrxnsResource.getAllTransactions({
      cardId: routeParams.id,
      offset: 0,
      limit: scope.transactionsPerPage,
      associationChildTransaction: true
    }, function (data) {
      scope.totalTransactions = data.totalFilteredRecords;
      scope.creditAccountTransactions = data.pageItems;
    });
  };

  scope.reversalCharge = function (transactionId) {
    location.path(`/credit-accounts/${routeParams.id}/transactions/${transactionId}/reversal-charge`);
  };

  resourceFactory.creditAccountResource.get({ accountId: routeParams.id, associations: 'account' }, function (data) {
    scope.creditaccountdetails = data;
    scope.formatDecimalPlace = data.creditAccountData.currency.decimalPlaces;
    if (data.creditAccountData.subStatus.id === 400) {
      scope.accountBlockButtonText = 'button.unBlockAccount';
      scope.blockPermission = 'UNBLOCK_SAVINGSACCOUNT';
    } else {
      scope.accountBlockButtonText = 'button.blockAccount';
      scope.blockPermission = 'BLOCK_SAVINGSACCOUNT';
    }
    scope.initPage();

    scope.date.toDate = new Date();

    scope.status = data.creditAccountData.status;
    if (scope.status.value === 'Submitted and pending approval' || scope.status.value === 'Active' || scope.status.value === 'Approved') {
      scope.choice = true;
    }
    scope.chargeAction = data.creditAccountData.status.value === 'Submitted and pending approval';
    scope.chargePayAction = data.creditAccountData.status.value === 'Active';
    if (scope.creditaccountdetails.charges) {
      scope.charges = scope.creditaccountdetails.creditAccountData.charges;
      if (data.charges) {
        for (const i in scope.charges) {
          if (scope.charges[i].name === 'Annual fee - INR') {
            scope.buttons.moreOptions.push({
              name: 'button.applyAnnualFees',
              taskPermissionName: 'APPLYANNUALFEE_SAVINGSACCOUNT'
            });
            scope.annualChargeId = scope.charges[i].id;
          }
          // covert object date into correct format
          const day = scope.charges[i].feeOnMonthDay?.day;
          const affix = findDateAffix(day);
          if (day !== undefined && scope.charges[i].chargeTimeType.value === 'Monthly Fee') {
            scope.charges[i].feeOnMonthDay = day + affix;
          }
          if (day !== undefined && scope.charges[i].chargeTimeType.value === 'Annual Fee') {
            scope.charges[i].feeOnMonthDay = `${day + affix} ${dateFilter(new Date(Object.values(scope.charges[i].feeOnMonthDay)), 'MMMM')}`;
          }
          if (day !== undefined && scope.charges[i].chargeTimeType.value === 'Weekly Fee') {
            scope.charges[i].feeOnMonthDay = '';
          }
        }
      }
      scope.chargeTableShow = true;
    } else {
      scope.chargeTableShow = false;
    }

    if (data.creditAccountData.status.value === 'Submitted and pending approval') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.modifyapplication',
            icon: 'fas fa-pencil-alt ',
            taskPermissionName: 'UPDATE_CREDIT_CARD'
          },
          {
            name: 'button.approve',
            icon: 'fas fa-check',
            taskPermissionName: 'APPROVE_CREDIT_CARD'
          }
        ],
        moreOptions: [
          {
            name: 'button.reject',
            taskPermissionName: 'REJECT_CREDIT_CARD'
          },
          {
            name: 'button.withdrawnbyclient',
            taskPermissionName: 'WITHDRAW_CREDIT_CARD'
          },
          {
            name: 'button.delete',
            taskPermissionName: 'DELETE_CREDIT_CARD'
          }
        ],
        cardStatuses: []
      };
    } else if (data.creditAccountData.status.value === 'Active') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.adjustLimit',
            icon: 'fas fa-pencil-alt',
            taskPermissionName: 'ADJUSTLIMIT_CREDIT_CARD'
          },
          {
            name: 'button.makepayment',
            icon: 'fas fa-dollar-sign',
            taskPermissionName: 'PAYMENTDUE_CREDIT_CARD'
          }
        ],
        moreOptions: [
          {
            name: 'button.statementsAndDocuments',
            taskPermissionName: 'READ_MEDIA'
          },
          {
            name: 'button.refund',
            taskPermissionName: 'READ_CREDIT_CARD'
          },
          {
            name: 'button.closeaccount',
            taskPermissionName: ''
          }
        ],
        cardStatuses: []
      };
      if (scope.cardInfo?.cardProcessorName?.toLowerCase() === 'hps') {
        scope.buttons.singlebuttons.push({
          name: 'button.card.editSecurities',
          icon: 'fas fa-pencil-alt',
          taskPermissionName: 'UPDATE_CARD'
        },
        {
          name: 'button.card.velocityRules',
          icon: 'fas fa-pencil-alt',
          taskPermissionName: 'UPDATE_CARD'
        });
      }
      scope.initPage();
    } else {
      scope.buttons = {
        moreOptions: [
          {
            name: 'button.statementsAndDocuments',
            taskPermissionName: 'READ_MEDIA'
          }
        ]
      };
    }

    if (data.cardStatus.toLowerCase() === 'active') {
      const option1 = {
        action: 'suspend',
        taskPermissionName: 'SUSPEND_CARD'
      };
      const option2 = {
        action: 'terminate',
        taskPermissionName: 'TERMINATE_CARD'
      };
      scope.buttons.cardStatuses.push(option1, option2);
    }
    if (data.cardStatus.toLowerCase() === 'suspended') {
      const option1 = {
        action: 'activate',
        taskPermissionName: 'ACTIVATE_CARD'
      };
      const option2 = {
        action: 'terminate',
        taskPermissionName: 'TERMINATE_CARD'
      };
      scope.buttons.cardStatuses.push(option1, option2);
    }
  });

  resourceFactory.cardsResource.get({ cardId: routeParams.id }, function (card) {
    scope.cardInfo = card;
  });

  resourceFactory.DataTablesResource.getAllDataTables({ apptable: 'm_credit_account' }, function (data) {
    scope.creditDatatables = data;
  });

  scope.getPendingTransactions = function (pageNumber = 1) {
    resourceFactory.getPendingCreditCardTransactions.get({
      cardId: routeParams.id,
      offset: ((pageNumber - 1) * scope.transactionsPerPage),
      limit: scope.transactionsPerPage
    }, function (response) {
      scope.creditAccountPendingTransactions = response.pageItems?.map(item => ({
        transfer: item.transfer,
        cardAuthorization: item.cardAuthorization,
        transferId: item.transfer?.id || item.cardAuthorization?.id,
        cardAuthorizationsId: item.cardAuthorization?.externalAuthId,
        createdAt: item.createdAt,
        valueDate: item.valueDate,
        amount: item.amount,
        pendingAmount: item.pendingAmount,
        description: item.transfer?.reference?.join('\n') || item.cardAuthorization?.merchant?.description
      }));
      scope.totalPendingTransactions = response.totalFilteredRecords;
    });
  };

  scope.hideActions = function (status) {
    return !['REJECTED', 'TERMINATED', 'EXPIRED'].includes(status);
  };

  scope.tabChange = function (tabName) {
    switch (tabName) {
      case 'pendingTransactions':
        scope.getPendingTransactions();
        break;
      case 'notes':
        scope.getNotesData();
        break;
      case 'charges':
        scope.getChargeData();
        break;
      default:
        break;
    }
  };
  const findDateAffix = function (day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  const fetchFunction = function (offset, limit) {
    const params = {};
    params.offset = offset;
    params.limit = limit;
    params.locale = scope.optlang.code;
    params.fromAccountId = routeParams.id;
    params.fromAccountType = 2;
    params.clientId = scope.creditaccountdetails.clientId;
    params.clientName = scope.creditaccountdetails.clientName;
    params.dateFormat = scope.df;
  };

  scope.searchTransaction = function () {
    scope.displayResults = true;
    scope.instructions = paginatorService.paginate(fetchFunction, 14);
    scope.isCollapsed = false;
  };

  scope.saveNote = function () {
    resourceFactory.noteResource.save({ resourceType: 'creditCards', resourceId: routeParams.id }, { note: scope.formData.note }, function () {
      scope.getNotesData();
      scope.formData.note = undefined;
    });
  };

  const EditNoteCtrl = function ($scope, $uibModalInstance, items) {
    $scope.editData = {};
    $scope.editData.editNote = items.sNote;
    $scope.editNote = function () {
      resourceFactory.noteResource.put({ resourceType: 'creditCards', resourceId: routeParams.id, noteId: items.sId }, { note: this.editData.editNote }, function () {
        scope.creditCardNotes[items.index].note = $scope.editData.editNote;
        $uibModalInstance.close();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.showEditNote = function (index) {
    const sId = scope.creditCardNotes[index].id;
    const sNote = scope.creditCardNotes[index].note;
    $uibModal.open({
      templateUrl: 'editNote.html',
      controller: EditNoteCtrl,
      resolve: {
        items () {
          return {
            sId,
            sNote,
            index
          };
        }
      },
      size: 'lg'
    });
  };

  scope.showDeleteNote = function (index) {
    const sId = scope.creditCardNotes[index].id;
    const sNote = scope.creditCardNotes[index].note;
    $uibModal.open({
      templateUrl: 'deleteNote.html',
      controller: DeleteNoteCtrl,
      resolve: {
        items () {
          return {
            sId,
            sNote,
            index
          };
        }
      },
      size: 'lg'
    });
  };

  const DeleteNoteCtrl = function ($scope, $uibModalInstance, items) {
    $scope.note = items.sNote;
    $scope.deleteNote = function () {
      resourceFactory.noteResource.delete({ resourceType: 'creditCards', resourceId: routeParams.id, noteId: items.sId }, '', function () {
        $uibModalInstance.close();
        scope.creditCardNotes.splice(items.index, 1);
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.dataTableChange = function (datatable) {
    resourceFactory.DataTablesResource.getTableDetails({
      datatablename: datatable.registeredTableName,
      entityId: scope.creditaccountdetails.creditAccountData.id,
      genericResultSet: 'true'
    }, function (data) {
      scope.datatabledetails = data;
      scope.datatabledetails.isData = data.data.length > 0;
      scope.datatabledetails.isMultirow = data.columnHeaders[0].columnName === 'id';
      scope.showDataTableAddButton = !scope.datatabledetails.isData || scope.datatabledetails.isMultirow;
      scope.showDataTableEditButton = scope.datatabledetails.isData && !scope.datatabledetails.isMultirow;
      const columnHeaderIndexMap = data.columnHeaders.reduce((map, column, index) => {
        map[column.columnName] = index;
        return map;
      }, {});
      if (scope.datatabledetails.isMultirow) {
        scope.datatabledetails.data = data.data.map(entry => {
          const row = entry.row.map((value, index) => {
            const column = data.columnHeaders[columnHeaderIndexMap[data.columnHeaders[index].columnName]];
            if (column.columnType === 'multiselect' || column.columnType === 'dropdown') {
              const ids = value.split(',');
              return column.columnValues
                .filter(obj => ids.includes(obj.id.toString()))
                .map(obj => obj.value)
                .toString();
            }
            return value;
          });
          return { row };
        });
      } else {
        const row = data.data[0].row;
        scope.datatabledetails.singleRow = data.columnHeaders.map((column, index) => {
          const value = row[index];
          const columnValue = column.columnValues.find(obj => obj.id === value);
          return { key: column.columnName, value: columnValue ? columnValue.value : value };
        });
      }
    });
  };

  scope.export = function () {
    scope.report = true;
    scope.printbtn = false;
    scope.viewReport = false;
    scope.viewSavingReport = true;
    scope.viewTransactionReport = false;
  };

  scope.viewDataTable = function (registeredTableName, data) {
    if (scope.datatabledetails.isMultirow) {
      location.url(`/viewdatatableentry/${registeredTableName}/${scope.creditaccountdetails.creditAccountData.id}/${data.row[0]}?fromEntity=creditaccount&cardId=${scope.cardId}`);
    } else {
      location.url(`/viewsingledatatableentry/${registeredTableName}/${scope.creditaccountdetails.creditAccountData.id}?fromEntity=creditaccount&cardId=${scope.cardId}`);
    }
  };

  scope.viewSavingDetails = function () {
    scope.report = false;
    scope.hidePentahoReport = true;
    scope.viewReport = false;
  };

  scope.viewPrintDetails = function () {
    // scope.printbtn = true;
    scope.report = true;
    scope.viewTransactionReport = false;
    scope.viewReport = true;
    scope.hidePentahoReport = true;
    scope.startDate = dateFilter(scope.date.fromDate, 'yyyy-MM-dd');
    scope.endDate = dateFilter(scope.date.toDate, 'yyyy-MM-dd');

    const reportParams = {
      'output-type': 'PDF',
      locale: scope.optlang.code,
      R_startDate: scope.startDate,
      R_endDate: scope.endDate,
      R_savingsAccountId: scope.creditaccountdetails.accountNo
    };

    resourceFactory.clientSavingTransactionsReportResource.get(reportParams, function (data) {
      const file = new Blob([new Uint8Array(data.data)], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      // allow untrusted urls for iframe http://docs.angularjs.org/error/$sce/insecurl
      scope.viewReportDetails = $sce.trustAsResourceUrl(fileURL);
    });
  };

  scope.exportToCsv = function () {
    scope.startDate = dateFilter(scope.date.fromDate, 'yyyy-MM-dd');
    scope.endDate = dateFilter(scope.date.toDate, 'yyyy-MM-dd');
    const reportParams = {
      'output-type': 'CSV',
      locale: scope.optlang.code,
      R_startDate: scope.startDate,
      R_endDate: scope.endDate,
      R_savingsAccountId: scope.creditaccountdetails.accountNo
    };
    resourceFactory.clientSavingTransactionsCSVReportResource.get(reportParams, function (data) {
      const file = new Blob([new Uint8Array(data.data)], { type: 'text/csv;charset=utf-8;' });

      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(file, 'download');
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(file);
          link.setAttribute('href', url);
          link.setAttribute('download', `${scope.creditaccountdetails.accountNo}_savings_transactions`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    });
  };

  scope.viewSavingsTransactionReceipts = function (transactionId) {
    scope.report = true;
    scope.viewTransactionReport = true;
    scope.viewSavingReport = false;
    scope.printbtn = false;
    scope.viewReport = true;
    scope.hidePentahoReport = true;

    resourceFactory.savingsTransactionReciept.get({ R_transactionId: transactionId }, function (data) {
      const file = new Blob([new Uint8Array(data.data)], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      // allow untrusted urls for iframe http://docs.angularjs.org/error/$sce/insecurl
      scope.viewReportDetails = $sce.trustAsResourceUrl(fileURL);
    });
  };

  scope.printReport = function () {
    window.print();
    window.close();
  };

  scope.deleteAll = function (apptableName, entityId) {
    resourceFactory.DataTablesResource.delete({ datatablename: apptableName, entityId, genericResultSet: 'true' }, {}, function () {
      route.reload();
    });
  };

  scope.modifyTransaction = function (accountId, transactionId) {
    location.path(`/savingaccount/${accountId}/modifytransaction?transactionId=${transactionId}`);
  };

  scope.transactionSort = {
    column: 'id',
    descending: true
  };

  scope.changeTransactionSort = function (column) {
    const sort = scope.transactionSort;
    if (sort.column === column.orderBy) {
      sort.descending = !sort.descending;
    } else {
      sort.column = column.orderBy;
      sort.descending = true;
    }
  };

  scope.checkStatus = function () {
    if (scope.status === 'Active' || scope.status === 'Closed' || scope.status === 'Transfer in progress' ||
      scope.status === 'Transfer on hold' || scope.status === 'Premature Closed' || scope.status === 'Matured') {
      return true;
    }
    return false;
  };

  scope.getNotesData = function () {
    resourceFactory.creditCardNotes.query({ cardId: routeParams.id }, function (data) {
      scope.creditCardNotes = data;
    });
  };

  scope.getChargeData = function () {
    resourceFactory.creditCardCharges.query({ cardId: routeParams.id }, function (data) {
      scope.creditCardCharges = data;
    });
  };

  scope.getStoppedPayments = function () {
    resourceFactory.blockMerchantResource.get({ clientId: scope.creditaccountdetails.clientId, accountId: routeParams.id }, function (data) {
      scope.blockedMerchants = data.pageItems;
    });
  };

  scope.deleteBlockMerchant = function (id) {
    resourceFactory.blockMerchantResource.delete({ blockId: id }, function () {
      scope.getStoppedPayments();
    });
  };

  scope.initFloatingRatePage = function () {
    resourceFactory.savingsFloatingRatesResource.get({
      cardId: routeParams.id,
      offset: 0,
      limit: scope.floatingRatesPerPage
    }, function (data) {
      scope.totalFloatingRates = data.totalFilteredRecords;
      scope.floatingRates = data.pageItems;
    });
  };

  scope.getFloatingRatesPage = function (pageNumber = 1) {
    resourceFactory.savingsFloatingRatesResource.get({
      cardId: routeParams.id,
      offset: ((pageNumber - 1) * scope.floatingRatesPerPage),
      limit: scope.floatingRatesPerPage
    }, function (data) {
      scope.totalFloatingRates = data.totalFilteredRecords;
      scope.floatingRates = data.pageItems;
    });
  };

  scope.getCreditCardDocuments = function () {
    resourceFactory.CreditCardDocumentResource.getCreditCardDocuments({ cardId: routeParams.id }, function (data) {
      scope.creditCardDocuments = data.map(item => {
        item.docUrl = `${API_VERSION}/creditcards/${item.parentEntityId}/documents/${item.id}/attachment?tenantIdentifier=${$rootScope.tenantIdentifier}`;
        if (item?.fileName) {
          item.fileIsImage = ['.jpg', '.jpeg', '.png'].includes(item.fileName.toLowerCase());
        }
        if (item?.type) {
          item.fileIsImage = item.type.toLowerCase().includes('image');
        }
        return item;
      });
    });
  };

  scope.deleteDocument = function (documentId, index) {
    resourceFactory.CreditCardDocumentResource.delete({ cardId: routeParams.id, documentId }, '', function () {
      scope.creditCardDocuments.splice(index, 1);
    });
  };

  scope.downloadDocumentXhr = function (xurl, fileType, fileName) {
    const configObj = {
      method: 'GET',
      url: xurl,
      responseType: 'arraybuffer'
    };

    http(configObj)
      .then(function onFulfilled (response) {
        const a = document.createElement('a');
        const windowUrl = window.URL || window.webkitURL;
        const blob = new Blob([response.data], { type: fileType, responseType: 'arraybuffer' });

        const url = windowUrl.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        windowUrl.revokeObjectURL(url);
      }).catch(function onRejection (errorResponse) {
        console.error({ errorResponse });
      });
  };

  scope.isPreviewAbleFile = (fileName) => {
    const fileExtension = fileName?.split('.').pop();
    const previewAbleFileExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    return previewAbleFileExtensions.includes(fileExtension);
  };

  scope.showCreditCardDocument = function (document) {
    $uibModal.open({
      templateUrl: 'credit-cards-document.html',
      controller: ViewCreditCardDocumentCtrl,
      size: 'xl',
      resolve: {
        items () {
          return {
            xurl: `${scope.hostUrl}${document.docUrl}`,
            fileName: document.fileName
          };
        }
      }
    });
  };

  const ViewCreditCardDocumentCtrl = function ($scope, $uibModalInstance, items) {
    $scope.items = items;

    function previewDocument () {
      http({
        method: 'GET',
        url: items.xurl,
        responseType: 'arraybuffer'
      }).then(function (imageData) {
        if ($scope.fileType.startsWith('image')) {
          let binary = '';
          const bytes = new Uint8Array(imageData.data);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          $scope.fileBase64 = btoa(binary);
        } else {
          const blob = new Blob([imageData.data], { type: $scope.fileType });
          $scope.fileURL = URL.createObjectURL(blob);
          $scope.content = $sce.trustAsResourceUrl($scope.fileURL);
        }
      });

      if (items.fileName.toLowerCase().indexOf('.png') !== -1) {
        $scope.fileType = 'image/png';
        $scope.isImage = true;
      } else if (items.fileName.toLowerCase().indexOf('.jpg') !== -1) {
        $scope.fileType = 'image/jpg';
        $scope.isImage = true;
      } else if (items.fileName.toLowerCase().indexOf('.jpeg') !== -1) {
        $scope.fileType = 'image/jpeg';
        $scope.isImage = true;
      } else if (items.fileName.toLowerCase().indexOf('.pdf') !== -1) {
        $scope.fileType = 'application/pdf';
        $scope.isImage = false;
      } else if (items.fileName.toLowerCase().indexOf('.doc') !== -1) {
        $scope.fileType = 'application/msword';
        $scope.isImage = false;
      } else if (items.fileName.toLowerCase().indexOf('.docx') !== -1) {
        $scope.fileType = 'application/msword';
        $scope.isImage = false;
      }
    }

    previewDocument();

    $scope.downloadDocument = function () {
      const a = document.createElement('a');
      a.href = $scope.fileURL;
      a.download = items.fileName;
      a.click();
    };

    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewCreditAccountController.$inject = ['$scope', '$routeParams', 'ResourceFactory', 'paginatorService', '$location', '$uibModal', '$route', 'dateFilter', '$sce', '$http', 'API_VERSION', '$rootScope'];
