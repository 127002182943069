import _ from 'underscore';

export function CreateCreditAccountController (scope, resourceFactory, location, routeParams, dateFilter, WizardHandler) {
  scope.products = [];
  scope.fieldOfficers = [];
  scope.formData = {};
  scope.formDat = {};
  scope.creditAccountDetails = {};
  scope.restrictDate = new Date();
  scope.clientId = routeParams.clientId;
  scope.date = {};
  scope.date.submittedOnDate = new Date();
  scope.noOfTabs = 1;
  scope.step = '-';
  scope.tf = 'HH:mm';
  scope.disabled = true;

  if (routeParams.centerEntity) {
    scope.centerEntity = true;
  }
  scope.inparams = {};
  if (scope.clientId) {
    scope.inparams.clientId = scope.clientId;
  }

  resourceFactory.creditAccountTemplateResource.get(scope.inparams, function (data) {
    scope.products = data.creditProductOptions;
    scope.chargeOptions = data.chargeOptions;
    scope.clientName = data.clientName;
    scope.accounts = data.accountOptions;
    scope.cardProducts = data.cardProductOptions;
  });

  scope.goNext = function (form) {
    WizardHandler.wizard().checkValid(form);
  };

  scope.formValue = function (array, model, findattr, retAttr) {
    if (model !== undefined) {
      findattr = findattr || 'id';
      retAttr = retAttr || 'value';
      return _.find(array, function (obj) {
        return obj[findattr] === model;
      })[retAttr];
    }
  };

  scope.changeAccount = function (account) {
    scope.accountNo = account.accountNo;
    scope.formData.currentAccountId = account.id;
  };

  scope.calculateEffectiveInterestRate = function () {
    if (scope.formData.purchaseInterestRateDifferential !== undefined) {
      scope.effectivePurchaseInterestRate = parseFloat(scope.formData.purchaseInterestRateDifferential) + parseFloat(scope.purchaseProductEffectiveRate);
    }
    if (scope.formData.cashAdvanceInterestRateDifferential !== undefined) {
      scope.effectiveCashAdvanceInterestRate = parseFloat(scope.formData.cashAdvanceInterestRateDifferential) + parseFloat(scope.cashProductEffectiveRate);
    }
  };

  scope.changeProduct = function () {
    resourceFactory.cardProductsResource.get({ cardProductId: scope.formData.cardProductId }, function (data) {
      scope.creditProductId = data.creditProductId;
      scope.cardProductName = data.name;
      scope.isHps = data.cardProcessorDisplayName?.toLowerCase() === 'hps';
      if (scope.isHps) {
        scope.productVersionId = data.productVersionId || null;
      }
      resourceFactory.creditProductResource.get({ creditProductId: scope.creditProductId, template: 'true' }, function (response) {
        scope.data = response;
        scope.formData.nominalAnnualInterestRate = response.nominalAnnualInterestRate;
        scope.formData.nominalCashAdvanceInterestRate = response.nominalCashAdvanceInterestRate;
        scope.formData.nominalAnnualPenaltyInterestRate = response.nominalAnnualPenaltyInterestRate;
        scope.formData.isSecuredCreditProduct = response.isSecuredCreditProduct;
        if (response.interestCompoundingPeriodType) scope.formData.interestCompoundingPeriodType = response.interestCompoundingPeriodType.id;
        if (response.interestPostingPeriodType) scope.formData.interestPostingPeriodType = response.interestPostingPeriodType.id;
        if (response.interestCalculationType) scope.formData.interestCalculationType = response.interestCalculationType.id;
        if (response.interestCalculationDaysInYearType) scope.formData.interestCalculationDaysInYearType = response.interestCalculationDaysInYearType.id;
        if (response.lockinPeriodFrequencyType) scope.formData.lockinPeriodFrequencyType = response.lockinPeriodFrequencyType.id;
        scope.creditAccountDetails = angular.copy(scope.formData);
        scope.isLinkedToFloatingInterestRates = scope.data.isLinkedToFloatingInterestRates;
        if (scope.isLinkedToFloatingInterestRates) {
          for (const creditAccountData of response.creditAccountFloatingData) {
            if (creditAccountData.creditInterestType.id === 0) {
              scope.purchaseProductEffectiveRate = creditAccountData.effectiveInterestRate;
            } else if (creditAccountData.creditInterestType.id === 1) {
              scope.cashProductEffectiveRate = creditAccountData.effectiveInterestRate;
            }
          }
        }
        scope.creditAccountDetails.interestCompoundingPeriodTypeValue = scope.formValue(response.interestCompoundingPeriodTypeOptions, scope.formData.interestCompoundingPeriodType);
        scope.creditAccountDetails.interestPostingPeriodTypeValue = scope.formValue(response.interestPostingPeriodTypeOptions, scope.formData.interestPostingPeriodType);
        scope.creditAccountDetails.interestCalculationTypeValue = scope.formValue(response.interestCalculationTypeOptions, scope.formData.interestCalculationType);
      });
    });
    scope.disabled = false;
  };

  scope.$watch('formData', function (newVal) {
    scope.creditAccountDetails = angular.extend(scope.creditAccountDetails, newVal);
  });

  // return input type
  scope.fieldType = function (type) {
    let fieldType = '';
    if (type) {
      if (type === 'CODELOOKUP' || type === 'CODEVALUE') {
        fieldType = 'SELECT';
      } else if (type === 'DATE') {
        fieldType = 'DATE';
      } else if (type === 'DATETIME') {
        fieldType = 'DATETIME';
      } else if (type === 'BOOLEAN') {
        fieldType = 'BOOLEAN';
      } else {
        fieldType = 'TEXT';
      }
    }
    return fieldType;
  };

  scope.submit = function () {
    if (!scope.isHps) {
      delete this.formData.cardNumber;
    }
    if (scope.date) {
      this.formData.submittedOnDate = dateFilter(scope.date.submittedOnDate, scope.df);
      this.formData.accountDifferentialExpiryDate = dateFilter(scope.date.accountDifferentialExpiryDate, scope.df);
    }
    delete scope.formData.isSecuredCreditProduct;
    delete scope.formData.reserveProductId;
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    if (scope.isLinkedToFloatingInterestRates) {
      this.formData.isLinkedToFloatingInterestRates = true;
      delete scope.formData.nominalAnnualInterestRate;
      delete scope.formData.nominalCashAdvanceInterestRate;
    }

    if (scope.clientId) this.formData.clientId = scope.clientId;

    resourceFactory.creditAccountResource.save(this.formData, function (data) {
      location.path(`/viewcreditaccount/${data.resourceId}`);
    });
  };

  scope.cancel = function () {
    if (scope.clientId) {
      location.path(`/viewclient/${scope.clientId}`);
    } else if (scope.centerEntity) {
      location.path(`/viewcenter/${scope.groupId}`);
    } else {
      location.path(`/viewgroup/${scope.groupId}`);
    }
  };
}

CreateCreditAccountController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', 'dateFilter', 'WizardHandler'];
