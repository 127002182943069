import angular from 'angular';
import Vue from 'vue';
import i18n from './plugins/i18n';

// COMPONENTS
import TopNavbar from '@/layouts/TopNavBar.vue';
import LeftNav from '@/layouts/left-nav.vue';
import StopPayment from '@/components/StopPayment.vue';
import Clipboard from '@/components/shared/Clipboard.vue';
import QrCodeGenerate from '@/components/shared/QrCodeGenerate';
import ManageAgingBucket from '@/components/system/transfer-limits/manageAgingBucket/Index.vue';
import ViewOrEditRiskRating from '@/pages/clients/risk-rating/view-or-edit.vue';
import UsersAttach from '@/components/clients/UsersAttach.vue';
import TransactionsTable from '@/components/clients/credit-accounts/TransactionsTable.vue';
import ViewClientDocument from '@/components/clients/modals/ViewDocument.vue';
import CommunicationDetails from '@/components/clients/communication-details/CommunicationDetails.vue';
import AverageMonthlyBalance from '@/components/clients/saving-accounts/tabs/AverageMonthlyBalance.vue';
import ClientSavingAccountTabs from '@/components/clients/saving-accounts/tabs/Tabs.vue';
import RepaymentTable from '@/components/clients/loan-acocunts/RepaymentTable.vue';
import EditPeriodicLimit from '@/components/clients/card/actions/EditPeriodicLimit.vue';

function registerVueComponent (componentName, component) {
  return ['createVueComponent', (createVueComponent) => createVueComponent(Vue.component(componentName, component))];
}

export default angular
  .module('mbanq.components', [])
  .config(['$ngVueProvider', $ngVueProvider => {
    $ngVueProvider.setRootVueInstanceProps({
      i18n
    });
  }])
  // COMPONENTS
  .directive('clipboard', registerVueComponent('clipboard', Clipboard))
  .directive('topNavbar', registerVueComponent('topNavbar', TopNavbar))
  .directive('leftNav', registerVueComponent('leftNav', LeftNav))
  .directive('stopPayment', registerVueComponent('stopPayment', StopPayment))
  .directive('inputSelect', registerVueComponent('inputSelect', () => import('@/components/shared/form-input/InputSelect.vue')))
  .directive('qrCodeGenerate', registerVueComponent('qrCodeGenerate', QrCodeGenerate))
  .directive('manageAgingBucket', registerVueComponent('manageAgingBucket', ManageAgingBucket))
  .directive('viewOrEditRiskRating', registerVueComponent('viewOrEditRiskRating', ViewOrEditRiskRating))
  .directive('usersAttach', registerVueComponent('usersAttach', UsersAttach))
  .directive('creditTransactionsTable', registerVueComponent('creditTransactionsTable', TransactionsTable))
  .directive('homePage', registerVueComponent('homePage', () => import('@/pages/home/index.vue')))
  .directive('viewClientDocument', registerVueComponent('viewClientDocument', ViewClientDocument))
  .directive('communicationDetails', registerVueComponent('communicationDetails', CommunicationDetails))
  .directive('averageMonthlyBalance', registerVueComponent('averageMonthlyBalance', AverageMonthlyBalance))
  .directive('clientSavingAccountTabs', registerVueComponent('clientSavingAccountTabs', ClientSavingAccountTabs))
  .directive('repaymentTable', registerVueComponent('repaymentTable', RepaymentTable))
  .directive('editPeriodicLimit', registerVueComponent('editPeriodicLimit', EditPeriodicLimit))
  .directive('transactions', registerVueComponent('transactions', () => import('@/components/clients/saving-accounts/tabs/Transactions.vue')))
  .directive('tableJournalEntriesResult', registerVueComponent('tableJournalEntriesResult', () => import('@/components/accounting/JournalEntriesTable.vue')))
  .name;
