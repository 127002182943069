import _ from 'underscore';

export function ViewCheckerinboxController (scope, resourceFactory, routeParams, location, $uibModal) {
  scope.details = {};
  resourceFactory.auditResource.get({ templateResource: routeParams.id }, function (data) {
    scope.details = data;
    scope.commandAsJson = data.commandAsJson;
    const obj = JSON.parse(scope.commandAsJson);
    scope.jsondata = [];
    _.each(obj, function (value, key) {
      scope.jsondata.push({ name: key, property: value });
    });
  });
  scope.checkerApprove = function (action) {
    $uibModal.open({
      templateUrl: 'approve.html',
      controller: ApproveCtrl,
      resolve: {
        action () {
          return action;
        }
      }
    });
  };
  const ApproveCtrl = function ($scope, $uibModalInstance, action) {
    $scope.approve = function () {
      resourceFactory.checkerInboxResource.save({ templateResource: routeParams.id, command: action }, {}, function () {
        $uibModalInstance.close('approve');
        location.path('/checkeractionperformed');
      }, () => $uibModalInstance.close('approve'));
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.checkerReject = function (action) {
    $uibModal.open({
      templateUrl: 'reject.html',
      controller: RejectCtrl,
      resolve: {
        action () {
          return action;
        }
      }
    });
  };
  const RejectCtrl = function ($scope, $uibModalInstance, action) {
    $scope.reject = function () {
      resourceFactory.checkerInboxResource.save({ templateResource: routeParams.id, command: action }, {}, function () {
        $uibModalInstance.close('reject');
        location.path('/checkeractionperformed');
      }, () => $uibModalInstance.close('reject'));
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
  scope.checkerDelete = function () {
    $uibModal.open({
      templateUrl: 'delete.html',
      controller: DeleteCtrl
    });
  };
  const DeleteCtrl = function ($scope, $uibModalInstance) {
    $scope.delete = function () {
      resourceFactory.checkerInboxResource.delete({ templateResource: routeParams.id }, {}, function () {
        $uibModalInstance.close('delete');
        location.path('/checkeractionperformed');
      }, () => $uibModalInstance.close('delete'));
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };
}

ViewCheckerinboxController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', '$uibModal'];
