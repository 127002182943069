<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.client.closeCreditAccount') }}
    </template>

    <template #actions>
      <button
        class="btn btn-primary"
        @click="handleShowPayoffInquiryConfirmation"
      >
        {{ $t('label.button.payoffInquiry') }}
      </button>
    </template>

    <div class="row">
      <div class="col-8">
        <form-close-credit-account />
      </div>
    </div>

    <modal-confirm-payoff-inquiry
      ref="confirmPayoff"
      @submit="handleConfirmPayoffInquiry"
    />

    <modal-download-closure-report
      ref="downloadClosureReport"
      :report-file="reportFile"
    />
  </main-content-layout>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import router from '@/router';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import i18n from '@/plugins/i18n';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import FormCloseCreditAccount from '@/components/clients/credit-accounts/FormCloseCreditAccount.vue';
import ModalConfirmPayoffInquiry from '@/components/clients/credit-accounts/ModalConfirmPayoffInquiry.vue';
import ModalDownloadClosureReport from '@/components/clients/credit-accounts/ModalDownloadClosureReport.vue';
import { get, set } from '@vueuse/core';

const { clientId, creditAccountId } = router.currentRoute.params;
const breadCrumbsStore = useBreadcrumbsStore();
const confirmPayoff = ref(null);
const downloadClosureReport = ref(null);
const creditAccountDataId = ref(null);
const reportFile = ref({});

onMounted(async () => {
  const { data: { displayName } } = await apiService.clients.get(clientId);
  const { data: { creditAccountData, cardNumber } } = await apiService.creditAccountResource.get(creditAccountId, { associations: 'account' });
  set(creditAccountDataId, creditAccountData.id);
  breadCrumbsStore.setBreadcrumbs([
    {
      text: i18n.t('label.anchor.clients'),
      href: '#/clients'
    },
    {
      text: displayName,
      href: `#/viewclient/${clientId}`
    },
    {
      text: `${creditAccountData.name} (${cardNumber})`,
      href: `#/viewcreditaccount/${creditAccountId}`
    },
    {
      text: i18n.t('label.anchor.creditAccountClosure')
    }
  ]);
});

async function handleShowPayoffInquiryConfirmation () {
  get(confirmPayoff).$children[0].show();
}

async function handleConfirmPayoffInquiry () {
  try {
    await apiService.creditAccountResource.save(creditAccountId, {}, { command: 'closureEnquiry' });

    const { data: { data: file }, headers } = await apiService.report.generate('Credit Card Closure Report', {
      R_CreditAccountId: get(creditAccountDataId),
      'output-type': 'PDF'
    }, 'blob');

    set(reportFile, { file, headers });

    $notify.success(i18n.t('label.message.success.payoffQuote'));
    get(downloadClosureReport).$children[0].show();
  } catch (error) {
    $notify.error(error);
  } finally {
    get(confirmPayoff).$children[0].hide();
  }
}
</script>
