<template>
  <div
    class="form-group align-items-center pb-3"
    :class="{row: !vertical}"
  >
    <label
      v-if="label"
      class="control-label"
      :class="vertical ? 'mb-1' : 'text-end col-sm-4'"
      :for="id"
    >
      {{ label }}
      <span
        v-if="required"
        class="required"
      >*</span>
      <span
        v-if="hint"
        v-tooltips="hint"
        class="fa fa-question-circle"
      />
    </label>

    <div :class="{'col': !vertical}">
      <div class="input-group">
        <component
          :is="type === 'textarea' ? 'textarea' : 'input'"
          v-if="!mask"
          :id="id"
          ref="input"
          :name="id"
          :type="localType"
          :class="['form-control', {'is-invalid': errorMessage}]"
          :disabled="disabled"
          :rows="rows"
          :value="value"
          :placeholder="placeholder"
          :step="step"
          :min="minimum"
          @input="updateInput"
        >
          {{ value }}
        </component>

        <input
          v-if="mask"
          :id="id"
          ref="input"
          v-facade="mask"
          :name="id"
          :type="localType"
          :class="['form-control', {'is-invalid': errorMessage}]"
          :disabled="disabled"
          :rows="rows"
          :value="value"
          :placeholder="placeholder"
          :step="step"
          @input="updateInput"
        >

        <button
          v-if="type === 'password' && !hidePasswordToggle"
          :id="`btn-${id}-show-password`"
          class="btn btn-outline-secondary input-addon-border"
          type="button"
          @click.prevent="showPassword = !showPassword"
        >
          <span class="icon is-small is-right">
            <i
              class="fas"
              :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"
            />
          </span>
        </button>
      </div>
    </div>
    <div
      v-if="errorMessage"
      class="text-danger"
      :class="{'offset-sm-4': !vertical}"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { facade } from 'vue-input-facade';
import formWrapperRegister from '@/components/shared/form-input/mixin.js';

export default {
  directives: { facade },
  mixins: [formWrapperRegister],
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: null || ''
    },
    required: {
      type: [Boolean, Object],
      default: false || (() => {})
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 3
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    vertical: {
      type: Boolean,
      defualt: false
    },
    step: {
      type: Number,
      default: 0.001
    },
    hint: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: null
    },
    hidePasswordToggle: {
      type: Boolean,
      default: false
    },
    minimum: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      showPassword: false
    };
  },
  computed: {
    localType () {
      if (this.type === 'password') {
        return this.showPassword ? 'text' : 'password';
      }
      return this.type;
    }
  },
  methods: {
    updateInput (e) {
      this.validatorRunner();
      this.$emit('input', e.target.value);
    }
  }
};
</script>

<style scoped>
.input-addon-border {
  border: solid 1px #ccc !important;
}
</style>
