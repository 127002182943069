<template>
  <FormWrapper @submit="submit">
    <div class="row col-sm-6">
      <InputText
        id="dataDisplayName"
        v-model="formData.datatableTitle"
        required
        vertical
        class="col-sm-12 col-lg-6"
        :label="$t('label.anchor.dataDisplayName')"
        @input="(value) => formatDataTableName(value, formData)"
      />
      <InputText
        id="dataTableName"
        v-model="formData.datatableName"
        disabled
        vertical
        class="col-sm-12 col-lg-6"
        :label="$t('label.anchor.dataTableName')"
      />
      <InputSelect
        id="applicationTableName"
        v-model="formData.apptableName"
        required
        vertical
        value-field="value"
        class="col-sm-12 col-lg-6"
        :options="appDataTableOptions"
        append-to-body
        :label="$t('label.anchor.applicationTableName')"
      />
      <InputSelect
        v-if="formData.apptableName === 'm_client'"
        id="legalForm"
        v-model="formData.legalFormId"
        vertical
        value-field="id"
        class="col-sm-12 col-lg-6"
        :label="$t('label.anchor.legalForm')"
        :options="clientLegalFormOptions"
        append-to-body
      />
      <InputText
        id="resourcePath"
        v-model="formData.resourcePath"
        vertical
        class="col-sm-12 col-lg-6"
        :label="$t('label.anchor.resourcePath')"
      />
      <InputText
        id="description"
        v-model="formData.description"
        type="textarea"
        vertical
        class="col-sm-12"
        :label="$t('label.anchor.description')"
      />
      <InputCheckBox
        id="multiRow"
        v-model="formData.multiRow"
        reverse
        no-padding
        col-auto
        class="col-sm-12"
        :label="$t('label.anchor.multiRow')"
      />
    </div>

    <SectionForm
      v-if="!isLoadingSection"
      :code-options="codeOptions"
      :data-table-sections="formData.sections"
      @on-section-change="onSectionChange"
    />

    <div class="text-center pt-4">
      <button
        type="button"
        class="btn btn-default"
        @click="$emit('on-cancel')"
      >
        {{ $t('label.button.cancel') }}
      </button>
      <button
        type="submit"
        class="btn btn-primary"
      >
        {{ $t('label.button.save') }}
      </button>
    </div>
  </formwrapper>
</template>

<script setup>
import { set, get } from '@vueuse/core';
import { ref, reactive, onMounted } from 'vue';
import { removeEmptyFields } from '@/utils/common';
import { $notify } from '@/plugins/prototypes';
import { InputText, InputCheckBox, InputSelect, FormWrapper } from '@/components/shared/form-input/index';
import { appDataTableOptions, clientLegalFormOptions, formatName, sectionFieldsData } from '@/components/system/manage-data-tables/DataTableCommons.js';
import apiService from '@/services/apiService';
import SectionForm from '@/components/system/manage-data-tables/SectionForm.vue';
import router from '@/router/index';

const props = defineProps({
  editFormData: {
    type: Object,
    default: () => ({})
  }
});
const emit = defineEmits(['on-submit']);
const isLoadingSection = ref(false);
const codeOptions = ref([]);
const dataTableNameParam = ref(router.currentRoute.params.dataTableName);
const dropExistingColumnsOfSection = ref([]);
const dropSections = ref([]);
const formData = reactive({
  datatableName: '',
  apptableName: '',
  datatableTitle: '',
  description: '',
  multiRow: false,
  legalFormId: null,
  resourcePath: '',
  sections: [JSON.parse(JSON.stringify(sectionFieldsData))]
});

onMounted(async () => {
  set(isLoadingSection, true);
  try {
    const { data } = await apiService.codeResources.getAllCodes();
    if (Object.keys(props.editFormData).length) Object.assign(formData, props.editFormData);
    set(codeOptions, data);
  } catch (error) {
    $notify.error(error);
  } finally {
    set(isLoadingSection, false);
  }
});

const formatDataTableName = (value, dataTable) => {
  if (get(dataTableNameParam)) return;
  formatName(value, dataTable, 'datatableName', 'd_');
};

const onSectionChange = ({ sections, dropExistingColumns = [], dropSections: dropCurrentSections }) => {
  formData.sections = sections;
  set(dropExistingColumnsOfSection, dropExistingColumns);
  set(dropSections, dropCurrentSections);
};

const submit = async () => {
  const { sections, ...formDataPayload } = formData;
  const sectionsPayload = sections.map(({ columns, ...section }, sectionIndex) => {
    const columnsPayload = columns.map((column, columnIndex) => {
      return {
        ...removeEmptyFields(column),
        position: columnIndex,
        sectionIndex
      };
    });
    return {
      ...removeEmptyFields(section),
      position: sectionIndex,
      columns: columnsPayload
    };
  });
  const additionalItems = router.currentRoute.params.dataTableName ? { dropExistingColumnsOfSection: get(dropExistingColumnsOfSection), dropSections: get(dropSections) } : { };
  emit('on-submit', { ...removeEmptyFields(formDataPayload), sections: sectionsPayload, ...additionalItems });
};
</script>
