import ComplianceReview from '@/pages/compliance-review/index.vue';
import ViewComplianceReview from '@/pages/compliance-review/view.vue';

export const complianceReviewRoutes = [
  {
    path: '/compliance-review',
    name: 'ComplianceReview',
    component: ComplianceReview
  },
  {
    path: '/compliance-review/:id(\\d+)',
    name: 'ViewComplianceReview',
    component: ViewComplianceReview
  }
];
