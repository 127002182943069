<template>
  <form-wrapper
    class="row p-5"
    @submit="onSubmit"
  >
    <div class="col-12 col-lg-6">
      <input-select
        id="range-select"
        v-model="range"
        :label="$t('label.input.selectRange')"
        :options="ranges"
        value-field="value"
        text-field="label"
        not-clearable
        required
      />

      <input-date-picker
        v-if="range === 'custom'"
        id="date-range"
        v-model="dateRange"
        :label="$t('label.input.dateRange')"
        :max-date="new Date()"
        :default-value="defaultDateRange"
        range
        required
      />

      <input-text
        id="average-daily-balance"
        v-model="averageBalance"
        :label="$t('label.input.averageMonthlyBalance')"
        disabled
      />

      <div class="d-flex">
        <button
          class="btn btn-primary mx-auto"
          type="submit"
        >
          {{ $t('label.button.calculate') }}
        </button>
      </div>
    </div>
  </form-wrapper>
</template>

<script setup>
import { FormWrapper, InputSelect, InputDatePicker, InputText } from '@/components/shared/form-input/index';
import { ref, watch } from 'vue';
import { dateStringToFormat } from '@/helper/dateHelper';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';

const props = defineProps({
  savingAccountId: {
    type: Number,
    required: true
  }
});

const dateRange = ref(null);
const ranges = ref([
  { label: 'Last 1 Month', value: '1Month' },
  { label: 'Last 3 Months', value: '3Months' },
  { label: 'Custom Range', value: 'custom' }
]);

const range = ref(null);
const averageBalance = ref(null);

const previousMonthStart = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
const defaultDateRange = ref([previousMonthStart, previousMonthStart]);

watch(range, () => {
  averageBalance.value = null;
});

const onSubmit = async () => {
  try {
    const now = new Date();
    let startDate;
    let endDate = now;

    if (range.value === 'custom') {
      [startDate, endDate] = dateRange.value;
    } else {
      const monthOffset = { '1Month': -1, '3Months': -3 }[range.value];
      startDate = new Date(now.getFullYear(), now.getMonth() + monthOffset, 1);
      endDate = new Date(now.getFullYear(), now.getMonth(), 0);
    }

    const { data: { averageDailyBalance } } = await apiService.savingAccounts.getAverageDailyBalance(props.savingAccountId, {
      startDate: dateStringToFormat(startDate, 'YYYY-MM-DD'),
      endDate: dateStringToFormat(endDate, 'YYYY-MM-DD')
    });
    averageBalance.value = averageDailyBalance;
  } catch (error) {
    $notify.error(error);
  }
};
</script>
