<template>
  <b-modal
    id="tx-change-status-modal"
    :title="modalTitle"
    hide-header-close
    size="md"
    @show="selectedReason = ''"
  >
    <input-select
      id="selected-reason"
      v-model="selectedReason"
      :label="$t('label.input.reason')"
      :options="getReasons"
      required
      vertical
    />
    <template #modal-footer>
      <button
        class="btn btn-default"
        @click="cancel"
      >
        {{ $t('label.button.cancel') }}
      </button>
      <button
        class="btn btn-primary"
        :disabled="!selectedReason?.length"
        @click="statusChange"
      >
        {{ $t('label.button.confirm') }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import apiService from '@/services/apiService';
import { useTxMonitoringStore } from '@/stores/txmonitoring';
import { useSharedStore } from '@/stores/shared';
import { InputSelect } from '@/components/shared/form-input/index';
import { isPlainObject as _isPlainObject } from 'lodash';

export default {
  name: 'TxTransactionChangeStatusModal',
  components: {
    InputSelect
  },
  data () {
    return {
      selectedReason: '',
      reasons: {},
      titles: {
        approve: 'Are you sure you want to allow this transaction?',
        reject: 'Are you sure you want to stop this transaction?',
        suspend: 'Are you sure you want to suspend this transaction?',
        rescreen: 'Are you sure you want to rescreen this transaction?',
        OFAC_APPROVE: 'Are you sure you want to allow this transaction?',
        OFAC_REJECT: 'Are you sure you want to reject this transaction?'
      }
    };
  },
  computed: {
    ...mapState(useTxMonitoringStore, ['transaction', 'selectedAction']),
    modalTitle () {
      if (!this.selectedAction) return '';
      return this.titles[this.selectedAction];
    },
    getReasons () {
      const reasonOptions = Object.values(this.reasons);

      if (!reasonOptions.length) return [];

      const result = [];

      reasonOptions.forEach(list => {
        if (_isPlainObject(list)) {
          result.push(...Object.values(list));
        } else result.push(...list);
      });

      return result;
    }
  },
  async mounted () {
    await this.getTemplate();
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setNeedsToReloadList']),
    ...mapActions(useSharedStore, ['resetNotificationMessage']),
    async getTemplate () {
      try {
        const { data: result } = await apiService.amlScreeningResult.template();
        this.reasons = result;
      } catch (error) {
        this.$notify.error(error);
      }
    },
    async statusChange () {
      this.resetNotificationMessage();
      if (!this.selectedAction || !this.transaction) return;
      try {
        await apiService.amlScreeningResult.changeStatus(this.transaction.id, this.selectedAction, { reason: this.selectedReason });
        this.$bvModal.hide('tx-change-status-modal');
        this.$bvModal.hide('tx-details-modal');
        this.setNeedsToReloadList(true);
      } catch (e) {
        this.$notify.error(e);
      }
    },
    cancel () {
      this.resetNotificationMessage();
      this.$bvModal.hide('tx-change-status-modal');
    }
  }
};
</script>
