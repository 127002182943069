<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div
      v-if="!vertical"
      class="table-responsive"
    >
      <table
        id="base-table"
        v-sortable
        class="table align-middle"
        :class="[{'table-hover': clickable, 'table-bordered': bordered}]"
      >
        <thead>
          <tr>
            <th
              v-if="multiselect"
              class="w-auto"
            >
              <input
                v-if="isAbleToSelectAll"
                :checked="selectAllRows"
                class="form-check-input"
                type="checkbox"
                @click="handleAllSelectedRows"
              >
            </th>
            <th
              v-for="(field, index) in fields"
              :key="index"
              :ref="field.key"
              :class="!field.sortable || 'sortable'"
              @click="sortColumn(field)"
            >
              {{ field.label || field.key }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="loading">
            <tr>
              <td :colspan="colspan">
                <div class="text-center my-2">
                  <strong>{{ $t('label.loading') }}...</strong>
                </div>
              </td>
            </tr>
          </template>

          <template v-if="$scopedSlots.row">
            <slot
              name="row"
              :item="items"
            />
          </template>

          <template v-if="!$scopedSlots.row && !loading">
            <tr
              v-for="(item, index) in items"
              :key="index"
              class="align-top"
              @click="rowClicked(item)"
            >
              <td
                v-if="multiselect"
                class="ps-2"
                @click.stop
              >
                <slot
                  name="checkbox"
                  :item="item"
                >
                  <input
                    v-model="selectedRows"
                    class="form-check-input"
                    type="checkbox"
                    :disabled="item.disabled"
                    :value="item"
                    @click="selectRow(item)"
                  >
                </slot>
              </td>
              <td
                v-for="(field, fieldIndex) in fields"
                :key="fieldIndex"
              >
                <slot
                  :name="field.key"
                  :item="item"
                  :row-index="index"
                >
                  {{ getValue(item, field.key) }}
                </slot>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <slot name="footer" />
        </tfoot>
      </table>
    </div>
    <div
      v-else
      class="table-responsive"
    >
      <table
        id="vertical-table"
        class="table table-bordered"
      >
        <thead>
          <slot />
          <tr v-if="title">
            <th
              colspan="3"
              class="text-center"
            >
              {{ title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
          >
            <th class="align-middle col-6">
              {{ item.label }}
            </th>
            <td>
              <slot
                name="value"
                :item="item"
              >
                <span
                  v-if="sanitize"
                  v-html="$sanitize(item.value)"
                />
                <span v-else>
                  <slot
                    :name="item.key"
                    :item="item"
                  >
                    {{ item.key }} {{ item.value }}
                  </slot>
                </span>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';

export default {
  name: 'BaseTable',
  props: {
    title: {
      type: String,
      default: ''
    },
    vertical: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    sanitize: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sortedField: '',
      columnReverse: false,
      selectedRows: []
    };
  },
  computed: {
    colspan () {
      return this.fields.length;
    },
    selectAllRows () {
      return this.items.every(item => this.selectedRows.some(row => _.isEqual(item, row)));
    },
    isAbleToSelectAll () {
      if (this.multiselect) {
        return this.items.every(item => !item.disabled);
      }
      return false;
    }
  },
  methods: {
    handleAllSelectedRows (items) {
      const selectedRows = this.selectedRows.filter(row => !this.items.some(item => _.isEqual(item, row)));
      if (!this.selectAllRows) {
        if (items?.length) { // take the pass augument items
          selectedRows.push(...items);
        } else { // if not pass the augument items take all items
          selectedRows.push(...this.items);
        }
      }
      this.$emit('row-selected', this.selectedRows = selectedRows);
    },
    clearSelectedRows () {
      this.selectedRows = [];
      this.$emit('row-selected', this.selectedRows);
    },
    getValue (item, key) {
      return _.get(item, key?.split('.'));
    },
    sortColumn (field) {
      if (!field.sortable) return;
      this.columnReverse = !this.columnReverse;
      this.sortedField = field.key;
      const sortOrder = this.columnReverse ? 'ASC' : 'DESC';
      this.$emit('sort-column', { orderBy: this.sortedField, sortOrder });
    },
    rowClicked (item) {
      if (!this.clickable) return;
      this.$emit('click-row', item);
    },
    selectRow (item) {
      const indexToRemove = _.findIndex(this.selectedRows, function (obj) {
        return _.isEqual(obj, item);
      });
      if (indexToRemove >= 0) {
        this.selectedRows = _.without(this.selectedRows, this.selectedRows[indexToRemove]);
      } else {
        this.selectedRows.push(item);
      }
      this.$emit('row-selected', this.selectedRows);
    }
  }
};
</script>

<style scoped>
.form-check-input:disabled {
  pointer-events: visible;
  cursor: not-allowed;
}

th.sortable {
  position: relative;
  cursor: pointer;
}

th.sortable::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f0dc";
  position: absolute;
  right: 8px;
  color: #999;
}

th.sortable.asc::after {
  content: "\f0d8";
}

th.sortable.desc::after {
  content: "\f0d7";
}

th.sortable:hover::after {
  color: #333;
}

/* Overwrite bootstrap padding for first child */
.table-component td:first-child {
  padding-left: 0.5rem;
}
</style>
