export function InterestRateChartController ($scope, routeParams, resourceFactory, location, $uibModal) {
  $scope.edit = function (id) {
    location.path(`/editinterestratechart/${id}`);
  };
  $scope.productName = routeParams.productName;
  $scope.productId = routeParams.productId;
  $scope.productsLink = '';
  $scope.viewProductLink = '';
  $scope.chartSlabs = [];
  $scope.productType = routeParams.productType;
  if (routeParams.productType === 'fixeddepositproduct') {
    $scope.productsLink = 'fixeddepositproducts';
    $scope.viewProductLink = 'viewfixeddepositproduct';
  } else if (routeParams.productType === 'recurringdepositproduct') {
    $scope.productsLink = 'recurringdepositproducts';
    $scope.viewProductLink = 'viewrecurringdepositproduct';
  }

  resourceFactory.interestRateChartResource.getAllInterestRateCharts({ productId: routeParams.productId }, function (data) {
    $scope.charts = data;
    $scope.charts.forEach((chart) => {
      $scope.chartSlabs.push(chart.chartSlabs);
    });
  });
}

InterestRateChartController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$uibModal'];
