<template>
  <div>
    <button
      class="btn btn-primary"
      @click="onModalShow"
    >
      {{ $t('label.button.reviewUpdates') }}
    </button>
    <base-modal
      id="modal-review-updates"
      ref="reviewUpdateModal"
      modal-size="lg"
      hide-footer
      @hidden="clearData"
    >
      <template #header>
        {{ $t('label.heading.reviewUpdates') }}
      </template>
      <template #body>
        <div v-if="!isPendingReview">
          {{ $t('label.heading.thereAreNoPendingUpdates') }}
        </div>
        <div v-else>
          <div v-if="!confirmMessage">
            <data-grid
              :items="displayData"
              :fields="fields"
              hide-entries-per-page
            />
            <form-wrapper ref="formSubmit">
              <input-text
                v-model="notes"
                type="textarea"
                :label="$t('label.input.notes')"
                vertical
                required
              />

              <div class="d-flex justify-content-end pt-4">
                <button
                  class="btn btn-primary me-2"
                  type="submit"
                  :disabled="loading"
                  @click="confirming"
                >
                  {{ $t('label.button.accept') }}
                </button>
                <button
                  class="btn btn-danger"
                  :disabled="loading"
                  @click="reject"
                >
                  {{ $t('label.button.reject') }}
                </button>
              </div>
            </form-wrapper>
          </div>
          <div v-else>
            {{ confirmMessage }}
            <div class="d-flex justify-content-end pt-4">
              <button
                class="btn btn-light me-2"
                @click="confirmMessage = ''"
              >
                {{ $t('label.button.back') }}
              </button>
              <button
                class="btn btn-primary"
                :disabled="loading"
                @click="accept"
              >
                {{ $t('label.button.ok') }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import i18n from '@/plugins/i18n';
import { ref, computed } from 'vue';
import DataGrid from '@/components/shared/data-grid/index.vue';
import apiService from '@/services/apiService';
import { $notify } from '@/plugins/prototypes';
import { InputText, FormWrapper } from '@/components/shared/form-input/index';
import router from '@/router/index';
import { useSharedStore } from '@/stores/shared';
import { formatSeperatorCapitalizeName } from '@/helper/stringHelper';

const props = defineProps({
  riskProfile: {
    type: Array,
    default: () => []
  }
});
const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);

const reviewUpdateModal = ref();
const confirmMessage = ref('');
const notes = ref('');

const fields = ref([
  { key: 'parameter', label: i18n.t('label.head.parameter') },
  { key: 'subParameter', label: i18n.t('label.head.subParameter') },
  { key: 'old', label: i18n.t('label.head.old') },
  { key: 'new', label: i18n.t('label.head.new') },
  { key: 'source', label: i18n.t('label.head.source') }
]);
const { id } = router.currentRoute.params;
const formSubmit = ref();

const reviewLogs = ref({});
const isPendingReview = ref(false);

const onModalShow = async () => {
  reviewUpdateModal.value.show();
  getAudit();
};

const getAudit = async () => {
  const { data } = await apiService.audits.getAll({
    entityName: 'CLIENT_RISK_RATING_CALCULATION_RESULT',
    clientId: id
  });

  if (data.length && data[0]?.processingResult === 'awaiting.approval') {
    isPendingReview.value = true;
    const { data: audit } = await apiService.audits.get(data[0]?.id);
    reviewLogs.value = JSON.parse(audit.commandAsJson);
  } else {
    isPendingReview.value = false;
  }
};

const displayData = computed(() => {
  return reviewLogs.value?.parameters
    ? reviewLogs.value.parameters.flatMap(({ name: parameter, label, input }) => {
      const calculateResult = props.riskProfile.find(({ name }) => name === parameter);
      const usedValue = calculateResult?.inputUsed?.[0] || {};
      return Object.entries(input).map(([subParameter, newValue]) => ({
        parameter: formatSeperatorCapitalizeName(parameter, '_'),
        subParameter: formatSeperatorCapitalizeName(subParameter, '_'),
        old: usedValue[subParameter] || '',
        new: newValue || '',
        source: input.source || ''
      }));
    })
    : [];
});

const confirming = async () => {
  const isValid = await formSubmit.value.isFormValid;
  if (!isValid) return formSubmit.value.validate();
  confirmMessage.value = 'The acceptance of revised Risk Parameter inputs will cause the customer’s Risk score to be recalculated.Click ‘OK’ to confirm that you have carefully verified the updated data.Click ‘Back’ to cancel and return to the previous screen.';
};

const accept = async () => {
  try {
    await apiService.complianceReview.makerChecker({ ids: [id], notes }, { command: 'approve' });
    reviewUpdateModal.value.hide();
    $notify.success(i18n.t('messages.approveSuccess'));
    clearData();
  } catch (error) {
    $notify.error(error);
  }
};

const reject = async () => {
  const isValid = await formSubmit.value.isFormValid;
  if (!isValid) return formSubmit.value.validate();
  try {
    await apiService.complianceReview.makerChecker({ ids: [id], notes }, { command: 'reject' });
    reviewUpdateModal.value.hide();
    $notify.success(i18n.t('messages.rejectSuccess'));
    clearData();
  } catch (error) {
    $notify.error(error);
  }
};

const clearData = () => {
  confirmMessage.value = '';
  notes.value = '';
};
</script>
