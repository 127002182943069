<template>
  <BaseModal
    ref="modal"
    hide-footer
  >
    <template #header>
      {{ $t('label.heading.createNewDataTable') }}
    </template>
    <template #body>
      <FormWrapper @submit="submitHandler">
        <InputSelect
          v-model="formData.entity"
          :label="$t('label.input.entity')"
          :options="entityTables"
          required
        />
        <InputSelect
          v-model="formData.datatableName"
          :label="$t('label.input.dataTable')"
          :disabled="!formData.entity"
          :options="dataTables"
          required
        />
        <InputSelect
          v-model="formData.productId"
          :label="$t('label.input.loanProduct')"
          :disabled="!formData.entity"
          :options="products"
          text-field="name"
          value-field="id"
          required
        />
        <div class="text-end mt-3">
          <button
            class="btn btn-default me-2"
            @click.prevent="modal.hide()"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            class="btn btn-primary"
            type="submit"
          >
            {{ $t('label.button.submit') }}
          </button>
        </div>
      </FormWrapper>
    </template>
  </BaseModal>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import { FormWrapper, InputSelect } from '@/components/shared/form-input';
import { $notify } from '@/plugins/prototypes';
import apiService from '@/services/apiService';
import { onMounted, reactive, ref, watch } from 'vue';
import { get, set } from '@vueuse/core';
import i18n from '@/plugins/i18n';

const emits = defineEmits(['success']);

const modal = ref();
const entityTables = ref([]);
const dataTables = ref([]);
const products = ref([]);
const formData = reactive({
  entity: null,
  productId: null,
  datatableName: null
});

watch(() => formData.entity, async newEntityValue => {
  if (!newEntityValue) return;
  await getTemplate(newEntityValue);
});

onMounted(async () => {
  await getEntityTables();
});

async function getEntityTables () {
  try {
    const { data: { entities } } = await apiService.loanDataTable.template();
    set(entityTables, entities);
  } catch (error) {
    $notify.error(error);
  }
}

async function getTemplate (appTableName) {
  try {
    const { data: response } = await apiService.loanDataTable.template({ apptable: appTableName });
    set(dataTables, response.dataTables);
    set(products, response.products);
  } catch (error) {
    $notify.error(error);
  }
}

async function submitHandler () {
  try {
    await apiService.loanDataTable.create({ ...formData });
    $notify.success(i18n.t('messages.loanDatatableMapping.createdDataMappingSuccess'));
    get(modal).hide();
    formData.entity = null;
    formData.datatableName = null;
    formData.productId = null;
    emits('success');
  } catch (error) {
    $notify.error(error);
  }
}
</script>
