import _ from 'underscore';

export function EditFixedDepositProductController (scope, resourceFactory, location, routeParams, dateFilter, $uibModal) {
  scope.formData = {};
  scope.charges = [];
  scope.showOrHideValue = 'show';
  scope.configureFundOptions = [];
  scope.specificIncomeaccounts = [];
  scope.penaltySpecificIncomeaccounts = [];
  scope.configureFundOption = {};

  // interest rate chart details
  scope.chart = {};
  scope.restrictDate = new Date();
  scope.fromDate = {}; // required for date formatting
  scope.endDate = {};// required for date formatting
  scope.isPrimaryGroupingByAmount = false;

  resourceFactory.fixedDepositProductResource.get({ productId: routeParams.productId, template: 'true' }, function (data) {
    scope.product = data;
    scope.charges = data.charges;
    scope.assetAccountOptions = scope.product.accountingMappingOptions.assetAccountOptions || [];
    scope.liabilityAccountOptions = scope.product.accountingMappingOptions.liabilityAccountOptions || [];
    scope.incomeAccountOptions = scope.product.accountingMappingOptions.incomeAccountOptions || [];
    scope.expenseAccountOptions = scope.product.accountingMappingOptions.expenseAccountOptions || [];
    const preClosurePenalInterestOnTypeId = (_.isNull(data.preClosurePenalInterestOnType) || _.isUndefined(data.preClosurePenalInterestOnType)) ? '' : data.preClosurePenalInterestOnType.id;
    const minDepositTermTypeId = (_.isNull(data.minDepositTermType) || _.isUndefined(data.minDepositTermType)) ? '' : data.minDepositTermType.id;
    const maxDepositTermTypeId = (_.isNull(data.maxDepositTermType) || _.isUndefined(data.maxDepositTermType)) ? '' : data.maxDepositTermType.id;
    const inMultiplesOfDepositTermTypeId = (_.isNull(data.inMultiplesOfDepositTermType) || _.isUndefined(data.inMultiplesOfDepositTermType)) ? '' : data.inMultiplesOfDepositTermType.id;
    scope.formData = {
      name: data.name,
      shortName: data.shortName,
      description: data.description,
      currencyCode: data.currency.code,
      digitsAfterDecimal: data.currency.decimalPlaces,
      inMultiplesOf: data.currency.inMultiplesOf,
      minDepositAmount: data.minDepositAmount,
      depositAmount: data.depositAmount,
      maxDepositAmount: data.maxDepositAmount,
      nominalAnnualInterestRate: data.nominalAnnualInterestRate,
      minRequiredOpeningBalance: data.minRequiredOpeningBalance,
      lockinPeriodFrequency: data.lockinPeriodFrequency,
      interestCompoundingPeriodType: data.interestCompoundingPeriodType.id,
      interestPostingPeriodType: data.interestPostingPeriodType.id,
      interestCalculationType: data.interestCalculationType.id,
      interestCalculationDaysInYearType: data.interestCalculationDaysInYearType.id,
      accountingRule: data.accountingRule?.id?.toString(),
      //                    interestFreePeriodApplicable: data.interestFreePeriodApplicable,
      //                    interestFreeFromPeriod: data.interestFreeFromPeriod,
      //                    interestFreeToPeriod: data.interestFreeToPeriod,
      //                    interestFreePeriodFrequencyTypeId: interestFreePeriodFrequencyTypeId,
      preClosurePenalApplicable: data.preClosurePenalApplicable,
      preClosurePenalInterest: data.preClosurePenalInterest,
      preClosurePenalInterestOnTypeId,
      minDepositTerm: data.minDepositTerm,
      maxDepositTerm: data.maxDepositTerm,
      minDepositTermTypeId,
      maxDepositTermTypeId,
      inMultiplesOfDepositTerm: data.inMultiplesOfDepositTerm,
      inMultiplesOfDepositTermTypeId,
      withHoldTax: data.withHoldTax
    };

    if (data.withHoldTax) {
      scope.formData.taxGroupId = data.taxGroup.id;
    }
    scope.chart = scope.product.activeChart;
    if (data.clientLegalForm) {
      scope.formData.clientLegalForm = data.clientLegalForm.id;
    }

    _.each(scope.chart.chartSlabs, function (chartSlab) {
      chartSlab.amountRangeTo = chartSlab.amountRangeTo || '';
      chartSlab.amountRangeFrom = chartSlab.amountRangeFrom || '';
      chartSlab.annualInterestRate = chartSlab.annualInterestRate === null ? '' : chartSlab.annualInterestRate;
      chartSlab.fromPeriod = chartSlab.fromPeriod || '';
      chartSlab.toPeriod = chartSlab.toPeriod || '';
      chartSlab.periodType = chartSlab.periodType.id;
    });
    // format chart date values
    if (scope.chart.fromDate) {
      const fromDate = dateFilter(scope.chart.fromDate, scope.df);
      scope.fromDate.date = new Date(fromDate);
    }
    if (scope.chart.endDate) {
      const endDate = dateFilter(scope.chart.endDate, scope.df);
      scope.endDate.date = new Date(endDate);
    }
    scope.isPrimaryGroupingByAmount = scope.chart.isPrimaryGroupingByAmount;
    if (data.lockinPeriodFrequencyType) {
      scope.formData.lockinPeriodFrequencyType = data.lockinPeriodFrequencyType.id;
    }
    if (scope.formData.accountingRule === '2' || scope.formData.accountingRule === '3') {
      if (scope.formData.accounting === undefined) {
        scope.formData.accounting = {};
      }
      scope.formData.accounting.savingsReferenceAccountId = data.accountingMappings.savingsReferenceAccount.id;
      scope.formData.accounting.savingsControlAccountId = data.accountingMappings.savingsControlAccount.id;
      scope.formData.accounting.transfersInSuspenseAccountId = data.accountingMappings.transfersInSuspenseAccount.id;
      scope.formData.accounting.incomeFromFeeAccountId = data.accountingMappings.incomeFromFeeAccount.id;
      scope.formData.accounting.incomeFromPenaltyAccountId = data.accountingMappings.incomeFromPenaltyAccount.id;
      scope.formData.accounting.interestOnSavingsAccountId = data.accountingMappings.interestOnSavingsAccount.id;
      scope.formData.accounting.interestPayableLiabilityId = data.accountingMappings.interestPayableAccount.id;

      _.each(scope.product.paymentChannelToFundSourceMappings, function (fundSource) {
        scope.configureFundOptions.push({
          paymentTypeId: fundSource.paymentType.id,
          fundSourceAccountId: fundSource.fundSourceAccount.id,
          paymentTypeOptions: scope.product.paymentTypeOptions,
          assetAccountOptions: scope.assetAccountOptions
        });
      });

      _.each(scope.product.feeToIncomeAccountMappings, function (fees) {
        scope.specificIncomeaccounts.push({
          chargeId: fees.charge.id,
          incomeAccountId: fees.incomeAccount.id,
          chargeOptions: scope.product.chargeOptions,
          incomeAccountOptions: scope.incomeAccountOptions
        });
      });

      _.each(scope.product.penaltyToIncomeAccountMappings, function (penalty) {
        scope.penaltySpecificIncomeaccounts.push({
          chargeId: penalty.charge.id,
          incomeAccountId: penalty.incomeAccount.id,
          penaltyOptions: scope.product.penaltyOptions,
          incomeAccountOptions: scope.incomeAccountOptions
        });
      });
    }
  });

  // advanced accounting rule
  scope.showOrHide = function (showOrHideValue) {
    if (showOrHideValue === 'show') {
      scope.showOrHideValue = 'hide';
    }

    if (showOrHideValue === 'hide') {
      scope.showOrHideValue = 'show';
    }
  };

  scope.chargeSelected = function (chargeId) {
    if (chargeId) {
      resourceFactory.chargeResource.get({ chargeId, template: 'true' }, this.formData, function (data) {
        data.chargeId = data.id;
        scope.charges.push(data);
        // to charge select box empty
        scope.chargeId = '';
      });
    }
  };

  scope.deleteCharge = function (index) {
    scope.charges.splice(index, 1);
  };

  scope.addConfigureFundSource = function () {
    if (scope.product.paymentTypeOptions && scope.product.paymentTypeOptions.length > 0 &&
      scope.assetAccountOptions && scope.assetAccountOptions.length > 0) {
      scope.configureFundOptions.push({
        paymentTypeId: scope.product.paymentTypeOptions[0].id,
        fundSourceAccountId: scope.assetAccountOptions[0].id,
        paymentTypeOptions: scope.product.paymentTypeOptions,
        assetAccountOptions: scope.assetAccountOptions
      });
    }
  };

  scope.mapFees = function () {
    if (scope.product.chargeOptions && scope.product.chargeOptions.length > 0 && scope.incomeAccountOptions && scope.incomeAccountOptions.length > 0) {
      scope.specificIncomeaccounts.push({
        chargeId: scope.product.chargeOptions[0].id,
        incomeAccountId: scope.incomeAccountOptions[0].id,
        chargeOptions: scope.product.chargeOptions,
        incomeAccountOptions: scope.product.accountingMappingOptions.incomeAccountOptions
      });
    }
  };

  scope.mapPenalty = function () {
    if (scope.product.penaltyOptions && scope.product.penaltyOptions.length > 0 && scope.incomeAccountOptions && scope.incomeAccountOptions.length > 0) {
      scope.penaltySpecificIncomeaccounts.push({
        chargeId: scope.product.penaltyOptions[0].id,
        incomeAccountId: scope.incomeAccountOptions[0].id,
        penaltyOptions: scope.product.penaltyOptions,
        incomeAccountOptions: scope.incomeAccountOptions
      });
    }
  };

  scope.deleteFund = function (index) {
    scope.configureFundOptions.splice(index, 1);
  };

  scope.deleteFee = function (index) {
    scope.specificIncomeaccounts.splice(index, 1);
  };

  scope.deletePenalty = function (index) {
    scope.penaltySpecificIncomeaccounts.splice(index, 1);
  };

  scope.cancel = function () {
    location.path(`/viewfixeddepositproduct/${routeParams.productId}`);
  };

  scope.submit = function () {
    scope.paymentChannelToFundSourceMappings = [];
    scope.feeToIncomeAccountMappings = [];
    scope.penaltyToIncomeAccountMappings = [];
    scope.chargesSelected = [];

    let temp = '';

    // configure fund sources for payment channels
    for (const i in scope.configureFundOptions) {
      temp = {
        paymentTypeId: scope.configureFundOptions[i].paymentTypeId,
        fundSourceAccountId: scope.configureFundOptions[i].fundSourceAccountId
      };
      scope.paymentChannelToFundSourceMappings.push(temp);
    }

    // map fees to specific income accounts
    for (const i in scope.specificIncomeaccounts) {
      temp = {
        chargeId: scope.specificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.specificIncomeaccounts[i].incomeAccountId
      };
      scope.feeToIncomeAccountMappings.push(temp);
    }

    // map penalties to specific income accounts
    for (const i in scope.penaltySpecificIncomeaccounts) {
      temp = {
        chargeId: scope.penaltySpecificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.penaltySpecificIncomeaccounts[i].incomeAccountId
      };
      scope.penaltyToIncomeAccountMappings.push(temp);
    }

    for (const i in scope.charges) {
      temp = {
        id: scope.charges[i].id
      };
      scope.chargesSelected.push(temp);
    }
    if (this.formData.accounting === undefined) {
      this.formData.accounting = {};
    }
    this.formData.accounting.paymentChannelToFundSourceMappings = scope.paymentChannelToFundSourceMappings;
    this.formData.accounting.feeToIncomeAccountMappings = scope.feeToIncomeAccountMappings;
    this.formData.accounting.penaltyToIncomeAccountMappings = scope.penaltyToIncomeAccountMappings;
    this.formData.charges = scope.chargesSelected;
    this.formData.locale = scope.optlang.code;
    this.formData.charts = [];// declare charts array
    this.formData.charts.push(copyChartData(scope.chart));// add chart details
    temp = { lockinPeriodFrequency: this.formData.lockinPeriodFrequency };
    this.formData = removeEmptyValues(this.formData);
    this.formData.lockinPeriodFrequency = temp.lockinPeriodFrequency;
    resourceFactory.fixedDepositProductResource.update({ productId: routeParams.productId }, this.formData, function (data) {
      location.path(`/viewfixeddepositproduct/${data.resourceId}`);
    });
  };

  /**
             * Add a new row with default values for entering chart details
             */
  scope.addNewRow = function () {
    let fromPeriod = '';
    let amountRangeFrom = '';
    let periodType = '';
    let toPeriod = '';
    let amountRangeTo = '';
    if (_.isNull(scope.chart.chartSlabs) || _.isUndefined(scope.chart.chartSlabs)) {
      scope.chart.chartSlabs = [];
    } else {
      let lastChartSlab = {};
      if (scope.chart.chartSlabs.length > 0) {
        lastChartSlab = angular.copy(scope.chart.chartSlabs[scope.chart.chartSlabs.length - 1]);
      } else {
        lastChartSlab = null;
      }
      if (!(_.isNull(lastChartSlab) || _.isUndefined(lastChartSlab))) {
        if (scope.isPrimaryGroupingByAmount) {
          if ((_.isNull(lastChartSlab.toPeriod) || _.isUndefined(lastChartSlab.toPeriod) || lastChartSlab.toPeriod.length === 0)) {
            amountRangeFrom = _.isNull(lastChartSlab) ? '' : parseFloat(lastChartSlab.amountRangeTo) + 1;
            fromPeriod = (_.isNull(lastChartSlab.fromPeriod) || _.isUndefined(lastChartSlab.fromPeriod) || lastChartSlab.fromPeriod.length === 0) ? '' : 1;
          } else {
            amountRangeFrom = lastChartSlab.amountRangeFrom;
            amountRangeTo = lastChartSlab.amountRangeTo;
            fromPeriod = _.isNull(lastChartSlab) ? '' : parseInt(lastChartSlab.toPeriod, 10) + 1;
          }
        } else if ((_.isNull(lastChartSlab.amountRangeTo) || _.isUndefined(lastChartSlab.amountRangeTo) || lastChartSlab.amountRangeTo.length === 0)) {
          amountRangeFrom = (_.isNull(lastChartSlab.amountRangeFrom) || _.isUndefined(lastChartSlab.amountRangeFrom) || lastChartSlab.amountRangeFrom.length === 0) ? '' : 1;
          fromPeriod = _.isNull(lastChartSlab) ? '' : parseFloat(lastChartSlab.toPeriod) + 1;
        } else {
          fromPeriod = lastChartSlab.fromPeriod;
          toPeriod = lastChartSlab.toPeriod;
          amountRangeFrom = _.isNull(lastChartSlab) ? '' : parseInt(lastChartSlab.amountRangeTo, 10) + 1;
        }
        periodType = angular.copy(lastChartSlab.periodType);
      }
    }

    const chartSlab = {
      periodType,
      fromPeriod,
      amountRangeFrom
    };
    if (!_.isUndefined(toPeriod) && toPeriod.length > 0) {
      chartSlab.toPeriod = toPeriod;
    }
    if (!_.isUndefined(amountRangeTo) && amountRangeTo.length > 0) {
      chartSlab.amountRangeTo = amountRangeTo;
    }
    scope.chart.chartSlabs.push(chartSlab);
  };

  /**
             *  create new chart data object
             */

  const copyChartData = function () {
    const newChartData = {
      id: scope.chart.id,
      name: scope.chart.name,
      description: scope.chart.description,
      fromDate: dateFilter(scope.fromDate.date, scope.df),
      endDate: dateFilter(scope.endDate.date, scope.df),
      isPrimaryGroupingByAmount: scope.isPrimaryGroupingByAmount,
      dateFormat: scope.df,
      locale: scope.optlang.code,
      chartSlabs: angular.copy(copyChartSlabs(scope.chart.chartSlabs))
    };

    // remove empty values
    _.each(newChartData, function (v, k) {
      if (!v) { delete newChartData[k]; }
    });

    return newChartData;
  };

  /**
             *  copy all chart details to a new Array
             * @param chartSlabs
             * @returns {Array}
             */
  const copyChartSlabs = function (chartSlabs) {
    const detailsArray = [];
    _.each(chartSlabs, function (chartSlab) {
      const chartSlabData = copyChartSlab(chartSlab);
      detailsArray.push(chartSlabData);
    });
    return detailsArray;
  };

  /**
             * create new chart detail object data from chartSlab
             * @param chartSlab
             *
             */

  const copyChartSlab = function (chartSlab) {
    const newChartSlabData = {
      id: chartSlab.id,
      description: chartSlab.description,
      fromPeriod: chartSlab.fromPeriod,
      toPeriod: chartSlab.toPeriod,
      amountRangeFrom: chartSlab.amountRangeFrom,
      amountRangeTo: chartSlab.amountRangeTo,
      annualInterestRate: chartSlab.annualInterestRate,
      locale: scope.optlang.code,
      delete: chartSlab.delete
    };

    if (chartSlab.periodType !== undefined) {
      newChartSlabData.periodType = chartSlab.periodType;
    }

    // remove empty values
    _.each(newChartSlabData, function (v, k) {
      if (!v && v !== 0) { delete newChartSlabData[k]; }
    });

    return newChartSlabData;
  };

  const removeEmptyValues = function (objArray) {
    _.each(objArray, function (v, k) {
      // alert(k + ':' + v);
      if (_.isNull(v) || _.isUndefined(v) || v === '') {
        // alert('remove' + k + ':' + v);
        delete objArray[k];
      }
    });

    return objArray;
  };

  /**
             * Remove chart details row
             */
  scope.removeRow = function (index) {
    if (scope.chart.chartSlabs[index].id !== undefined) {
      scope.chart.chartSlabs[index].delete = true;
    } else {
      scope.chart.chartSlabs.splice(index, 1);
    }
  };
}

EditFixedDepositProductController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', 'dateFilter', '$uibModal'];
