<template>
  <div class="mb-2">
    <div class="row">
      <div>
        <div class="d-flex justify-content-between align-items-center mb-3 pb-2 border-bottom">
          <h3 class="mb-0">
            {{ $t('label.heading.addresses') }}
          </h3>
          <button
            v-has-permission="'CREATE_ADDRESS'"
            class="btn btn-outline-primary pull-right"
            @click="routeToAddAddress"
          >
            <i class="fa-solid fa-plus me-1" /> {{ $t('label.button.addAddress') }}
          </button>
        </div>
      </div>

      <address-details-table
        v-has-permission="'READ_ADDRESS'"
        :addresses="data.addresses"
        :client-id="clientId"
        @update="$emit('update')"
      />

      <div class="mt-4">
        <div class="d-flex justify-content-between align-items-center mb-3 pb-2 border-bottom">
          <h3 class="mb-0">
            {{ $t('label.heading.phoneNumbers') }}
          </h3>
          <button
            v-has-permission="'CREATE_CLIENT_MOBILE'"
            class="btn btn-outline-primary pull-right"
            @click="routeToAddPhoneNumber"
          >
            <i class="fa-solid fa-plus me-1" /> {{ $t('label.button.addPhoneNumber') }}
          </button>
        </div>
      </div>

      <phone-number-details-table
        :phone-numbers="data.phoneNumbers"
        :client-id="clientId"
        @update="$emit('update')"
      />
    </div>
  </div>
</template>

<script setup>
import AddressDetailsTable from '@/components/clients/communication-details/AddressDetailsTable.vue';
import PhoneNumberDetailsTable from '@/components/clients/communication-details/PhoneNumberDetailsTable.vue';
import router from '@/router/index';

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  clientId: {
    type: String,
    required: true
  }
});

defineEmits(['update']);

const { clientId } = props;

function routeToAddAddress () {
  router.push({ name: 'AddClientAddress', params: { clientId } });
}

function routeToAddPhoneNumber () {
  router.push({ name: 'AddPhoneNumber', params: { clientId } });
}
</script>
