import router from '@/router/index';
export function EditCreditProductController (scope, resourceFactory, location, routeParams) {
  scope.formData = {};
  scope.charges = [];
  scope.showOrHideValue = 'show';
  scope.configureFundOptions = [];
  scope.specificIncomeaccounts = [];
  scope.penaltySpecificIncomeaccounts = [];
  scope.configureFundOption = {};

  resourceFactory.creditProductResource.get({ creditProductId: routeParams.id, template: 'true' }, function (data) {
    scope.product = data;
    scope.charges = data.charges;
    scope.assetAccountOptions = scope.product.accountingMappingOptions.assetAccountOptions || [];
    scope.liabilityAccountOptions = scope.product.accountingMappingOptions.liabilityAccountOptions || [];
    scope.incomeAccountOptions = scope.product.accountingMappingOptions.incomeAccountOptions || [];
    scope.expenseAccountOptions = scope.product.accountingMappingOptions.expenseAccountOptions || [];
    scope.reserveProductOptions = scope.product.reserveProductOptions || [];
    scope.formData = {
      name: data.name,
      shortName: data.shortName,
      description: data.description,
      currencyCode: data.currency.code,
      digitsAfterDecimal: data.currency.decimalPlaces,
      inMultiplesOf: data.currency.inMultiplesOf,
      nominalAnnualInterestRate: data.nominalAnnualInterestRate,
      lockinPeriodFrequency: data.lockinPeriodFrequency,
      interestCompoundingPeriodType: data.interestCompoundingPeriodType.id,
      interestPostingPeriodType: data.interestPostingPeriodType.id,
      interestCalculationType: data.interestCalculationType.id,
      interestCalculationDaysInYearType: data.interestCalculationDaysInYearType.id,
      accountingRule: data.accountingRule.id,
      allowOverdraft: data.allowOverdraft,
      overdraftLimit: data.overdraftLimit,
      nominalAnnualInterestRateOverdraft: data.nominalAnnualInterestRateOverdraft,
      minOverdraftForInterestCalculation: data.minOverdraftForInterestCalculation,
      minBalanceForInterestCalculation: data.minBalanceForInterestCalculation,
      enforceMinRequiredBalance: data.enforceMinRequiredBalance,
      minRequiredBalance: data.minRequiredBalance,
      withHoldTax: data.withHoldTax,
      isDormancyTrackingActive: data.isDormancyTrackingActive,
      daysToInactive: data.daysToInactive,
      daysToDormancy: data.daysToDormancy,
      daysToEscheat: data.daysToEscheat,
      nominalCashAdvanceInterestRate: data.nominalCashAdvanceInterestRate,
      nominalAnnualPenaltyInterestRate: data.nominalAnnualPenaltyInterestRate,
      gracePeriod: data.gracePeriod,
      minimumPayCalculationRate: data.minimumPayCalculationRate,
      minimumPayFixedAmount: data.minimumPayFixedAmount,
      isSecuredCreditProduct: data.isSecuredCreditProduct,
      statementDay: data.statementDay,
      graceForArrearsAging: data.graceForArrearsAging
    };
    if (data.isSecuredCreditProduct) {
      scope.formData.reserveProductId = data.reserveProduct.id;
    }
    if (data.lockinPeriodFrequencyType) {
      scope.formData.lockinPeriodFrequencyType = data.lockinPeriodFrequencyType.id;
    }

    if (scope.formData.accountingRule === 2 || scope.formData.accountingRule === 3 || scope.formData.accountingRule === 4) {
      scope.formData.fundSourceAccountId = scope.product.accountingMappings.fundSourceAccount.id;
      scope.formData.creditPortfolioAccountId = scope.product.accountingMappings.creditPortfolioAccount.id;
      if (scope.formData.accountingRule === 3 || scope.formData.accountingRule === 4) {
        scope.formData.receivableInterestAccountId = scope.product.accountingMappings.receivableInterestAccount.id;
        scope.formData.receivableFeeAccountId = scope.product.accountingMappings.receivableFeeAccount.id;
        scope.formData.receivablePenaltyAccountId = scope.product.accountingMappings.receivablePenaltyAccount.id;
      }

      scope.formData.transfersInSuspenseAccountId = scope.product.accountingMappings.transfersInSuspenseAccount.id;
      scope.formData.disputesInSuspenseAccountId = scope.product.accountingMappings.disputesInSuspenseAccount?.id;
      scope.formData.interestOnIncomeAccountId = scope.product.accountingMappings.interestOnIncomeAccount.id;
      scope.formData.incomeFromFeeAccountId = scope.product.accountingMappings.incomeFromFeeAccount.id;
      scope.formData.incomeFromPenaltyAccountId = scope.product.accountingMappings.incomeFromPenaltyAccount.id;
      scope.formData.incomeFromRecoveryAccountId = scope.product.accountingMappings.incomeFromRecoveryAccount.id;
      scope.formData.writeOffAccountId = scope.product.accountingMappings.writeOffAccount.id;
      scope.formData.overpaymentLiabilityAccountId = scope.product.accountingMappings.overpaymentLiabilityAccount.id;

      scope.product.paymentChannelToFundSourceMappings.forEach(fundSource => {
        scope.configureFundOptions.push({
          paymentTypeId: fundSource.paymentType.id,
          fundSourceAccountId: fundSource.fundSourceAccount.id,
          paymentTypeOptions: scope.product.paymentTypeOptions,
          assetAccountOptions: scope.assetAccountOptions
        });
      });

      scope.product.feeToIncomeAccountMappings.forEach(fees => {
        scope.specificIncomeAccountMapping.push({
          chargeId: fees.charge.id,
          incomeAccountId: fees.incomeAccount.id
        });
      });

      scope.product.penaltyToIncomeAccountMappings.forEach(penalty => {
        scope.penaltySpecificIncomeaccounts.push({
          chargeId: penalty.charge.id,
          incomeAccountId: penalty.incomeAccount.id
        });
      });
    }
  });

  scope.isAccountingEnabled = function () {
    if (scope.formData.accountingRule === 2 || scope.formData.accountingRule === 3 || scope.formData.accountingRule === 4) {
      return true;
    }
    return false;
  };

  scope.isAccrualAccountingEnabled = function () {
    if (scope.formData.accountingRule === 3 || scope.formData.accountingRule === 4) {
      return true;
    }
    return false;
  };
  // advanced accounting rule
  scope.showOrHide = function (showOrHideValue) {
    if (showOrHideValue === 'show') {
      scope.showOrHideValue = 'hide';
    }

    if (showOrHideValue === 'hide') {
      scope.showOrHideValue = 'show';
    }
  };

  scope.chargeSelected = function (chargeId) {
    if (chargeId) {
      resourceFactory.chargeResource.get(
        { chargeId, template: 'true' },
        this.formData,
        function (data) {
          data.chargeId = data.id;
          data.isMandatory = false;
          scope.charges.push(data);
          // to charge select box empty
          scope.chargeId = '';
        }
      );
    }
  };

  scope.cancel = function () {
    router.push('/products/credit');
  };

  scope.deleteCharge = function (index) {
    scope.charges.splice(index, 1);
  };

  scope.addConfigureFundSource = function () {
    if (
      scope.product.paymentTypeOptions &&
            scope.product.paymentTypeOptions.length > 0 &&
            scope.assetAccountOptions &&
            scope.assetAccountOptions.length > 0
    ) {
      scope.configureFundOptions.push({
        paymentTypeId: scope.product.paymentTypeOptions[0].id,
        fundSourceAccountId: scope.assetAccountOptions[0].id,
        paymentTypeOptions: scope.product.paymentTypeOptions,
        assetAccountOptions: scope.assetAccountOptions
      });
    }
  };

  scope.mapFees = function () {
    if (
      scope.product.chargeOptions &&
            scope.product.chargeOptions.length > 0 &&
            scope.incomeAccountOptions &&
            scope.incomeAccountOptions.length > 0
    ) {
      scope.specificIncomeaccounts.push({
        chargeId: scope.product.chargeOptions[0].id,
        incomeAccountId: scope.incomeAccountOptions[0].id,
        chargeOptions: scope.product.chargeOptions,
        incomeAccountOptions: scope.product.accountingMappingOptions.incomeAccountOptions
      });
    }
  };

  scope.mapPenalty = function () {
    if (
      scope.product.penaltyOptions &&
            scope.product.penaltyOptions.length > 0 &&
            scope.incomeAccountOptions &&
            scope.incomeAccountOptions.length > 0
    ) {
      scope.penaltySpecificIncomeaccounts.push({
        chargeId: scope.product.penaltyOptions[0].id,
        incomeAccountId: scope.incomeAccountOptions[0].id,
        penaltyOptions: scope.product.penaltyOptions,
        incomeAccountOptions: scope.incomeAccountOptions
      });
    }
  };

  scope.deleteFund = function (index) {
    scope.configureFundOptions.splice(index, 1);
  };

  scope.deleteFee = function (index) {
    scope.specificIncomeaccounts.splice(index, 1);
  };

  scope.deletePenalty = function (index) {
    scope.penaltySpecificIncomeaccounts.splice(index, 1);
  };

  scope.cancel = function () {
    location.path(`/viewcreditproduct/${routeParams.id}`);
  };

  scope.submit = function () {
    scope.paymentChannelToFundSourceMappings = [];
    scope.feeToIncomeAccountMappings = [];
    scope.penaltyToIncomeAccountMappings = [];
    scope.chargesSelected = [];

    let temp = '';

    // configure fund sources for payment channels
    for (const i in scope.configureFundOptions) {
      temp = {
        paymentTypeId: scope.configureFundOptions[i].paymentTypeId,
        fundSourceAccountId: scope.configureFundOptions[i].fundSourceAccountId
      };
      scope.paymentChannelToFundSourceMappings.push(temp);
    }

    // map fees to specific income accounts
    for (const i in scope.specificIncomeaccounts) {
      temp = {
        chargeId: scope.specificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.specificIncomeaccounts[i].incomeAccountId
      };
      scope.feeToIncomeAccountMappings.push(temp);
    }

    // map penalties to specific income accounts
    for (const i in scope.penaltySpecificIncomeaccounts) {
      temp = {
        chargeId: scope.penaltySpecificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.penaltySpecificIncomeaccounts[i].incomeAccountId
      };
      scope.penaltyToIncomeAccountMappings.push(temp);
    }

    for (const i in scope.charges) {
      temp = {
        id: scope.charges[i].id,
        isMandatory: scope.charges[i].isMandatory
      };
      scope.chargesSelected.push(temp);
    }

    this.formData.paymentChannelToFundSourceMappings = scope.paymentChannelToFundSourceMappings;
    this.formData.feeToIncomeAccountMappings = scope.feeToIncomeAccountMappings;
    this.formData.penaltyToIncomeAccountMappings = scope.penaltyToIncomeAccountMappings;
    this.formData.charges = scope.chargesSelected;
    this.formData.locale = scope.optlang.code;

    resourceFactory.creditProductResource.update(
      { creditProductId: routeParams.id },
      this.formData,
      function (data) {
        location.path(`/viewcreditproduct/${data.resourceId}`);
      }
    );
  };
}

EditCreditProductController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
