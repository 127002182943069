import router from '@/router/index';
export function RunReportsController (scope, routeParams, resourceFactory, dateFilter, $sce) {
  scope.isCollapsed = false; // displays options div on startup
  scope.hideTable = true; // hides the results div on startup
  scope.hidePentahoReport = true; // hides the results div on startup
  scope.hideChart = true;
  scope.piechart = false;
  scope.barchart = false;
  scope.formData = {};
  scope.reportParams = [];
  scope.reportDateParams = [];
  scope.reqFields = [];
  scope.reportTextParams = [];
  scope.reportData = {};
  scope.reportData.columnHeaders = [];
  scope.reportData.data = [];
  scope.baseURL = '';
  scope.csvData = [];
  scope.row = [];
  scope.reportName = routeParams.name;
  scope.reportType = routeParams.type;
  scope.reportId = routeParams.reportId;
  scope.pentahoReportParameters = [];
  scope.type = 'pie';

  let colorArrayPie;

  scope.routeToReport = () => {
    const querySavedFromPrevious = { pageSize: routeParams.pageSize, filter: routeParams.filter, page: routeParams.page };
    router.push({ path: '/reports', query: querySavedFromPrevious });
  };

  scope.highlight = function (id) {
    const i = document.getElementById(id);
    if (i.className === 'selected-row') {
      i.className = 'text-pointer';
    } else {
      i.className = 'selected-row';
    }
  };
  if (scope.reportType === 'Pentaho') {
    scope.formData.outputType = 'HTML';
  }

  resourceFactory.runReportsResource.getReport(
    {
      reportSource: 'FullParameterList',
      parameterType: true,
      R_reportListing: `'${routeParams.name}'`
    },
    function (data) {
      for (const i in data.data) {
        const temp = {
          name: data.data[i].row[0],
          letiable: data.data[i].row[1],
          label: data.data[i].row[2],
          displayType: data.data[i].row[3],
          formatType: data.data[i].row[4],
          defaultVal: data.data[i].row[5],
          selectOne: data.data[i].row[6],
          selectAll: data.data[i].row[7],
          parentParameterName: data.data[i].row[8],
          inputName: `R_${data.data[i].row[1]}` // model name
        };
        scope.reqFields.push(temp);
        if (temp.displayType === 'select' && temp.parentParameterName === null) {
          intializeParams(temp, {});
        } else if (temp.displayType === 'date') {
          scope.reportDateParams.push(temp);
        } else if (temp.displayType === 'text') {
          scope.reportTextParams.push(temp);
        }
      }
    }
  );

  if (scope.reportType === 'Pentaho') {
    resourceFactory.reportsResource.get(
      { id: scope.reportId, fields: 'reportParameters' },
      function (data) {
        scope.pentahoReportParameters = data.reportParameters || [];
      }
    );
  }

  function getSuccuessFunction (paramData) {
    const successFunction = function (data) {
      const selectData = [];
      let isExistedRecord = false;
      for (const i in data.data) {
        selectData.push({ id: data.data[i].row[0], name: data.data[i].row[1] });
      }
      for (const j in scope.reportParams) {
        if (scope.reportParams[j].name === paramData.name) {
          scope.reportParams[j].selectOptions = selectData;
          isExistedRecord = true;
        }
      }
      if (!isExistedRecord) {
        if (paramData.selectAll === 'Y') {
          selectData.push({ id: '-1', name: 'All' });
        }
        paramData.selectOptions = selectData;
        scope.reportParams.push(paramData);
      }
    };
    return successFunction;
  }

  function intializeParams (paramData, params) {
    scope.errorStatus = undefined;
    scope.errorDetails = [];
    params.reportSource = paramData.name;
    params.parameterType = true;
    const successFunction = getSuccuessFunction(paramData);
    resourceFactory.runReportsResource.getReport(params, successFunction);
  }

  scope.getDependencies = function (paramData) {
    for (let i = 0; i < scope.reqFields.length; i++) {
      const temp = scope.reqFields[i];
      if (temp.parentParameterName === paramData.name) {
        if (temp.displayType === 'select') {
          const parentParamValue = this.formData[paramData.inputName];
          if (parentParamValue !== undefined) {
            const params = {};
            params[paramData.inputName] = 'parentParamValue';
            intializeParams(temp, params);
          }
        } else if (temp.displayType === 'date') {
          scope.reportDateParams.push(temp);
        }
      }
    }
  };

  scope.exportToCsv = function () {
    const processRow = function (row) {
      let finalVal = '';
      for (let j = 0; j < row.length; j++) {
        let innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) { result = `"${result}"`; }
        if (j > 0) { finalVal += ','; }
        finalVal += result;
      }
      return `${finalVal}\n`;
    };

    let csvFile = '';
    for (let i = 0; i < scope.csvData.length; i++) {
      csvFile += processRow(scope.csvData[i]);
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, 'download');
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'download');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  scope.checkStatus = function () {
    let collapsed = false;
    if (scope.isCollapsed) {
      collapsed = true;
    }
    return collapsed;
  };

  function invalidDate (checkDate) {
    const dateformat = /^\d{4}(-|\/|\.)\d{1,2}\1\d{1,2}$/;

    if (!dateformat.test(checkDate)) {
      return true;
    } else {
      const [year, month, day] = checkDate.split('-');
      const newDate = new Date(year, month - 1, day);
      return !(
        Number(day) === newDate.getDate() &&
        Number(month) === newDate.getMonth() + 1 &&
        Number(year) === newDate.getFullYear()
      );
    }
  }

  function removeErrors () {
    const $inputs = $(':input');
    $inputs.each(function () {
      $(this).removeClass('validationerror');
    });
  }

  function parameterValidationErrors () {
    let tmpDate;
    let errorObj;
    let paramDetails;
    scope.errorDetails = [];
    for (const i in scope.reqFields) {
      let selectedVal;
      paramDetails = scope.reqFields[i];
      switch (paramDetails.displayType) {
        case 'select':
          selectedVal = scope.formData[paramDetails.inputName];
          if (selectedVal === undefined || selectedVal === 0) {
            const fieldId = `#${paramDetails.inputName}`;
            $(fieldId).addClass('validationerror');
            const errorObj = {};
            errorObj.field = paramDetails.inputName;
            errorObj.code = 'error.message.report.parameter.required';
            errorObj.args = { params: [] };
            errorObj.args.params.push({ value: paramDetails.label });
            scope.errorDetails.push(errorObj);
          }
          break;
        case 'date':
          tmpDate = scope.formData[paramDetails.inputName];
          if (tmpDate === undefined || !(tmpDate > '')) {
            const fieldId = `#${paramDetails.inputName}`;
            $(fieldId).addClass('validationerror');
            const errorObj = {};
            errorObj.field = paramDetails.inputName;
            errorObj.code = 'error.message.report.parameter.required';
            errorObj.args = { params: [] };
            errorObj.args.params.push({ value: paramDetails.label });
            scope.errorDetails.push(errorObj);
          }
          if (tmpDate && invalidDate(tmpDate) === true) {
            const fieldId = `#${paramDetails.inputName}`;
            $(fieldId).addClass('validationerror');
            const errorObj = {};
            errorObj.field = paramDetails.inputName;
            errorObj.code = 'error.message.report.invalid.value.for.parameter';
            errorObj.args = { params: [] };
            errorObj.args.params.push({ value: paramDetails.label });
            scope.errorDetails.push(errorObj);
          }
          break;
        case 'text':
          selectedVal = scope.formData[paramDetails.inputName];
          if (selectedVal === undefined || selectedVal.trim() === '') {
            const fieldId = `#${paramDetails.inputName}`;
            $(fieldId).addClass('validationerror');
            const errorObj = {};
            errorObj.field = paramDetails.inputName;
            errorObj.code = 'error.message.report.parameter.required';
            errorObj.args = { params: [] };
            errorObj.args.params.push({ value: paramDetails.label });
            scope.errorDetails.push(errorObj);
          }
          break;
        default:
          errorObj = {};
          errorObj.field = paramDetails.inputName;
          errorObj.code = 'error.message.report.parameter.invalid';
          errorObj.args = { params: [] };
          errorObj.args.params.push({ value: paramDetails.label });
          scope.errorDetails.push(errorObj);
          break;
      }
    }
  }

  function buildReportParms () {
    const reqFields = scope.reqFields;
    const pentahoReportParameters = scope.pentahoReportParameters;
    const reportParams = {};
    for (const reqField of reqFields) {
      for (const pentahoReportParameter of pentahoReportParameters) {
        if (reqField.name === pentahoReportParameter.parameterName) {
          reportParams[`R_${pentahoReportParameter.reportParameterName}`] = scope.formData[reqField.inputName];
        }
      }
    }
    return reportParams;
  }

  scope.xFunction = function () {
    return function (d) {
      return d.key;
    };
  };
  scope.yFunction = function () {
    return function (d) {
      return d.values;
    };
  };
  scope.setTypePie = function () {
    if (scope.type === 'bar') {
      scope.type = 'pie';
    }
  };
  scope.setTypeBar = function () {
    if (scope.type === 'pie') {
      scope.type = 'bar';
    }
  };

  scope.colorFunctionPie = function () {
    return function (d, i) {
      return colorArrayPie[i];
    };
  };
  scope.isDecimal = function (index) {
    if (
      scope.reportData.columnHeaders &&
      scope.reportData.columnHeaders.length > 0
    ) {
      for (let i = 0; i < scope.reportData.columnHeaders.length; i++) {
        if (scope.reportData.columnHeaders[index].columnType === 'DECIMAL') {
          return true;
        }
      }
    }
    return false;
  };
  scope.runReport = function () {
    // clear the previous errors
    scope.errorDetails = [];
    removeErrors();

    // update date fields with proper dateformat
    for (const i in scope.reportDateParams) {
      if (scope.formData[scope.reportDateParams[i].inputName]) {
        scope.formData[scope.reportDateParams[i].inputName] = dateFilter(
          scope.formData[scope.reportDateParams[i].inputName],
          'yyyy-MM-dd'
        );
      }
    }

    // Custom validation for report parameters
    parameterValidationErrors();
    if (scope.errorDetails.length === 0) {
      scope.isCollapsed = true;
      switch (scope.reportType) {
        case 'Table':
        case 'SMS':
          scope.hideTable = false;
          scope.hidePentahoReport = true;
          scope.hideChart = true;
          scope.formData.reportSource = scope.reportName;
          resourceFactory.runReportsResource.getReport(scope.formData, function (data) {
            // clear the csvData array for each request
            scope.csvData = [];
            scope.row = [];
            scope.reportData.columnHeaders = data.columnHeaders;
            scope.reportData.data = data.data;
            for (const i in data.columnHeaders) {
              scope.row.push(data.columnHeaders[i].columnName);
            }
            scope.csvData.push(scope.row);
            for (const k in data.data) {
              scope.csvData.push(data.data[k].row);
            }
          }
          );
          break;

        case 'Pentaho':
          scope.hideTable = true;
          scope.hidePentahoReport = false;
          scope.hideChart = true;
          resourceFactory.pentahaReport.get(
            {
              reportSource: scope.reportName,
              locale: scope.optlang.code,
              dateFormat: scope.df,
              'output-type': scope.formData.outputType,
              ...buildReportParms()
            }, (data, headers) => {
              const contentType = headers('Content-Type');
              const file = new Blob([data.data], { type: contentType });
              const fileContent = URL.createObjectURL(file);
              // Pass the form data to the iframe as a data url.
              scope.baseURL = $sce.trustAsResourceUrl(fileContent);
            }
          );
          break;
        case 'Chart':
          scope.hideTable = true;
          scope.hidePentahoReport = true;
          scope.hideChart = false;
          scope.formData.reportSource = scope.reportName;
          resourceFactory.runReportsResource.getReport(
            scope.formData,
            function (data) {
              scope.reportData.columnHeaders = data.columnHeaders;
              scope.reportData.data = data.data;
              scope.chartData = [];
              scope.barData = [];
              const l = data.data.length;
              for (let i = 0; i < l; i++) {
                scope.row = {};
                scope.row.key = data.data[i].row[0];
                scope.row.values = data.data[i].row[1];
                scope.chartData.push(scope.row);
              }
              const x = {};
              x.key = 'summary';
              x.values = [];
              for (let m = 0; m < l; m++) {
                const inner = [data.data[m].row[0], data.data[m].row[1]];
                x.values.push(inner);
              }
              scope.barData.push(x);
            }
          );
          break;

        default: {
          const errorObj = {};
          errorObj.field = scope.reportType;
          errorObj.code = 'error.message.report.type.is.invalid';
          errorObj.args = { params: [] };
          errorObj.args.params.push({ value: scope.reportType });
          scope.errorDetails.push(errorObj);
        }
      }
    }
  };
}

RunReportsController.$inject = ['$scope', '$routeParams', 'ResourceFactory', 'dateFilter', '$sce'];
