<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.riskRating') }}
    </template>
    <template #actions>
      <button
        v-has-permission="'READ_CLIENT_RISK_RATING_CALCULATION_RESULT'"
        class="btn btn-primary"
        @click="riskRatingHistoryModal.show()"
      >
        {{ $t('label.button.history') }}
      </button>
    </template>
    <form-wrapper
      v-if="!isLoading"
      class="col-sm-6 col-md-4 mt-4"
      @submit="submit"
    >
      <input-select
        v-if="isEditing"
        v-model="formData.riskRating"
        :options="riskRatingItems"
        :label="$t('label.input.riskRating')"
        not-clearable
        value-field="id"
        vertical
      />
      <div
        v-else
        class="d-flex p-2 mb-3 fw-medium rounded-2 risk-rating-container"
        :class="color"
      >
        <div
          class="rounded-1 me-2 risk-rating-icon"
          :class="color"
        />
        <div class="flex-grow-1">
          {{ selectedRiskRating }}
        </div>
        <div
          v-if="isEditable"
          v-has-permission="'UPDATE_CLIENT_RISK_RATING'"
          class="risk-rating-button text-primary"
          @click="edit"
        >
          <i class="fa-regular fa-pen-to-square" /> {{ $t('label.button.edit') }}
        </div>
      </div>
      <input-date-picker
        v-model="formData.dueDiligenceDate"
        format="YYYY-MM-DD"
        :label="$t('label.input.riseRatingDate')"
        vertical
        :display-mode="!isEditing"
        zero-padding
      />
      <div
        v-if="isEditing"
        class="offset-md-4 mt-3 d-flex"
      >
        <button
          class="btn btn-default mx-1"
          @click.prevent="cancel"
        >
          {{ $t('label.button.cancel') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary d-inline-flex align-items-center justify-content-center mx-1"
        >
          <span
            v-if="isSubmitting"
            class="spinner-border spinner-border-sm me-2"
            aria-hidden="true"
          />
          <span>{{ $t('label.button.submit') }}</span>
        </button>
      </div>
    </form-wrapper>
    <div
      v-else
      class="col-sm-5 mt-4"
    >
      {{ $t('label.loading') }}...
    </div>
    <base-modal
      ref="riskRatingHistoryModal"
      modal-size="xl"
      hide-footer
      show-button-close
      :options="{ keyboard: false, backdrop: 'static' }"
    >
      <template #header>
        <h4 class="modal-title">
          {{ $t('label.heading.riskRatingHistory') }}
        </h4>
      </template>
      <template #body>
        <base-table
          :items="riskRatingHistory"
          :fields="fields"
        >
          <template #riskRatingDetails="{ item }">
            <button
              class="btn btn-link p-0"
              @click="onOpenRiskRatingDetailsModal(item.riskRatingDetails)"
            >
              Details
            </button>
          </template>
        </base-table>
      </template>
    </base-modal>
    <RiskRatingDetails
      ref="riskRatingDetailsModal"
      :items="riskRatingDetailsItems"
    />
  </main-content-layout>
</template>
<script setup>
import i18n from '@/plugins/i18n';
import apiService from '@/services/apiService';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import BaseModal from '@/components/shared/BaseModal.vue';
import BaseTable from '@/components/shared/data-grid/BaseTable.vue';
import RiskRatingDetails from '@/components/compliance-review/modals/RiskRatingDetails.vue';
import { set, get } from '@vueuse/core';
import { dateStringToFormat } from '@/helper/dateHelper';
import { ref, onMounted, reactive, computed, watch } from 'vue';
import { InputSelect, InputDatePicker, FormWrapper } from '@/components/shared/form-input';
import { getAngularRootScope } from '@/helper/angularRootScope';
import { $notify } from '@/plugins/prototypes';
import { formatSeperatorCapitalizeName } from '@/helper/stringHelper';

const riskRatingItems = ref([]);
const initialFormData = ref({});
const riskRatingDetailsModal = ref(null);
const riskRatingHistory = ref([]);
const riskRatingDetailsItems = ref([]);
const isEditing = ref(false);
const isLoading = ref(false);
const isSubmitting = ref(false);
const isRiskRatingDetailsModalOpen = ref(false);
const riskRatingHistoryModal = ref(null);
const fields = ref([
  { key: 'timestamp', label: i18n.t('label.timeStamp') },
  { key: 'riskLevel', label: i18n.t('label.riskLevel') },
  { key: 'riskScore', label: i18n.t('label.riskScore') },
  { key: 'isPep', label: i18n.t('label.isPep') },
  { key: 'riskRatingDetails', label: i18n.t('label.riskRatingDetails') },
  { key: 'updatedBy', label: i18n.t('label.updatedBy') },
  { key: 'approvedBy', label: i18n.t('label.approvedBy') }
]);
const isEditable = computed(() => !process.env.VUE_APP_HIDE_RISK_RATING_EDIT);

const formData = reactive({
  riskRating: null,
  dueDiligenceDate: null
});

const selectedRiskRating = computed(() => {
  const selectedItem = get(riskRatingItems).find(item => item.id === formData.riskRating);
  return selectedItem?.name;
});

watch(() => riskRatingDetailsModal.value?.$children[0].visible, (newValue) => {
  if (!get(isRiskRatingDetailsModalOpen)) return;
  if (newValue) return get(riskRatingHistoryModal).hide();
  else {
    get(riskRatingHistoryModal).show();
    set(isRiskRatingDetailsModalOpen, newValue);
  }
});

const color = computed(() => {
  const rating = selectedRiskRating.value?.toLowerCase();
  let colorRating = 'NONE';
  if (rating?.includes('high')) {
    colorRating = 'HIGH';
  } else if (rating?.includes('medium')) {
    colorRating = 'MEDIUM';
  } else if (rating?.includes('low')) {
    colorRating = 'LOW';
  }
  return colorRating;
});

const props = defineProps({
  clientId: {
    type: String,
    required: true
  }
});

onMounted(async () => getRiskRatings());

async function getRiskRatings () {
  set(isLoading, true);
  try {
    const { data: { riskRatings } } = await apiService.riskRating.template(props.clientId);
    const { data: { clientHistory } } = await apiService.riskRating.getHistory(props.clientId);
    const { data: { dueDiligenceDate, riskRating } } = await apiService.riskRating.get(props.clientId);

    set(riskRatingItems, riskRatings);
    set(riskRatingHistory, clientHistory.map(({ oldValue, updatedAt, updatedBy, newValue }) => {
      const value = Object.keys(oldValue)?.length ? oldValue : newValue;
      return {
        timestamp: updatedAt,
        riskLevel: value?.final_score?.level,
        riskScore: value?.final_score?.score,
        updatedBy,
        isPep: ['has_profile', 'pep', 'tbr'].includes(value?.pep?.inputUsed[0]?.pep_flag?.toLowerCase()) ? 'YES' : 'NO',
        approvedBy: updatedBy,
        riskRatingDetails: value
      };
    }));
    formData.riskRating = riskRating.id;
    formData.dueDiligenceDate = dateStringToFormat(dueDiligenceDate, 'YYYY-MM-DD');
  } catch (error) {
    $notify.error(error);
  } finally {
    set(isLoading, false);
  }
}

const parseInputUsed = (key, value) => {
  if (key === 'final_score') return '';
  if (key === 'pep') return value.inputUsed[0].pep_flag;
  return value.inputUsed[0].input;
};

const onOpenRiskRatingDetailsModal = (riskDetails) => {
  set(riskRatingDetailsItems, parseRiskRatingDetails(riskDetails));
  get(riskRatingDetailsModal).$children[0].show();
  set(isRiskRatingDetailsModalOpen, true);
};

const parseRiskRatingDetails = (riskDetails) => {
  return Object.entries(riskDetails).map(([key, value]) => {
    return {
      riskParameters: formatSeperatorCapitalizeName(key, '_'),
      riskScore: value.score,
      riskLevel: value.level,
      inputs: parseInputUsed(key, value)
    };
  }).sort((a, b) => {
    if (a.riskParameters === 'Final Score') return 1;
    if (b.riskParameters === 'Final Score') return -1;
    return a.riskParameters.localeCompare(b.riskParameters);
  });
};

function edit () {
  Object.assign(get(initialFormData), { ...formData });
  set(isEditing, true);
}

function cancel () {
  Object.assign(formData, { ...get(initialFormData) });
  set(isEditing, false);
}

async function submit () {
  set(isSubmitting, true);
  try {
    await apiService.riskRating.update(props.clientId, formData);
    set(isEditing, false);
    getRiskRatings();
  } catch (error) {
    const $rootScope = getAngularRootScope();
    $rootScope.setApiValidationError(error);
  } finally {
    set(isSubmitting, false);
  }
}
</script>

<style lang="scss" scoped>
$high-color: #EC6A5E;
$medium-color: #F4BE4F;
$low-color: #61C554;

div.risk-rating-container {
  border-style: solid;

  &.HIGH {
    border-color: $high-color;
    background-color: rgba($color: $high-color, $alpha: 0.1);
  }

  &.MEDIUM {
    border-color: $medium-color;
    background-color: rgba($color: $medium-color, $alpha: 0.1);
  }

  &.LOW {
    border-color: $low-color;
    background-color: rgba($color: $low-color, $alpha: 0.1);
  }
  &.NONE {
    border-color: #999;
    background-color: rgba($color: #999, $alpha: 0.1);
  }

  .risk-rating-icon {
    width: 1.2rem;
    height: 1.2rem;

    &.HIGH {
      background-color: $high-color;
    }

    &.MEDIUM {
      background-color: $medium-color;
    }

    &.LOW {
      background-color: $low-color;
    }

    &.NONE {
      background-color: #999;
    }
  }

  .risk-rating-button {
    all: unset;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
