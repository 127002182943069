export function CardProductController ($scope, resourceFactory, location) {
  $scope.searchText = '';
  $scope.cardProducts = [];
  $scope.cardProductsPerPage = 10;
  $scope.totalCardProducts = 0;
  $scope.routeTo = function (id) {
    location.path(`/viewcardproduct/${id}`);
  };

  $scope.getCardProducts = (pageNumber = 1) => {
    resourceFactory.cardProductsResource.get({
      offset: ((pageNumber - 1) * $scope.cardProductsPerPage),
      limit: $scope.cardProductsPerPage,
      name: $scope.searchText
    }, (cardProduct) => {
      $scope.totalCardProducts = cardProduct.totalFilteredRecords;
      $scope.cardproducts = cardProduct.pageItems;
    });
  };

  $scope.getCardProducts();

  const legalFormNames = {
    1: 'PERSON',
    2: 'ENTITY'
  };
  $scope.getLegalFormName = id => legalFormNames[id] || '';
}

CardProductController.$inject = ['$scope', 'ResourceFactory', '$location'];
