export function ViewFixedDepositProductController (scope, routeParams, location, anchorScroll, resourceFactory, $uibModal) {
  resourceFactory.fixedDepositProductResource.get({ productId: routeParams.productId, template: 'true' }, function (data) {
    scope.depositproduct = data;
    scope.charts = scope.depositproduct.interestRateCharts;
    scope.chartSlabs = [];
    scope.charts.forEach((chart) => {
      scope.chartSlabs.push(chart.chartSlabs);
    });
    scope.hasAccounting = data.accountingRule.id > 1;
  });

  scope.scrollto = function (link) {
    location.hash(link);
    anchorScroll();
  };
}

ViewFixedDepositProductController.$inject = ['$scope', '$routeParams', '$location', '$anchorScroll', 'ResourceFactory', '$uibModal'];
