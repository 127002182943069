<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.addOrEditPhoneNumber', { action: get(isEditing) ? 'Edit' : 'Add'}) }}
    </template>
    <div class="col-lg-4">
      <form-wrapper @submit="submit">
        <input-select
          v-model="formData.typeId"
          :label="$t('label.input.phoneNumberType')"
          :options="phoneNumberTypes"
          text-field="name"
          value-field="id"
          required
          vertical
          not-clearable
        />
        <input-text
          id="mobile-country-code"
          v-model="formData.mobileCountryCode"
          :label="$t('label.input.countryCode')"
          :rules="[countryCodeRules]"
          :placeholder="digitRegexToMask(mobileFormat.countryCode)"
          :mask="digitRegexToMask(mobileFormat.countryCode)"
          vertical
          required
        />

        <input-text
          id="mobile-number"
          v-model="formData.mobileNo"
          :label="$t('label.input.phoneNumber')"
          :rules="[mobileNumberRules]"
          :placeholder="digitRegexToMask(mobileFormat.number)"
          :mask="digitRegexToMask(mobileFormat.number)"
          vertical
          required
        />
        <div class="d-flex justify-content-center gap-2">
          <button
            type="reset"
            class="btn btn-outline-secondary"
            @click="back"
          >
            {{ $t('label.button.cancel') }}
          </button>
          <button
            v-has-permission="get(isEditing) ? 'UPDATE_CLIENT_MOBILE' : 'CREATE_CLIENT_MOBILE'"
            class="btn btn-primary"
            type="submit"
          >
            {{ $t('label.button.save') }}
          </button>
        </div>
      </form-wrapper>
    </div>
  </main-content-layout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import {
  FormWrapper,
  InputSelect,
  InputText
} from '@/components/shared/form-input/index';
import router from '@/router/index';
import apiService from '@/services/apiService';
import { computed, onMounted, reactive, ref } from 'vue';
import { get, set } from '@vueuse/core';
import { $notify } from '@/plugins/prototypes';
import i18n from '@/plugins/i18n';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { regexValidate } from '@/components/shared/form-input/rules';
import { digitRegexToMask } from '@/utils/common';

const breadcrumbsStore = useBreadcrumbsStore();
const { phoneNumberId, clientId } = router.currentRoute.params;
const formData = ref({ typeId: null, mobileCountryCode: null, mobileNo: null });
const phoneNumberTypes = ref([]);
const mobileFormat = reactive({
  countryCode: '',
  number: ''
});
const isEditing = computed(() => !!phoneNumberId);

onMounted(() => {
  try {
    setBreadcrumbs();
    getPhoneNumberTypes();
    getTemplate();
    if (phoneNumberId) {
      getPhoneNumberData();
    }
  } catch (error) {
    $notify.error(error);
  }
});

async function submit () {
  try {
    if (!phoneNumberId) await apiService.clientPhoneNumber.save(clientId, get(formData));
    else {
      await apiService.clientPhoneNumber.put(clientId, phoneNumberId, get(formData));
    }
    back();
    $notify.success(i18n.t(get(isEditing) ? 'messages.phoneNumberUpdated' : 'messages.newPhoneNumberAdded'));
  } catch (error) {
    $notify.error(error);
  }
}

async function getTemplate () {
  const { data: { mobileNumberFormat } } = await apiService.clients.template();

  const findRegex = (regexName) => mobileNumberFormat.find(({ name }) => name === regexName)?.value;
  mobileFormat.number = findRegex('mobile-number-regex');
  mobileFormat.countryCode = findRegex('mobile-country-code-regex');
}

const mobileNumberRules = computed(() => {
  if (!formData.value.mobileNo) return () => true;
  return value => regexValidate(value, mobileFormat.number, i18n.t('messages.rules.invalidPhoneNumber'));
});

const countryCodeRules = computed(() => {
  if (!formData.value.mobileCountryCode) return () => true;
  return value => regexValidate(value, mobileFormat.countryCode, i18n.t('messages.rules.invalidCountryCode'));
});

async function getPhoneNumberData () {
  const { data } = await apiService.clientPhoneNumber.get(clientId, phoneNumberId);

  data.typeId = data.type?.id;
  delete data.type;

  set(formData, data);
}

async function getPhoneNumberTypes () {
  const { data } = await apiService.codeValueResource.getCodeValueByName('Phone Number Type');
  set(phoneNumberTypes, data);
}

async function setBreadcrumbs () {
  const { data: { displayName } } = await apiService.clients.get(clientId);

  const breadcrumbs = [
    { text: i18n.t('label.anchor.clients'), href: '#/clients' },
    { text: displayName, href: `#/viewclient/${clientId}?tab=address` },
    { text: i18n.t('label.anchor.addOrEditPhoneNumber', { action: get(isEditing) ? 'Edit' : 'Add' }) }
  ];

  breadcrumbsStore.setBreadcrumbs(breadcrumbs);
}

function back () {
  router.push({ path: `/viewclient/${clientId}?tab=address` });
}
</script>
