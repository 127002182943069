import i18n from '@/plugins/i18n';

export const appDataTableOptions = [
  { name: i18n.t('label.anchor.client'), value: 'm_client' },
  { name: i18n.t('label.anchor.loan'), value: 'm_loan' },
  { name: i18n.t('label.anchor.office'), value: 'm_office' },
  { name: i18n.t('label.anchor.savingsAccount'), value: 'm_savings_account' },
  { name: i18n.t('label.anchor.creditAccount'), value: 'm_credit_account' }
];

export const columnTypeOptions = [
  { name: i18n.t('label.anchor.string'), value: 'string' },
  { name: i18n.t('label.anchor.number'), value: 'number' },
  { name: i18n.t('label.anchor.decimal'), value: 'decimal' },
  { name: i18n.t('label.anchor.boolean'), value: 'boolean' },
  { name: i18n.t('label.anchor.date'), value: 'date' },
  { name: i18n.t('label.anchor.datetime'), value: 'datetime' },
  { name: i18n.t('label.anchor.text'), value: 'text' },
  { name: i18n.t('label.anchor.dropdown'), value: 'dropdown' },
  { name: i18n.t('label.anchor.fileupload'), value: 'fileupload' },
  { name: i18n.t('label.anchor.multiselect'), value: 'multiselect' }
];

export const clientLegalFormOptions = [
  { id: 1, name: 'PERSON' },
  { id: 2, name: 'ENTITY' }
];

export const formatName = (value, object, key, prefix = '') => {
  object[key] = value ? `${prefix}${value.replace(/\s/g, '_').toLowerCase()}` : '';
};

export const typeLookUp = (type) => {
  if (type === 'character varying') return 'string';
  else if (type === 'numeric') return 'decimal';
  else if (type === 'timestamp without time zone') return 'datetime';
  return type;
};

export const unmaskedSuffixSupportedTypes = ['string', 'number', 'decimal'];
export const codeSupportedTypes = ['dropdown', 'fileupload', 'multiselect'];
export const regexSupportedTypes = ['string', 'text'];
export const sectionFieldsData = {
  name: '',
  title: '',
  description: '',
  parentNameAttribute: '',
  columns: [
    {
      title: '',
      name: '',
      description: '',
      position: '',
      mandatory: false,
      unmaskedSuffixLength: '',
      parentNameAttribute: '',
      regex: '',
      type: '',
      length: '',
      code: ''
    }
  ]
};
